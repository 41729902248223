export const CRM_ACCOUNTING_REPORT = `
  mutation(
    $accountingType: CrmAccountingReportType!
    $fromDate: DateString!
    $toDate: DateString!
  ) {
    crmAccountingReport(
      accountingType: $accountingType
      fromDate: $fromDate
      toDate: $toDate
    ) {
      files {
        fileName
        downloadUrl
      }
    }
  }
`;

export const CRM_GENERAL_REPORT = `
  mutation(
    $generalType: CrmGeneralReportType!
    $fromDate: DateString!
    $toDate: DateString!
  ) {
    crmGeneralReport(
      generalType: $generalType
      fromDate: $fromDate
      toDate: $toDate
    ) {
      files {
        fileName
        downloadUrl
      }
    }
  }
`;
