import { apolloClient } from "@/plugins/apollo";
import { 
    CREATE_USER_GUIDE_USER_MANUAL,
    USER_GUIDE_LIST_USER_MANUAL,
    DELETE_USER_GUIDE
} from "@/api/graphql/dashboard/webPageManage/webDocument/userManual/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async createUserGuideUserManual({ commit, state, dispatch }, { data }) {
        const finishData = {
            year: state.yearUserManual,
            url: state.fileUrlUserManual,
            ...data
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${CREATE_USER_GUIDE_USER_MANUAL}`,
                    variables: finishData
                }).then(data => {
                    dispatch('getUserGuideListUserManual')
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CREATE_USER_GUIDE_USER_MANUAL, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async getUserGuideListUserManual({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${USER_GUIDE_LIST_USER_MANUAL}`,
                    fetchPolicy: 'network-only'
                }).then(data => {
                    commit('setUserGuideListUserManual',data.data.userGuideList)
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, USER_GUIDE_LIST_USER_MANUAL, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async deleteUserGuide({ commit, state, dispatch },id) {
        const finishData = {
            id:parseInt(id)
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${DELETE_USER_GUIDE}`,
                    variables: finishData
                }).then(data => {
                    commit('setAlertSuccess', '削除しました。')
                    dispatch('getUserGuideListUserManual')
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, DELETE_USER_GUIDE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}

