export const CLIENT_SEARCH_PAGINATED_RESPONSE = `
query($filter: ClientFilterSearchInput!,$pagination: PaginationInput!, $orderBy: [OrderByInput!]) {
    searchClientList(
        filter: $filter,
        pagination: $pagination,
        orderBy: $orderBy
        useNewMainContact: true
    ) {
      items{
        contracts {
          id
        }
        id 
        type
        isCompany 
        name 
        birthday 
        purchaseRate {name} 
        doNotContact 
        memberId 
        companyPicName
        companyPicTitle
        companyPicDepartment
        membershipCancellationDate 
        pic {
          id
          name
        } 
        mainContact {
          labelName 
          postalCode 
          prefecture 
          locality 
          address1 
          address2
          tel 
        }
      }
      total 
      hasMore 
    }
  }
`;

export const ENUM_PURCHASERATE_LIST = `
query {
  enumPurchaseRateList {
    id
    name
  }
}`;

export const CAMPAIGN_LIST_APPLICANT = `
query {
  enumCampaignMediaTypeList {
      id
      name
  }
}`;

export const CLIENT_ACTION_METHOD_LIST = `
query {
  enumClientActionMethodList {
    id name
  }
}`

export const BOOKING_TYPES_LIST_APPLICANT = `
query {
  enumProductTypeList {
    id
    code
  }
}`;

export const ENUM_CAMPAIGN_GROUP_LIST = `
query {
  enumCampaignGroupList {
      name
  }
}`;

export const ENUM_PREFECTURE_LIST = `
query {
  enumPrefectureList {name}
}`;

export const OTHER_PUBLIC_DOC_TYPE_LIST = `
query {
  otherPublicDocTypeList{
   id
   name
   __typename
 }
}
`