import actions from './actions';

const ticketsBuy = {
    state: {
        ticketsBuy: {
            clientId: null,
            productId: null,
            quantity: '',
            contractId: null
        },
        pointExchangeProductList: []
    },
    getters: {
        getProductId(state) {
            return state.ticketsBuy.productId;
        },
        getPointExchangeProductList(state) {
            return state.pointExchangeProductList;
        },
    },
    mutations: {
        setTicketsBuy(state, payload) {
            state.ticketsBuy = payload.ticketsBuy
        },
        setProductId(state, payload) {
            state.ticketsBuy.productId = payload.ticketsBuy.productId
        },
        setPointExchangeProductList(state, payload) {
            state.pointExchangeProductList = payload
        },

    },
    actions,
};

export default ticketsBuy;
