import moment from 'moment';
import { getDateTime, getAgeFromDate as getAgeFromDateFunc } from './dateUtil';

export function currency(value, symbol = '¥') {
  let final = Math.round(value * 100) / 100;
  final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return `${symbol} ${final}`;
}
export function currency2(value, symbol = '¥') {
  let final = Math.round(value * 100) / 100;
  final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${symbol}${final}`;
}
export function postalCode(code) {
  if (code) {
    return `〒${code.substring(0, 3)}-${code.substring(3)}`;
  }
  return '';
}
export function filename(url) {
  if (!url) return '';
  let filename = url.split('/').pop();
  filename = filename.split('?')[0];
  filename = decodeURIComponent(filename)

  return filename;
}

export function formatDate(date) {
  if (!date) return '';
  return moment(date).format('YYYY/MM/DD');
};

export function formatDateISO(date) {
  if (!date) return '';
  return moment(date).format('YYYY-MM-DD');
};

export function formatTime(timeString) {
  if (!timeString) return '';
  return timeString.toString().substr(0, 5);
}

export function formatMonthDate(date) {
  if (!date) return '';
  return moment(date).format('MM/DD');
};

export function formatYearMonth(date) {
  if (!date) return '';
  return moment(date).format('YYYY/MM');
};

export function formatDateTime(date, options) {
  if (!date) return '';
  return getDateTime(date, options);
}

export function formatDateTimeISONoSecond(date) {
  if (!date) return '';
  return getDateTime(date, { noSecond: true, delimeter: '-' });
}

export function toThousands (num) {
  if (num === null) return ''
  num = Number(num)
  if (isNaN(num)) return ''
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getAgeFromDate = getAgeFromDateFunc

export function ellipsis (value, length = 20) {
  value = `${value || ''}`
  if (value.length > length) {
    return value.slice(0, length) + '...';
  } else {
    return value;
  }
}

export function stayDays (days) {
  return `${days ?? '-'}泊`
}

export function sex (sex) {
  switch (sex) {
    case 'Male': return '男性'
    case 'Female': return '女性'
    case 'Other': return 'その他'
    default: return sex
  }
}

export function tvpBulkProcessType(type) {
  switch(type) {
    case 'Grant': return '付与'
    case 'Revoke': return '取消'
    default: return 'その他'
  }
}
