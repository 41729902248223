import { apolloClient } from '@/plugins/apollo';
import {
  BOOKING_TICKET_TYPE_LIST,
  BOOKING_TICKET_TYPE,
  DISABLE_BOOKING_TICKET,
} from '@/api/graphql/bookingTicketType';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async fetchDataBookingTicketTypeList({ commit, state }, obj) {
    const finishData = {
      hasIntendedClient: true,
      ticketNo: obj ? obj.ticketNo : null,
      isAvailable: obj ? obj.isAvailable : null,
      take: state.take,
      skip: state.skip,
      orderBy: obj.orderBy
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_TICKET_TYPE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit(
            'setBookingTicketTypeList',
            data.data.bookingTicketTypeList.items,
          );
          commit('setTotalBookingTicketTypeList', data.data.bookingTicketTypeList.total)
          commit('setLoadingOverlayHide', { root: true })
          resolve(true);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_TICKET_TYPE_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async fetchBookingTicketType({ commit }, id) {
    const finishData = {
      id,
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_TICKET_TYPE}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          resolve(data);
          commit('setBookingTicketTypeDetail', data.data.bookingTicketType);
          commit('setLoadingOverlayHide', { root: true })
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_TICKET_TYPE, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async disableBookingTicket({ commit, rootState }, obj) {
    const finishData = {
      ticketId: obj.ticketId,
      disabled: obj.disabled,
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DISABLE_BOOKING_TICKET}`,
          variables: finishData
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true })
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
            );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DISABLE_BOOKING_TICKET, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
