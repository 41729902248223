export const GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES = `
query ($data: ListClientActionHistoryInput) {
    clientActionHistoryList( 
        data: $data
    ){
      items {
        id 
        actionPlannedAt 
        staff { name } 
        participants 
        actionMethod {name} 
        priority 
        action 
      }
    }
  }
`;

export const GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES_HISTORY = `
query ($data: ListClientActionHistoryInput, $pagination: PaginationInput) {
  clientActionHistoryList( 
    data: $data
    pagination: $pagination
  ) {
    items {
      id
      actionAt 
      staff { name } 
      participants 
      actionMethod {name} 
      remarks 
      isComplaint 
      purchaseRateBefore {
        name 
      } 
      purchaseRate { 
        name 
      }
      furtherPurchaseRateBefore {name} 
      furtherPurchaseRate {name} 
      renewPurchaseRateBefore {name} 
      renewPurchaseRate {name}
      kujiraCrmUrl
    }
  }
}
`;