export const CREATE_STAFF_GROUP = `
mutation (
  $name: String! #名称
) {
  createStaffGroup(
    name: $name
  ) {
    id
    name # もしnameにスペースが含まれる場合 _ に置き換わります
  }
}
`
