import { apolloClient } from '@/plugins/apollo';
import { ADVANCE_POINTS } from '@/api/graphql/contract/contractDetail/sp_ep/carryForward/carryForward.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async advancePointsCarry({ dispatch, commit, state, rootState  }, carryData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ADVANCE_POINTS}`,
          variables: carryData
        })
        .then(data => {
          resolve(data);
          dispatch('contractPointSummaryList')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ADVANCE_POINTS, carryData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
