import actions from './actions';

const transferContract = {
  state: {
    data: {
    },
    clientBasicInfo: []
  },
  getters: {
    getValidTranferContract(state) {
      return state.data
    },
    getClientBasicInfoTra(state){
      return state.clientBasicInfo
    }

  },
  mutations: {
    setValidTranferContract(state, payload) {
      state.data = payload
    },
    setClientBasicInfoTra(state, payload){
      state.clientBasicInfo = payload
    }

  },
  actions,
};

export default transferContract;
