import { apolloClient } from '@/plugins/apollo';
import { 
    GET_EMAIL_TEMPLATE_LIST,
    UPDATE_EMAIL_TEMPLATE
} from '@/api/graphql/mail/index.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getEmailTemplateListMail({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${GET_EMAIL_TEMPLATE_LIST}`,
          fetchPolicy: 'network-only'
        })
        .then(data => {
            commit('setEmailTemplateListMail',data.data.emailTemplateList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GET_EMAIL_TEMPLATE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async updateEmailTemplate({ commit, state, dispatch },key) {
    const finishData = {
      key: key,
      subject: state.contentMail.subject,
      body: state.contentMail.body,
      remarks: state.contentMail.remarks,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_EMAIL_TEMPLATE}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('getEmailTemplateListMail')
          commit('setAlertSuccess', '更新しました。', {
            root: true,
          });
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_EMAIL_TEMPLATE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};

