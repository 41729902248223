export const SEARCH_CLIENT_LIST_MEETING = `
query ($filter:ClientFilterSearchInput!,$orderBy:[OrderByInput!],$pagination:PaginationInput!){
    searchClientList(
        filter: $filter
        orderBy:$orderBy
        pagination: $pagination
    ) {
      total
      items {
        id 
        isCompany 
        name 
        type
        lastMeetMethod
        pic { 
            id
            name
        }
        lastClientActionHistory {
          actionAt
          actionPlannedAt
          actionMethodId
          participants 
        }
      }
    }
  }`;

export const CLIENT_ACTION_HISTORY_LIST_MEETING = `
query{
  enumClientActionMethodList{
    id
    name
    isFaceToFace
  }
}`;