export const WEB_NOTIFICATION_LIST = `
query (
  $includeHidden: Boolean
  $published: Boolean
  $take: Int
  $skip: Int
  $orderBy: [OrderByInput!]
) {
  webNotificationList(
    filter: {
      published: $published
      includeHidden: $includeHidden
    }
    pagination: {
      take: $take
      skip: $skip
    }
    orderBy: $orderBy
  ) {
    total
    items {
      id
      createdAt
      status
      important
      title 
      body
      imageUrl
      staff {
        name
      }
      showFromDate
      showToDate
      publishedAt
      hiddenAt
    }
  }
}
`