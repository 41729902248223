import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST } from '@/api/graphql/customer/response/ExperienceBasedAccommodationCustomers/index.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionGetSearchClientListExp({ commit, state }, filterData) {
    const finishData = {
      filter: {
        picIds: filterData.picId,
        trialStay: {
          fromDate: filterData.fromDate,
          isCancelled: filterData.isCancelled,
        },
      },
      orderBy: filterData.orderBy,
      pagination: {
        take: filterData.take,
        skip: filterData.skip,
      },
    }
    return new Promise((resolve, reject) => {
      commit('setLoadingOverlayShow', { root: true })
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true })
          commit('searchClientListExp', data.data.searchClientList.items);
          resolve(data.data.searchClientList.total);
        })
        .catch(error => {
          commit('setLoadingOverlayHide', { root: true })
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
