<template>
  <div>
    <v-navigation-drawer
      width="200"
      v-model="drawer"
      :permanent="navigationDrawerState"
      :expand-on-hover="isExpanded"
      dark
      fixed
    >
      <template v-slot:prepend>
        <v-list-item class="px-2 logo-item">
          <v-list-item-avatar class="logo-min">
            <v-img src="@/assets/images/favicon_crm.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <v-list nav dense>
        <v-list-item
          v-for="(menuItem,idx) in mainMenuItemsSidebar"
          :key="idx"
          link
          :to="menuItem.route"
        >
          <v-list-item-icon>
            <v-icon v-if="idx!==3" >{{ menuItem.icon }}</v-icon>
            <ad :witdh="40" :height="32" v-else></ad>
          </v-list-item-icon>
          <v-list-item-title v-once><span class="icon-layout">{{ menuItem.title }}</span></v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list nav dense>
          <v-list-item
            v-for="item in lowerMenuItemsSidebar"
            :key="item.title"
            link
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon class="ml-1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="redirectPagePMS()">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title><span class="icon-layout">{{ $t('sidebar.user') }}</span></v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-toolbar color="white" class="hidden-sm-and-up" width="100%">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-toolbar>
  </div>
</template>

<script>
import Ad from '../components/icons/ad.vue';
import { mapGetters } from 'vuex'
import {updateRefreshToken} from '@/plugins/apollo'

export default {
  components:{Ad},
  name: 'SidebarLayout',
  data() {
    return {
      drawer: false,
      isExpanded: false,
      lowerMenuItems: [],
      lowerMenuItemsSidebar: [],
      mainMenuItems: [],
      mainMenuItemsSidebar: [],
      arrMenuPath: ['personal-report', 'customer', 'contract', 'advertising', 'report', 'corporate-ticket', 'confirm-annual', 'gift-ticket', 'manager', 'mail', 'web-manage', 'batch'],
      mainMenuItemsTmp: [
        {
          icon: 'mdi-monitor-dashboard',
          title: this.$t('sidebar.myPage'),
          route: '/personal-report',
          pathRoot: 'personal-report',
          order: 1
        },
        {
          icon: 'mdi-human-handsup',
          title: this.$t('sidebar.customer'),
          route: '/customer',
          pathRoot: 'customer',
          order: 2
        },
        {
          icon: 'mdi-card-account-details',
          title: this.$t('sidebar.contract'),
          route: '/contract',
          pathRoot: 'contract',
          order: 3
        },
        {
          icon: 'fas fa-ad',
          title: this.$t('sidebar.advertising'),
          route: '/advertising',
          pathRoot: 'advertising',
          order: 4
        },
        {
          icon: 'mdi-printer-settings',
          title: this.$t('sidebar.report'),
          route: '/report',
          pathRoot: 'report',
          order: 5
        },
        {
          icon: 'fas fa-ticket-alt',
          title: this.$t('sidebar.corporate'),
          route: '/corporate-ticket',
          pathRoot: 'corporate-ticket',
          order: 6
        },
        {
          icon: 'fa fa-check-square',
          title: this.$t('sidebar.membershipFeeConfirmation'),
          route: '/confirm-annual',
          pathRoot: 'confirm-annual',
          order: 7
        },
        {
          icon: 'fas fa-gifts',
          title: this.$t('sidebar.giftTicket'),
          route: '/gift-ticket',
          pathRoot: 'gift-ticket',
          order: 8
        },
        {
          icon: 'mdi-account-cog',
          title: this.$t('sidebar.inCharge'),
          route: '/manager',
          pathRoot: 'manager',
          order: 9
        },
        {
          icon: 'fas fa-envelope-open-text',
          title: "自動メール",
          route: '/mail',
          pathRoot: 'mail',
          order: 10
        },
        {
          icon: 'mdi-card-multiple',
          title: "一括処理",
          route: '/batch',
          pathRoot: 'batch',
          order: 11
        },
      ],
      lowerMenuItemsTmp: [
        {
          icon: 'fas fa-mobile-alt',
          title: this.$t('sidebar.webPageManage'),
          route: '/web-manage',
          pathRoot: 'web-manage',
          order: 1
        }
      ]
    };
  },
  emits: {
    'is-sidebar-open': null,
  },
  mounted() {
    this.permissionUserSideBar()
    this.isExpanded = this.navigationDrawerState;
  },
  watch: {
    navigationDrawerState(state) {
      this.drawer = state;
      this.isExpanded = state;
    },
    isExpanded(value) {
      this.$emit('is-sidebar-open', value);
    },
  },
  computed: {
    ...mapGetters(['getRoleUser', 'getPermissionUser']),
    navigationDrawerState() {
      return ['sm', 'md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    refreshToken () {
      updateRefreshToken()
    },
    logout() {
      const value = ''
      if(!localStorage.getItem("rememberID")){
        localStorage.removeItem("rememberID", value)
        localStorage.removeItem("email", value)
      }
      localStorage.removeItem("name", value)
      localStorage.removeItem("refreshToken", value)
      localStorage.removeItem("token", value)
      localStorage.removeItem("id", value)
      location.reload()
    },

    permissionUserSideBar () {
      this.lowerMenuItems = []
      this.mainMenuItems = []
      this.lowerMenuItemsSidebar = []
      this.mainMenuItemsSidebar = []
      if (this.getRoleUser) {
        this.mainMenuItems = this.mainMenuItemsTmp
        this.lowerMenuItems = this.lowerMenuItemsTmp
      } else {
        if (Object.keys(this.getPermissionUser).length !== 0) {
          for (const [key] of Object.entries(this.getPermissionUser)) {
            const arrPathPerUser = key.split('/')
            const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
            if (!checkArrPath && this.arrMenuPath.includes(arrPathPerUser[1])) {
              if (arrPathPerUser[1] !== 'web-manage') {
                const menuCurrent = this.mainMenuItemsTmp.filter(({ pathRoot }) => pathRoot === arrPathPerUser[1])
                if (menuCurrent.length > 0) {
                  this.mainMenuItems.push(menuCurrent[0])
                }
              } else {
                const menuCurrentLow = this.lowerMenuItemsTmp.filter(({ pathRoot }) => pathRoot === arrPathPerUser[1])
                if (menuCurrentLow.length > 0) {
                  this.lowerMenuItems.push(menuCurrentLow[0])
                }
              }
            }
          }
        }
      }
      this.mainMenuItems = [...new Set(this.mainMenuItems)]
      this.mainMenuItems.sort((a, b) => a.order - b.order)
      this.lowerMenuItems = [...new Set(this.lowerMenuItems)]
      this.lowerMenuItems.sort((a, b) => a.order - b.order)
      this.lowerMenuItemsSidebar = this.lowerMenuItems
      this.mainMenuItemsSidebar = this.mainMenuItems
    },
    redirectPagePMS () {
      const urlPMS =  process.env.VUE_APP_URL_PMS + '/users?fromPage=crm'
      window.open(urlPMS, '_blank')
    }
  },
};
</script>

<style scoped lang="scss">
  .nav-custom {
    z-index: 200;
  }
.v-navigation-drawer.v-navigation-drawer--mini-variant {
}

.v-navigation-drawer {
  background-color: #13ace0 !important;

  .logo-min {
    display: block;
  }

  .v-list-item__icon {
    margin-right: 15px !important;
  }
}

.v-list-item {
  &.v-list-item__icon {
    background-size: contain;
    width: 24px;
    margin-right: 12px !important;
  }
}
::v-deep {
  .icon-layout{
    font-size: 15px !important;
    color: #FAFAFA;
    font-family: 'Noto Sans' !important;
  }
}
</style>
