export const VACATION_LIFE_LIST_VACATIONLIFE  = `
query {
    vacationLifeList {
     id 
     year 
     month 
     url 
   }
 }
`;

export const GET_URL_IMG_LIST_VACATIONLIFE = `
mutation (
  $contentType: String!
  $contentLength: Int!
  $type: String! 
  $fileName: String
) {
  generateUploadUrl(
    type: $type
    contentType: $contentType
    contentLength: $contentLength
    fileName: $fileName
    ) {
    uploadUrl
    fileUrl 
  }
}
`;

export const CREATE_VACATIONLIFE = ` 
mutation (
  $year: Int! 
  $month: String! 
  $url: String! 
) {
  createVacationLife(data: {
    year: $year
    month: $month
    url: $url
  }) {
    id
  }
}`;

export const DELETE_VACATIONLIFE = ` 
mutation (
  $id: Int!
) {
  deleteVacationLife(id: $id)
}`;

