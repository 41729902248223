import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloLink } from 'apollo-link'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { setContext } from 'apollo-link-context'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import config from '../constants';
import store from '../store';
import { serverErrors } from '../constants/errors';
import { currentLanguage } from './i18n';
// import { onError } from '@apollo/client/link/error'
import { execRefreshToken, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { matchTokenPermissions } from '@/utils/permissions'
import gql from 'graphql-tag';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'http://api.tvos2022dev.com/graphql';

const httpLink = {
  uri: httpEndpoint,
  headers: {
    'Content-Language': currentLanguage(),
  },
};

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  // tokenName: config.authToken,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: httpLink

  httpLinkOptions: httpLink,

  // Override default cache
  // cache: new InMemoryCache()

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

function needsTokenRefresh (payload) {
  const expiresAt = payload.exp * 1000
  if (expiresAt - Date.now() < 1 /* minute */ * 60 * 1000) {
    return true
  }
  return false
}

export async function updateRefreshToken() {
  const prevToken = localStorage[config.authToken]
  const decodedPrev = prevToken && jwt_decode(prevToken)
  const refreshToken = localStorage.refreshToken
  let token;
  if (refreshToken) {
    try {
      const newTokens = await execRefreshToken()
      localStorage[config.authToken] = newTokens.accessToken
      localStorage.refreshToken = newTokens.refreshToken
      token = newTokens.accessToken
      const decodedNew = jwt_decode(token)
      // we check if any of the permissions has been changed before/after. If it has force reload the page to reflect the changes
      if (!matchTokenPermissions(decodedPrev, decodedNew)) {
        location.reload()
      }
    } catch (e) {
      token = null
    }
  }
  return token
}

let waitRefresh = Promise.resolve()

const authLink = setContext(async (ctx, { headers, ...rest }) => {
  const operationName = ctx.operationName

  let token = localStorage[config.authToken]
  const decodedPrev = token && jwt_decode(token)
  if (decodedPrev && needsTokenRefresh(decodedPrev) && operationName !== 'refreshAccessToken') {
    let resolveWait
    // await this refresh for the simultaneous request handling
    await waitRefresh

    waitRefresh = new Promise((resolve, reject) => {
      resolveWait = resolve
    })
    try {
      token = await updateRefreshToken()
    } finally {
      resolveWait()
    }
  }

  // return the headers to the context so HTTP link can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  }
})

// Create apollo client
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions,
  link: ApolloLink.from([authLink])
});
// Call this in the Vue app file
export function createProvider(options = {}) {
  apolloClient.wsClient = wsClient;
  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    async errorHandler(error) {
      if (
        error.graphQLErrors.some(
          err => err.code === serverErrors.unauthorizedError.code,
        )
      ) {
        await store.dispatch('logOut');
      }
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });
}

export async function viewMutate({
  query,
  variables,
  successMessage = '処理を正常に完了しました',
  returnKey = null
}) {
  store.commit('setLoadingOverlayShow')
  try {
    const data = await apolloClient.mutate({
      mutation: gql`${query}`,
      variables
    });
    store.commit('setAlertSuccess', successMessage)
    if (returnKey) return data[returnKey]
    return data;
  } catch (error) {
    store.commit('setCopyErrorText', getCopyErrorTextView(query, variables, error.graphQLErrors))
    const errorTmp = await handlErrorView(error.graphQLErrors, 
      (p) => store.commit('setPermissionUser', p),
      (p) => store.commit('setRoleAdminUser', p)
    )
    if (errorTmp) {
      store.commit('setAlertError', errorTmp)
    }
    throw error
  } finally {
    store.commit('setLoadingOverlayHide')
  }
}

export const apolloHelperPlugin = {
  install (Vue) {
    Vue.prototype.$viewMutate = viewMutate; 
  }
} 