import { apolloClient } from "@/plugins/apollo";
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

import {
  UPDATE_WEB_REQUEST
} from "@/api/graphql/webRequest"

export default {
  async updateWebRequest({ commit, rootState }, obj) {
    const status = obj.handledAt ? 'Handled' : 'New'
    commit('setLoadingOverlayShow', { root: true })
    const finishData = {
      id: parseInt(obj.id),
      remarks: obj.remarks,
      status
    }
    await apolloClient.mutate({
      mutation: gql`${UPDATE_WEB_REQUEST}`,
      variables: finishData
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true })
      commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
    }).catch(error => {
      setCopyErrorTextCommit(commit, UPDATE_WEB_REQUEST, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  }
}