const config = {
  apiUrl: 'https://run.mocky.io/v3/',
  commentOptions: {
    owner: 'tuanpa-miichi',
    repo: 'vssue-comment',
    clientId: '522bc42515c6533b9bf4',
    clientSecret: 'cb0473d4221a65ab64a1db2f102e7bb14c171a0e',
  },
  authToken: 'token',
  shortToken: 'shortToken',
  user: 'currentUser',
};
export default config;
