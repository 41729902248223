import actions from './actions';

const WebPageManageInfo = {
  state: {
    total: null,
    duplicatedClientList: [],
  },
  getters: {
    getTotalDuplicatedClientList(state) {
      return state.total
    },

    getDuplicatedClientList(state) {
      return state.duplicatedClientList;
    },
  },
  mutations: {
    setTotalDuplicatedClientList (state, data) {
      state.total = data
    },

    setDuplicatedClientList(state, data) {
      state.duplicatedClientList = data;
    },
  },
  actions,
};

export default WebPageManageInfo;
