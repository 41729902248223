import actions from './actions'

const otherMaterials = {
    state: {
      otherPublicDoclistOtherMaterials:[],
      categoryName:[],
      fileUrlOtherMaterials:'',
      docTypeOtherMaterials:'',
      fileNameOtherMaterials:'',
      alertSuccessOtherMaterials:'',
      otherPublicDoctypeListOtherMaterials:[]
    },
    getters: {
        otherPublicDoclistOtherMaterials(state){
            return state.otherPublicDoclistOtherMaterials
        },
        categoryName(state){
            return state.categoryName
        },
        docTypeOtherMaterials(state){
            return state.docTypeOtherMaterials
        },
        fileNameOtherMaterials(state){
            return state.fileNameOtherMaterials
        },
        otherPublicDoctypeListOtherMaterials(state){
            return state.otherPublicDoctypeListOtherMaterials
        },
    },
    mutations: {
        setOtherPublicDoclistOtherMaterials(state,data){
            state.otherPublicDoclistOtherMaterials = data
            state.otherPublicDoclistOtherMaterials.forEach(e => {
                state.categoryName.push(e.docType.name)
            });
        },
        setFileUrlOtherMaterials(state,val){
            state.fileUrlOtherMaterials = val
        },
        setDocTypeOtherMaterials(state,val){
            state.docTypeOtherMaterials = val
            
        },
        setFileNameOtherMaterials(state,val){
            state.fileNameOtherMaterials = val
        },
        setAlertSuccessOtherMaterials(state,val){
            state.alertSuccessOtherMaterials = val
        },
        setOtherPublicDoctypeListOtherMaterials(state,data){
            state.otherPublicDoctypeListOtherMaterials = data
        },
    },
    actions,
}

export default otherMaterials;