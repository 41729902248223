import { apolloClient } from '@/plugins/apollo';
import {
  DUPLICATED_CLIENT_LIST,
  ASSIGN_CLIENT_WEB_REQUEST,
  VS_SIGN_UP_TRANSFER_CONTRACT_OWNER_SHIP,
  CREATE_CLIENT_WEB_REQUEST,
  VS_SIGN_UP_DUPLICATE_CHECK,
  VS_SIGN_UP_DISMISS
} from '@/api/graphql/dashboard/webPageManage/info/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  actionDuplicatedClientList({ commit, state }, paginate) {
    const finishData = {
      pagination: {
        take: paginate.take,
        skip: paginate.skip,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${DUPLICATED_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setTotalDuplicatedClientList', data.data.duplicatedClientList.total)
          commit(
            'setDuplicatedClientList',
            data.data.duplicatedClientList.items,
          );
          resolve(data.data.duplicatedClientList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DUPLICATED_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  // addToDuplicateCustomers ko phải VS
  actionAssignClientWebRequest({ commit, state }, data) {
    const finishData = {
      webRequestId: data.webRequestId,
      clientId: data.clientId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ASSIGN_CLIENT_WEB_REQUEST}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '成功しました。', {
            root: true,
          });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ASSIGN_CLIENT_WEB_REQUEST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  // addToDuplicateCustomers là VS
  actionVsSignupTransferContractOwnership({ commit, state }, data) {
    const finishData = {
      vsApplicationId: data.vsApplicationId,
      clientId: data.clientId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${VS_SIGN_UP_TRANSFER_CONTRACT_OWNER_SHIP}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '成功しました。', {
            root: true,
          });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, VS_SIGN_UP_TRANSFER_CONTRACT_OWNER_SHIP, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  // doNotAddToDuplicateCustomers ko phải VS
  actionCreateClientWebRequest({ commit, state }, data) {
    const finishData = {
      webRequestId: data.webRequestId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CLIENT_WEB_REQUEST}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '成功しました。', {
            root: true,
          });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_CLIENT_WEB_REQUEST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  // doNotAddToDuplicateCustomers là VS
  actionVsSignupDuplicateCheck({ commit, state }, data) {
    const finishData = {
      vsApplicationId: data.vsApplicationId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${VS_SIGN_UP_DUPLICATE_CHECK}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '成功しました。', {
            root: true,
          });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, VS_SIGN_UP_DUPLICATE_CHECK, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionDismissVsSignup({ commit }, data) {
    const finishData = {
      vsApplicationId: data.vsApplicationId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${VS_SIGN_UP_DISMISS}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '成功しました。', {
            root: true,
          });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, VS_SIGN_UP_DISMISS, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
