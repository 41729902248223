import { apolloClient } from '@/plugins/apollo';
import {
  DELETE_CLIENT
} from '@/api/graphql/customer/delete/delete.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async deleteClient({ commit, rootState, dispatch }, { clientId }) {
    const finishData = {
      id: clientId
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_CLIENT}`,
          variables: finishData,
        })
        .then(data => {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.deleteSuccess,
            { root: true },
          );

          
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_CLIENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

};
