export const CLIENT_CHANGE_HISTORY_LIST = `
query ($filter: ClientChangeHistoryListFilterInput, $pagination:PaginationInput) {
  clientChangeHistoryList(
    filter: $filter
    pagination: $pagination
  ) {
    items {
      changedAt
      fieldName
      valueBefore
      valueAfter
      changedById
      memberUserId
      changedByName
    }
  }
}
`;
