import { apolloClient } from '@/plugins/apollo'
import { STAFF_GROUP_LIST } from '@/api/graphql/manager/group/group.js'
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async groupList ({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${STAFF_GROUP_LIST}`,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setStaffGroupList', data.data.staffGroupList)
          resolve(data.data.staffGroupList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, STAFF_GROUP_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
}
