export const SEARCH_CLIENT_LIST = `
  query(
    $filter: ClientFilterSearchInput!
    $load: ClientSearchLoadInput
    $orderBy: [OrderByInput!]
    $pagination: PaginationInput!
  ) {
    searchClientList(
      filter: $filter
      load: $load
      orderBy: $orderBy
      pagination: $pagination
    ) {
      total
      items {
        type
        furtherPurchaseRate {
          id
          name
        }
        lastResponseDate
        lastClientActionHistory {
          actionAt
          remarks
        }
        lastCampaignApplyResponse {
          campaign {
            id
            name
          }
        }
        isCompany
        name
        nameKana
        remarksForStaff
        pic {
          id
          name
        }
        id
      }
    }
  }
`;
