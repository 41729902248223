import { apolloClient } from "@/plugins/apollo";
// import { ENUM_EMPLOYMENT_TYPE_LIST } from "@/api/graphql/enum/enumIncomeLevelList.js";
import { ENUM_INCOME_LEVEL_LIST } from "@/api/graphql/enum/enumIncomeLevelList.js";
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async enumIncomeLevelList({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${ENUM_INCOME_LEVEL_LIST}`,
                })
                .then(data => {
                    commit('setEnumIncomeLevelList', data.data.enumIncomeLevelList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, ENUM_INCOME_LEVEL_LIST, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    }
}