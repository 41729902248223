import { apolloClient } from '@/plugins/apollo';
import { UPDATE_CONTRCTPOINT_DURATIONG } from '@/api/graphql/contract/contractDetail/sp_ep/changePeriod/changePeriod.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async updateContractPointDuration({ commit, state, rootState, dispatch }) {
    const finishData = {
      id: parseInt(state.data.id),
      validFrom: state.data.validFrom,
      validThru: state.data.validThru
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CONTRCTPOINT_DURATIONG}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('contractPointList')
          resolve(data);
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CONTRCTPOINT_DURATIONG, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
