export const WEB_REQUESTLIST_DOCUMENT_REQUESTLIST = `
query( 
  $orderBy: [OrderByInput!]
  $pagination: PaginationInput
  $filter: WebRequestListFilterInput
) {
  webRequestList(
    orderBy: $orderBy,
    filter: $filter,
    pagination: $pagination
  ) {
    total
    items {
      id
      medias {
        mediaType
      }
      client {
        id
        isCompany
        name
        type
        pic {
          id
          name
        }
      }
      campaign{
        name
         id
       }
      createdAt
      forVm
      forVs
      importMethod
    }
  }
}
`;

export const WEBREQUEST_NOT_ISCOPANY_DOCUMENT_REQUESTLIST = `
  query($id: Int!) {
    webRequest(id: $id) {
      id
      medias {
        mediaType
        mediaOther
      }
      clientId
      name
      client {
        picId
        name
      }
      forVm
      forVs
      createdAt
      facilities
      seasons
      actionRemarks
      purchaseRate {
        name
      }
      handledAt
      firstDate
      firstTime
      secondDate
      secondTime
      inquiry
      firstFacility {
        name
      }
      secondFacility {
        name
      }
      campaign {
        id
        name
        groups
        startDate
        note
        createdAt
        attachment1Url
        attachment2Url
        attachment3Url
      }
      appliedCampaigns {
        id
        name
        groups
        startDate
        createdAt
        note
        attachment1Url
        attachment2Url
        attachment3Url
      }
      isCompany
      lastName
      firstName
      lastKana
      firstKana
      birthday
      sex
      postalCode
      prefecture
      locality
      address1
      address2
      tel
      telMobile
      email
      remarks
      companyName
      companyNameKana
      companySize
      companyPicName
      companyPicKana
      businessType{
        name
      }
      businessTypeOther
      companyPicDepartment
      companyPicTitle
      workStyleReform
    }
  }
`;
export const UPLOAD_DUPLICATE_EXISTING_CUSTOMERS = `
  mutation($file: Upload!) {
    bulkWebRequest(file: $file)
  }
`;

export const WEB_REQUEST_IDS_LIST = `
  query {
    webRequestList {
      items {
        id
      }
    }
  }
`;

export const SUGGEST_CLIENT_PIC = `
  mutation($maxClients: Int!, $staffIds: [Int!]!, $webRequestIds: [Int!]!) {
    suggestClientPIC(
      maxClients: $maxClients
      candidateStaffIds: $staffIds
      webRequestIds: $webRequestIds
    ) {
      suggestedPic {
        id
        name
      }
      webRequest {
        id
        medias {
          mediaType
        }
        createdAt
        isCompany
        name
        forVm
        forVs
        importMethod
      }
    }
  }
`;

export const CONFIRM_CLIENT_PIC = `
  mutation($assignments: [ClientPicAssignmentInput!]!) {
    confirmClientPIC(assignments: $assignments)
  }
`;

export const UPDATE_WEB_REQUEST_REMARKS_AND_PICID = `
  mutation (
    $id: Int!
    $remarks: String
    $picId: Int
  ) {
    updateWebRequest(
      webRequestId: $id
      data: {
        remarks: $remarks
        picId: $picId
      }
    ) {
      remarks
      client{
        pic{
          id
          name
        }
      }
      __typename
    }
  }
`