import actions from './actions';

const salesRepresentative = {
  state: {
    staffListSales: [],
    showDisabled: false,
    crmRep: true
  },
  getters: {
    getStaffListSales(state) {
      return state.staffListSales;
    },
    getShowDisabled(state) {
      return state.showDisabled;
    },
  },
  mutations: {
    setStaffListSales(state, arr) {
      state.staffListSales = arr;
    },
    setShowDisabled(state, payload){
      state.showDisabled = payload
    }
  },
  actions,
};

export default salesRepresentative;
