import { apolloClient } from '@/plugins/apollo';
import {
  CLIENT_CHARGE_LIST,
  CREATE_CLIENT_CHANGE,
  RECEIPT,
  UPDATE_CLIENT_CHARGE
} from '@/api/graphql/customer/response/detail/membershipBillingHistory';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionGetClientChargeList({ commit, state }) {
    const finishData = {
      filter:{
        clientId: parseInt(router.currentRoute.params.id),
      },
      pagination:{
        skip: null,
        take: null,
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_CHARGE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setClientChargeList', data.data.clientChargeList.items);
          resolve(data.data.clientChargeList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_CHARGE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionCreateClientChange({ commit }, finishData) {
    const variables = {
      clientId: finishData.clientId,
      price: finishData.price,
      note: finishData.note,
      paymentMethod: finishData.paymentMethod,
      clientChargeTypeId: 999,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CLIENT_CHANGE}`,
          variables: variables,
          update: (store, { data: createClientCharge }) => {
            const data = store.readQuery({
              query: CLIENT_CHARGE_LIST,
              variables: {
                filter:{
                  clientId: finishData.clientId,
                },
                pagination:{
                  skip: null,
                  take: null,
                }
              },
            });
            const dataCreateClientCharge = {
              ...createClientCharge.createClientCharge,
              clientChargeType: {
                name: 'その他',
              },
            };
            data.clientChargeList.items.unshift(dataCreateClientCharge);
          },
        })
        .then(data => {
          // commit('setCreateClientChange', data.data.createClientCharge);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_CLIENT_CHANGE, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionReceipt({ commit, state }, id) {
    const finishData = {
      id: id,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${RECEIPT}`,
          variables: finishData,
        })
        .then(data => {
          // commit('setClientChargeList', data.data.receipt.pdfFileUrl);
          resolve(data.data.receipt.pdfFileUrl);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, RECEIPT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionUpdateClientChange({ commit, rootState }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CLIENT_CHARGE}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT_CHARGE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
