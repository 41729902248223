import actions from './actions';

const PageManageBase = {
  state: {
    showRedDotList: [],
  },
  getters: {
    getShowRedDotList(state) {
      return state.showRedDotList;
    }
  },
  mutations: {
    setShowRedDotList(state, arr) {
      state.showRedDotList = arr;
    }
  },
  actions,
};

export default PageManageBase;
