export default {
  messages: {
    successfulNewCreation: '作成しました。',
    successfulUpdate: '更新しました。',
    successSend: '送信しました。',
    deletedSuccessfully: '削除しました。',
    tokenExpires: 'トークンの有効期限',
    updateError: '更新失敗',
    formValidationError: '入力内容にエラーがあります。',
    createError: '新しいものを作成できません',
    duplicateDates: '重複する日付 !!',
    passwordError: 'パスワードが設定できませんでした。',
    salesRepresentativeDecisionError: '営業担当決定失敗した。',
    searchFailed: '検索に失敗しました',
    emailExists: 'メールは既に存在します。',
    transferred:'譲渡しました。'
  },
  auth: {
    login: 'ログイン',
    username: 'ユーザー名',
    password: 'パスワード',
  },
  sidebar: {
    myPage: 'マイページ',
    advertising: '広告施策',
    customer: '顧客',
    contract: '契約',
    corporate: '法人チケット',
    inCharge: '担当',
    report: '帳票・CSV',
    webPageManage: 'WEBページ管理',
    user: 'ユーザー',
    membershipFeeConfirmation: '年会費確認',
    giftTicket: 'ギフトチケット',
  },
  routeTitles: {
    dashboard: 'ダッシュボード',
    calendar: 'カレンダー',
    booking: '予約',
    feesPoints: '料金・ポイント',
    externalCooperation: '外部連携',
    report: 'レポート',
    facility: '施設',
    editingFacility: 'Editing facility',
    roomTypesSettings: "Rooms' Types Settings",
    roomTypeEditing: "Room's Type Editing",
    roomsSettings: 'Rooms Settings',
    roomsEditing: "Room's Editing",
    loans: '貸出品',
    keySettings: '鍵設定',
    printingForm: '帳票印刷',
    pageNotFound: 'ページが見つかりません',
    facilitySettings: '施設情報',
    loanProducts: '貸出品',
    carPark: '駐車場',
    parkingTax: '宿泊税',
    roomTypeInfo: '部屋タイプ情報',
    roomInfo: '部屋情報',
    mwUsageStatus: '野田 健太:MW契約',
    RCIUsageStatus: '野田健太:新固定契約',
  },
  common: {
    btnAddBase: '施設を追加',
    inDevelopment: '開発中',
    title: '名称',
    search: '探',
    status: 'ステータス',
    contract: '契約',
    facility: '施設',
    roomType: '部屋タイプ',
    room: '部屋',
    roomNumber: '号室',
    checkIn: 'チェックイン',
    checkOut: 'チェックアウト',
    numOfDays: '日数',
    representative: '代表者',
    adult: '大人',
    children: '子供',
    email: 'Email',
    cases: '件のうち',
    showing: '件を表示',
    noResults: '結果がありません',
    toDay: '今日',
    kindName: '今金種名日',
    fileDownload: 'ファイルダウンロード',
    notIn: '未イン',
    notOut: '未アウト',
    unpaid: '未精算',
    member: '会員',
    bedSharing: '添寝',
    large: '大',
    small: '小',
    landing: '貸',
    block: 'ブロック',
    confirmed: '確認済み',
    none: 'なし',
    memberName: '会員名',
    group: 'グループ',
    remark: '備考',
    city: '都市',
    country: '国',
    amountOfMoney: '金額',
    numberOfSheets: '枚数',
    close: '閉じる',
    save: '保存',
    notices: '特記事項',
    dateOfOccurrence: '発生日',
    unitPrice: '単価',
    startOfDeadline: '期限開始',
    endOfDeadline: '期限終了',
    subjectName: '科目名称',
    unit: '単位',
    points: 'ポイント',
    quantity: '数量',
    order: '個数',
    VAT: '消費税',
    subtotal: '小計',
    total: '合計',
    night: '泊目',
    cash: '現金',
    settlementMethod: '精算方法',
    cardType: 'カード種類',
    unionPay: '銀聯',
    membershipRegisteredCard: '会員登録済みカード',
    beforeChange: '変更前',
    proviso: '但し書き',
    provisoCut: '但',
    fullName: '名前',
    page: 'ページ',
    target: '対象',
    totalUse: '利用合計',
    checked: 'チェックをつけた',
    remarksContentSearch: '備考内容検索',
    numOfPersons: '人数',
    unaccompanied: '非添い寝',
    accompanied: '添い寝',
    occupation: '職業',
    age: '年齢',
    nationality: '国籍',
    payableDate: '受付日',
    reservation: '予約',
    time: '時刻',
    question: '質問',
    questionOrder: '選択肢',
    questionContent: '質問内容',
    freeText: 'フリーテキスト',
    option: '選択',
    requiredAnswer: '必須回答',
    optionalAnswer: '任意回答',
    author: '作成者',
    checkInDate: 'チェックイン日',
    start: '開始',
    end: '終了',
    noSet: '設定しない',
    set: '設定する',
    discount: '引き',
    yen: '円',
    overNight: '泊以上',
    untilNight: '泊まで',
    period: '期間',
    months: 'ヶ月',
    item: '項目',
    startPeriod: '期間開始',
    endPeriod: '期間終了',
    date: '日付',
    number: '数',
    credit: 'クレジット',
    point: 'ポイント',
    subItem: '小項目',
    day: '日',
    icon: 'アイコン',
    enable: '有効',
    disable: '無効',
    image: '画像',
    noSmoking: '禁煙',
    smoking: '喫煙',
    roomTypeTitle: 'ペット',
    paymentUnit: '支払単位',
    totalAmount: '総数量',
    stay: '滞在',
  },
  weekdays: {
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土',
    sunday: '日',
    holidays: '祝',
  },
  commons: {
    action: 'アクション',
    status: '状況',
    date: '日付',
    setting: '設定',
    gender: '性別',
    age: '年齢',
    furigana: 'フリガナ',
    aboutUs: '会社情報',
    zipCode: '郵便番号',
    prefecture: '都道府県',
    city: '市区郡',
    townStreet: '町名・番地',
    buildingName: 'ビル名',
    campaign: 'キャンペーン',
    cost: '費用',
    cases: '件のうち',
    showing: '件を表示',
    all: '全て',
    customerName: '顧客名',
    customerCode: '顧客コード',
    evaluation: '評価',
    phone: '電話番号',
    buildingNameCondominium: 'ビル名、マンション名等',
    expirationDate: '有効期限日',
    start: '開始',
    end: '終了',
    contact: '連絡先',
    privateCorporation: '個人/法人',
    period: '期間',
    item: '件',
    comment: 'コメント',
    onesHome: '自宅',
    total: '総計',
    empty: '空の',
    contractStatus: 'ステータス',
    facility: '施設',
    year: '年度',
    month: '月号',
    week: '週',
    remark: '備考',
    dateAndTime: '日時',
    unitPrice: '単価',
    processing: '処理',
    point: 'ポイント',
    actualDate: '発生日(優先度)',
    startDate: '期限開始',
    endDate: '期限終了',
    unitPoint: 'ポイント単価',
    target: 'ターゲット',
    male: '男性',
    female: '女性',
    purpose: '目的',
    ageUnit: '代',
    browsing: '閲覧',
    webSearch: 'インターネット検索',
    magazine: '雑誌',
    television: 'テレビ',
    program: '番組',
    official: '公式',
    others: 'その他',
    introduction: 'ご紹介',
    newspaper: '新聞',
    implementation: '実施',
    reaction: '反応',
    reacted: '反応有',
    latestActivityHistory: '最新活動履歴',
    dateAdded: '追加日',
    contractProduct: '契約商品',
    phase: 'フェーズ',
    contractDetail: '契約詳細',
    yen: '円',
    reReaction: '再反応',
    implemented: '実施済',
    none: 'なし',
    benefits: '特典内容',
    amount: '金額',
    miles: 'マイル数',
    usageContract: '利用契約',
    checkIn: 'チェックイン',
    checkOut: 'チェックアウト',
    nights: '泊',
    adult: '大人',
  },
  buttons: {
    retainAndAdd: '保存して追加',
    registerLoanItems: '貸出品追加登録',
    registerNewParkingLot: '新規駐車場登録',
    add: '追加',
    login: 'ログイン',
    createNewFacility: '新規施設作成',
    eachRoomSettings: '各部屋設定',
    createNewRoomType: '新規部屋タイプ作成',
    createNewRoom: '新規部屋作成',
    edit: '編集',
    activityScheduleRegistration: '活動予定登録',
    moveToActivityHistory: '活動履歴に移動',
    editCustomerInformation: '顧客情報を編集',
    formPrinting: '帳票印刷',
    reservationHistory: '予約履歴',
    newRoomReservation: '新規宿泊予約',
    cancel: 'キャンセル',
    save: '保存',
    delete: '削除',
    close: '閉じる',
    addMember: '会員を追加',
    addPersonInCharge: '担当者を追加',
    searchOtherCondition: '上記条件以外を担当顧客総計',
    search: '検索',
    labelSelectionItems: '選択項目をラベル印刷',
    detail: '詳細',
    csvOutput: '選択項目をCSV出力',
    adaptingAdvertisingMeasures: '選択項目に広告施策適応',
    run: '実行',
    change: '変更',
    update: '更新',
    done: '完了',
    confirmationWithPMS: 'PMSで予約確認',
    createNewAdvertisingPolicies: '新規広告施策作成',
    editAdvertisingPolicyContent: '広告施策内容編集',
    advertisingPolicyEdit: '広告施策編集',
    createNewContractBenefits: '新規契約特典作成',
    bookingRoom: '予約作成',
    importFromCalendar: 'カレンダーから入力',
    exchange: '交換',
    create: '作成',
  },
  rules: {
    isKatakana: '全角カタカナでご入力下さい',
    isNumber: '整数でご入力下さい',
    isRequired: '必須項目です。',
    isInvalid: '入力内容に誤りがあります。',
    noData: 'データなし',
    nameIsRequired: '名前は必須です。',
    minimumNCharacter: '{value}文字以上である必要があります',
    maximumNCharacter: '{value}文字未満である必要があります',
    minimumN: '最低でも{value}人は必要です。',
    maximumNumberInt32: '2147483647以下である必要があります',
    includeNCharacters: '{value}文字以上である必要があります',
    exactNCharacters: '{value}文字でご入力下さい',
    loanItemsRequired: 'ローンアイテムが必要',
    totalQuantityRequired: '総量が必要です。',
    totalQuantityMoreThanN: '合計数量が{value}以上であること',
    typeRequired: 'タイプは必須',
    numberInvalid: '数値が無効です。',
    requiredField: '必須項目です',
    emailIsInvalid: 'メールアドレスが不正な形式です',
    characters255 : '255文字以内',
    invalidUrl: '不正なURLです'
  },
  webPageManage: {
    infoRequest: {
      DuplicateCustomerName: '重複先顧客名',
      DuplicateCustomerDetails: '重複先顧客詳細',
      MatchContent: '一致内容',
      ReceivedDate: '受信日',
      WEBInquiryContents: 'WEB問い合わせ内容',
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      document: '資料請求内容',
      remark: '備考',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    infoRequestDetail: {
      back: '資料請求一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'アンケート',
      interest: '興味のある施設',
      learn: '東急バケーションズを知った経緯',
    },
    documentRequest: {
      medium: '反響媒体',
      customerSalesStaff: '顧客営業担当',
      receiveDate: '受信日',
      CustomerDetails: '顧客詳細',
      customer: '法人/個人',
      companyName: '名前/会社名',
      BillingMaterial: '請求資料',
      importMethod: '取り込み方法',
      detail: '詳細',
      btnLeft: '営業担当なし',
      btnRight: '全て',
      btnCsv: 'CSVアップロード',
      btnTable: '選択項目に担当者割振',
      titleDialog: '営業担当割振',
      checkin: '当日出勤',
      contractProcedure: '契約手続き中・Good・Coolの管理上限',
      btnAllocationExecution: '割り振り実行',
      titleDialogCsv: '資料請求CSVアップロード',
      labelCsv: '資料請求CSVファイルをアップロード',
      titleDialogAllowSetting: '割振結果',
      btnSalesRepresentative: '営業担当決定',

      date: '受信日',
      AdvertisingMeasures: '広告施策',

      SalesRepresentative: '営業担当者',
      RequestForMaterials_KoheiImazu: '資料請求_今津康平',
      Individual: '個人',
      OpenCustomerInformation: '顧客情報を開く',
      ActivityHistoryRegistration: '活動履歴登録',
      Status: '状況',
      PurchaseEvaluation: '購入評価',
      remarks: '備考',

      CorporateName: '法人名',
      CorporateNameKana: '法人名カナ',
      Industry: '業種',
      NameOfPersonInCharge: '担当者名',
      PersonInChargeKana: '担当者カナ',
      PersonInChargeDepartment: '担当者部署',
      PersonInCharge: '担当者役職',
      NumberOfEmployees: '社員数',
      WorkStyleReform: '働き方改革',
      requestDetail: '資料請求内容',
      ContactZipCode: '連絡先郵便番号',
      ContactPrefecture: '連絡先都道府県',
      ContactCity: '連絡先市区郡',
      ContactTownName: '連絡先町名',
      ContactBuildingName: '連絡先ビル名',
      ContactPhoneNumber: '連絡先電話番号',
      ContactMobilePhoneNumber: '連絡先携帯電話番号',
      ContactEmailAddress: '連絡先メールアドレス',
      SeasonOfInterest: '興味のあるシーズン',
      ReasonForInquiry: '問い合わせた理由',
      close: '閉じる',
      save: '保存',
      CustomersSales: '顧客の担当営業',
      DocumentInquiryInformation: '資料問い合わせ情報',
      DocumentRequestDate: '資料請求日',
      address: '番地',
      CondominiumName: 'マンション名',
      FacilitiesOfInterest: '興味のある施設',
      AdaptiveAdvertisingMeasures: '適応広告施策',
      Surname: '姓',
      Name: '名',
      LastNameKatakana: '姓カナ',
      NameKatakana: '名カナ',
      Birthday: '生年月日',
      gender: '性別',
      HomeZipCode: '自宅郵便番号',
      HomePrefecture: '自宅都道府県',
      HomeCity: '自宅市区郡 ',
      HomeTownName: '自宅町名',
      HomeBuildingName: '自宅ビル名',
      HomePhoneNumber: '自宅電話番号',
      HomeMobilePhoneNumber: '自宅携帯電話番号',
      HomeEmailAddress: '自宅メールアドレス',
      multiple: '複数',
    },
    oldCustomer: {
      btnCsv: 'CSVアップロード',
      receiveDate: '受信日',
      medium: '反響媒体',
      CustomerDetails: '顧客詳細',
      customer: '顧客詳細',
      companyName: '法人/個人',
      BillingMaterial: '請求資料',
      importMethod: '取り込み方法',
      detail: '詳細',
    },
    booking: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    bookingDetail: {
      back: 'ご来店予約一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容',
    },
    consultation: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    consultationDetail: {
      back: 'オンライン相談一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容',
    },
    experience: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望',
      secondHope: '第二希望',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
      salesRepresentative: '営業担当者',
      numOfTimes: '回数',

      AlsoDisplayedAsSupported: '対応済も表示',
      telephoneNumber: '電話番号',
      Email: 'メール',
      FirstChoiceDate: '第一希望日',
      secondChoiceDate: '第二希望日',
      OnlineConsultation: 'オンライン相談',
      client: '顧客',
      CustomerDetails: '顧客詳細',

      VisitReservation: '来店予約',
      ReceivedDate: '受信日',
      NoCustomers: '顧客なし',
      ConnectWithExistingCustomers: '既存顧客に結びつける',
      CreateANewCustomer: '新規顧客を作成',
      CustomerInformation: 'お客様情報',
      DocumentRequestDetails: '資料請求内容',
      Name: 'お名前',

      Furigana: 'フリガナ',
      Birthday: '生年月日',
      PostalCode: '郵便番号',
      Address: 'ご住所',
      Prefectures: '都道府県',
      CityOrCounty: '市区郡',
      TownName: '町名',
      address: '番地',
      BuildingName: 'ビル名',
      CondominiumName: 'マンション名',
      ContactInformation: 'ご連絡先',
      HomePhoneNumber: '自宅電話番号',
      cellPhoneNumber: '携帯電話番号',
      remarks: '備考',
      DesiredDateOfVisit: 'ご来店希望日',
      ContentOfConsultation: 'ご相談内容',
      close: '閉じる',
      save: '保存',

      OnlineConsultationDesiredDate: 'オンライン相談希望日',
      CustomerID: '顧客番号',

      AccommodationExperience: '体験宿泊',
    },
    experienceDetail: {
      back: '体験宿泊申込一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: '体験宿泊希望日・施設',
      firstHope: '第一希望',
      secondHope: '第二希望',
      content: 'ご相談内容',
    },
    VisitReservation: {
      search: '検索',
      add: '新規お知らせ',
      status: 'ステータス',
      date: '受信日',
      creator: '作成者',
      category: 'カテゴリー',
      title: 'タイトル',
      content: '内容',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',

      AlsoDisplayedAsSupported: '対応済も表示',
      telephoneNumber: '電話番号',
      Email: 'メール',
      FirstChoiceDate: '第一希望日',
      secondChoiceDate: '第二希望日',
      VisitReservationDetails: '来店予約詳細',
      client: '顧客',
      CustomerDetails: '顧客詳細',

      VisitReservation: '来店予約',
      ReceivedDate: '受信日',
      NoCustomers: '顧客なし',
      ConnectWithExistingCustomers: '既存顧客に結びつける',
      CreateANewCustomer: '新規顧客を作成',
      CustomerInformation: 'お客様情報',
      DocumentRequestDetails: '資料請求内容',
      name: 'お名前',

      Furigana: 'フリガナ',
      Birthday: '生年月日',
      PostalCode: '郵便番号',
      Address: 'ご住所',
      Prefectures: '都道府県',
      CityOrCounty: '市区郡',
      TownName: '町名',
      address: '番地',
      BuildingName: 'ビル名',
      CondominiumName: 'マンション名',
      ContactInformation: 'ご連絡先',
      HomePhoneNumber: '自宅電話番号',
      cellPhoneNumber: '携帯電話番号',
      remarks: '備考',
      DesiredDateOfVisit: 'ご来店希望日',
      ContentOfConsultation: 'ご相談内容',
      close: '閉じる',
      save: '保存',
    },
    ConnectExistingCustomer: {
      ConnectWithExistingCustomers: '既存顧客に結びつける',
      CustomerID: '顧客番号',
      close: '閉じる',
      save: '保存',
    },
    notice: {
      ShowUnpublishedPosts: '非掲載投稿も表示',
      notice: 'お知らせ',
      CreatedDate: '作成日',
      status: 'ステータス',
      title: 'タイトル',
      Contents: '内容',
      image: '画像',
      CreateUser: '作成ユーザー',
      edit: '編集',
      ScheduledToStartPosting: '掲載開始予定',
      ScheduledToEndPosting: '掲載終了予定',
      important: '重要',
      draft: '下書き',
      NotificationCreation: 'お知らせ作成',
    },
    noticeInformationEditing: {
      DraftEditing: '下書き編集',
      CreationDate: '作成日',
      delete: '削除',
      SaveAsDraft: '下書きとして保存',
      PostedAsImportantNotice: '重要お知らせとして投稿',
      Post: '投稿',
      InformationEdit: 'お知らせ編集',
      Hide: '非掲載にする',
      keep: '保存',
      NotPosted: '非掲載',
    },
    noticeDetail: {
      back: 'お知らせ一覧に戻る',
      date: '作成日：',
      title: 'タイトル',
      checkbox: '重要',
      text: '本文',
      delete: '削除',
      update: '保存',
      push: '掲載',
      UploadImageFile: '画像ファイルをアップロード',
      ImagePreview: '画像プレビュー',
    },
    routeName: {
      info: 'WEBページ管理_既存顧客重複',
      documentRequest: 'WEBページ管理_資料請求',
      oldCustomer: 'WEBページ管理_資料請求',
      infoDetail: 'WEBページ管理_資料請求_今津康平',
      bookingDetail: 'WEBページ管理_来店予約_今津康平',
      booking: 'WEBページ管理_来店予約',
      consultation: 'WEBページ管理_オンライン相談',
      consultationDetail: 'WEBページ管理_オンライン相談_今津康平',
      experience: 'WEBページ管理_オンライン相談',
      experienceDetail: 'WEBページ管理_体験宿泊_今津康平',
      facility: 'WEBページ管理_体験宿泊',
      facilitySetting: 'WEBページ管理_施設設定',
      notice: 'WEBページ管理_お知らせ',
      VisitReservationeDetail: 'WEBページ管理_お知らせ_新型コロナウイルス感染',
      customer: '東急太郎',
      VisitReservation: 'WEBページ管理_来店予約',
      webDocument: 'WEBページ管理_会員WEB資料',
    },
    facility: {
      HPFacilityInformationScreen: 'HP施設情報画面',
      memberPageFacilitySetting: '会員ページ施設設定',
      displayFacilityName: '表示施設名',
      facilityNameAbbreviation: '施設名(略称)',
      latitude: '緯度',
      longitude: '経度',
      addressAbbreviation: '住所(略称)',
      descriptionText: '説明文章',
      remarkSubject: '備考科目',
      otherRemarks: 'その他備考',
      searchScreenDescription: '検索画面説明',
      searchTags: '検索タグ',
      mainImage: 'メイン画像',
      remarkRoomType: '部屋タイプ別予約時備考',
      otherMemos: 'その他メモなど(会員ページ非表示)',
      otherNotes: 'その他メモ',
      selectFacility: '施設を選択',
      postalCode: '郵便番号',
      address: '住所',
    },
  },
  customerDetail: {
    tabs: {
      basicInformation: '基本情報',
      comment: 'コメント',
      activities: '活動',
      changeHistory: '変更履歴',
    },
    activity: {
      scheduledActionDate: '行動予定日',
      personInCharge: '担当者',
      means: '手段',
      opponent: '相手',
      priority: '優先度',
      negotiationDate: '折衝日',
      cti: 'CTI',
      complaintHandling: 'クレーム対応',
      activityHistoryRegistration: '活動履歴登録',
      equalizationDay: '折衡日',
      registrationCti: 'CTI経由 活動履歴登録',
    },
    changeHistory: {
      beforeChange: '変更前',
      afterChange: '変更後',
    },
    infoEdit: {
      customerInfo: '顧客情報',
      customerRecordType: '顧客レコードタイプ',
      customerLastName: '顧客名（姓）',
      customerName: '顧客名（名）',
      furiganaLastName: 'フリガナ（姓）',
      furiganaName: 'フリガナ（名）',
      membershipNumber: '会員番号',
      customerNumber: '顧客番号（コード？）',
      contractDate: '契約日',
      withdrawal: '退会',
      withdrawalDate: '退会日',
      reasonForWithdrawal: '退会理由',
      totalCustomer: '顧客総数',
      country: '国',
      zipCode: '郵便番号(自宅）',
      prefecture: '都道府県(自宅）',
      city: '市区郡(自宅）',
      townStreet: '町名・番地(自宅）',
      buildingName: 'ビル名、マンション名等(自宅）',
      personalBudget: '個人予算',
      overallBudget: '営業全体予算',
      annualIncome: '年収',
      paymentDay: '入金日',
      depositDate: '入金予定日',
      depositAmount: '入金金額',
      depositType: '入金種別',
      depositAmountDifferent: '入金額差異',
      faceToFaceMethod: '対面方法',
      faceToFacepartner: '対面相手',
      shareWithStaff: '他のスタッフへの要共有事項',
      memberInfo: '会員情報',
      givenNames: '名前',
      relationship: '続柄',
      familyMember: 'ファミリー会員',
      deputyMember: '副会員',
      personInCharge: '担当者',
      saleStaff: '営業担当',
      responsibleStartDate: '担当開始日',
      contactInCharge: '担当連絡',
      introducer: '紹介者',
      contractInfo: '契約情報',
      numberOfContract: '成約数',
      contractAmount: '成約金額',
      contractUnitPrice: '成約単価',
      PWSelectionFacility1: 'PW選択施設①',
      PWSelectionFacility2: 'PW選択施設②',
      pointUtilizationRate: 'ポイント利用率',
      totalNumberOfPoints: '総ポイント数',
      totalPointUtilizationRate: '総ポイント利用率',
      renewalRate: '更新率（年）',
      updateRate: '更新率（月）',
      renewalRateByDivide: '更新率（商品別・ポイント数別）',
      numberContracts: '契約口数',
      reservationInformation: '予約情報',
      visitDay: '見学日',
      experienceFacilityName: '体験施設名',
      occupancyRate: '平日・週末・トップシーズン別の稼働率',
      reservationLeadTime: '予約リードタイム',
      reverberationInformation: '反響情報',
      echoSource: '反響ソース',
      echoDay: '反響日',
      numberOfReverberations: '反響数',
      contractRate: '成約率',
      facilitiesOfInterest: '興味のある施設',
      reasonWithdrawalConsideration: '検討離脱理由',
      reasonForWithdrawalOther: '検討離脱理由(その他)',
      individualBusinessPartner: '個人取引先: 年齢（問い合せ時）',
      responseAcquisition: '反響獲得単価',
      purposeOfUse: '利用目的',
      mainPurposeOfUse: '主な利用目的',
      purposeOfUseOfSub: 'サブの利用目的',
      workNameKanji: '勤務先名（漢字）',
      personalBusinessFuriganaLastName: '個人取引先: フリガナ（姓）',
      personalBusinessFuriganaName: '個人取引先: フリガナ（名）',
      salesPerson: '販売担当者',
      campaignPeriod: 'キャンペーン期間',
      pointForSP: 'ＳＰ年間使用ポイント数',
      possessionContract: '所持契約',
      subContract: 'サブ契約',
    },
  },
  myPage: {
    title: 'マイページ',
    titleTab: {
      myPage: 'マイページ',
      CustomerInCharge: '担当顧客',
      trialStay: '体験宿泊',
      faceToFace: '対面',
    },
    trialStay: {
      dateTrialStayAndVisit: '体験宿泊日・見学日',
      salesRepresentative: '営業担当者',
      customerNumber: '顧客番号',
      customerName: '顧客名',
      expFacilityName: '体験施設名',
      numOfNight: '宿泊数',
      cancellation: 'キャンセル',
      details: '詳細',
      VSEnrollmentDuplication: 'VS入会重複',
    },
    myPage: {
      ActivitySchedule: '活動予定',
      MoveToActivityHistory: '活動履歴に移動',
      OpenMemberInformation: '会員情報を開く',
      username: 'ユーザー名',
      scheduledTime: '予定時間',
      CustomerDetails: '顧客詳細',
      CustomerName: '顧客名',
      partner: '相手',
      means: '手段',
      priority: '優先度',
      action: 'アクション',
      edit: '編集',
    },
    CustomerInCharge: {
      PurchaseEvaluation: '購入評価',
      AdditionalpurchaseEvaluation: '買増評価',
      UpdateEvaluation: '更新評価',
      salesRepresentative: '営業担当者',
      subject: '件',
      CustomerNumber: '顧客番号',
      Lastname: '姓',
      CampaignName: 'キャンペーン名',
      Name: '名',
      Furigana: 'フリガナ',
      Echoday: '反響日',
      LastActivityDate: '最終活動日',
      evaluation: '評価',
      LatestActivityStatus: '最新活動状況',
      ItemsShared: '他のスタッフへの要共有事項',
      LatestCampaign: '最新キャンペーン',
      detail: '詳細',
    },
    ExperienceLodging: {
      ExperienceAccommodationDate: '体験宿泊日',
      VisitDay: '見学日',
      salesRepresentative: '営業担当者',
      cancel: 'キャンセル',
      subject: '件',
      detail: '詳細',
      CustomerNumber: '顧客番号',
      CustomerName: '顧客名',
      ExperienceFacilityName: '体験施設名',
      numberOfStays: '宿泊数',
      LastActivityDate: 'キャンセル',
    },
    Meeting: {
      CustomerRecordType: '顧客レコードタイプ',
      CustomerName: '顧客名',
      Faceday: '対面日',
      FacePartner: '対面相手',
      Facemethod: '対面方法',
      detail: '詳細',
      subject: '件',
      total: '総計',
      run: '実行',
      FurtherNarrowDown: 'さらに絞り込む',
    },
  },
  personalReport: {
    title: '個人レポート',
    searchTitle: '顧客/契約/活動検索',
    titleTab: {
      GLCList: 'GLCリスト',
      experience: '体験宿泊',
      meeting: '対面',
    },
    headerList: {
      responseNum: '営業担当者別反響件数',
      latest: '最新',
      usageStatus: '体験宿泊利用状況',
    },
    experience: {
      customerId: '顧客番号',
      customerName: '顧客名',
      comingDate: '体験宿泊日・見学日',
      comingLocation: '体験施設名',
      detail: '詳細',
    },
    meeting: {
      total: '顧客の対面件数 ',
      meetingInfo: '対面情報',
      contractInfo: '契約情報',
      experienceInfo: '体験情報',
      comingDate: '体験宿泊日・見学日',
      comingLocation: '体験施設名',
      productName: '商品名（システム用）',
      pointByYear: 'SP年間ポイント数',
      customerType: '顧客レコードタイプ',
      customerName: '顧客名',
      personalAge: '個人引取先・年齢',
      address: '等道府県（自宅）',
      responseSource: '反響ソース',
      applyMethod: '申込方法',
      saleAmount: '営業売上金額',
      salePerson: '販売担当者',
      meetingDate: '対面日',
      meetingMethod: '対面方法',
      meetingPartner: '対面相手',
      detail: '詳細',
    },
  },
  advertising: {
    title: '広告施策',
    search: '検索',
    detail: '詳細',
    buttonAdd: '新規広告施策',
    lastUpdate: '最終更新者',
    stage: '広告施策期間',
    personInCharge: '担当者',
    addCustomer: '対象顧客追加',
    note:
      '※ 顧客情報または契約情報が存在しない場合のみ、下部の削除ボタンより削除することができます',
    routeName: {
      advertising: '広告施策',
      advertisingPoliciesDetail: 'インターネット検索',
      /* policy -.- */
      contractSpecialDetail: '東急グループ社員　紹介特典',
      /* advertising > contractSpecialDetail */
    },
    tabs: {
      policies: '広告施策リスト',
      contractSpecial: '契約特典リスト',
    },
    policies: {
      advertisingName: '広告施策名',
      policiesName: '契約特典名',
      customerNum: '広告施策顧客数',
      contractRate: '成約率 (成約数/反響数)',
      cost: '費用',
      contractAmount: '成約金額',
      advertisingPeriod: '広告施策期間',
      advertisingData: '発送資料データ',
      back: '広告施策一覧に戻る',
      feedbackQuantity: '反響数',
      numOfStatedTrading: '取引開始済みの反響数',
      customerQuantity: '顧客数',
      description: '説明',
      customerName: '顧客',
      address: '住所',
      phone: '電話番号',
      age: '年齢',
      evalution: '評価',
      latestStatus: '最新折衝状況',
      contractPoint: '契約ポイント数',
      salePrice: '営業売上金額',
      salesRepresentative: '販売担当',
      uploadFile: 'ファイルアップロード',
      alsoShowsSupported: '対応済みも表示',
      detail: '広告施策詳細',
      groupName: '広告施策タグ名',
      implemenCost: '実施広告費用',
      overYearsOld: '代以上',
      awarenessExpansion: '認知拡大',
      harvestingPolicy: '刈取施策',
      promotionOfUse: '利用促進',
      method: '手段',
      signboard: '看板',
      emailNewsletter: 'メルマガ',
      createNewDescription:
        'ホームページからの資料請求において、インターネット検索をきっかけと選択していた方、ホームページを見て電話で問い合わせがあった方が主な対象。ホームページを見るに至るきっかけがヒアリングできた際は履歴か備考に記載をお願いします。',
      uploadShippingMaterialData: '発送資料データをアップロード',
      selectTargetResponseMedia: '対象反響媒体選択(資料請求自動紐付け用)',
      trainAdvertisement: '電車広告',
      tokyuInCarAdvertisement: '東急線　車内広告',
      tokyuStationAdvertisement: '東急線　駅構内広告',
      JAJVacations: 'JALバケーションズ',
      detailNotice:
        'ホームページからの資料請求において、インターネット検索をきっかけと選択していた方、ホームページを見て電話で問い合わせがあった方が主な対象。ホームページを見るに至るきっかけがヒアリングできた際は履歴か備考に記載をお願いします。',
      detailFirstHeading: '広告施策実施対応顧客',
      detailSecondHeading: '広告施策が紐づいた契約',
      implementationOrReaction: '表示する実施・反応',
      corporateContactPerson: '法人窓口担当者',
      corporateContactPersonTitle: '法人窓口担当者役職名',
      contractStatus: '契約ステータス',
      advertisingPolicyGroup: '広告施策グループ',
      totalReactions: '反応総数',
      newCustomer: '新規',
      targetEchoMediumSelection: '対象反響媒体選択',
    },
    contractSpecial: {
      benefitItems: '特典項目',
      targetCustomerNum: '対象顧客数',
      back: '契約特典一覧に戻る',
      contractPrivilegePeriod: '契約特典期間',
      content: '内容',
      targetPersons: '対象者及び条件',
      newContractBenefits: '新規契約特典',
      contractPrivilegeName: '契約特典名',
      thanksForSupport: '対応よろしくお願いいたします',
      contractName: '契約名',
      contractID: '契約ID',
      contractor: '契約者',
      company: '個人/法人',
      contractDate: '契約日',
      contractPeriod: '契約期間',
      paymentDate: '入金日',
      deposit: '入金金額',
      echoPath: '反響経路',
      applicationDate: '申込日',
      salesRepresentative: '販売担当',
      exchangePoints: 'ポイント交換',
      benefitName: '特典名',
      numericType: '数値種別',
      numericalValue: '数値',
      automaticAfterJoiningVS: 'VS入会後自動特典付与',
      pointOrFee: 'ポイント/料金',
      numberOfContracts: '対象契約数',
      contractBenefitsDetails: '契約特典詳細',
      contractBenefits: '契約特典内容',
      fillChecbox: '金額/ポイント/マイル数を記入する',
      entryValue: '記入数値',
      issueCodeAfterJoiningVS: 'VS入会後自動特典付与コードを発行する',
      benefitCode: '特典コード',
      discountMembership: '年会費O円引き',
      tokyuPointsGranted: '東急バケーションズポイント付与',
    },
    detailListContract: {
      title: '契約特典名',
      buttonClose: '閉じる',
      buttonSave: '保存',
      buttonForm: '広告施策内容編集',
      titleTable: '契約特典が紐づいた契約',
      label: '表示する特典内容付与状況',
      titleDialog: '新規契約特典',
      status: 'ステータス',
      nameAdd: '広告施策名',
      calendar: '期間',
      titleArea: '契約特典内容',
      checkboxForm1: '金額/ポイント/マイル数を記入する',
      labelOption: '記入数値',
      amountMonney: '金額',
      point: 'ポイント',
      miles: 'マイル数',
      valueNumber: '数値',
      grantPermission: 'VS入会後自動特典付与コードを発行する',
      benefitCode: '特典コード',
      valueNumberEnter: '記入数値',
      membershipFeeAnual: '年会費O円引き',
      vacationsPointsAwarded: '東急バケーションズポイント付与',
    },
  },
  customerMember: {
    title: '顧客/会員',
    searchTitle: '顧客/契約/活動検索',
    list: {
      corporateIndividualClassification: '法人個人区分',
      usageRightYear: '利用権年',
      purchaseFacility: '購入施設',
      roomType: '部屋タイプ',
      numberOfWords: '口数',
      itemsInPossession: '所持している商品',
      yearEachProduct: '商品毎の 年数',
      membershipDeadline: '会員期限',
      deadlineLastProduct: '最後に終わる 商品の期限',
      shippingAddress: '送付先住所',
      purchaseRank: '購入ランク',
      advertisingMeasures: '広告施策',
    },
    detail: '詳細',
    tabNames: {
      applicant: '願客リスト',
      response: '反響リスト',
      member: '会員リスト',
    },
    tabChild: {
      all: '全て',
      responseDM: '既反響DM',
      followCustomer: 'D客フォロー',
      takenPlace: '体験宿泊',
      face2Face: '対面',
    },
    experienceLodging: {
      facilityDate: '体験宿泊日・見学日',
      facilityName: '体験施設名',
    },
    headerList: {
      productName: '商品名',
      totalCustomerInCharge: '担当顧客総計',
      numberOfResponses: '営業担当者別反響件数',
      residence: '居住地',
      pastEchoCampaign: '過去反響キャンペーン',
      lastEchoDay: '最終反響日',
      faceToFaceCorrespondence: '対面対応',
    },
    reactionDM: {
      createdDate: '作成日',
      membershipNumber: '会員番号',
      customerName: '顧客名',
      age: '個人引取先・年齢（問い合わせ時)',
      latestCampaign: '最新キャンペーン',
      detailAddress: 'ビル名・マンション名など（自宅)',
      latestActivity: '最新活動状況',
      paymentStatus: '退会',
      businessPartner: '個人取引先（資料送付NG)',
      lastActivityDate: '最終活動日',
      lastUpdated: '最終更新日',
      customerNumber: '顧客番号',
    },
    customerFollowList: {
      echoDay: '反響日',
      membershipNumber: '会員番号',
      lastActivityDate: '最終活動日',
      listShare: '他のスタッフへの要共有事項',
      latestStatus: '最新活動状況',
      customerNumber: '顧客番号',
      detailAddress: 'ビル名、マンション名など（自宅)',
    },
    meetingList: {
      meetingInfo: '対面情報',
      contractInfo: '契約情報',
      experienceInfo: '体験情報',
      tourDate: '体験宿泊日・見学日',
      facilityDate: '体験施設日',
      productName: '商品名（システム用)',
      annualPoints: 'SP年間ポイント数',
      recorderType: '顧客レコーダタイプ',
      age: '個人引取先：年齢',
      echoSource: '反響ソース',
      applyMethod: '申込方法',
      salesAmount: '営業売上金額',
      salesPerson: '販売担当者',
      meetingDate: '対面日',
      meetingMethod: '対面方法',
      meetingPartner: '対面相手',
    },
    member: {
      tabChild: {
        all: '全て',
        resignation: '退会・元会員',
        expiredMember: '満了会員',
        multipleEntry: '複数口',
        introduces: '紹介者',
      },
      introducesList: {
        memberNum: '会員番号',
        customerName: '顧客名',
        productName: '商品名（システム用）',
        introducer: '紹介者',
      },
      expiredMemberList: {
        memberNum: '会員番号',
        productName: '商品名',
        sysProductName: '商品名（システム用）',
        SPAnnualPoint: 'SP年間ポイント数',
        expirationDate: '契約満了日',
        chargePerson: '担当者',
        narrowDownProducts: '商品を絞り込む',
      },
      multipleEntryList: {
        memberNum: '会員番号',
        customerName: '顧客名',
        sysProductName: '商品名（システム用）',
        expirationDate: '契約満了日',
        contractNum: '契約口数',
      },
      resignationList: {
        customerInfo: '顧客情報',
        contractInfo: '契約情報',
        resignation: '退会',
        resignationDate: '退会日',
        resignationNum: '退会番号',
        customerName: '顧客名',
        sysProductName: '商品名（システム用）',
        contractExDate: '契約満了日',
        reasonResignation: '退会理由',
      },
    },
  },
  customerCreate: {
    Individual: '個人',
    Corporation: '法人',
    CustomerName: '顧客名',
    Surname: '姓',
    Name: '名',
    Furigana: 'フリガナ',
    close: '閉じる',
    save: '作成',
  },
  contract: {
    routeName: {
      contract: '契約',
      sp: '野田健太:SP契約',
      mw: '野田 健太:MW契約',
      pw: '野田健太:PW契約',
    },
    contractList: {
      createNewContract: '新規契約作成',
    },
    contractTab: {
      ShowExpiredContracts: '満了済みの契約も表示',
      all: '全て',
      UndigestedList: '権利未消化リスト',
    },
    contrac_all_UndigestedList: {
      status: 'ステータス',
      ContractProduct: '契約商品',
      ContractNumber: '契約番号',
      Phase: 'フェーズ',
      ContractStartYear: '契約開始年',
      ContractTerminationYear: '契約終了年',
      contract: '契約詳細',
      Individual: '個人',
      Corporation: '法人',
      CustomerName: '顧客名',
      SalesRepresentative: '営業担当者',
      CustomerDetails: '顧客詳細',
    },
    usageStatus: {
      usageSituation: '利用状況',
      mwContract: 'MW契約',
      reservationId: '予約ID',
      accommodationStartDate: '宿泊開始日',
      accommodationEndDate: '宿泊終了日',
      cashEquivalent: 'MW現金換算額',
      rightsFacility: '権利施設',
      depositCashEquivalent: 'デポジット現金換算額',
      expiredMWContract: '期限切れのMW契約',
      contractChangeHistory: '契約変更履歴',
      returnPoints: '返還ポイント',
      pointId: 'ポイントID',
      changer: '変更者',
      SPTotal: 'SP合計',
    },

    manager: {
      list: '一覧',
      management: '担当者',
      manager: '管理',
      group: 'グループ',
      CreateNewGroup: '新規グループ作成',
      SelectAGroup: 'グループを選択',
      AddTheCheckedPersonToTheSelectedGroup:
        'チェックした担当者を選択したグループに追加',
      LastLogin: '最終ログイン',

      ShowDisabledUsers: '無効ユーザーも表示',
      salesRepresentative: '営業担当者',
      mentionName: 'メンション名',
      DuringContractProcedure: '担当総数',
      Good: '購入評価 契約手続き中',
      Cool: '購入評価 Good',
      Dead: '購入評価 Cool',
      NextActivitySchedule: '次の活動予定',
      NextActivityScheduledTime: '次の活動予定時間',
      UserID: 'ユーザーID',

      groupName: 'グループ名',
      numOfPersons: '人数',
      member: 'メンバー',

      GroupEditing: 'グループ編集',
      delete: '削除',
      save: '保存',
      close: '閉じる',
      SalesRepresentative: '営業担当者',
    },
    contractInformation: {
      basicInformation: 'SP契約基本情報',
      detailInformation: 'SP契約詳細情報',
      contractNumber: '契約番号',
      salesCategory: '販売区分',
      contractYearStartDate: '契約年度開始日',
      contractYears: '契約年数',
      priceInformation: '料金情報',
      basicPoints: '基本ポイント',
      additionalPoint: '契約時追加ポイント単価',
      membershipFee: '入会時メンバーシップフィ',
      membershipFeeConsumption: '入会時メンバーシップフィ消費税',
      carryoverFee: '繰越手数料',
    },
  },
  task_29786: {
    CreateNewAccommodationReservation: '新規宿泊予約作成',
    keep: '保存',
    close: '閉じる',
    Overview: '概要',
    MembershipContract: '会員契約',
    ProspectInformation: '見込客情報',
    BasicInformation: '基本情報',
    Activities: '活動',
    BillingHistory: '請求作成履歴',
    changeHistory: '変更履歴',
    delete: '削除',
    ContractInformation: '契約情報',
    CorporateTicket: '法人チケット',
    Individual: '個人',
    CustomerID: '顧客番号',
    CustomerName: '顧客名',
    Furigana: 'フリガナ',
    sex: '性別',
    DateOfBirthAge: '生年月日-年齢',
    PrefectureCity: '都道府県-市区郡',
    PhoneContactPhoneNumber: '電話連絡先電話番号',
    Profession: '職業',
    child: '子供',
    annualIncome: '年収',
    ViceMemberInformation: '副会員情報',
    PersonInChargeInformation: '担当者情報',
    ItemsToBeSharedWithOtherStaff: '他のスタッフへの要共有事項',
    ReservationInformation: '予約情報',
    edit: '編集',
    comment: 'コメント',
    EnterAComment: 'コメントを入力',
    page: 'ページ',
    username: 'ユーザー名',
    status: 'ステータス',
    ContractNumber: '契約番号',
    ContractProduct: '契約商品',
    Phase: 'フェーズ',
    ContractStartYear: '契約開始年',
    ContractTerminationYear: '契約終了年',
    dateOfIssue: '発行日',
    TicketNo: 'チケットNo',
    NumberOfIssues: '発行枚数',
    name: '名称',
    ExpirationDate: '失効期限',
    ReservationStartDate: '予約開始日',
    ReservationPeriod: '予約可能期間',
    NumberOfSheetsUsed: '利用枚数',
    Available: '利用可能',
    relationship: '続柄',
    age: '年齢',
    SalesRepresentativeStartDate: '営業担当開始日',
    Name: '名前',
    contract: '契約',
    room: '部屋',
    plan: 'プラン',
    checkIn: 'チェックイン',
    checkOut: 'チェックアウト',
    Representative: '代表者',
    adult: '大人',
    BedSharing: '添寝',
    parking: '駐車場',
    RentalListing: '貸出品',
    LastModifiedDate: '最終変更日時',
    ReservationDate: '予約日',
    NotificationMember: '通知先会員',
    NotificationMemberName: '通知先会員名',
    kinds: '種別',
    ReservationID: '予約ID',
    RegistrationDate: '登録日',
    ReservationEdit: '予約編集',
    institution: '施設',
    LastDay: '最終日',
    NumberOfNights: '泊数',
    ParkingStartDate: '駐車開始日',
    LastDayOfParking: '駐車最終日',
    CancelNotification: '通知解除',
    ProspectInformationTab: {
      ProspectInfor: '見込客情報',
      NoNegotiation: '折衝不可',
      PurchaseEvaluation: '購入評価',
      UpdatedByActivityHistoryInput: '活動履歴入力で更新される',
      AdditionalPurchaseEvaluation: '買増評価',
      UpdateEvaluation: '更新評価',
      ReasonForWithdrawalFromConsideration: '検討離脱理由',

      EchoDay: '反響日',
      DesiredSeason: '希望シーズン',
      FacilitiesOfInterest: '興味のある施設',

      manager: '担当者',
      salesRepresentative: '営業担当者',
      WEBApplicationHistory: 'WEB申込履歴',
      DocumentRequest: '資料請求',
      VisitReservation: '来店予約',
      Supported: '未対応',
      NotCompatible: '対応済み',

      OnlineConsultation: 'オンライン相談',
      AnnualFee: '年会費',
      AdditionOfAdvertisingMeasures: '広告施策追加',
      addition: '追加',
      AddedWithReaction: '反応有で追加',
      SalesRepresentativeStartDate: '営業担当開始日',
      name: '名前',
      ReceivedDate: '受信日',
      BillingMaterials: '請求資料',
      remarks: '備考',
      AdvertisingMeasures: '広告施策',
      AdvertisingDetails: '広告施策詳細',
      AdvertisingPolicyAdaptation: '広告施策適応方法',
      edit: '詳細',
      status: 'ステータス',
      FirstChoiceDate: '第一希望日',
      SecondChoiceDate: '第二希望日',
      NumberOfTimes: '回数',
      AdvertisingMeasureName: '広告施策名',
      AdvertisingPolicyGroupName: '広告施策グループ名',
      reaction: '反応',
      DateOfAddition: '追加日',
      explanation: '説明',
      ShippingMaterials: '発送資料',
      delete: '削除',
      detail: '詳細',
    },
    CustomerMembership: {
      contract: '契約',
      DeleteSelectedYear: '選択した年を削除',
      AddSelectedYear: '選択した年を追加',
      MembershipNumber: '会員番号',
      EmailAddress: 'ログインメールアドレス',
      PasswordSettingLink: 'パスワード設定リンクを送信',
      SendNewsletter: '会報誌送付希望',
      UsageGuide: '利用ガイド送付希望',
      CorporateMemberPaymentMethod: '法人会員支払い方法',
      AccommodationFee: '宿泊料',
      UsageFee: '利用料',
      SetupFee: 'セットアップ料',
      OptionalCleaningFee: 'オプション清掃料',
      BathTax: '入湯税',
      OccupancyTax: '宿泊税',
      MemberName: '会員名',
      Furigana: 'フリガナ',
      Birthday: '生年月日',
      age: '年齢',
      relationship: '続柄',
      sex: '性別',
      ViceMemberRemarks: '副会員備考',
      FamilyMember: 'ファミリー会員',
      AddFamilyMembers: 'ファミリー会員を追加',
      DeleteViceMembers: '副会員を削除',
      DeleteFamilyMembers: 'ファミリー会員を削除',
      Number: '件数',
      AddUseCancelButton: '追加/利用・取り消し',
      TokyuVacationsPoint: '東急バケーションズポイント',
      OwnedPoints: '保有ポイント',
      BuyGiftTickets: 'ギフトチケット購入',
      addition: '追加',
      use: '利用',
      cancel: '取り消し',
      MakingReservationsAffiliatedFacilities: '提携施設予約作成',
      GiftTicketOrderConfirmationList: 'ギフトチケット注文確認一覧',
      AnnualFee: '年会費',
      orderNumber: '注文番号',
      ApplicationService: '申し込みサービス',
      number: '件数',
      TicketIssuerEmailOpenConfirmation: 'チケット発行会社メール開封確認',
      MessageSendingDateAndTime: 'メッセージ送信日時',
      Year: '年',
      ScheduledBillingMonth: '予定請求月',
      PaymentRemarks: '支払い備考',
      consumptionTax: '消費税',
      MembershipFee: 'メンバーシップフィー',
      ManagementFee: '管理費',
      HotSpringMaintenanceFee: '温泉維持費',
      Change: '変更',
      DateAndTime: '日時',
      DateConfirmed: '請求作成日',
      CreateNewVSContract: 'VS契約作成',
      CreateNewVMContract: 'VM契約作成',
      status: 'ステータス',
    },
    ActivitiesTab: {
      ActivitySchedule: '活動予定',
      ActivityScheduleRegistration: '活動予定登録',
      MoveToActivityHistory: '活動履歴に移動',
      ActivityHistory: '活動履歴',
      ScheduledActivityDate: '活動予定日',
      ActivityPerson: '活動担当者',
      partner: '相手',
      means: '手段',
      action: 'アクション',
      priority: '優先度',
      edit: '編集',
      NegotiationDate: '折衝日',
      Status: '状況',
      ComplaintHandling: 'クレーム対応',
      CustomerRatingChange: '顧客評価変更',
      CTIPhoneHistory: 'CTI電話履歴',
      ActivityHistoryRegistration: '活動履歴登録',
    },
  },
  report: {
    dowloadCSVNotice: 'ボタンを選択してCSVダウンロード',
    notice: '※範囲ではなく特定の日付を要求するレポートに関しては、期間の終了日が条件として利用されます',
    linkDownload: {
      weeklyReportList: '週報リスト',
      listByCampaign: 'キャンペーン別集計一覧',
      campaignList: 'キャンペーン一覧',
      detailedListByCampaign: 'キャンペーン別詳細一覧',
      numberOfMonthlyContracts: '月別契約数',
      purchaseMotivationRatio: '購入動機比率',
      listOfPurchaseMotives: '購入動機一覧',
      purposeOfUseRatio: '利用目的比率',
      numberOfContractsByRegion: '地域別契約数',
      newMembersByYear: '年度別新規入会者',
      genderRatio: '男女比',
      numberOfContractPoints: '成約ポイント数',
      individualOrCorporateRatio: '個人、法人比率',
      reasonForWithdrawal: '検討離脱理由',
      averageUnitPriceByProduct: '商品別平均単価',
      howToApplyForAContract: '成約申込方法',
      numberOfOvernightStaysByMonth: '月別体験宿泊件数',
      numberOfOvernightStaysByFacility: '施設別体験宿泊件数',
      contractAndExperienceInformation: '契約情報と体験情報',
      contractRateFromExperience: '体験からの成約率',
      contractRateForEachFacility: '施設ごとの成約率',
      faceToFaceContractRateWithRate: '対面の成約率・対面率',
      contractBenefitSummaryReport: '契約特典集計レポート',
      tallyOfFacilitiesOfInterestAtTheTimeOfResponse:
        '反響時の興味のある施設集計',
      salesRatioToBudget: '予算比販売率(金額、口数)',
      numberOfDaysToCloseReport: '成約日数レポート',
      annualRevenueReport: '年収レポート率',
      memberPointUsageRate: '会員ポイント利用率',
      facilityOccupancyRate: '施設稼働率',
      facilityUtilizationRateByMember: '会員様ごとの施設利用率',
      corporateTicketUsageData: '法人チケット利用データ',
      paymentProcessingHistory: '入金処理履歴',
      withdrawalProcessingHistory: '退会処理履歴',
      usageRateOfRentalItems: '貸出品利用率',
      renewalRate: '更新率',
      listOfProductUsageResults: '各商品利用実績一覧',
      customerVoiceList: 'お客様のお声リスト',
      salesFigures: '営業数字一覧',
      listOfMembersAndNumberOfContracts: '会員、契約口数一覧',
    },
  },
  giftTicket: {
    orderConfirmationList: 'ご注文確認一覧',
    orderNumber: '注文番号',
    applicant: '申込者',
    corporateOrIndividual: '法人/個人',
    applicationService: '申し込みサービス',
    numberOfCase: '数量',
    ticketIssuerEmailOpenConfirmation: 'チケット発行会社メール開封確認',
    dateTimeSending: 'メッセージ送信日時',
  },
  invitationsAndCoupons: {
    backToInvitationList: '招待券・利用券一覧に戻る',
    dateOfIssue: '発行日',
    invitationTicketNo: '招待券・利用券 No.',
    ticketIssueNum: '発行 枚数',
    expirationDate: '失効期限',
    reservationStartDate: '予約開始日',
    deadlineForUse: '利用期限',
    reservationPeriod: '予約可能期間',
    sheetsUsedNum: '利用 枚数',
    available: '利用 可能',
    authenticationNum: '認証用番号',
    customerName: '顧客名',
    enabledOrDisable: '有効/無効',
    applicationDate: '申込日',
    exportToCSV: '選択項目をCSV出力',
    copyAndCreate: 'コピーして新規作成',
    createNewInvitationTitle: '新規招待券・利用券発行',
    reservedPath: '予約経路',
    dayOfWeek: '曜日',
    exclusionDay: '除外日',
    userOrNot: '利用有無',
    nightwear: 'ナイトウェア',
    toothbrush: '歯ブラシ',
    shaving: '髭剃り',
    itemAvailableRent: '貸出品利用可能',
    amountUsed: '利用金額',
    free: '無料',
    fixedFree: '固定料金',
    taxInclude: '(税込)',
    cancelPolicy: 'キャンセルポリシー',
    amountRightReserved: '権利設定額',
    fixedAmountRightSetting: '権利設定定額',
    numSheetUser: '利用枚数',
    numSheetAvailable: '利用可能枚数',
    lastUpdatedBy: '最終更新者',
    callCenter: 'コールセンター',
    sales: '営業',
    note:
      '※ 招待券・利用券が未使用の場合は、下記の「削除」より削除することができます。',
    invitationNo: '利用券・招待券 No.',
    numSheetIssued: '発行枚数',
    ticketNo: 'チケットNo.',
    representativeName: '代表者名',
    ticketDetail: 'チケット詳細',
    exportTicketNumToCSV: 'チケット番号をCSV出力',
    editInvitation: '招待券・利用券編集',
  },
  corporateTicket: {
    TokyuCorporation: '東急株式会社',
    customerDetail: '顧客詳細',
    TicketDetails: 'チケット詳細',
    CSVuOtputfOTicketNumber: 'チケット番号をCSV出力',
    applicationDate: '申込日',
    EnabledDisabled: '有効/無効',
    RepresentativeAddress: '代表者住所',
    RepresentativePhoneNumber: '代表者電話番号',
    RepresentativeName: '代表者名',
    AuthenticationNumber: '認証用番号',
    lastUpdate: '最終更新者',
    ExpirationaDte: '利用期限',
    remarks: '備考',
    AvailableNumberOfSheets: '利用可能枚数',
    NumberOfSheetsUsed: '利用枚数',
    RightSettingFixedAmount: '権利設定定額',
    ReservationPeriod: '予約可能期間',
    ReservationStartDate: '予約開始日',
    ExpirationDate: '失効期限',
    ReservationRoute: '予約経路',
    UsageAmount: '利用金額',
    WhetherToUse: '利用有無',
    ExclusionDate: '除外日',
    DayOfTheƯeek: '曜日',
    contractStatus: 'ステータス',
    date: '日付',
    roomTypeInfo: '部屋タイプ情報',
    title: '法人チケット',
    nameSearch: '表示',
    dateRange: '発行日',
    nameCompanyCustomer: '法人顧客名',
    numberTicket: 'チケットNo.',
    numberProblem: '発行枚数',
    name: '名称',
    basis: '施設',
    placeHoderFormSearch: 'チケットNo.検索',
    expirationDate: '失効期限',
    bookingDate: '予約開始日',
    bookingTime: '予約可能期間',
    numberOfSheetUsed: '利用 枚数',
    available: '利用 可能',
    corporateTicketBookingCreation: '法人チケット予約作成',
    selectAFacility: '施設を選択',
    childNonSleeping: '子供(非添い寝)',
    childCoSleeping: '子供(添い寝)',
    remark: '予約バーに表示させる備考内容',
    lodgingRepresentative: '宿泊代表者',
    lodgingRepresentativeYomigana: '宿泊代表者ヨミガナ',
    selectACountry: '国を選択',
    routeName: {
      corporateTicket: '法人チケット',
      detail: '法人チケット詳細',
      ticketDetail: '法人チケット・チケット詳細',
    },
  },

  VacationLife: {
    viewFile: 'ファイルを表示',
  },
  reservationCalendar: {
    title: '枠と利用契約と人数を入力してください',
    titleNoGuest: '枠を選択してください',
    select: '選択中',
    checkIn: 'チェックイン',
    facility: '施設',
    typeRoom: '部屋タイプ',
    datePicker: '箱根強羅',
    mwWeek: 'MWPW週番号',
    emptyRoom: '空室通知件数',
    frameNumber: '枠数表示',
    oldInventoryType: '変更元',
    numberFrames: '枠数',
    newInventoryType: '変更先',
    applicableRoomType: '適用部屋タイプ',
    scopeOfApplication: '適用範囲'
  },
};
