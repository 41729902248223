const emptyDialog = () => ({
  message: '',
  title: '',
  cancelText: '',
  okText: '',
  ok: null,
  cancel: null,
  type: 'confirm'
})
const setNextDialog = (state) => {
  state.dialogs.pop()
}
const getCurrentDialog = state => state.dialogs[state.dialogs.length - 1]
const confirmDialog = {
  state: {
    dialogs: [],
  },
  getters: {
    confirmDialog: state => getCurrentDialog(state)
  },
  mutations: {
    hideConfirmDialog: (state) => {
      getCurrentDialog(state)?.cancel?.()
      setNextDialog(state)
    },
    confirmDialogOkClick: state => {
      getCurrentDialog(state)?.ok?.()
      setNextDialog(state)
    },
    addConfirmDialog: (state, dialog) => {
      state.dialogs.push(dialog)
    }
  },
  actions: {
    async confirmDialog({commit}, {
      title, message, okText, cancelText, ok, cancel, type, warning
    }) {
      const dialog = {
        ...emptyDialog(),
        title,
        message,
        okText,
        cancelText,
        ok,
        cancel,
        type: type || 'confirm',
        warning: warning ?? ''
      }
      commit('addConfirmDialog', dialog)
    }
  }
}

export default confirmDialog;
