import actions from './actions'

const staffGroupList = {
  state: {
    staffGroupList: []
    // name: ''
  },
  getters: {
    getStaffGroupList (state) {
      return state.staffGroupList
    }
    // getNameList(state) {
    //   return state.name;
    // },

  },
  mutations: {
    setStaffGroupList(state, arr) {
      state.staffGroupList = arr
    }
    // setNameList(state, arr) {
    //   state.name = arr;
    // },
  },
  actions
}

export default staffGroupList
