import actions from './actions';

const bookingTicket = {
  state: {
    total: 0,
    take: 30,
    skip: 0,
    bookingTicketTypeList: [],
    bookingTicketTypeDetail: {},
    direction: 'DESC'
  },
  getters: {
    getTotalBookingTicketTypeList(state) {
      return state.total
    }
  },
  mutations: {
    setBookingTicketTypeList(state, arr) {
      state.bookingTicketTypeList = [...arr]
    },
    setBookingTicketTypeDetail(state, obj) {
      state.bookingTicketTypeDetail = {...obj}
    },
    setTotalBookingTicketTypeList(state, payload) {
      state.total = payload
    },
    setBookingTicketTypeListTake(state, payload) {
      state.take = payload
    },
    setBookingTicketTypeListSkip(state, payload) {
      state.skip = payload
    },
    setBookingTicketTypeListSortDirection(state, payload) {
      state.direction = payload
    }
  },
  actions
}

export default bookingTicket
