export const SEARCH_CLIENT_LIST = `
query ($filter: ClientFilterSearchInput!, $orderBy: [OrderByInput!], $pagination: PaginationInput!) {
    searchClientList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
        items {
            id
            isCompany
            name
            lastBooking {
                checkedOutAt
                id
                checkInDate
                checkOutDate
                room { roomType { facility { name } } }
                computed {
                    status
                }
            }
            pic { name}
        }
        total
    }
  }
`