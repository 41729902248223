import { apolloClient } from "@/plugins/apollo";
import {
    WEB_REQUESTLIST_DOCUMENT_REQUESTLIST,
    WEBREQUEST_NOT_ISCOPANY_DOCUMENT_REQUESTLIST,
    UPLOAD_DUPLICATE_EXISTING_CUSTOMERS,
} from "@/api/graphql/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getWebRequestlistDocumentRequestlist({ commit, state }) {
        const finishData = {
            hasPic: null,
            take: 10,
            skip: 0,
            forVm: true,
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${WEB_REQUESTLIST_DOCUMENT_REQUESTLIST}`,
                    variables: finishData 
                }).then(data => {
                    commit('setWebRequestlistDocumentRequestlist', data.data.webRequestList.items)
                    resolve(data.data.webRequestList.items)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, WEB_REQUESTLIST_DOCUMENT_REQUESTLIST, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    async getWebRequestlistOldCustomerlist({ commit, state }) {
        const finishData = {
            hasPic: null,
            take: null,
            skip: null,
            forVs: true,
          }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${WEB_REQUESTLIST_DOCUMENT_REQUESTLIST}`,
                    variables: finishData
                }).then(data => {
                    commit('setWebRequestlistOldCustomerlist',data.data.webRequestList.items)
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, WEB_REQUESTLIST_DOCUMENT_REQUESTLIST, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    async getWebrequestNotIscopanyDocumentRequestList({ commit, state },id) {
        const finishData = {
            id:parseInt(id)
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${WEBREQUEST_NOT_ISCOPANY_DOCUMENT_REQUESTLIST}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                }).then(async data => {
                    await commit('setWebrequestNotIscopanyDocumentRequestList',data.data.webRequest)
                    await commit('setClientRemarks',data.data.webRequest.remarks)
                    await commit('setClientPicID',data.data.webRequest.client.picId)
                    await resolve(data.data.webRequest)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, WEBREQUEST_NOT_ISCOPANY_DOCUMENT_REQUESTLIST, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    async UploadFileDuplicateExistingCustomers({ commit, state },file) {
        const finishData = {
            file
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${UPLOAD_DUPLICATE_EXISTING_CUSTOMERS}`,
                    variables: finishData
                }).then(data => {
                    commit(
                        'setAlertSuccess',
                        '顧客CSVのアップロードに成功しました',
                        { root: true },
                      );
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, UPLOAD_DUPLICATE_EXISTING_CUSTOMERS, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}