import actions from './actions';

const prospectInformation = {
  state: {
    enumPur: [],
    enumSeason: [],
    facilityList: [],
    enumNego: [],
    staffList: [],
    clientChange: [],
    proBrochures: [],
    proVisits: [],
    proOnlines: [],
    proTrials: [],
    dataTableEnd: [],
    selectTable: [],
    dataUpdate: {
      doNotContact: true,
    },
    responseAt: [
      { showStatusPro: true, name: '反応有' },
      { showStatusPro: null, name: '反応無' },
    ],
    showProVsinterest: [
      { id: 1, name: '反応有' },
      { id: 2, name: '反応無' },
    ],
    createCamApp: {
      response: true,
      campaignId: null,
    },
    clientBasicInfo: {}
  },
  getters: {
    getIdPro(state) {
      return state.id;
    },
    getClientBasicInfoPro(state) {
      return state.clientBasicInfo
    },
    getEnumPur(state) {
      return state.enumPur;
    },
    getEnumSeason(state) {
      return state.enumSeason;
    },
    getFacilityList(state) {
      return state.facilityList;
    },
    getEnumNego(state) {
      return state.enumNego;
    },
    getStaffList(state) {
      return state.staffList;
    },
    getClientChange(state) {
      return state.clientChange;
    },
    getProBrochures(state) {
      return state.proBrochures;
    },
    getProVisits(state) {
      return state.proVisits;
    },
    getProOnlines(state) {
      return state.proOnlines;
    },
    getProTrials(state) {
      return state.proTrials;
    },
    getDataTableEnd(state) {
      return state.dataTableEnd;
    },
    getSelectTable(state) {
      return state.selectTable;
    },
    getShowPro(state) {
      return state.responseAt;
      // return state.showStatusPro;
    },
    getShowProVsinterest(state) {
      return state.showProVsinterest;
    },
  },
  mutations: {
    setIdPro(state, payload) {
      state.id = payload;
    },
    setClientBasicInfoPro(state, payload) {
      state.clientBasicInfo = payload;
    },
    setEnumPur(state, payload) {
      state.enumPur = payload;
    },
    setEnumSeason(state, payload) {
      state.enumSeason = payload;
    },
    setFacilityList(state, payload) {
      state.facilityList = payload;
    },
    setEnumNego(state, payload) {
      state.enumNego = payload;
    },
    setStaffList(state, payload) {
      state.staffList = payload;
    },
    setClientChange(state, payload) {
      state.clientChange = payload;
    },
    setProBrochures(state, payload) {
      state.proBrochures = payload;
    },
    setProVisits(state, payload) {
      state.proVisits = payload;
    },
    setProOnlines(state, payload) {
      state.proOnlines = payload;
    },
    setProTrials(state, payload) {
      state.proTrials = payload;
    },
    setDataTableEnd(state, payload) {
      state.dataTableEnd = payload;
    },
    setSelectTable(state, payload) {
      state.selectTable = payload;
    },
    setDataUpdatePro(state, payload) {
      state.dataUpdate = payload.dataUpdate;
    },
  },
  actions,
};

export default prospectInformation;
