import actions from './actions';

const enumIncomeLevelList = {
    state: {
        enum: [],
        // id: null,
        // name: ''

    },
    getters: {
        getEnumIncomeLevelList(state) {
            return state.enum;
        },
    },
    mutations: {
        setEnumIncomeLevelList(state, arr) {
            state.enum = arr;
        },
    },
    actions,
};

export default enumIncomeLevelList;
