import { apolloClient } from '@/plugins/apollo';
import { GENERATE_UPLOAD_URL, CREATE_CONTRACT_ATTACHMENT } from '@/api/graphql/contract/contractDetail/sp_ep/UploadDocuments/uploadDocuments.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async createPointExchange({ commit, state, rootState, dispatch }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${GENERATE_UPLOAD_URL}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('actionContractTimeInformation')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GENERATE_UPLOAD_URL, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async createContractAttachmentUpload({ commit, state, rootState, dispatch }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
      fileName: state.fileName,
      fileKey: state.fileKey
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CONTRACT_ATTACHMENT}`,
          variables: finishData,
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true })
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          commit('setLoadingOverlayHide', { root: true })
          setCopyErrorTextCommit(commit, CREATE_CONTRACT_ATTACHMENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
