import actions from "./action";

const experience = {
    state: {
      totalWebRequestExperienceList: 0,
      listFilter: {
        skip: null,
        take: null  
      },
      webrequestlistExperience:[],
      webrequestlistExperienceOC:[],
      keywordExperience:'',
      handledExperience: null,
      statusOC: null,
      statusExperience: false,
      remarksOC: ''
    },
    getters: {
      getWebRequestExperienceList (state) {
        return state.totalWebRequestExperienceList
      },
      webrequestlistExperience(state){
        return state.webrequestlistExperience
      },
      keywordExperience(state){
        return state.keywordExperience
      },
      handledExperience(state){
        return state.handledExperience
      },
      setWebrequestlistExperienceOC(state){
        return state.setWebrequestlistExperienceOC
      },
      // statusOC(state){
      //   return state.statusOC
      // }
    },
    mutations: {
      setWebRequestExperienceListTake (state, take) {
        state.listFilter.take = take
      },
      setWebRequestExperienceListSkip (state, skip) {
        state.listFilter.skip = skip
      },
      setTotalWebRequestExperienceList (state, total) {
        state.totalWebRequestExperienceList = total
      },
      setWebrequestlistExperience(state,data){
        state.webrequestlistExperience = data
      },
      setWebrequestlistExperienceOC(state,data){
        state.webrequestlistExperienceOC = data
      },
      setKeywordExperience(state,val){
        state.keywordExperience = val
      },
      setHandledExperience(state,val){
        state.handledExperience = val
        if(val) {
          state.statusExperience = null
        } else {
          state.statusExperience = false
        }
      },
      setSatusOC(state,val){
        state.statusOC = val
      },
      setRemarksOC(state,val){
        state.remarksOC = val
      },
    },
    actions,
}

export default experience;