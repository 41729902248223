import gql from 'graphql-tag';

export const CONTRACT_LIST = gql`
  query($clientId: Int!) {
    contractList(
      filter: { clientId: $clientId, includeTentative: true }
      orderBy: { fieldName: "contract.id", direction: "DESC" }
    ) {
      items {
        id
        status
        productType {
          name
        }
        contractNumber
        phase {
          name
        }
        startDate
        endDate
      }
    }
  }
`;

export const CLIENT_MEMBER_INFO = `
  query($clientId: Int!) {
    clientMemberInfo(id: $clientId) {
      id
      isCompany
      name
      memberUser {
        id
      }
      subMembers {
        id
        subMemberType {
          name
        }
        name
        memberUser {
          id
        }
      }
    }
  }
`;
