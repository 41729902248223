import { apolloClient } from '@/plugins/apollo'
import {
  UPDATE_STAFF_GROUP, DELETE_STAFF_GROUP
} from '@/api/graphql/manager/groupDetail/groupDetail.js'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {

  async groupUpdateDetail ({ commit, state, rootState, dispatch }, dataUpdateGroup) {
    const finishData = {
      id: parseInt(state.dataUpdateGroup.id),
      name: state.dataUpdateGroup.name
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_STAFF_GROUP}`,
          variables: finishData
        })
        .then(data => {
          dispatch('groupList')
          resolve(data)
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true }
          )
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_STAFF_GROUP, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async groupDeleteDetail ({ commit, state, rootState, dispatch }, dataDeleteGroup) {
    const finishData = {
      id: parseInt(state.dataDeleteGroup.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_STAFF_GROUP}`,
          variables: finishData
          // fetchPolicy: 'no-cache'
        })
        .then(data => {
          dispatch('groupList')
          resolve(data)
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.deleteSuccess,
            { root: true }
          )
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_STAFF_GROUP, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }

}
