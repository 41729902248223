import { apolloClient } from '@/plugins/apollo';
import {
  GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES,
  GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES_HISTORY,
} from '@/api/graphql/customer/response/detail/activities/activities/index.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientActionHistoryListActivities({ commit, state }, take) {
    const finishData = {
      data: {
        clientId: parseInt(router.currentRoute.params.id),
        status: 'Todo',
      },
      pagination: {
        skip: null,
        take: null,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit(
            'setClientActionHistoryListActivities',
            data.data.clientActionHistoryList.items,
          );
          resolve(data.data.clientActionHistoryList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getClientActionHistoryListActivitiesHistory({ commit, state }, take) {
    const finishData = {
      data: {
        clientId: parseInt(router.currentRoute.params.id),
        status: 'Done',
      },
      pagination: {
        skip: null,
        take: null,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES_HISTORY}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit(
            'setClientActionHistoryListActivitiesHistory',
            data.data.clientActionHistoryList.items,
          );
          resolve(data.data.clientActionHistoryList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES_HISTORY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
