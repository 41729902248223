export const CLIENT_CHARGE_LIST = `
  query($filter: ClientChargeListFilterInput!, $pagination: PaginationInput!) {
    clientChargeList(
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        createdAt
        chargeDate
        clientChargeType {
          name
        }
        note
        price
        paymentMethod
        isError
        showMember
        receiptId
        receipt {
          pdfFileUrl
        }
      }
    }
  }
`;

export const CREATE_CLIENT_CHANGE = `
  mutation(
    $clientId: Int!
    $price: Int!
    $note: String
    $paymentMethod: ClientChargePaymentMethod!
  ) {
    createClientCharge(
      data: {
        paymentMethod: $paymentMethod
        clientId: $clientId
        price: $price
        note: $note
        clientChargeTypeId: 999
      }
    ) {
      id
      createdAt
      chargeDate
      clientChargeType {
        name
      }
      note
      price
      paymentMethod
      isError
      showMember
      receiptId
    }
  }
`;

export const RECEIPT = `
  query($id: Int!) {
    receipt(id: $id) {
      pdfFileUrl
    }
  }
`;

export const UPDATE_CLIENT_CHARGE = `
  mutation (
    $id: Int!
    $showMember: Boolean!
  ) {
    updateClientCharge (id: $id, showMember: $showMember) {
      showMember
    }
  }
`