import actions from './actions';

const spuSageStatus = {
  state: {
    contractTimeInformation: null,
  },
  getters: {
    getContractTimeInformation(state) {
      return state.contractId;
    },
  },
  mutations: {
    setContractTimeInformation(state, payload) {
      state.contractTimeInformation = payload;
    },
  },
  actions,
};

export default spuSageStatus;
