import actions from './actions'
import moment from 'moment';

const Meeting = {
    state: {
        searchClientListMeeting: [],
        lastActionDateFrom: moment().add(1, 'days').toISOString().substr(0, 10),
        picIds: [{ id: null, name: '佐々木剛' }],
        picSelectedMeeting: parseInt(localStorage.getItem('id')),
        hasActionParticipants: ['本人', '夫', '妻', '子供', '父親', '母親', '副会員', 'ファミリー会員', '友人', '孫(息子)', '孫(娘)', 'その他'],
        hasActionParticipantsType: 'Any',
        clientActionHistoryList: [],
        actionMethodIds: [],
        listActions:[],
    },
    getters: {
        // get value table
        searchClientListMeeting(state) {
            return state.searchClientListMeeting
        },
        // get value 対面日
        lastActionDateFrom(state) {
            return state.lastActionDateFrom
        },
        // 対面相手
        hasActionParticipants(state) {
            return state.hasActionParticipants
        },
        // 担当営業者
        picIds(state) {
            return state.picIds
        },
        picSelectedMeeting(state) {
            return state.picSelectedMeeting
        },
        // いずれかを含む
        hasActionParticipantsType(state) {
            return state.hasActionParticipantsType
        },
        // client Action History List
        clientActionHistoryList(state) {
            return state.clientActionHistoryList
        },
        // listActions
        listActions(state){
            return state.listActions
        }
    },
    mutations: {
        // get value table
        getSearchClientListMeeting(state, data) {
            if (state.actionMethodIds.length === 0 || state.hasActionParticipants.length === 0) {
                state.searchClientListMeeting = []
            } else {
                state.searchClientListMeeting = data
            }
            
        },
        // client Action History List
        getClientActionHistoryList(state, data) {
            if(state.listActions.length === 0){
                data.forEach(element => {
                    if(element.isFaceToFace){
                        var objPic = {
                            id: element.id,
                            name: element.name,
                        };
                        state.clientActionHistoryList.push(objPic);
                        state.listActions.push(objPic)
                        state.actionMethodIds.push(element.id)
                    }
                });
            }else{
                var arr = []
                state.listAction.forEach(element => {
                    if (state.actionMethodIds.includes(element.id)) {
                        arr.push(element)
                    };
                });
                state.listAction = arr
            }      
        },
        // set value btn 対面日
        setLastActionDateFrom(state, val) {
            state.lastActionDateFrom = val
        },
        // set 対面相手
        setHasActionParticipants(state, val) {
            state.hasActionParticipants = val
        },
        setPicSelectedMeeting(state, val) {
            state.picSelectedMeeting = val
        },
        // set いずれかを含む
        setHasActionParticipantsType(state, val) {
            state.hasActionParticipantsType = val
        },
        setClientActionHistoryList(state, val) {
            var arr1 = []
            var arr2 = []
            state.clientActionHistoryList.forEach(element => {
                if (val.includes(element.id)) {
                    arr1.push(element.id)
                    arr2.push(element)
                };
            });
            state.actionMethodIds = arr1
            state.listActions = arr2
        },
    },
    actions,
}

export default Meeting;