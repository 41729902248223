import actions from './actions';

const ResponseAll = {
  state: {
    clientSearchPaginatedResponseAll: [],
    enumPurchaseRateListResponseAll: [],
    picIdsResponseAll: [],
    // ---
    arrPurchaseRatesSelectResponseAll: [],
    listPurchaseRatesSelectResponseAll: [],
    arrPicIdsResponseAll: [],
    listPicIdsResponseAll: [],
    includeDoNotContactResponseAll: null,
    lastResponseDateFromResponseAll: null,
    lastResponseDateToResponseAll: null,
    membershipStatusResponseAll: 'NoCurrent',
    membershipCancellationDateFromResponseAll: null,
    membershipCancellationDateToResponseAll: null,
    lastActionDateToResponseAll: null,
    lastActionDateFromResponseAll: null,
    enumClientActionMethodListResponseAll: [],
    arrEnumClientActionMethodListResponseAll: null,
    listEnumClientActionMethodListResponseAll: [],
    hasActionMethodIdsTypeResponseAll: 'Any',
    campaignListResponseAll: [],
    arrCampaignListResponseAll: [],
    listCampaignListResponseAll: [],
    campaignListGroupsResponseAll: [],
    listCampaignGroupsResponseAll: [],
    itemCampaignTimesResponseAll: null,
    hasTrialStayResponseAll: null,
    remarksResponseAll: '',
    clientContactTypeResponseAll: null,
    contactPostalCodesResponseAll: null,
    contactPrefecturesResponseAll: [],
    contactLocalitiesResponseAll: [],
    brochureRequetTypeResponseAll: null,
    includeCompanyResponseAll: true,
    includeIndividualResponseAll: true,
    clientIdsResponseAll: [],
    sexResponseAll: null,
    ageFromResponseAll: null,
    ageToResponseAll: null,
    enumCampaignGroupListResponseAll: [],
    actionWithActionTypeResponseAll: null,
  },
  getters: {
    // return clientSearchPaginatedResponseAll
    clientSearchPaginatedResponseAll(state) {
      return state.clientSearchPaginatedResponseAll;
    },
    // return enumPurchaseRateListResponseAll
    enumPurchaseRateListResponseAll(state) {
      return state.enumPurchaseRateListResponseAll;
    },
    // return picIdsResponseAll
    picIdsResponseAll(state) {
      return state.picIdsResponseAll;
    },
    // return arrPurchaseRatesSelectResponseAll
    arrPurchaseRatesSelectResponseAll(state) {
      return state.arrPurchaseRatesSelectResponseAll;
    },
    // return arrPicIdsResponseAll
    arrPicIdsResponseAll(state) {
      return state.arrPicIdsResponseAll;
    },
    // return includeDoNotContactResponseAll
    includeDoNotContactResponseAll(state) {
      return state.includeDoNotContactResponseAll;
    },
    // return lastResponseDateFromResponseAll
    lastResponseDateFromResponseAll(state) {
      return state.lastResponseDateFromResponseAll;
    },
    // return lastResponseDateToResponseAll
    lastResponseDateToResponseAll(state) {
      return state.lastResponseDateToResponseAll;
    },
    // return membershipStatusResponseAll
    membershipStatusResponseAll(state) {
      return state.membershipStatusResponseAll;
    },
    // return membershipCancellationDateFromResponseAll
    membershipCancellationDateFromResponseAll(state) {
      return state.membershipCancellationDateFromResponseAll;
    },
    // return hasProductExpiringToDateResponseAll
    membershipCancellationDateToResponseAll(state) {
      return state.membershipCancellationDateToResponseAll;
    },
    // return lastActionDateToResponseAll
    lastActionDateToResponseAll(state) {
      return state.lastActionDateToResponseAll;
    },
    // return lastActionDateFromResponseAll
    lastActionDateFromResponseAll(state) {
      return state.lastActionDateFromResponseAll;
    },
    // return enumClientActionMethodList
    enumClientActionMethodListResponseAll(state) {
      return state.enumClientActionMethodListResponseAll;
    },
    // return listEnumClientActionMethodListResponseAll
    listEnumClientActionMethodListResponseAll(state) {
      return state.listEnumClientActionMethodListResponseAll;
    },
    // return hasActionMethodIdsType
    hasActionMethodIdsTypeResponseAll(state) {
      return state.hasActionMethodIdsTypeResponseAll;
    },
    campaignListResponseAll(state) {
      return state.campaignListResponseAll;
    },
    listCampaignListResponseAll(state) {
      return state.listCampaignListResponseAll;
    },
    campaignListGroupsResponseAll(state) {
      return state.campaignListGroupsResponseAll;
    },
    listCampaignGroupsResponseAll(state) {
      return state.listCampaignGroupsResponseAll;
    },
    itemCampaignTimesResponseAll(state) {
      return state.itemCampaignTimesResponseAll;
    },
    hasTrialStayResponseAll(state) {
      return state.hasTrialStayResponseAll;
    },
    remarksResponseAll(state) {
      return state.remarksResponseAll;
    },
    // return clientContactType
    clientContactTypeResponseAll(state) {
      return state.clientContactTypeResponseAll;
    },
    // return contactPostalCodesResponseAll
    contactPostalCodesResponseAll(state) {
      return state.contactPostalCodesResponseAll;
    },
    // return contactPrefectures
    contactPrefecturesResponseAll(state) {
      return state.contactPrefecturesResponseAll;
    },
    // return contactLocalitiesResponseAll
    contactLocalitiesResponseAll(state) {
      return state.contactLocalitiesResponseAll;
    },
    // return brochureRequetTypeResponseAll
    brochureRequetTypeResponseAll(state) {
      return state.brochureRequetTypeResponseAll;
    },
    // return include company
    includeCompanyResponseAll(state) {
      return state.includeCompanyResponseAll;
    },
    // return clientIds
    clientIdsResponseAll(state) {
      return state.clientIdsResponseAll;
    },
    // return sexResponseAll
    sexResponseAll(state) {
      return state.sexResponseAll;
    },
    // return sexResponseAll
    ageFromResponseAll(state) {
      return state.ageFromResponseAll;
    },
    // return sexResponseAll
    ageToResponseAll(state) {
      return state.ageToResponseAll;
    },
    // return enumCampaignGroupListResponseAll
    enumCampaignGroupListResponseAll(state) {
      return state.enumCampaignGroupListResponseAll;
    },
    getActionWithActionTypeResponseAll(state) {
      return state.actionWithActionTypeResponseAll;
    },
  },
  mutations: {
    setClientSearchPaginatedResponseAll(state, data) {
      state.clientSearchPaginatedResponseAll = data;
      data.forEach(element => {
        if (element.pic !== null) {
          var objPic = {
            id: element.pic.id,
            name: element.pic.name,
          };
          state.picIdsResponseAll.push(objPic);
        }
      });
    },
    setActionWithActionTypeResponseAll(state, data) {
      state.actionWithActionTypeResponseAll = data;
    },
    setEnumPurchaseRateListResponseAll(state, data) {
      state.enumPurchaseRateListResponseAll = data;
    },
    setClientActionMethodListResponseAll(state, data) {
      state.enumClientActionMethodListResponseAll = data
    },
    setCampaignListResponseAll(state, data) {
      state.campaignListResponseAll = [];
      // state.campaignListGroupsResponseAll = []
      data.forEach(element => {
        if (element !== null) {
          var objcampaignList = {
            id: element.id,
            name: element.name,
          };
          state.campaignListResponseAll.push(objcampaignList);
          // element.groups.forEach(e => {
          //     if (e !== null) {
          //         state.campaignListGroupsResponseAll.push(e)
          //     }
          // });
        }
      });
    },
    setEnumCampaignGroupListResponseAll(state, data) {
      state.enumCampaignGroupListResponseAll = [];
      data.forEach(element => {
        if (element !== null) {
          var objGroupList = {
            name: element.name,
          };
          state.enumCampaignGroupListResponseAll.push(objGroupList);
        }
      });
    },
    // set listPurchaseRatesSelectResponseAll
    setPurchaseRatesSelectResponseAll(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumPurchaseRateListResponseAll.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrPurchaseRatesSelectResponseAll = arr1;
      state.listPurchaseRatesSelectResponseAll = arr2;
    },
    // set listPicIdsResponseAll
    setListPicIdsResponseAll(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.picIdsResponseAll.forEach(element => {
        if (val.includes(element.id) && !arr1.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrPicIdsResponseAll = arr1;
      state.listPicIdsResponseAll = arr2;
    },
    // set includeDoNotContactResponseAll
    setIncludeDoNotContactResponseAll(state, val) {
      state.includeDoNotContactResponseAll = val;
    },
    // set lastResponseDateFromResponseAll
    setLastResponseDateFromResponseAll(state, val) {
      state.lastResponseDateFromResponseAll = val;
    },
    // set lastResponseDateToResponseAll
    setLastResponseDateToResponseAll(state, val) {
      state.lastResponseDateToResponseAll = val;
    },
    // set membershipStatusResponseAll
    setMembershipStatusResponseAll(state, val) {
      state.membershipStatusResponseAll = val;
    },
    // set membershipCancellationDateFromResponseAll
    setMembershipCancellationDateFromResponseAll(state, val) {
      state.membershipCancellationDateFromResponseAll = val;
    },
    // set membershipCancellationDateToResponseAll
    setMembershipCancellationDateToResponseAll(state, val) {
      state.membershipCancellationDateToResponseAll = val;
    },
    // set lastActionDateToResponseAll
    setLastActionDateToResponseAll(state, val) {
      state.lastActionDateToResponseAll = val;
    },
    // set lastActionDateFromResponseAll
    setLastActionDateFromResponseAll(state, val) {
      state.lastActionDateFromResponseAll = val;
    },
    // set ListEnumClientActionMethodListResponseAll
    setListClientActionMethodListResponseAll(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumClientActionMethodListResponseAll.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      if (arr1.length === 0) {
        state.arrEnumClientActionMethodListResponseAll = null;
      } else {
        state.arrEnumClientActionMethodListResponseAll = arr1;
      }
      state.listEnumClientActionMethodListResponseAll = arr2;
    },
    // set hasActionMethodIdsType
    setHasActionMethodIdsTypeResponseAll(state, val) {
      state.hasActionMethodIdsTypeResponseAll = val;
    },
    setListCampaignListResponseAll(state, val) {
      // var arr1 = [];
      // var arr2 = [];
      // state.campaignListResponseAll.forEach(element => {
      //   if (val.includes(element.id)) {
      //     arr1.push(element.id);
      //     arr2.push(element);
      //   }
      // });
      // state.arrCampaignListResponseAll = arr1;
      // state.listCampaignListResponseAll = arr2;
      state.listCampaignListResponseAll = val;
    },
    setListCampaignGroupsResponseAll(state, val) {
      state.listCampaignGroupsResponseAll = val;
    },
    setItemCampaignTimesResponseAll(state, val) {
      state.itemCampaignTimesResponseAll = val;
    },
    setHasTrialStayResponseAll(state, val) {
      state.hasTrialStayResponseAll = val;
    },
    setRemarksResponseAll(state, val) {
      state.remarksResponseAll = val;
    },
    // set clientContactTypeResponseAll
    setClientContactTypeResponseAll(state, val) {
      state.clientContactTypeResponseAll = val;
    },
    // set contactPostalCodesResponseAll
    setContactPostalCodesResponseAll(state, val) {
      state.contactPostalCodesResponseAll = val;
    },
    // set contactPrefecturesResponseAll
    setContactPrefecturesResponseAll(state, val) {
      state.contactPrefecturesResponseAll = val;
    },
    // set contactLocalitiesResponseAll
    setContactLocalitiesResponseAll(state, val) {
      state.contactLocalitiesResponseAll = val;
    },
    // set brochureRequetTypeResponseAll
    setBrochureRequetTypeResponseAll(state, val) {
      state.brochureRequetTypeResponseAll = val;
    },
    // set include company
    setIncludeCompanyResponseAll(state, val) {
      if (val) {
        state.includeCompanyResponseAll = true;
        state.includeIndividualResponseAll = true;
      } else if (val === null) {
        state.includeCompanyResponseAll = true;
        state.includeIndividualResponseAll = false;
      } else {
        state.includeCompanyResponseAll = false;
        state.includeIndividualResponseAll = true;
      }
      // state.includeCompanyResponseAll = val
    },
    // set clientIds
    setClientIdsResponseAll(state, val) {
      state.clientIdsResponseAll = val;
    },
    // set sexResponseAll
    setSexResponseAll(state, val) {
      state.sexResponseAll = val;
    },
    // set ageFromResponseAll
    setAgeFromResponseAll(state, val) {
      state.ageFromResponseAll = parseInt(val);
    },
    // set ageToResponseAll
    setAgeToResponseAll(state, val) {
      state.ageToResponseAll = parseInt(val);
    },
  },
  actions,
};

export default ResponseAll;
