import { apolloClient } from '@/plugins/apollo';
import {
  CLIENT_ANNUALFEE_LIST_CUSTOMER_MEMBERSHIP_CONTRACT,
  CONTRACTLIST_CUSTOMER_MEMBERSHIP_CONTRACT,
  CLIENTMEMBERINFO_CUSTOMER_MEMBERSHIP_CONTRACT,
  CREATESUBMEMBER_CUSTOMER_MEMBERSHIP_CONTRACT,
  ENUM_PERSONRELATIONSHIP_LIST_CUSTOMER_MC,
  ENUM_ENUMPREFECTURE_LIST_CUSTOMER_MC,
  TVPHISTORYLIST_LIST_CUSTOMER_MC,
  CREATE_POINT_EXCHANGE_CUSTOMER_MC,
  DELETEANNUALFEE_CUSTOMER_MC,
  POINTEXCHANGELIST_CUSTOMER_MC,
  CREATEANNUALFEE_CUSTOMER_MC,
  UPDATEMEMBERUSER_CUSTOMER_MC,
  CREATE_VM_TENTATIVE_CONTRACT,
  CREATE_VS_TENTATIVE_CONTRACT,
  DELETE_SUB_MEMBER,
  UPDATE_MEMBER_USER,
  SEND_NEW_FAMILY_MEMBER_REGISTERED_EMAIL,
  SUB_MEMBER_DELETE_WARNING
} from '@/api/graphql/customer/response/detail/membershipContract/CustomerMembershipContract/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import router from '@/router';
import gql from 'graphql-tag'

export default {
  async getContractlistCustomerMembershipContract(
    { state, commit }, id
  ) {
    commit('setLoadingOverlayShow', { root: true });
    const finishData = {
      filter: {
        clientId: parseInt(id),
        includeTentative: true
      },
      orderBy: {
        fieldName: "contract.id",
        direction: "DESC"
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACTLIST_CUSTOMER_MEMBERSHIP_CONTRACT}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit(
            'setContractlistCustomerMembershipContract',
            data.data.contractList.items
          );
          resolve(data.data.contractList.items)
          commit('setLoadingOverlayHide', { root: true });
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACTLIST_CUSTOMER_MEMBERSHIP_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
          commit('setLoadingOverlayHide', { root: true });
        });
    });
  },

  async getClientAnnualfeeListCustomerMembershipContract(
    { state, commit },
    data,
  ) {
    const finishData = {
      filter: {
        clientId: parseInt(data.id),
      },
      pagination: {
        take: data.paginate.take,
        skip: data.paginate.skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_ANNUALFEE_LIST_CUSTOMER_MEMBERSHIP_CONTRACT}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setTotalClientAnnualfeeListCustomerMembershipContract', data.data.clientAnnualFeeList.total)
          commit(
            'setClientAnnualfeeListCustomerMembershipContract',
            data.data.clientAnnualFeeList.items,
          );
          resolve(data.data.clientAnnualFeeList.items)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_ANNUALFEE_LIST_CUSTOMER_MEMBERSHIP_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getClientMemberInfoCustomerMembershipContract(
    { state, commit },
    id,
  ) {
    const finishData = {
      clientId: parseInt(id),
    }
    commit('setLoadingOverlayShow');
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENTMEMBERINFO_CUSTOMER_MEMBERSHIP_CONTRACT}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide');
          commit(
            'setClientMemberInfoCustomerMembershipContract',
            data.data.clientMemberInfo,
          );
          resolve(data.data.clientMemberInfo)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENTMEMBERINFO_CUSTOMER_MEMBERSHIP_CONTRACT, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide');
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createsubmemberCustomerMembershipContract(
    { state, commit, dispatch },
    id
  ) {
    const finishData = {
      clientId: parseInt(id),
      data: {
        subMemberTypeId: parseInt(state.subMemberTypeIdCustomerMc),
        loginEmail: state.emailCustomerMC
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATESUBMEMBER_CUSTOMER_MEMBERSHIP_CONTRACT}`,
          variables: finishData
        })
        .then(data => {
          dispatch('getClientMemberInfoCustomerMembershipContract', parseInt(router.currentRoute.params.id))
          commit('setAlertSuccess', '作成しました。', {
            root: true,
          });
          resolve(data.data.createSubMember)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATESUBMEMBER_CUSTOMER_MEMBERSHIP_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionDeleteSubmember(
    { state, commit }, id
  ) {
    const finishData = {
      id: id,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_SUB_MEMBER}`,
          variables: finishData,
          // fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
          resolve(data.data.deleteSubMember)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_SUB_MEMBER, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async sendSubMemberRegisterEmail({commit}, id) {
    const variables = { subMemberId: id }
    try {
      const data = await apolloClient
        .mutate({
          mutation: gql`${SEND_NEW_FAMILY_MEMBER_REGISTERED_EMAIL}`,
          variables
        });
      commit('setAlertSuccess', '送信しました。', {
        root: true,
      });
      return data.data.sendNewFamilyMemberRegisteredEmail
    } catch (error) {
      setCopyErrorTextCommit(commit, SEND_NEW_FAMILY_MEMBER_REGISTERED_EMAIL, variables, error.graphQLErrors)
      handlError(commit, error.graphQLErrors);
      throw error;
    }
  },

  async getSubMemberDeleteWarning({ commit }, id) {
    const variables = { subMemberId: id }
    try {
      const data = await apolloClient
        .query({
          query: gql`${SUB_MEMBER_DELETE_WARNING}`,
          variables,
          fetchPolicy: 'no-cache'
        });
      return data.data.subMemberDeleteWarning
    } catch (error) {
      setCopyErrorTextCommit(commit, SUB_MEMBER_DELETE_WARNING, variables, error.graphQLErrors)
      handlError(commit, error.graphQLErrors);
      throw error;
    }
  },

  async getEnumPersonrelationshipListCustomerMc(
    { state, commit },
  ) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PERSONRELATIONSHIP_LIST_CUSTOMER_MC}`,
        })
        .then(data => {
          commit('setEnumPersonrelationshipListCustomerMc', data.data.enumPersonRelationshipList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PERSONRELATIONSHIP_LIST_CUSTOMER_MC, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumPrefectureListCustomerMc(
    { state, commit },
  ) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_ENUMPREFECTURE_LIST_CUSTOMER_MC}`,
        })
        .then(data => {
          commit('setEnumPrefectureListCustomerMc', data.data.enumPrefectureList)
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_ENUMPREFECTURE_LIST_CUSTOMER_MC, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getTvphistoryListCustomerMc(
    { state, commit }, { id, take, skip }
  ) {
    const finishData = {
      clientId: parseInt(id),
      pagination: {
        take,
        skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${TVPHISTORYLIST_LIST_CUSTOMER_MC}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setTvphistoryListCustomerMc', data.data.tvpHistoryList.items)
          resolve(data.data.tvpHistoryList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, TVPHISTORYLIST_LIST_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createPointExchangeCustomerMc(
    { state, commit, rootState },
    id
  ) {
    commit('setLoadingOverlayShow');
    const finishData = {
      clientId: parseInt(id),
      orders: {
        productId: parseInt(state.productIdCustomerMc),
        quantity: parseInt(state.quantityCustomerMc)
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_POINT_EXCHANGE_CUSTOMER_MC}`,
          variables: finishData,
        })
        .then(data => {
          commit('setFlagTvphistoryListCustomerMc')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
          resolve(data.data.createPointExchange)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_POINT_EXCHANGE_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        })
        .finally(() => {
          commit('setLoadingOverlayHide');
        })
    });
  },

  async deleteannualfeeCustomerMc(
    { state, commit }, id
  ) {
    const finishData = {
      id: parseInt(id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETEANNUALFEE_CUSTOMER_MC}`,
          variables: finishData
        })
        .then(data => {
          console.log("success");
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETEANNUALFEE_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getPointexchangelistCustomerMc(
    { state, commit }, data
  ) {
    const finishData = {
      filter: {
        clientId: parseInt(data.id)
      },
      pagination: {
        take: data.paginate.take,
        skip: data.paginate.skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${POINTEXCHANGELIST_CUSTOMER_MC}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setTotalPointexchangelistCustomerMc', data.data.pointExchangeList.total)
          commit("setPointexchangelistCustomerMc", data.data.pointExchangeList.items);
          resolve(true)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, POINTEXCHANGELIST_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createVmTentativeContractCustomerMc(
    { state, commit }, id
  ) {
    const finishData = {
      clientId: parseInt(id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_VM_TENTATIVE_CONTRACT}`,
          variables: finishData,
        })
        .then(data => {
          commit('setIDVmTentativeContractCustomerMc', data.data.createVmTentativeContract.id)
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_VM_TENTATIVE_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createVsTentativeContractCustomerMc(
    { state, commit }, id
  ) {
    const finishData = {
      clientId: parseInt(id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_VS_TENTATIVE_CONTRACT}`,
          variables: finishData,
        })
        .then(data => {
          commit('setIDVsTentativeContractCustomerMc', data.data.createVsContract.id)
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_VS_TENTATIVE_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createannualfeeCustomerMc(
    { state, commit }, id
  ) {
    const finishData = {
      clientId: parseInt(id),
      remarks: state.remarksCustomerMc,
      year: parseInt(state.defaultYearSelected),
      month: state.monthCustomerMc,
      annualFee: state.annualFeeCustomerMc,
      membershipFee: state.membershipFeeCustomerMc,
      managementFee: state.managementFeeCustomerMc,
      hotspringFee: state.hotspringFeeCustomerMc,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATEANNUALFEE_CUSTOMER_MC}`,
          variables: finishData
        })
        .then(data => {
          commit('setFlagUpdate')
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATEANNUALFEE_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async updatememberuserCustomerMC(
    { state, commit }, id
  ) {
    const finishData = {
      clientId: parseInt(id),
      mainUser: {
        email: state.emailCustomerMC || null,
      },
      clientInfo: {
        requestMemberMagazineMail: state.requestMemberMagazineMailCustomerMC,
        requestBrochureMail: state.requestBrochureMailCustomerMC,
        accommodationFeePayment: state.accommodationFeePayment || null,
        facilityFeePayment: state.facilityFeePayment ? state.facilityFeePayment : null,
        setupFeePayment: state.setupFeePayment ? state.setupFeePayment : null,
        optionalCleaningPayment: state.optionalCleaningPayment ? state.optionalCleaningPayment : null,
        taxPayment: state.taxPayment ? state.taxPayment : null,
        otherServicePayment: state.otherServicePayment ? state.otherServicePayment : null,
      },
      subMembers: state.dataSubmembers?.map(sub => ({
        ...sub,
        email: sub.email || null,
        loginEmail: sub.loginEmail || null
      }))
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATEMEMBERUSER_CUSTOMER_MC}`,
          variables: finishData,
        })
        .then(data => {
          state.dataSubmembers = []
          resolve(data)
          commit('setAlertSuccess', '更新しました。', {
            root: true,
          });
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATEMEMBERUSER_CUSTOMER_MC, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionUpdateMemberUser(
    { state, commit },
    finishData
  ) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_MEMBER_USER}`,
          variables: finishData,
          // update: (store, { data: createSubMember }) => {
          //   const data = store.readQuery({
          //     query: CLIENTMEMBERINFO_CUSTOMER_MEMBERSHIP_CONTRACT,
          //     variables: {
          //       clientId: parseInt(router.currentRoute.params.id),
          //     },
          //   });
          //   const dataCreateSubMember = {
          //     ...createSubMember.createSubMember
          //   };
          //   data.clientMemberInfo.subMembers.push(dataCreateSubMember);
          //   if (dataCreateSubMember.subMemberType.id === 1) {
          //     state.clientMemberInfoCustomerMCSubmember.push(dataCreateSubMember)
          //   } else {
          //     state.clientMemberInfoCustomerMCFamily.push(dataCreateSubMember)
          //   }
          // },
        })
        .then(data => {
          commit('setAlertSuccess', '更新しました。', {
            root: true,
          });
          resolve(data.data.updateMemberUser)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_MEMBER_USER, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
