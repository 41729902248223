export const TERMINATE_CONTRACT = `
mutation (
  $contractId: Int!
  $reason: TerminateContractReason! 
  $refundAmount: Int
) {
  terminateContract (
    contractId: $contractId
    reason: $reason
    refundAmount: $refundAmount
  )
}
`