import actions from './actions';

const advertising = {
    state: {
        signUpBonusList: [],
        signUpBonusDetail: {},
        signUpBonusApplicationList: [],
        // create Advertising policies
        enumCampaignGroupList: [],
        enumCampaignMediaTypeList: [],
        campaignDetail: {},
        applyResponsesDetailCam: [],
        contractsDetailCam: [],
        showStatusCam: [
            { id: 1, name: '反応有' },
            { id: 2, name: 'なし' },
        ],
        total: null,
        pagi: {
            take: 100,
            skip: 0
        }
    },
    getters: {
        getEnumCampaignGroupList (state) {
            return state.enumCampaignGroupList;
        },
        getEnumCampaignMediaTypeList (state) {
            return state.enumCampaignMediaTypeList;
        },
        getCampaignDetail (state) {
            return state.campaignDetail;
        },
        getApplyResponsesDetailCam (state) {
            return state.applyResponsesDetailCam;
        },
        getContractsDetailCam (state) {
            return state.contractsDetailCam;
        },
        getShowStatusCam(state) {
            return state.showStatusCam;
        },
        signUpBonusList (state) {
            return state.signUpBonusList
        },
        signUpBonusDetail (state) {
            return state.signUpBonusDetail
        },
        signUpBonusAppList (state) {
            return state.signUpBonusApplicationList
        },
        totalOfSignUpBonusAppList (state) {
            return state.total
        }
    },
    mutations: {
        setTotalOfSignUpBonusAppList (state, payload) {
            state.total = payload
        },
        setSignUpBonusPagiTake(state, payload) {
            state.pagi.take = payload
        },
        setSignUpBonusPagiSkip(state, payload) {
            state.pagi.skip = payload
        },
        setEnumCampaignGroupList (state, data) {
            state.enumCampaignGroupList = data.enumCampaignGroupList
        },
        setCampaignMediaTypeList (state, data) {
            state.enumCampaignMediaTypeList = data.enumCampaignMediaTypeList
        },
        setDetailPolicy (state, data) {
            state.campaignDetail = data.campaignDetail
            state.contractsDetailCam = data.campaignDetail.contracts
            state.applyResponsesDetailCam = data.campaignDetail.applyResponses
        },
        setSignUpBonusList(state, data) {
            state.signUpBonusList = data
        },
        setSignUpBonusDetail (state, data) {
            state.signUpBonusDetail = data
        },
        setSignUpBonusAppList (state, data) {
            state.signUpBonusApplicationList = data
        }
    },
    actions,
};

export default advertising;