import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST_MEMBER_ALL } from '@/api/graphql/customer/member/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionSearchClientListMember({ commit, state }, data) {
    const finishData = {
      filter: data.filter,
      pagination: { 
        take: data.pagination.take,
        skip: data.pagination.skip,
      },
      orderBy: data.orderBy
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST_MEMBER_ALL}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit(
            'setSearchClientListMember',
            response.data.searchClientList.items,
          );
          commit('setTotal', response.data.searchClientList.total)
          resolve(response.data.searchClientList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST_MEMBER_ALL, finishData, error.graphQLErrors)
          // if (error.graphQLErrors.length === 0) {
          //   commit('setAlertError', 'ゲートウェイタイムアウト', { root: true });
          // } else {
          handlError(commit, error.graphQLErrors);
          // }
          reject(error);
        });
    });
  },
};
