import { apolloClient } from "@/plugins/apollo";
import { 
    CONTRACT_LIST, 
    EXPIRING_CONTRACT_POINTS, 
    EXPIRING_MW_RIGHTS } from "@/api/graphql/contract/contractUndigestedList/contractUndigestedList.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async contractUndigestedList({ commit, state }, $orderBy) {
        const finishData = {
            filter: {
                paymentDateFrom: state.paymentDateFromSelected,
                paymentDateTo: state.paymentDateToSelected,
                accountingChecked: state.accountingCheck
            },
            pagination:
            {
                skip: state.skip,
                take: state.take    
            },
            orderBy: $orderBy
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${CONTRACT_LIST}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                })
                .then(data => {
                    commit('setTotalContractList', data.data.contractList.total)
                    commit('setContractList', data.data.contractList.items)
                    resolve(true)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CONTRACT_LIST, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async fetchExpiringContractPointsList({ commit }, obj) {
        commit('setLoadingOverlayShow', { root: true })
        const finishData = {
            year: parseInt(obj.year),
            month: parseInt(obj.month),
            toYear: parseInt(obj.toYear),
            toMonth: parseInt(obj.toMonth),
            productTypeId: parseInt(obj.type),
            skip: obj.skip,
            take: obj.take,
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${EXPIRING_CONTRACT_POINTS}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                })
                .then(data => {
                    commit('setLoadingOverlayHide', { root: true })
                    commit('setExpiringContractPointsList', data.data.expiringContractPoints.items)
                    resolve(data.data.expiringContractPoints.total);
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, EXPIRING_CONTRACT_POINTS, finishData, error.graphQLErrors)
                    commit('setLoadingOverlayHide', { root: true })
                    handlError(commit, error.graphQLErrors)
                });
        })
    },
    async fetchExpiringMwRightsList({ commit }, obj) {
        commit('setLoadingOverlayShow', { root: true })
        const finishData = {
            year: parseInt(obj.year),
            month: parseInt(obj.month),
            toYear: parseInt(obj.toYear),
            toMonth: parseInt(obj.toMonth),
            skip: obj.skip,
            take: obj.take,
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${EXPIRING_MW_RIGHTS}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                })
                .then(data => {
                    commit('setLoadingOverlayHide', { root: true })
                    commit('setExpiringMwRightsList', data.data.expiringMwRights.items)
                    resolve(data.data.expiringMwRights.total);
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, EXPIRING_MW_RIGHTS, finishData, error.graphQLErrors)
                    commit('setLoadingOverlayHide', { root: true })
                    handlError(commit, error.graphQLErrors)
                });
        })
    }
}