import actions from './actions'

const invitationTicket = {
  state: {},
  mutations: {
    setInvitationTicket (state, payload) {
      state.invitationTicket = payload.invitationTicket
    },
    setInvitationTicketId (state, payload) {
      state.invitationTicket.idTicket = payload
    }
  },
  actions
}

export default invitationTicket
