import { apolloClient } from '@/plugins/apollo';
import {
  UPDATE_WEB_NOTIFICATION,
  CREATE_WEB_NOTIFICATION,
  DELETE_WEB_NOTIFICATION,
  HIDDEN_WEB_NOTIFICATION
} from '@/api/graphql/dashboard/webPageManage/notice/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  updateWebNotification({ commit, state }) {
    const finishData = {
      id: state.IDNotice,
      title: state.titleNotice,
      body: state.bodyNotice,
      imageUrl: state.imageUrlNotice,
      showFromDate: state.showFromDateNotice,
      showToDate: state.showToDateNotice,
      important: state.importantNotice,
      published: state.publishedNotice
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_WEB_NOTIFICATION}`,
          variables: finishData,
        })
        .then(data => {
          commit('setReload',true);
          // commit('setAlertSuccess', '削除しました。', {
          //   root: true,
          // });
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_WEB_NOTIFICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  createWebNotification({ commit, state }) {
    const finishData = {
      title: state.titleNotice,
      body: state.bodyNotice,
      imageUrl: state.imageUrlNotice,
      showFromDate: state.showFromDateNotice,
      showToDate: state.showToDateNotice,
      important: state.importantNotice,
      published: state.publishedNotice
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_WEB_NOTIFICATION}`,
          variables: finishData,
        })
        .then(data => {
          commit('setReload',true);
          // commit('setAlertSuccess', '削除しました。', {
          //   root: true,
          // });
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_WEB_NOTIFICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  deleteWebNotification({ dispatch, commit, state },id) {
    const finishData = {
      id: parseInt(id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_WEB_NOTIFICATION}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
          commit('setReload',true);
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_WEB_NOTIFICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  hiddenWebNotification({ commit, state },id) {
    const finishData = {
      id: parseInt(id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${HIDDEN_WEB_NOTIFICATION}`,
          variables: finishData,
        })
        .then(data => {
          commit('setReload',true);
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, HIDDEN_WEB_NOTIFICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};

