import { apolloClient } from '@/plugins/apollo';
import { REQUEST_RESET_PASSWORD_CLIENT_FOR_STAFF } from '@/api/graphql/customer/settingLink/settingLink.js';

import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getSetting({ state, commit, rootState }) {
    const finishData = {
      clientId: parseInt(state.objReset.clientId),
      newEmail: state.objReset.newEmail
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${REQUEST_RESET_PASSWORD_CLIENT_FOR_STAFF}`,
          variables: finishData,
        })
        .then(data => {
          commit('setStatus', true);
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.successSend,
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, REQUEST_RESET_PASSWORD_CLIENT_FOR_STAFF, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
