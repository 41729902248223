import { apolloClient } from '@/plugins/apollo';
import { TERMINATE_CONTRACT } from '@/api/graphql/contract/contractDetail/sp_ep/contractCancellation/contractCancellation.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
// import router from '@/router'
import gql from 'graphql-tag'

export default {
  async deleContractCanellation({ commit, state, rootState},changeData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${TERMINATE_CONTRACT}`,
          variables: changeData
        })
        .then(data => {
          resolve(data);
          commit(
            'setAlertSuccess',
            '解除しました', 
            // edit text FB
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, TERMINATE_CONTRACT, changeData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
