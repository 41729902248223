import actions from './actions';

const carryForward = {
  state: {
    carryData: {

    }
  },
  getters: {
    getContractIdCarry(state) {
      return state.contractId;
    },

    getValidCarry(state) {
      return state.carryData
    }
  },
  mutations: {
    setContractIdCarry(state, payload) {
      state.contractId = payload
    },
    setValidCarry(state, payload) {
      state.carryData = payload
    }

  },
  actions,
};

export default carryForward;
