export const CONTRACT_LIST = `
  query($filter: ContractListFilterInput, $pagination: PaginationInput, $orderBy: [OrderByInput!]) {
    contractList(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      items {
        id
        productType {
          name
          code
        }
        status
        contractNumber
        client {
          id
          memberId
          name
          nameKana
        }
        paymentDate
        receivedPayment
        purchasePaymentMethod {
          name
        }
        accountingCheck
        paymentTotalWithTax
        entranceFee
        entranceFeeTax
        annualFee
        salesStaff {
          name
        }
        entranceFee
        accountingNotes
        computedFacility {
          facilityName
        }
      }
      total
    }
  }
`;

export const EXPIRING_CONTRACT_POINTS = `
  query(
    $productTypeId: Int!
    $year: Int!
    $month: Int!
    $toYear: Int!
    $toMonth: Int!
    $skip: Int
    $take: Int
  ) {
    expiringContractPoints(
      filter: { productTypeId: $productTypeId, year: $year, month: $month, toYear: $toYear, toMonth: $toMonth }
      pagination: { skip: $skip, take: $take }
    ) {
      items {
        validThru
        pointRemains
        year
        contractId
        contract {
          basePoint
          productType{
            code
          }
          client {
            isCompany
            name
            memberId 
            id
            type
          }
        }
      }
      total
    }
  }
`;

export const EXPIRING_MW_RIGHTS = `
query (
  $year: Int!
  $month: Int!
  $toYear: Int!
  $toMonth: Int!
  $skip: Int
  $take: Int
){
  expiringMwRights (
    filter: {
      year: $year
      month: $month
      toYear: $toYear
      toMonth: $toMonth
    }
    pagination: {
      skip: $skip,
      take: $take
    }
  ) {
    items {
      expireDate 
      depositType 
      year 
      contract {
        productType{
          code
        }
        id
        mwPwRoomType {
          facility {
            name
          }
        }
        mwPwWeekNumber 
        mwRank 
        id
        client {
          type
          name
          id 
          isCompany
          memberId
        }
      }
    }
    total
  }
}
`