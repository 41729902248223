import actions from './actions';

const DocumentRequest = {
  state: {
    webRequestIds: [],
  },
  getters: {
    getWebRequestIds(state) {
      return state.webRequestIds;
    },
  },
  mutations: {
    setWebRequestIds(state, data) {
      state.webRequestIds = data;
    },
  },
  actions,
};

export default DocumentRequest;
