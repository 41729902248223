import actions from "./actions"

const webRequest = {
  state: {
    webRequestData: {}
  },
  getters: {
    webRequestData (state) {
      return state.webRequestData
    }
  },
  mutations: {
    setWebRequestData (state, obj) {
      state.webRequestData = obj
    }
  },
  actions
}

export default webRequest