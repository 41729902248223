import { apolloClient , viewMutate } from '@/plugins/apollo';
import gql from 'graphql-tag';
import { ADD_CLIENT_TVP_BULK_PROCESS, EXECUTE_TVP_BULK_PROCESS,
  CREATE_TVP_BULK_PROCESS, GET_TVP_BULK_PROCESS, 
  TVP_BULK_PROCESS_LIST, UPDATE_TVP_BULK_PROCESS 
} from '../../../api/graphql/batch/tvp-batch';

export default {
  async tvpBulkProcessList({ commit }, obj) {
    commit('setLoadingOverlayShow');
    const pagination = {
      take: obj.take,
      skip: obj.skip
    }
    try {
      const result = await apolloClient
        .query({
          query: gql`${TVP_BULK_PROCESS_LIST}`,
          variables: { pagination },
          fetchPolicy: 'no-cache'
        })

      return result.data.tvpBulkProcessList
    } finally {
      commit('setLoadingOverlayHide');
    }
  },
  async getTVPBulkProcess({ commit }, id) {
    commit('setLoadingOverlayShow');
    try {
      const result = await apolloClient
        .query({
          query: gql`${GET_TVP_BULK_PROCESS}`,
          variables: { id },
          fetchPolicy: 'no-cache'
        })

      return result.data.tvpBulkProcess
    } finally {
      commit('setLoadingOverlayHide');
    }
  },
  async createTvpBulkProcess({ commit }, data) {
    return await viewMutate({
      query: CREATE_TVP_BULK_PROCESS,
      variables: { 
        id: data.id,
        name: data.name,
        type: data.type,
        remarks: data.remarks,
        point: data.point
      },
      returnKey: 'createTVPBulkProcess',
    })
  },

  async updateTvpBulkProcess({ commit }, data) {
    return await viewMutate({
      query: UPDATE_TVP_BULK_PROCESS,
      variables: { 
        id: data.id,
        name: data.name,
        remarks: data.remarks,
        point: data.point
      },
      returnKey: 'updateTVPBulkProcess',
      successMessage: '処理内容を更新しました'
    })
  },
  async addClientsTVPBulkProcess({ commit }, data) {
    return await viewMutate({
      query: ADD_CLIENT_TVP_BULK_PROCESS,
      variables: { 
        id: data.id,
        memberIds: data.memberIds
      },
      returnKey: 'addClientsTVPBulkProcess',
      successMessage: '会員番号をアップロードしました'
    })
  },
  async executeTVPBulkProcess({ commit, ...rest }, data) {
    return await viewMutate({
      query: EXECUTE_TVP_BULK_PROCESS,
      variables: { 
        id: data.id,
      },
      returnKey: 'executeTVPBulkProcess',
      successMessage: 'TVP処理を実行しました'
    })
  },
};
