export const CREATE_CONTRACT_ATTACHMENT = `
mutation (
  $contractId: Int!
  $fileKey: String! #すぐ上のUpload用URL作成のMutationを使い、Keyを送ること
  $fileName: String! #ファイル名
) {
  createContractAttachment(
    contractId: $contractId
    fileKey: $fileKey
    fileName: $fileName
  ) {
    id
  }
}
`