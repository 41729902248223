export const CUSTOMER_ALT_ROUTES = {
  'customer-details': 'member-ship-contract',
  'member-ship-contract': 'prospect-information',
  'prospect-information': 'basic-information-customer-detail',
  'basic-information-customer-detail': 'activities-customer-detail',
  'activities-customer-detail': 'membership-billing-history',
  'membership-billing-history': 'change-history-customer-detail',
  'change-history-customer-detail': 'delete-custome-detail'
}

export const CONTRACT_ALT_ROUTES = {
  'usage-situation-sp-pw': 'basic-infomation-contract',
  'usage-situation-mw': 'basic-infomation-contract',
  'usage-situation-vm': 'basic-infomation-contract',
  'vs-vs': 'basic-infomation-contract',
  'basic-infomation-contract': 'contract-time-information',
  'contract-time-information': 'contract-cancellation',
  'contract-cancellation': 'contract-transfer'
}

export default {
  ...CUSTOMER_ALT_ROUTES,
  ...CONTRACT_ALT_ROUTES
}
