export const ADVANCE_POINTS = `
mutation (
  $contractId: Int! #契約ID
  $year: Int! #一覧APIから取得する year （年度）
  $points: Int! #移動ポイント数
  $remarks: String #備考
) {
  advancePoints(
    year: $year
    contractId: $contractId
    points: $points
    remarks: $remarks
  )
}
`