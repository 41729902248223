import actions from './actions';

const contractUndigestedList = {
    state: {
        contractList: [],
        paymentDate: null,
        paymentDateFrom: null,
        paymentDateTo: null,
        paymentDateFromSelected: null,
        paymentDateToSelected: null,
        expiringContractPointsList: [],
        expiringMwRightsList: [],
        accountingCheck: false,
        skip: 0,
        take: 0,
        total: 0
    },
    getters: {
        getSkipContractList(state) {
            return state.skip;
        },
        getTotalContractList(state) {
            return state.total;
        },
        getTakeContractList(state) {
            return state.take;
        },
        getContractList(state) {
            return state.contractList;
        },
        getPaymentDate(state) {
            return state.paymentDate;
        },

        getPaymentDateFrom(state) {
            return state.paymentDateFrom;
        },
        getPaymentDateTo(state) {
            return state.paymentDateTo;
        },
        getPaymentDateFromSelected(state) {
            return state.paymentDateFromSelected;
        },
        getPaymentDateToSelected(state) {
            return state.paymentDateToSelected;
        },
        getAccountingCheck(state) {
            return state.accountingCheck === null;
        },
        expiringContractPointsList(state) {
            return state.expiringContractPointsList
        },
        expiringMwRightsList(state) {
            return state.expiringMwRightsList
        }
    },
    mutations: {
        setSkipContractList(state, val) {
            state.skip = val;
        },
        setTotalContractList(state, val) {
            state.total = val;
        },
        setTakeContractList(state, val) {
            state.take = val;
        },
        setContractList(state, arr) {
            state.contractList = arr;
        },
        setPaymentDate(state, val) {
            state.paymentDate = val
        },
        setPaymentDateFromSelected(state, val) {
            state.paymentDateFromSelected = val
        },
        setPaymentDateToSelected(state, val) {
            state.paymentDateToSelected = val
        },
        setAccountingCheck(state, val) {
            if(val){
                state.accountingCheck = null
            }else{
                state.accountingCheck = false
            }
        },
        setExpiringContractPointsList(state, arr) {
            if (arr.length > 0) {
                let idx = 0
                for(let i = 0; i < arr.length; i++) {
                    idx = idx + 1
                    arr[i].idx = idx
                }
                state.expiringContractPointsList = arr
            }
        },
        setExpiringMwRightsList(state, arr) {
            if (arr.length > 0) {
                let idx = 0
                for(let i = 0; i < arr.length; i++) {
                    idx = idx + 1
                    arr[i].idx = idx
                }
                state.expiringMwRightsList = arr
            }
        }
    },
    actions,
};

export default contractUndigestedList;
