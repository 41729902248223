import { apolloClient } from "@/plugins/apollo"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'
import { CRM_ACCOUNTING_REPORT,  CRM_GENERAL_REPORT } from '@/api/graphql/report';
import { downloadFile } from '@/utils/download';

export default  {
  async crmAccountingReport({ commit }, obj) {
    const finishData = {
      accountingType: obj.accountingType,
      fromDate: obj.fromDate,
      toDate: obj.toDate
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.mutate({
      mutation: gql`${CRM_ACCOUNTING_REPORT}`,
      variables: finishData
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true })
      const fileList = data.data.crmAccountingReport.files
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          downloadFile(
            fileList[i].downloadUrl,
            fileList[i].fileName
          )
        }
      }
    }).catch(error => {
      setCopyErrorTextCommit(commit, CRM_ACCOUNTING_REPORT, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  },

  async crmGeneralReport({ commit }, obj) {
    const finishData = {
      generalType: obj.generalType,
      fromDate: obj.fromDate,
      toDate: obj.toDate
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.mutate({
      mutation: gql`${CRM_GENERAL_REPORT}`,
      variables: finishData
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true })
      const fileList = data.data.crmGeneralReport.files
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          downloadFile(
            fileList[i].downloadUrl,
            fileList[i].fileName
          )
        }
      }
    }).catch(error => {
      setCopyErrorTextCommit(commit, CRM_GENERAL_REPORT, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  }
}