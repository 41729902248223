export const TYPE_CLIENT = {
    TENTATIVE: 'TENTATIVE',
    VSINTEREST: 'VSINTEREST',
    MEMBER: 'MEMBER'
  }

export const NAME_ROUTER_CUSTOMER_PATH = [
  {
    name: 'vm-contract-usage',
    path: '/customer/vm-contract-usage'
  }
]
export const NAME_ROUTER_CUSTOMER_DETAIL_PATH = [
  {
    name: 'customer-details',
    path: '/customer-details'
  },
  {
    name: 'member-ship-contract',
    path: '/customer-details/member-ship-contract'
  },
  {
    name: 'prospect-information',
    path: '/customer-details/prospect-information'
  },
  {
    name: 'basic-information-customer-detail',
    path: '/customer-details/basic-information'
  },
  {
    name: 'credit-card-customer-detail',
    path: '/customer-details/credit-card'
  },
  {
    name: 'activities-customer-detail',
    path: '/customer-details/activities'
  },
  {
    name: 'membership-billing-history',
    path: '/customer-details/membership-billing-history'
  },
  {
    name: 'change-history-customer-detail',
    path: '/customer-details/change-history'
  },
  {
    name: 'delete-custome-detail',
    path: '/customer-details/delete'
  },
  {
    name: 'basic-information-and-uncontracted-prospects',
    path: '/customer-details/basic-information/uncontracted-prospects'
  },
  {
    name: 'potential-information-and-uncontracted-leads',
    path: '/customer-details/potential-information/uncontracted-leads'
  },
  {
    name: 'basic-information-information',
    path: '/customer-details/basic-information/information'
  },
  {
    name: 'basic-information-activities',
    path: '/customer-details/basic-information/activities'
  },
  {
    name: 'basic-information-change-history',
    path: '/customer-details/basic-information/change-history'
  },
]
export const NAME_ROUTER_VM_TEMPORARY_CONTRACT_PATH = [
  {
    name: 'vm-temporary-contract',
    path: '/vm-temporary-contract'
  },
]
export const NAME_ROUTER_CONTRACT_PATH = [
  {
    name: 'usage-situation-sp-pw',
    path: '/contract/usage-situation-sp-pw'
  },
  {
    name: 'usage-situation-mw',
    path: '/contract/usage-situation-mw'
  },
  {
    name: 'usage-situation-vm',
    path: '/contract/usage-situation-vm'
  },
  {
    name: 'reservation_point_affiliated_facility',
    path: '/contract/reservation_point_affiliated_facility'
  },
  {
    name: 'contract-time-information',
    path: '/contract/contract-time-information'
  },
  {
    name: 'contract-cancellation',
    path: '/contract/contract-cancellation'
  },
  {
    name: 'contract-transfer',
    path: '/contract/contract-transfer'
  },
  {
    name: 'basic-infomation-contract',
    path: '/contract/basic-infomation'
  },
  {
    name: 'vs-vs',
    path: '/contract/vs-vs'
  }
]

export const MENU_SIDEBAR_PATH = ['personal-report', 'customer', 'contract', 'advertising', 'report', 'corporate-ticket', 'confirm-annual', 'gift-ticket', 'manager', 'mail', 'web-manage']

export const PERSONAL_REPORT_PATH = '/personal-report'
export const PERSONAL_REPORT_PATH_SUB = '/personal-report/'
export const PERSONAL_REPORT_ROUTER_SUB = [
  '/personal-report/my-page',
  '/personal-report/customer-in-charge',
  '/personal-report/experience',
  '/personal-report/meeting',
]

export const CUSTOMER_PATH = '/customer'
export const CUSTOMER_PATH_SUB = '/customer/'
export const CUSTOMER_ROUTER_SUB = [
  '/customer',
  '/customer/prospect-list',
  '/customer/member-list'
]

export const CONTRACT_PATH = '/contract'
export const CONTRACT_PATH_SUB = '/contract/'
export const CONTRACT_ROUTER_SUB = [
  '/contract',
]

export const WEB_MANAGE_PATH = '/web-manage'
export const WEB_MANAGE_PATH_SUB = '/web-manage/'
export const WEB_MANAGE_ROUTER_SUB = [
  '/web-manage/info',
  '/web-manage/document-request',
  '/web-manage/old-customer',
  '/web-manage/visit-reservation',
  '/web-manage/experience',
  '/web-manage/facility',
  '/web-manage/notice',
  '/web-manage/facility-setting',
  '/web-manage/web-document',
]

export const BATCH_PATH = '/batch'
export const BATCH_PATH_SUB = '/batch/'
export const BATCH_ROUTER_SUB = [
  '/batch/tvp-batch',
]

export const GIFT_TICKET_PATH = '/gift-ticket'
export const GIFT_TICKET_PATH_SUB = '/gift-ticket/'
export const GIFT_TICKET_ROUTER_SUB = [
  '/gift-ticket/products',
  '/gift-ticket/orders',
]

// Rediect from PMS
export const PAGE_CUSTOMER_DETAILS = '/customer-details'



export const MAX_INT = 2147483647