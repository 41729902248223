import { apolloClient } from '@/plugins/apollo';
import {
  STAFF,
  ENUM_PARTICIPANT_TYPE_LIST,
  ENUM_CLIENT_ACTION_METHOD_LIST,
  GET_CLIENT_ACTION_HISTORY,
  CREATE_CLIENT_ACTION_HISTORY,
  UPDATE_CLIENT_ACTION_HISTORY,
  ACTIVITY_HISTORY_CREATE,
  ACTIVITY_HISTORY_REGISTRATION,
  ACTIVITY_CLIENT_ACTION_REGISTRATION,
  CREATE_ACTIVITY_HISTORY_REGISTRATION,
  ACTION_SPLIT_MW_RIGHT,
  DELETE_CLIENT_ACTION_HISTORY
} from '@/api/graphql/customer/response/detail/activities/index.js';
import { ENUM_PURCHASE_RATE_LIST } from '@/api/graphql/enum/enumPurchaseRateList.js';
import { ENUM_NEGOTIATION_EXIT_REASON_LIST } from '@/api/graphql/enum/enumNegotiationExitReasonList.js';
import { ENUM_CLIENT_ACTION_TYPE_LIST } from '@/api/graphql/enum/enumClientActionTypeList.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import router from '@/router';
import gql from 'graphql-tag'

export default {
  actionEnumNegotiationExitReasonList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_NEGOTIATION_EXIT_REASON_LIST}`,
        })
        .then(data => {
          const newEnumNegotiationExitReasonList = [];
          data.data.enumNegotiationExitReasonList.forEach(element => {
            newEnumNegotiationExitReasonList.push(element.name);
          });
          commit(
            'setEnumNegotiationExitReasonList',
            newEnumNegotiationExitReasonList,
          );
          resolve(newEnumNegotiationExitReasonList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_NEGOTIATION_EXIT_REASON_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumClientActionTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CLIENT_ACTION_TYPE_LIST}`,
        })
        .then(data => {
          commit(
            'setEnumClientActionTypeList',
            data.data.enumClientActionTypeList,
          );
          resolve(data.data.enumClientActionTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CLIENT_ACTION_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumPurchaseRateList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PURCHASE_RATE_LIST}`,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setEnumPurchaseRateList', data.data.enumPurchaseRateList);
          resolve(data.data.enumPurchaseRateList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PURCHASE_RATE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionStaff({ commit, state }, id) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${STAFF}`,
          variables: finishData,
        })
        .then(data => {
          commit('setStaff', data.data.staff);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, STAFF, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumParticipantTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PARTICIPANT_TYPE_LIST}`,
        })
        .then(data => {
          const newEnumParticipantTypeList = [];
          data.data.enumParticipantTypeList.forEach(element => {
            newEnumParticipantTypeList.push(element.name);
          });

          commit('setEnumParticipantTypeList', newEnumParticipantTypeList);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PARTICIPANT_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumClientActionMethodList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CLIENT_ACTION_METHOD_LIST}`,
        })
        .then(data => {
          commit(
            'setEnumClientActionMethodList',
            data.data.enumClientActionMethodList,
          );
          resolve(data.data.enumClientActionMethodList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CLIENT_ACTION_METHOD_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionGetClientActionHistory({ commit, state }, id) {
    const finishData = {
      id: id,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${GET_CLIENT_ACTION_HISTORY}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setClientActionHistory', data.data.getClientActionHistory);
          resolve(data.data.getClientActionHistory);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GET_CLIENT_ACTION_HISTORY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionCreateClientActionHistory({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CLIENT_ACTION_HISTORY}`,
          variables: finishData,
          // update: (store, { data: createClientCharge }) => {
          //   const data = store.readQuery({
          //     query: GET_CLIENT_ACTION_HISTORY,
          //     variables: {
          //       // id: id,
          //     },
          //   });
          //   const dataCreateClientCharge = {
          //     ...createClientCharge.createClientCharge,
          //     clientChargeType: {
          //       name: 'その他',
          //     },
          //   };
          //   data.clientChargeList.items.unshift(dataCreateClientCharge);
          // },
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_CLIENT_ACTION_HISTORY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionUpdateClientActionHistory({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CLIENT_ACTION_HISTORY}`,
          variables: finishData,
        })
        .then(data => {
          // commit(
          //   'setUpdateClientActionHistory',
          //   data.data.updateClientActionHistory,
          // );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT_ACTION_HISTORY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  actionActivityHistoryCreate({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ACTIVITY_HISTORY_CREATE}`,
          variables: finishData,
        })
        .then(data => {
          // commit(
          //   'setUpdateClientActionHistory',
          //   data.data.updateClientActionHistory,
          // );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ACTIVITY_HISTORY_CREATE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionActivityHistoryRegistration({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ACTIVITY_HISTORY_REGISTRATION}`,
          variables: finishData,
        })
        .then(data => {
          // commit(
          //   'setUpdateClientActionHistory',
          //   data.data.updateClientActionHistory,
          // );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ACTIVITY_HISTORY_REGISTRATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionDeleteClientActionHistory({ commit, state }, id) {
    const finishData = {
      id: id
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_CLIENT_ACTION_HISTORY}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_CLIENT_ACTION_HISTORY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  actionAddNewPurchasePoints({ commit, state }, finishData) {
    const variables = {
      contractId: parseInt(state.addNewPurchasePoints.contractId),
      year: parseInt(state.addNewPurchasePoints.year),
      reason: state.addNewPurchasePoints.reason,
      // pointPriceOverride: parseInt(
      //   state.addNewPurchasePoints.pointPriceOverride,
      // ),
      price: parseInt(state.addNewPurchasePoints.pointPriceOverride),
      points: parseInt(state.addNewPurchasePoints.points),
      remarks: state.addNewPurchasePoints.remarks,
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ACTIVITY_CLIENT_ACTION_REGISTRATION}`,
          variables: variables,
        })
        .then(data => {
          commit('setStatusAddNewPurchasePoints', true);
          commit('setAlertSuccess', '購入しました'); // suss text theoo FB
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ACTIVITY_CLIENT_ACTION_REGISTRATION, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        })
        .finally(() => {
          commit('setLoadingOverlayHide', { root: true })
        })
    });
  },

  actionCreateActivityHistoryRegistration({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_ACTIVITY_HISTORY_REGISTRATION}`,
          variables: finishData,
          // update: (store, { data: clientActionHistoryListChange }) => {
          //   const data = store.readQuery({
          //     query: GET_CLIENT_ACTION_HISTORY_LIST_ACTIVITIES_HISTORY,
          //     variables: {
          //       clientId: parseInt(router.currentRoute.params.id),
          //       status: 'Done',
          //     },
          //   });
          //   const dataCreateClientCharge = {
          //     ...clientActionHistoryListChange.items,
          //   };
          //   data.clientActionHistoryList.items.unshift(dataCreateClientCharge);
          // },
        })
        .then(data => {
          commit('setChangeCreateActivityHistoryRegistration', true);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_ACTIVITY_HISTORY_REGISTRATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionsplitMwRight({ commit, state }, finishData) {
    const variables = {
      bookingGroupId: parseInt(finishData.bookingGroupId),
      bookings: finishData.bookings,
      contractId: finishData.contractId
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ACTION_SPLIT_MW_RIGHT}`,
          variables: variables,
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ACTION_SPLIT_MW_RIGHT, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
