export const SHOW_RED_DOT_DUPLICATED_CLIENT_LIST = `
# 各項目について、length > 0であれば赤丸を表示する。
query  {
  duplicatedClientList ( #既存顧客重複
    pagination:{take: 1 skip: 0}
  ) {
    items {
      id #請求のID
    }
  }
  vm: webRequestList( #VM資料請求
    filter:{
      hasPic: false
      clientAssigned: true
      forVm: true
    }
    pagination:{take: 1 skip: 0}
  ) {
    items {
      id #請求のID
    }
  }
  visit: webRequestList( #来店予約
    filter:{
      type: Visit
      handled: false
    }
    pagination:{take: 1 skip: 0}
  ) {
    items {
      id #Int! ID
    }
  }
  online: webRequestList( # オンライン相談
    filter:{
      type: OnlineCounseling
      handled: false
    }
    pagination:{take: 1 skip: 0}
  ) {
    items {
      id #Int! ID
    }
  }
  trial: webRequestList( #体験宿泊
    filter:{
      type: TrialStay
      handled: false
    }
    pagination:{take: 1 skip: 0}
  ) {
    items {
      id #Int! ID
    }
  }
}
`