export const CONTRACT_TIME_INFORMATION = `
  query($id: Int!) {
    contract(id: $id) {
      clientId
      productTypeId
      spFirstDate
      totalPoint
      pointPrice
      startDate
      years
      responseSource
      contractPurchaseTypeId
      contractPurchaseType {
        id
        name
      }
      contractPurchaseTypeDetailId
      contractPurchaseTypeDetail {
        id
      }
      salesStaffId
      salesStaff {
        id
        name
      }
      purchaseReason
      mainPurchasePurposeId
      mainPurchasePurpose {
        id
        name
      }
      subPurchasePurposeId
      subPurchasePurpose {
        id
        name
      }
      applicationDate
      applicationTypeId
      applicationType {
        id
        name
      }
      paymentExpectedDate
      paymentDate
      entranceFee
      entranceFeeTax
      memberRegistrationFee
      memberRegistrationFeeTax
      annualFee
      annualFeeTax
      managementFee
      managementFeeTax
      hotspringFee
      hotspringFeeTax
      paymentTotal
      paymentTotalTax
      entranceFeeDiscount
      entranceFeeDiscountTax
      paymentTotalWithTax
      purchasePaymentMethodId
      receivedPayment
      accountingCheck
      accountingNotes
      attachments {
        id
        name
        fileUrl
      }

      campaign {
        name
        groups
        createdAt
        note
        attachment1Url
        attachment2Url
        attachment3Url
      }
    }
  }
`;

export const UPDATE_CONTRACT_TIME_INFORMATION = `
  mutation($contractId: Int!, $data: UpdateContractInput!) {
    updateContract(contractId: $contractId, data: $data) {
      __typename
    }
  }
`;

export const CONTRACT_CAMPAIGN_LIST = `
  query (
    $id: Int!
  ) {  
    contract(id: $id) {
      campaign {
        id
        name
        groups
        createdAt
        note
        attachment1Url
        attachment2Url
        attachment3Url
      }
    }
  }
`

export const UPDATE_CONTRACT_CAMPAIGN_LIST = `
  mutation (
    $contractId: Int!
    $campaignId: Int
  ) {
    updateContract(
      contractId: $contractId
      data:{
        campaignId: $campaignId
      }
    ) {
      campaignId
    }
  }
`

export const CAMPAIGN_APPLY_RESPONSE_LIST = `
  query($filter: CampaignApplyResponseListFilterInput!) {
    campaignApplyResponseList(filter: $filter) {
      items {
        id
        campaign {
          id
          name
          groups
          note
          attachment1
          attachment2
          attachment3
          attachment1Url
          attachment2Url
          attachment3Url
        }
        responseAt
        createdAt
      }
    }
  }
`;
export const SIGN_UP_BONUS_APPLICATION_LIST = `
  query(
    $filter: ListSignupBonusApplicationFilterInput!
    $pagination: PaginationInput
    ) {
    signupBonusApplicationList(
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        signupBonus {
          id
          name
          description
          type
          amount

          vsBonuses
          vsBonusValues
        }
      }
    }
  }
`;
export const SIGN_UP_BONUS_LIST = `
  query {
    signupBonusList(pagination: { skip: 0, take: 500 }) {
      items {
        id
        name
      }
    }
  }
`;
export const DELETE_SIGN_UP_BONUS_APPLICATION = `
  mutation($id: Int!) {
    deleteSignupBonusApplication(id: $id) {
      id
    }
  }
`;
export const ADD_SIGN_UP_BONUS_APPLICATION = `
  mutation($contractId: Int!, $signupBonusId: Int!) {
    addSignupBonusApplication(
      contractId: $contractId
      signupBonusId: $signupBonusId
    ) {
      id
    }
  }
`;
export const ADD_UPDATE_CONTRACT = `
  mutation($contractId: Int!, $campaignId: Int) {
    updateContract(contractId: $contractId, data: { campaignId: $campaignId }) {
      clientId
      campaignId
    }
  }
`;
export const DELETE_CAMPAIGN_APPLY_RESPONSE = `
  mutation ($id: Int!) {
    deleteCampaignApplyResponse(id: $id){
      id
      clientId
    }
  }
`;
