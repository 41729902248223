export const STAFF_LIST = `
query (
  $showDisabled: Boolean #無効ユーザも表示の場合True
  $crmRep: Boolean
) {
  staffList(
    crmRep: $crmRep
    showDisabled: $showDisabled
  ) {
    id #Int! ユーザID
    name # 名称
    mentionName
    profileImgUrl
    groups { # array
      name #String! グループ名
    }
    crmComputed {
      clientCount #Int! 担当総数
      contractingCount #Int! 契約手続き中の数
      goodCount #Int! Good数
      coolCount #Int! Cool数
    }
    nextAction {
      action #String 次の活動予定
      actionPlannedAt #DateTime 次の活動予定日時
    }
    lastLoginAt #DateTime 最終ログイン
  }
}
`