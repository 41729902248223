<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20.131 15.098">
        <path id="FontAwsome_ad_" data-name="FontAwsome (ad)" d="M6.193,72.178H7.647L6.92,70.086Zm7.647-.629a.944.944,0,1,0,.944.944A.945.945,0,0,0,13.84,71.549ZM18.244,64H1.887A1.888,1.888,0,0,0,0,65.887V77.211A1.888,1.888,0,0,0,1.887,79.1H18.244a1.888,1.888,0,0,0,1.887-1.887V65.887A1.888,1.888,0,0,0,18.244,64ZM9.852,75.324H9.186a.63.63,0,0,1-.594-.423l-.29-.836H5.538l-.29.836a.629.629,0,0,1-.594.423H3.988a.629.629,0,0,1-.594-.836L5.5,68.408a.943.943,0,0,1,.891-.634H7.444a.943.943,0,0,1,.891.634l2.111,6.079A.629.629,0,0,1,9.852,75.324Zm6.819-.629a.629.629,0,0,1-.629.629h-.629a.619.619,0,0,1-.471-.223,2.838,2.838,0,1,1-.158-5.265V68.4a.629.629,0,0,1,.629-.629h.629a.629.629,0,0,1,.629.629Z" transform="translate(0 -64)" :fill="color"/>
    </svg>
</template>


<script>
export default {
    name:'mobile',
    props:{
        width:{
            width:Number,
            default:'24'
        },
        height:{
            height:Number,
            default:'24'
        },
        color:{
            color:String,
            default:'white'
        }
    }
}
</script>

<style>

</style>
