import { apolloClient } from '@/plugins/apollo';
import {
  CONTRACT_LIST,
  ENUM_PRODUCT_TYPE_LIST,
} from '@/api/graphql/contract/contractAll/contractAll.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  contractAll({ commit, state }) {
    const finishData = {
      filter: {
        productTypeIds: state.contractAll.productTypeIdSelected,
        ended: state.contractAll.endedSelected,
      },
      pagination: {
        skip: 0, take: 1
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT_LIST}`,
          variables: finishData,
        })
        .then(data => {
          commit('setTotalContractAll', data.data.contractList.total);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async enumProductTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PRODUCT_TYPE_LIST}`,
        })
        .then(data => {
          commit('setEnumPrefectureList', data.data.enumProductTypeList);
          resolve(data.data.enumProductTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PRODUCT_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
