import { apolloClient } from '@/plugins/apollo';
import {
  CAMPAIGN_LIST,
  SEARCH_CAMPAIGN_LIST,
} from '@/api/graphql/customer/response/AdvertisingMeasuresCustomers';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionCampaignList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CAMPAIGN_LIST}`,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          var newCampaignList = [];

          data.data.campaignList.items.forEach(element => {
            newCampaignList.push({
              ...element,
              isSelected: false,
            });
          });

          commit('setCampaignList', newCampaignList);
          resolve(newCampaignList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CAMPAIGN_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionSearchCampaignList({ commit, state }, {filterData, pagination, orderBy}) {
    const finishData = {
      filter: filterData,
      pagination: {
        take: pagination.take,
        skip: pagination.skip
      },
      orderBy: orderBy 
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CAMPAIGN_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          var newCampaignList = [];

          data.data.campaignList.items.forEach(element => {
            newCampaignList.push({
              ...element,
              isSelected: false,
            });
          });
          commit('setCampaignList', newCampaignList);
          resolve({newCampaignList: newCampaignList, total: data.data.campaignList.total});
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CAMPAIGN_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
