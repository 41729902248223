export const MONTHS = [
  { id: 1, name: '01' },
  { id: 2, name: '02' },
  { id: 3, name: '03' },
  { id: 4, name: '04' },
  { id: 5, name: '05' },
  { id: 6, name: '06' },
  { id: 7, name: '07' },
  { id: 8, name: '08' },
  { id: 9, name: '09' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
];
export const HOURS = [
  { id: 0, number: '00' },
  { id: 1, number: '01' },
  { id: 2, number: '02' },
  { id: 3, number: '03' },
  { id: 4, number: '04' },
  { id: 5, number: '05' },
  { id: 6, number: '06' },
  { id: 7, number: '07' },
  { id: 8, number: '08' },
  { id: 9, number: '09' },
  { id: 10, number: '10' },
  { id: 11, number: '11' },
  { id: 12, number: '12' },
  { id: 13, number: '13' },
  { id: 14, number: '14' },
  { id: 15, number: '15' },
  { id: 16, number: '16' },
  { id: 17, number: '17' },
  { id: 18, number: '18' },
  { id: 19, number: '19' },
  { id: 20, number: '20' },
  { id: 21, number: '21' },
  { id: 22, number: '22' },
  { id: 23, number: '23' },
];
export const MINUTES_OR_SECONDS = [
  { id: 0, number: '0' },
  // { id: 1, number: '01' },
  // { id: 2, number: '02' },
  // { id: 3, number: '03' },
  // { id: 4, number: '04' },
  // { id: 5, number: '05' },
  // { id: 6, number: '06' },
  // { id: 7, number: '07' },
  // { id: 8, number: '08' },
  // { id: 9, number: '09' },
  { id: 10, number: '10' },
  // { id: 11, number: '11' },
  // { id: 12, number: '12' },
  // { id: 13, number: '13' },
  // { id: 14, number: '14' },
  // { id: 15, number: '15' },
  // { id: 16, number: '16' },
  // { id: 17, number: '17' },
  // { id: 18, number: '18' },
  // { id: 19, number: '19' },
  { id: 20, number: '20' },
  // { id: 21, number: '21' },
  // { id: 22, number: '22' },
  // { id: 23, number: '23' },
  // { id: 24, number: '24' },
  // { id: 25, number: '25' },
  // { id: 26, number: '26' },
  // { id: 27, number: '27' },
  // { id: 28, number: '28' },
  // { id: 29, number: '29' },
  { id: 30, number: '30' },
  // { id: 31, number: '31' },
  // { id: 32, number: '32' },
  // { id: 33, number: '33' },
  // { id: 34, number: '34' },
  // { id: 35, number: '35' },
  // { id: 36, number: '36' },
  // { id: 37, number: '37' },
  // { id: 38, number: '38' },
  // { id: 39, number: '39' },
  { id: 40, number: '40' },
  // { id: 41, number: '41' },
  // { id: 42, number: '42' },
  // { id: 43, number: '43' },
  // { id: 44, number: '44' },
  // { id: 45, number: '45' },
  // { id: 46, number: '46' },
  // { id: 47, number: '47' },
  // { id: 48, number: '48' },
  // { id: 49, number: '49' },
  { id: 50, number: '50' },
  // { id: 51, number: '51' },
  // { id: 52, number: '52' },
  // { id: 53, number: '53' },
  // { id: 54, number: '54' },
  // { id: 55, number: '55' },
  // { id: 56, number: '56' },
  // { id: 57, number: '57' },
  // { id: 58, number: '58' },
  // { id: 59, number: '59' },
];
