import actions from './actions';
import { MINUTES_OR_SECONDS, HOURS } from '@/constants/constantTimes';

const customerDetailsActivities = {
  state: {
    statusUpdateAddNewPurchasePoints: false,
    addNewPurchasePoints: [],
    // Const Enum
    enumNegotiationExitReasonList: [],
    enumParticipantTypeList: [],
    enumClientActionMethodList: [],
    enumPurchaseRateList: [],
    enumClientActionTypeList: [],

    // Data call API
    staff: { name: null },
    clientActionHistory: {},
    currentPurchaseRate: null,
    currentFurtherPurchaseRate: null,
    currentRenewPurchaseRate: null,

    // Select default
    actionPlannedAt: null,
    actionAt: null,
    hours: HOURS,
    minutes: MINUTES_OR_SECONDS,
    participants: null,
    priority: [
      { id: 0, name: '低' },
      { id: 5, name: '中' },
      { id: 10, name: '高' },
    ],
    action: null,

    // Selected
    actionPlannedAtSelected: null,
    actionAtSelected: null,
    hoursSelected: null,
    minutesSelected: null,
    participantsSelected: null,
    prioritySelected: null,
    actionMethodIdSelected: null,
    purchaseRateIdSelected: null,
    furtherPurchaseRateIdSelected: null,
    renewPurchaseRateIdSelected: null,
    actionSelected: null,
    isComplaintSelected: false,
    negotiationExitReasonSelected: null,
    clientActionTypeIdSelected: null,
    remarksSelected: null,
    dateFromSelected: null,
    ReasonForWithdrawalSelected: null,
  },
  getters: {
    // Const Enum
    getEnumNegotiationExitReasonList(state) {
      return state.enumNegotiationExitReasonList;
    },
    getEnumParticipantTypeList(state) {
      return state.enumParticipantTypeList;
    },
    getEnumClientActionMethodList(state) {
      return state.enumClientActionMethodList;
    },
    getEnumPurchaseRateList(state) {
      return state.enumPurchaseRateList;
    },
    getEnumClientActionTypeList(state) {
      return state.enumClientActionTypeList;
    },

    // Data call API
    getStaff(state) {
      return state.staff;
    },
    getClientActionHistory(state) {
      return state.clientActionHistory;
    },
    getCurrentPurchaseRate(state) {
      return state.currentPurchaseRate;
    },
    getCurrentFurtherPurchaseRate(state) {
      return state.currentFurtherPurchaseRate;
    },
    getCurrentRenewPurchaseRate(state) {
      return state.currentRenewPurchaseRate;
    },

    // Select default
    getActionPlannedAt(state) {
      return state.actionPlannedAt;
    },
    getActionAt(state) {
      return state.actionAt;
    },
    getHours(state) {
      return state.hours;
    },
    getMinutes(state) {
      return state.minutes;
    },
    getParticipants(state) {
      return state.participants;
    },
    getPriority(state) {
      return state.priority;
    },
    getAction(state) {
      return state.action;
    },

    // Selected
    getActionPlannedAtSelected(state) {
      return state.actionPlannedAtSelected;
    },
    getActionAtSelected(state) {
      return state.actionAtSelected;
    },
    getHoursSelected(state) {
      return state.hoursSelected;
    },
    getMinutesSelected(state) {
      return state.minutesSelected;
    },
    getParticipantsSelected(state) {
      return state.participantsSelected;
    },
    getPrioritySelected(state) {
      return state.prioritySelected;
    },
    getActionMethodIdSelected(state) {
      return state.actionMethodIdSelected;
    },
    getPurchaseRateIdSelected(state) {
      return state.purchaseRateIdSelected;
    },
    getFurtherPurchaseRateIdSelected(state) {
      return state.furtherPurchaseRateIdSelected;
    },
    getRenewPurchaseRateIdSelected(state) {
      return state.renewPurchaseRateIdSelected;
    },
    getActionSelected(state) {
      return state.actionSelected;
    },
    getIsComplaintSelected(state) {
      return state.isComplaintSelected;
    },
    getNegotiationExitReasonSelected(state) {
      return state.negotiationExitReasonSelected;
    },
    getClientActionTypeIdSelected(state) {
      return state.clientActionTypeIdSelected;
    },
    getRemarksSelected(state) {
      return state.remarksSelected;
    },
    getDateFromSelected(state) {
      return state.dateFromSelected;
    },
    getStatusAddNewPurchasePoints (state) {
     return state.statusUpdateAddNewPurchasePoints;
    },
    getReasonForWithdrawalSelected (state) {
      return state.ReasonForWithdrawalSelected;
    }
  },
  mutations: {
    // Const Enum
    setEnumNegotiationExitReasonList(state, arr) {
      return (state.enumNegotiationExitReasonList = arr);
    },
    setEnumParticipantTypeList(state, arr) {
      return (state.enumParticipantTypeList = arr);
    },
    setEnumClientActionMethodList(state, arr) {
      return (state.enumClientActionMethodList = arr);
    },
    setEnumPurchaseRateList(state, arr) {
      return (state.enumPurchaseRateList = arr);
    },
    setEnumClientActionTypeList(state, arr) {
      return (state.enumClientActionTypeList = arr);
    },

    // Data call API
    setStaff(state, val) {
      return (state.staff = val);
    },
    setClientActionHistory(state, val) {
      return (state.clientActionHistory = val);
    },
    setCurrentPurchaseRate(state, val) {
      return (state.currentPurchaseRate = val);
    },
    setCurrentFurtherPurchaseRate(state, val) {
      return (state.currentFurtherPurchaseRate = val);
    },
    setCurrentRenewPurchaseRate(state, val) {
      return (state.currentRenewPurchaseRate = val);
    },

    // Selected
    setActionPlannedAtSelected(state, val) {
      return (state.actionPlannedAtSelected = val);
    },
    setActionAtSelected(state, val) {
      return (state.actionAtSelected = val);
    },
    setHoursSelected(state, val) {
      return (state.hoursSelected = val);
    },
    setMinutesSelected(state, val) {
      return (state.minutesSelected = val);
    },
    setParticipantsSelected(state, val) {
      return (state.participantsSelected = val);
    },
    setPrioritySelected(state, val) {
      return (state.prioritySelected = val);
    },
    setActionMethodIdSelected(state, val) {
      return (state.actionMethodIdSelected = val);
    },
    setPurchaseRateIdSelected(state, val) {
      return (state.purchaseRateIdSelected = val);
    },
    setFurtherPurchaseRateIdSelected(state, val) {
      return (state.furtherPurchaseRateIdSelected = val);
    },
    setRenewPurchaseRateIdSelected(state, val) {
      return (state.renewPurchaseRateIdSelected = val);
    },
    setActionSelected(state, val) {
      return (state.actionSelected = val);
    },
    setIsComplaintSelected(state, val) {
      return (state.isComplaintSelected = val);
    },
    setNegotiationExitReasonSelected(state, val) {
      return (state.negotiationExitReasonSelected = val);
    },
    setClientActionTypeIdSelected(state, val) {
      return (state.clientActionTypeIdSelected = val);
    },
    setRemarksSelected(state, val) {
      return (state.remarksSelected = val);
    },
    setDateFromSelected(state, val) {
      return (state.dateFromSelected = val);
    },
    setAddNewPurchasePoints(state, payload) {
      state.addNewPurchasePoints = payload.addNewPurchasePoints
    },
    setStatusAddNewPurchasePoints (state, payload) {
      state.statusUpdateAddNewPurchasePoints = !state.statusUpdateAddNewPurchasePoints
    },
    setReasonForWithdrawalSelected (state, payload) {
      return (state.ReasonForWithdrawalSelected = payload);
    }
  },
  actions,
};

export default customerDetailsActivities;
