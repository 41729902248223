import actions from './actions'

const VisitReservation = {
    state: {
        webRequestListVisitreservation:[],
        webReqEditVisitreservationState:[],
        handled: false,
        take: 0,
        skip: 0,
        totalWebRequestVisitList: 0,
        keywordVisit: null
    },
    getters: {
        webRequestListVisitreservation(state){
            return state.webRequestListVisitreservation
        },
        checkedVisitReservation(state){
            return state.checkedVisitReservation
        },
        webReqEditVisitreservationState(state){
            return state.webReqEditVisitreservationState
        },
        getWebRequestVisitListTake(state){
            return state.take
        },
        getWebRequestVisitListSkip(state){
            return state.skip
        },
        getTotalWebRequestVisitList(state){
            return state.totalWebRequestVisitList
        },
    },
    mutations: {
        setWebRequestListVisitreservation(state,data){
            state.webRequestListVisitreservation = data
        },
        setWebRequestVisitListTake (state, take) {
            state.take = take
          },
        setWebRequestVisitListSkip (state, skip) {
            state.skip = skip
        },
        setTotalWebRequestVisitList (state, total) {
            state.totalWebRequestVisitList = total
        },
        setKeywordVisit (state, data) {
            state.keywordVisit = data
        },
        setCheckedVisitReservation(state,val){
            state.handled = val
        },
        setWebReqEditVisitreservation(state,data){
            state.webReqEditVisitreservationState = data
        },
    },
    actions,
}

export default VisitReservation;