import moment from 'moment';

export const calculateDays = (bigValue, smallValue) => {
  const dateTo = new Date(bigValue);
  const dateFrom = new Date(smallValue);
  const days = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 60 * 60 * 24);
  return Math.ceil(days);
};

export const calculateStayDays = (fromDate, toDate) => {
  const now = moment(fromDate);
  const end = moment(toDate);
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return days * -1;
}

export const addMonths = (date, numOfMonths) => {
  var result = new Date(date);
  result.setMonth(result.getMonth() + numOfMonths);
  return result.toISOString().substr(0, 10);
};

export const getFirstDateOfMonth = (date) => {
  return moment(date).startOf('month').format('YYYY-MM-DD');
};

export const getLastDateOfMonth = (date) => {
  return moment(date).endOf('month').format('YYYY-MM-DD');
};

export const getISODate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const createISODateFromYMD = (year, month, date) => {
  return getISODate(new Date(year, month - 1, date))
}

/**
 *
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const getDaysArray = (startDate, endDate) => {
  endDate = new Date(endDate)
  const dates = [];
  let currentDate = new Date(startDate);

  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  while (currentDate <= endDate) {
    dates.push(moment(currentDate, 'YYYY-MM-DD').format('YYYY-MM-DD'));
    currentDate = addDays.call(currentDate, 1);
  }

  return dates;
};

/**
 *
 */
export const getDiff2Days = (day1, day2, type = 'days') => {
  const dateFrom = moment(day1);
  const dateTo = moment(day2);
  return dateTo.diff(dateFrom, type);
};

export const getDate = (value) => {
  var d = moment(value).format('dddd');
  switch (d) {
    case 'Sunday':
      d = '日';
      break;
    case 'Monday':
      d = '月';
      break;
    case 'Tuesday':
      d = '火';
      break;
    case 'Wednesday':
      d = '水';
      break;
    case 'Thursday':
      d = '木';
      break;
    case 'Friday':
      d = '金';
      break;
    case 'Saturday':
      d = '土';
      break;
  }
  return moment(value).format('DD (' + d + ')');
};

/**
 *
 * @param {*} date
 * @returns
 */
export const getDayInMonth = (date) => {
  return moment(date).date();
};

/**
 *
 * @param {*} month
 * @returns
 */
export const getTotalDaysInMonth = (month) => {
  return moment(month).daysInMonth();
};

/**
 *
 * @param startDate
 * @param endDate
 * @returns
 */
export const getAllDateInBetween2Dates = (startDate, endDate) => {
  const dates = [];

  const startOfMonth = moment(startDate);
  const lastDate = moment(endDate);

  dates.push(startOfMonth.clone().format('YYYY-MM-DD'));

  if (
    startOfMonth.clone().format('YYYY-MM-DD') !==
    lastDate.clone().format('YYYY-MM-DD')
  ) {
    while (startOfMonth.clone().add(1, 'days').diff(lastDate) < 0) {
      const date = startOfMonth.add(1, 'days');
      dates.push(date.format('YYYY-MM-DD'));
    }

    dates.push(lastDate.format('YYYY-MM-DD'));
  }

  return dates;
};

export const nextOrPrevDay = (dayIn, nextOrPrev) => {
  const currentDay = new Date(dayIn);
  const dayResult = currentDay.setDate(
    new Date(currentDay).getDate() + nextOrPrev,
  );
  const dayResultString = new Date(dayResult).toISOString().substr(0, 10);
  return dayResultString;
};

export function getMonthDateString (getMonthDateString) {
  if (typeof getMonthDateString !== 'string') {
    getMonthDateString = moment(getMonthDateString).format('YYYY-MM-DD')
  }
  const [, monthStr, dateStr] = getMonthDateString.split('-');
  return `${monthStr}-${dateStr}`
}

export function getDateTime (date, {
  noSecond,
  delimeter
} = {}) {
  delimeter = delimeter || '/'
  const format = `YYYY${delimeter}MM${delimeter}DD HH:mm${noSecond ? '' : ':ss'}`
  return moment(date).format(format);
}


export const getAgeFromDate = (birthdayDay) =>{
  if (!birthdayDay) {
    return '-'
  }
  const date = new Date(birthdayDay);
  const today = new Date();
  const thisYearBirthday = new Date(today.getFullYear(), date.getMonth(), date.getDate());
  let age = today.getFullYear() - date.getFullYear();
  if(today < thisYearBirthday){
    age--;
  }
  return age;
}

export function addDays(date, n, options = {}) {
  const date2 = moment(date).add(n, 'days');

  if (options.toISO) {
    return date2.format('YYYY-MM-DD')
  }
  return date2.toDate();
}

export function cardExpireToDate(expire) {
  if (!expire) return expire;
  // expire is YYMM format, so
  return `20${expire.substring(0, 2)}/${expire.substring(2)}`
}

export function cardExpireMonths(expire) {
  const expireYear = Number(`20${expire.substring(0, 2)}`);
  const expireMonth = Number(`${expire.substring(2)}`)
  
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const diffMonths = (expireYear * 12 + expireMonth) - (currentYear * 12 + currentMonth);
  return diffMonths;
}


export function isLastDayOfMonth (date) {
  date = new Date(date)
  const month = date.getMonth()
  date.setDate(date.getDate() + 1)

  return date.getMonth() !== month
}

export function addMonthInTokyuWay (date, months) {
  date = new Date(date)
  const monthsAfter = new Date(date)
  monthsAfter.setMonth(date.getMonth() + months)

  if (date.getDate() !== monthsAfter.getDate()) {
    // this means that the same date does not exist in the specified month so get the last day of the previous month
    monthsAfter.setDate(0)
  }

  if (isLastDayOfMonth(date)) {
    // if this is the last day of month, the N month later date should also be the last day of month.
    // see uat member-164
    monthsAfter.setDate(1)
    monthsAfter.setMonth(monthsAfter.getMonth() + 1)
    monthsAfter.setDate(0)
  }

  return monthsAfter
}

export function giftTicketSaleEndSoon (saleEndAt) {
  if (!saleEndAt) return false
  const oneMonth = addMonthInTokyuWay(new Date(), 1)
  return oneMonth > new Date(saleEndAt)
}

