import { apolloClient } from '@/plugins/apollo';
import
{
  CREATE_CANCEL_WAIT_LIST_FREE_NOTIFICATION_REGISTRATION,
  FACILITY_LIST_NOTIFICATION_REGISTRATION,
  FACILITY_LIST,
  ROOM_TYPE_LIST_NOTIFICATION_REGISTRATION,
  CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION,
  FACILITY_LIST_NOTIFICATION_REGISTRATION_BY_PT,
  CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION_BY_PT,
  ACTION_CREATE_BOOKING_V4,
  CLIENT_CHANGE_HISTORY_LIST
} from '@/api/graphql/customer/response/detail/overview/FreeNotificationRegistration/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'
import router from '@/router'

export default {
  async actionClientChangeHistoryListTake3Records({ commit, state })
  {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ CLIENT_CHANGE_HISTORY_LIST }`,
          variables: finishData,
        })
        .then(response =>
        {
          // commit(
          //   'setAlertSuccess',
          //   rootState.alert.success.messages.updateSuccess,
          //   { root: true },
          // );
          resolve(response.data.clientChangeHistoryList.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CLIENT_CHANGE_HISTORY_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createCancelWaitListFreeNotificationRegistration({
    commit,
    state,
    rootState,
    dispatch
  })
  {
    const finishData = {
      clientId: state.clientIdNreg,
      subMemberId: state.idClientInfoNReg === "notDataNumber" ? null : Number(state.idClientInfoNReg),
      roomTypeId: state.idRoomTypeNReg,
      checkInDate: state.checkInDateCRWaitList,
      checkOutDate: state.checkOutDateCRWaitList,
      type: 'Member',
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ CREATE_CANCEL_WAIT_LIST_FREE_NOTIFICATION_REGISTRATION }`,
          variables: finishData,
        })
        .then(data =>
        {
          dispatch('fetchCancelWaitList')
          // commit(
          //   'setAlertSuccessFreeNotificationRegistration',
          //   rootState.alert.success.messages.createSuccess,
          //   { root: true },
          // );
          resolve(data);
        })
        .catch(error =>
        {
          commit(
            'setAlertErrorFreeNotificationRegistration',
            rootState.alert.error.messages.createError,
            { root: true },
          );
          setCopyErrorTextCommit(commit, CREATE_CANCEL_WAIT_LIST_FREE_NOTIFICATION_REGISTRATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getFacilityListNotificationRegistration({ commit, state }, take)
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ FACILITY_LIST_NOTIFICATION_REGISTRATION }`,
        })
        .then(data =>
        {
          var arr = []
          data.data.facilityList.forEach(e =>
          {
            if (!e.type.includes("PT"))
            {
              arr.push(e)
            }
          });
          commit(
            'setFacilityListNotificationRegistration',
            arr,
          );
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, FACILITY_LIST_NOTIFICATION_REGISTRATION, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async getFacilityListFee({ commit, state }, take)
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ FACILITY_LIST }`,
        })
        .then(data =>
        {
          // var arr = []
          // data.data.facilityList.forEach(e => {
          //   if((!e.name.includes('ポイント提携') && (!e.name.includes('CNV')))){
          //     arr.push(e)
          //   }
          // });
          commit(
            'setFacilityListNotificationRegistration',
            data.data.facilityList,
          );
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, FACILITY_LIST_NOTIFICATION_REGISTRATION, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async getFacilityListNotificationRegistrationByPT({ commit, state }, take)
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ FACILITY_LIST_NOTIFICATION_REGISTRATION_BY_PT }`,
        })
        .then(data =>
        {
          commit(
            'setFacilityListNotificationRegistrationByPT',
            data.data.facilityList,
          );
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, FACILITY_LIST_NOTIFICATION_REGISTRATION_BY_PT, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getRoomTypeListNotificationRegistration({ commit, state }, take)
  {
    const finishData = {
      facilityId: state.idFacilityNReg,
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ ROOM_TYPE_LIST_NOTIFICATION_REGISTRATION }`,
          variables: finishData,
        })
        .then(data =>
        {
          commit('setRoomTypeListNReg', data.data.roomTypeList);
          resolve(data);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ROOM_TYPE_LIST_NOTIFICATION_REGISTRATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getClientMemberInfoNotificationRegistration({ commit, state }, id)
  {
    const finishData = {
      id: parseInt(id),
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION }`,
          variables: finishData,
        })
        .then(data =>
        {
          const client = data.data.clientMemberInfo
          // resolve(data);
          const members = [{
            subMemberId: undefined,
            name: client.name,
          }].concat(client.subMembers.map(sm =>
          {
            return {
              subMemberId: sm.id,
              name: sm.name
            }
          }))
          commit(
            'setClientMemberInfoNotificationRegistration', members
          );
          resolve(data)
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async getClientMemberInfoNotificationRegistrationByPT({ commit, state }, id)
  {
    const finishData = {
      id: parseInt(id),
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION_BY_PT }`,
          variables: finishData,
        })
        .then(data =>
        {
          // resolve(data);
          const parentClient = [];
          const info = {
            ...data.data.clientMemberInfo.mainContact,
            ClientId: data.data.clientMemberInfo.id,
            name: data.data.clientMemberInfo.name,
            nameKana: data.data.clientMemberInfo.nameKana,
          }
          parentClient.push(info)
          
          if (data.data.clientMemberInfo.subMembers)
          {
            const submember = data.data.clientMemberInfo.subMembers;
            submember.forEach(element =>
            {
              parentClient.push(element)
            });
          }
          commit(
            'setClientMemberInfoNotificationRegistrationByPT',
            parentClient,
          );
          resolve(data.data.clientMemberInfo)
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION_BY_PT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async ActionCreateBookingV4({ commit, state }, finishData)
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ ACTION_CREATE_BOOKING_V4 }`,
          variables: finishData,
        })
        .then(data =>
        {
          resolve(data.data.createBookingV4);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ACTION_CREATE_BOOKING_V4, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
