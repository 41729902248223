export const SEARCH_CLIENT_LIST_MEMBER_ALL = `
  query($filter: ClientFilterSearchInput!, $pagination: PaginationInput!, $orderBy: [OrderByInput!]) {
    searchClientList(filter: $filter, pagination: $pagination, orderBy: $orderBy, useNewMainContact: true) {
      total
      items {
        id
        type
        memberId
        name
        contracts {
          id
          clientId
          productType {
            code
          }
          mwPwRoomType {
            name
            facility {
              name
            }
          }
          mwPwWeekNumber
          vmRoomType {
            name
            facility {
              name
            }
          }
          startDate
          endDate
          years
          basePoint
        }
        mainContact {
          labelName
          postalCode
          prefecture
          locality
          address1
          address2
          tel
        }
        isCompany
        furtherPurchaseRate {
          name
        }
        renewPurchaseRate {
          name
        }
        pic {
          name
        }
        remarks
      }
      total
      hasMore
    }
  }
`;
