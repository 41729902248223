export const CONTRACT = `
query (
    $id: Int! #契約ID
  ) {
    contract(id: $id) {
      clientId
      client {
        name
        type
      }
      status #String! ステータス
      terminatedAt
      productTypeId #Int! 各契約のIDは以下の通りなので、それに応じて表示内容を変える
  #    MW = 1,
  #    SP = 2,
  #    FP = 3,
  #    VS = 4,
  #    VM = 5,
  #    PW = 6
      phase { #nullable
        id #Int! フェーズID、選択肢は enumContractPhaseList {id name} で取得すること
        name #String! フェーズ名
      }
      contractNumber #String 契約番号
      years #Int 契約年数
      basePoint #年間ポイント数
      additionalPointPrice #追加購入単価
      mwExchangeFee #Int MW交換手数料
      loginAllowedDate #DateStrng 利用開始日（VSのみ）
      endDate #DateString 契約満了日
      remarks #String 備考
    }
  }
`;

export const ENUM_CONTRACT_PHASE_LIST = `
query{
    enumContractPhaseList{
      id
      name
    }
  }
`;
export const UPDATE_CONTRACT = `
mutation (
  $contractId: Int!
  $data: UpdateContractInput!
) {
  updateContract(
    contractId: $contractId
    data: $data
  ) {
    id
  }
}
`;