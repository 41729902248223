export const CREATE_POINT_EXCHANGE = `
mutation (
    $clientId: Int! # 顧客ID
    $contractId: Int
    $orders: [CreatePointExchangeOrderInput!]!
    $memberUserId: Int
    $name: String
  ) {
    createPointExchange(
      clientId: $clientId
      contractId: $contractId
      orders: $orders
      memberUserId: $memberUserId
      address: {
        name: $name
      }
    )
  }
  
`;

export const POINT_EXCHANGE_PRODUCT_LIST = `
query ($onSale: Boolean) {
  pointExchangeProductList (
    filter: { onSale: $onSale }
  ) {
    id
    name #商品名
    spPoint #SPでの価格
    fpPoint #FPでの価格
    tvpPoint #TVP (東急バケーションズポイント)の価格
    showMember
    emailAddress
    companyName
    saleStartAt
    saleEndAt
  }
}
`;

export const GET_POINT_EXCHANGE_PRODUCT = `
query ($id: Int!) {
  pointExchangeProduct (id: $id) {
    id
    name #商品名
    spPoint #SPでの価格
    fpPoint #FPでの価格
    tvpPoint #TVP (東急バケーションズポイント)の価格
    showMember
    emailAddress
    companyName
    saleStartAt
    saleEndAt
    createdBy { name }
    createdAt
  }
}
`;

export const CREATE_POINT_EXCHANGE_PRODUCT = `
mutation (
  $name: String!
  $showMember: Boolean!
  $tvpPoint: Int!
  $spPoint: Int!
  $fpPoint: Int!
  $email: String!
  $companyName: String!
  $saleStartAt: DateTimeIso
  $saleEndAt: DateTimeIso
) {
  createPointExchangeProduct (
    name: $name
    showMember: $showMember
    tvpPoint: $tvpPoint
    spPoint: $spPoint
    fpPoint: $fpPoint
    email: $email
    companyName: $companyName
    saleStartAt: $saleStartAt
    saleEndAt: $saleEndAt
  ) {
    id
  }
}
`;

export const UPDATE_POINT_EXCHANGE_PRODUCT = `
mutation (
  $id: Int!
  $showMember: Boolean!
  $email: String!
  $companyName: String!
  $saleStartAt: DateTimeIso
  $saleEndAt: DateTimeIso
) {
  updatePointExchangeProduct (
    id: $id
    showMember: $showMember
    email: $email
    companyName: $companyName
    saleStartAt: $saleStartAt
    saleEndAt: $saleEndAt
  ) {
    id
  }
}
`;

export const DELETE_POINT_EXCHANGE_PRODUCT = `
mutation (
  $id: Int!
) {
  deletePointExchangeProduct (
    id: $id
  )
}
`;
