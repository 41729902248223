import actions from './actions';

const basicInformation = {
    state: {
        clientBasicInfo: {},
        clientId: null,
        imploymentTypeList: []
    },
    getters: {
        getClientBasicInfo(state) {
            return state.clientBasicInfo;
        },
        getClientId(state) {
            return state.clientId;
        },

    },
    mutations: {
        setClientBasicInfo(state, arr) {
            state.clientBasicInfo = arr;
        },
        setClientId(state, arr) {
            state.clientId = arr;
        },
        setImploymentTypeList (state, payload) {
            state.imploymentTypeList = payload
        }


    },
    actions,
};

export default basicInformation;
