// Filter
// purchaseRates: [1, 2] #購入評価のID - tab 1
// furtherPurchaseRates: [2, 3] #買い増し評価のID - tab 2
// renewPurchaseRates: [1, 2] #更新評価のID - tab 3

// picIds: [1, 2, 3] #担当者ID
// lastCampaignId: 2 #最終広告施策のID
export const SEARCH_CLIENT_LIST = `
  query(
    $filter: ClientFilterSearchInput!
    $load: ClientSearchLoadInput
    $orderBy: [OrderByInput!]
    $pagination: PaginationInput!
  ) {
    searchClientList(
      filter: $filter
      load: $load
      orderBy: $orderBy
      pagination: $pagination
    ) {
      total
      items {
        type
        lastResponseDate
        lastClientActionHistory {
          actionAt
          remarks
        }
        purchaseRate {
          id
          name
        }
        isCompany
        name
        nameKana
        lastCampaignApplyResponse {
          campaign {
            id
            name
          }
        }
        remarksForStaff
        pic {
          id
          name
        }
        id
      }
    }
  }
`;

export const STAFF_LIST = `
query{ staffList(crmRep:true, showDisabled:true) { id name } }
`

export const GET_PIC = `
  query ($clientId: Int!) {
    clientBasicInfo (id: $clientId) {
      pic {
        id
        name
      }
    }
  }
`
