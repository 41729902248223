export const CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT = `
mutation (
    $clientId: Int! 
    $forVs: Boolean! 
    $forVm: Boolean!
    $remarks: String 
  ) {
    createWebBrochureRequestToClient(
      clientId: $clientId
      forVs: $forVs
      forVm: $forVm
      remarks: $remarks
    ) {
            importMethod
    }
  }
`;
export const DELETE_DUPLICATED_CLIENT = `
mutation (
  $clientId: Int!
  $toClientId: Int!
) {
  deleteDuplicatedClient(
    duplicatedClientId: $clientId
    originalClientId: $toClientId
  )
}
`;