import { apolloClient } from '@/plugins/apollo';
import {
  CONTRACT_POINT_SUMMARY,
  CONTRACT_POINT_MOVEMENT_LIST,
  PWCONTRACT_USAGE_LIST,
  UPDATE_PWCONTRACT_USAGE_LIST,
  MWCONTRACT_USAGE_LIST,
  DEPOSIT_MW_BOOKING,
  MWCALCULATE_BOOKING_CANCEL_PENALTY,
  ACTION_CALCULATE_BOOKING_CANCEL_PENALTY,
  REVOKE_MW_RCI
} from '@/api/graphql/contract/contractDetail/sp_ep/sPUsageStatus/spuSageStatus.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async contractPointSummaryList({ commit, state }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
      contractType: parseInt(router.currentRoute.params.type),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT_POINT_SUMMARY}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setContractPointSummary', data.data.contractPointSummary);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT_POINT_SUMMARY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async contractPointMovementList({ commit, state }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
      contractType: parseInt(router.currentRoute.params.type),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT_POINT_MOVEMENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit(
            'setContractPointMovementList',
            data.data.contractPointMovementList,
          );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT_POINT_MOVEMENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async pwContractUsageList({ commit, state }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${PWCONTRACT_USAGE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
           commit(
             'setpwContractUsageList',
             data.data,
           );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, PWCONTRACT_USAGE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async UpdatepwContractUsageList({ commit, state },data) {
    const finishData = {
      bookingId: parseInt(data.id),
      undo: data.undo
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${UPDATE_PWCONTRACT_USAGE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          //  commit(
          //    'setpwContractUsageList',
          //    data.data,
          //  );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_PWCONTRACT_USAGE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async mwContractUsageList({ commit, state }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${MWCONTRACT_USAGE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
           commit(
             'setmwContractUsageList',
             data.data,
           );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, MWCONTRACT_USAGE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async mwGetCalculateBookingCancelPenalty({ commit, state },id) {
    const finishData = {
      bookingId: parseInt(id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${MWCALCULATE_BOOKING_CANCEL_PENALTY}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          // commit('setAlertError', 'システムエラーがあります', { root: true });
          setCopyErrorTextCommit(commit, MWCALCULATE_BOOKING_CANCEL_PENALTY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async ActionCalculateBookingCancelPenalty({ commit, state },data) {
    const finishData = {
      bookingId: parseInt(data.id),
      penaltyRates: data.penaltyRates
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ACTION_CALCULATE_BOOKING_CANCEL_PENALTY}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true })
          resolve(data);
        })
        .catch(error => {
          commit('setLoadingOverlayHide', { root: true })
          setCopyErrorTextCommit(commit, ACTION_CALCULATE_BOOKING_CANCEL_PENALTY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async updateDepositMwBooking({ commit, rootState }, obj) {
    const finishData = {
      contractId: parseInt(obj.id),
      year: parseInt(obj.year),
      firstHalf: obj.firstHalf,
      latterHalf: obj.latterHalf 
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.mutate({
      mutation: gql`${DEPOSIT_MW_BOOKING}`,
      variables: finishData
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true })
      commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
      commit('setStatusUpdateDepositMwBooking', true)
    }).catch(error => {
      setCopyErrorTextCommit(commit, DEPOSIT_MW_BOOKING, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors);
    })
  },
  async revokeMwRightForRCI({ commit }, obj) {
    const finishData = obj
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.mutate({
      mutation: gql`${REVOKE_MW_RCI}`,
      variables: finishData
    }).then(data => {
      commit('setAlertSuccess', 'RCI償却を実行しました', { root: true })
    }).catch(error => {
      setCopyErrorTextCommit(commit, DEPOSIT_MW_BOOKING, finishData, error.graphQLErrors)
      handlError(commit, error.graphQLErrors);
    }).finally(() => {
      commit('setLoadingOverlayHide', { root: true })
    })
  }
};
