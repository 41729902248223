import actions from './actions';

const FaceToFaceCustomer = {
  state: {
    searchClientListFaceToFaceCustomer: [],
    totalSearchClientListFaceToFaceCustomer: 0
  },
  getters: {
    getSearchClientListFaceToFaceCustomer(state) {
      return state.searchClientListFaceToFaceCustomer;
    },
    getTotalSearchClientListFaceToFaceCustomer(state){
      return state.totalSearchClientListFaceToFaceCustomer;
    }
  },
  mutations: {
    setSearchClientListFaceToFaceCustomer(state, data) {
      state.searchClientListFaceToFaceCustomer = data;
    },
    setTotalSearchClientListFaceToFaceCustomer(state, val){
      state.totalSearchClientListFaceToFaceCustomer = val
    }
  },
  actions,
};

export default FaceToFaceCustomer;
