import { apolloClient } from '@/plugins/apollo';
import {
    VMCONTRACT_USAGE,
    CONVERT_INTO_CONFIRMED_CONTRACT,
    GET_CLIENT_BASIC_CONTRACT_ID
} from '@/api/graphql/contract/contractDetail/sp_ep/VMTemporaryContract/vmtemporarycontract.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async VMComtractUsageList({ commit, state }, ContractID) {
    const finishData = {
      contractId: ContractID,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${VMCONTRACT_USAGE}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setVmContractUsageList', data.data.vmContractUsage);
          resolve(data.data.vmContractUsage);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, VMCONTRACT_USAGE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async VMConvertIntoConfirmedContract({ commit, state },data) {
    const finishData = {
      contractId: data.contractId,
      roomTypeId: data.roomTypeId,
      years: data.years,
      tvpPaymentAmount: data.tvpPaymentAmount,
      bookings: data.bookings
  }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CONVERT_INTO_CONFIRMED_CONTRACT}`,
          variables: finishData,
        })
        .then(data => {
          commit('setVMConvertIntoConfirmedContract');
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONVERT_INTO_CONFIRMED_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async getClientBasicContractIDInfo({ commit, state },id) {
    const finishData = {
      clientId: id
   }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${GET_CLIENT_BASIC_CONTRACT_ID}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, GET_CLIENT_BASIC_CONTRACT_ID, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
