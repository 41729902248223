export const CREATE_SIGNUP_BONUS = `
  mutation(
    $status: SignupBonusStatus!
    $name: String!
    $startDate: DateString!
    $endDate: DateString!
    $type: SignupBonusType!
    $description: String!
    $amount: Int
    $code: String
    $vsTvp: Int!
    $vsDiscount: Int!
    $canUseInMemberWeb: Boolean
    $canUseInPromoUrl: Boolean
  ) {
    createSignupBonus(
      data: {
        status: $status
        name: $name
        startDate: $startDate
        endDate: $endDate
        type: $type
        amount: $amount
        description: $description
        vsPromoCode: $code
        vsPromoTVP: $vsTvp
        vsPromoDiscount: $vsDiscount
        canUseInMemberWeb: $canUseInMemberWeb
        canUseInPromoUrl: $canUseInPromoUrl
      }
    ) {
      id
    }
  }
`;

export const UPDATE_SIGNUP_BONUS = `
mutation (
  $id: Int! 
  $status: SignupBonusStatus!
  $name: String!
  $startDate: DateString! 
  $endDate: DateString!
  $type: SignupBonusType! 
  $description: String! 
  $amount: Int
  $code: String 
  $vsTvp: Int! 
  $vsDiscount: Int!
  $canUseInMemberWeb: Boolean
  $canUseInPromoUrl: Boolean
) {
  updateSignupBonus(id: $id, data: {
    status: $status
    name: $name
    startDate: $startDate
    endDate: $endDate
    type: $type
    amount: $amount 
    description: $description
    vsPromoCode: $code
    vsPromoTVP: $vsTvp
    vsPromoDiscount: $vsDiscount
    canUseInMemberWeb: $canUseInMemberWeb
    canUseInPromoUrl: $canUseInPromoUrl
  }) {
    id
  }
}
`;

export const GENERATE_SIGNUP_BONUS_VS_PROMO_CODE = `
mutation {
  generateSignupBonusVsPromoCode
}
`
