<template>
  <v-main>
    <v-alert
      class="alert-default"
      transition="scale-transition"
      type="success"
      :value="alertSuccess !== null"
      >{{ alertSuccess }}</v-alert
    >
    <v-alert
      class="alert-default"
      transition="scale-transition"
      type="error"
      :value="alertError !== null"
      dismissible
      @input="clearError"
      >{{ alertError }}<v-btn @click="copyErrorToClipboard" color="error" style="border: thin solid #FFFFFF !important">エラー詳細をコピー</v-btn></v-alert
    >
    <v-dialog
      :value="errorDialog !== null"
      @click:outside="setErrorDialog(null)"
    >
      <v-alert
        class="alert-default --center text-h5 pa-10"
        type="error"
        icon="mdi-alert-circle-outline"
        width="320"
        prominent
      >
        {{ errorDialog }}
        <v-btn
          absolute
          top
          right
          fab
          small
          color="white"
          @click="setErrorDialog(null)"
        >
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-alert>
    </v-dialog>
    <SidebarLayout
      @is-sidebar-open="onSidebarStateChange"
      v-if="!isSidebarHidden"
    ></SidebarLayout>
    <div
      class="main-layout-container"
      :class="{ 'sidebar-open': isSidebarOpen }"
      style=" height: 100% !important;"
    >
      <slot />
    </div>
    <v-overlay
      :value="loadingOverlay"
      :z-index="zIndex"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      />
    </v-overlay>
    <confirm-dialog />
  </v-main>
</template>

<script>
import SidebarLayout from '../layouts/SidebarLayout'
import { mapGetters, mapMutations } from 'vuex'
import ConfirmDialog from '../components/dialog/ConfirmDialog.vue'

export default {
  name: 'MainLayout',
  components: { SidebarLayout, ConfirmDialog },
  data: () => {
    return {
      isSidebarOpen: false,
      isSidebarHidden: false,
      zIndex: 99999999
    }
  },
  mounted () {
    this.isSidebarHidden = this.$route.query.hideSidebar === '1' || this.$route.meta.noSideBar
  },
  watch: {
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    },
    doneTodosCountError (value) {
      // if (value !== null) {
      //   setTimeout(() => {
      //     this.setAlertError(null)
      //   }, 2000)
      // }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay', 'getCopyErrorText']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },
  methods: {
    ...mapMutations(['setAlertSuccess', 'setAlertError', 'setErrorDialog', 'setPermissionUser', 'setRoleAdminUser', 'setCopyErrorText']),
    onSidebarStateChange (value) {
      this.isSidebarOpen = value
    },
    copyErrorToClipboard () {
      navigator.clipboard.writeText(this.getCopyErrorText)
      this.setCopyErrorText(null)
      this.setAlertError(null)
    },
    clearError() {
      this.setCopyErrorText(null)
      this.setAlertError(null)
    }
  }
}
</script>

<style scoped lang="scss">
.main-layout-container.sidebar-open {
  width: calc(100% - 56px);
  margin-left: 56px;
}
.alert-default {
  position: fixed;
  width: 350px;
  z-index: 1000;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  &.--center {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
