import actions from './actions';

const breakdownList = {
    state: {
        contractId: null,
        contractPointList: [],
    },
    getters: {
        getContractIdBreak(state) {
            return state.contractId;
        },
        getContractPointList(state) {
            return state.contractPointList;
        },
        

    },
    mutations: {
        setContractId(state, payload) {
            state.contractId = payload
        },
        setContractPointList(state, payload) {
            state.contractPointList = payload
        },

      
    },
    actions,
};

export default breakdownList;
