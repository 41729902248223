import actions from './actions';

const Member = {
  state: {
    total: null,
    searchClientListMember: [],
  },
  getters: {
    getTotalItem (state) {
      return state.total
    },
    getSearchClientListMember(state) {
      return state.searchClientListMember;
    },
  },
  mutations: {
    setSearchClientListMember(state, val) {
      state.searchClientListMember = val;
    },
    setTotal(state, val) {
      state.total = val
    }
  },
  actions,
};

export default Member;
