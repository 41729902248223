import { apolloClient } from '@/plugins/apollo';
import { UPDATE_ANNUALFEE_CHANGE_MEMBER } from '@/api/graphql/customer/response/detail/membershipContract/ChangeMember/index.js';
// import { CLIENT_ANNUALFEE_LIST_CUSTOMER_MEMBERSHIP_CONTRACT } from '@/api/graphql/customer/response/detail/membershipContract/CustomerMembershipContract/index.js';
// import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async updateAnnualfeeChangeMember({ state, commit, rootState }, year) {
    const finishData = {
      id: parseInt(state.idChangeMember),
      remarks: state.remarksChangeMember,
      year: parseInt(year),
      month: state.monthChangeMember,
      annualFee: state.annualFeeChangeMember,
      membershipFee: state.membershipFeeChangeMember,
      managementFee: state.managementFeeChangeMember,
      hotspringFee: state.hotspringFeeChangeMember,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_ANNUALFEE_CHANGE_MEMBER}`,
          variables: finishData
        })
        .then(data => {
          commit('setFlagUpdate', true)
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
          );
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_ANNUALFEE_CHANGE_MEMBER, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
