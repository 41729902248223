export const WEB_REQUEST = `
query (
  $id: Int!
) {
  webRequest(id: $id) {
    id
    createdAt
    medias {
      mediaType
      mediaOther
    }
    client { 
      id
      picId
      name
    }
    forVm
    forVs
    facilities
    seasons
    actionRemarks 
    purchaseRate {
      name
    }
    handledAt
    clientId
    firstDate 
    firstTime 
    secondDate
    secondTime
    inquiry
    firstCheckInDate 
    firstStayDays
    secondCheckInDate
    secondStayDays 
    firstFacility {
      name 
    }
    secondFacility {
      name
    }
    isCompany 
    lastName 
    firstName
    lastKana 
    firstKana
    birthday 
    sex 
    postalCode 
    prefecture 
    locality 
    address1
    address2 
    tel 
    telMobile 
    email 
    remarks
    companyName 
    companyNameKana
    businessType {
      name 
    } 
    companyPicName 
    companyPicKana
    companyPicDepartment
    companyPicTitle 
    companySize
    workStyleReform
    
    appliedCampaigns {
      id
      name
      groups
      createdAt
      note
      attachment1Url
      attachment2Url
      attachment3Url
    }
  }
}
`

export const UPDATE_WEB_REQUEST = `
mutation (
  $id: Int!
  $remarks: String 
  $status: WebRequestStatus
) {
  updateWebRequest(
    webRequestId: $id
    data: {
      remarks: $remarks
      status: $status
    }
  ) {
    __typename
  }
}
`