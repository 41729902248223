/** When your routing table is too long, you can split it into small modules* */

import store from '@/store'
// import DashboardPage from '@/views/dashboard/dashboard.vue';
import DashboardComponent from '@/components/DashboardComponent'
import { i18n } from '@/plugins/i18n'
import { REFRESH_STAFF_TOKEN } from '@/api/login'
import { apolloClient } from '@/plugins/apollo'
import { utoa } from '@/constants/functions'

const dashboardRouter = {
  path: '',
  component: DashboardComponent,
  async beforeEnter(to, from, next)
  {
    const toSignin = () => {
      const paramsNext = utoa(to.fullPath)
      return next({
        name: 'signin',
        params: {
          strFrom: paramsNext
        }
      })
    }
    if (window.localStorage.getItem('token'))
    {
      if (to.meta.type === 'contract')
      {
        await store.dispatch('basicInformationList', to.params.id)
        document.title = store.getters.getBasicInfors.client.name + ' : ' + to.params.type + '契約';
      } else
      {
        document.title = to.meta.title
      }
      if (window.localStorage.getItem('refreshToken'))
      {
        await apolloClient
          .mutate({
            mutation: REFRESH_STAFF_TOKEN,
            variables: {
              refreshToken: store.state.user.refreshToken
            }
          })
          .then(data =>
          {
            return next()
          })
          .catch(error =>
          {
            console.log(error)
            if (!window.localStorage.getItem('rememberID'))
            {
              window.localStorage.removeItem('email')
            }
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('refreshToken')
            window.localStorage.removeItem('id')
            window.localStorage.removeItem('name')
            toSignin()
          })
      } else {
        return toSignin()
      }
    } else
    {
      return toSignin()
    }
  },

  children: [
    { path: '/' },
    {
      path: '/get',
      component: {
        render: c => c('router-view')
      },
      children: [
        {
          path: 'contract/:id',
          name: 'contract-detail',
          component: () =>
            import(
              '@/views/contract/contractDetail/ContractDetailRedirect.vue'
            ),
          meta: {
            title: 'CRM',
            noSideBar: true
          }
        }
      ]
    },
    // web-manage
    {
      path: '/web-manage',
    },
    {
      path: '/web-manage',
      component: {
        render: c => c('router-view')
      },
      children: [
        {
          path: 'info',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.info'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/info/informationRequest'
                ),
              meta: {
                title: 'WEBページ管理_資料請求'
              }
            }
          ]
        },
        {
          path: 'document-request',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/documentRequestList'
                ),
              meta: {
                title: 'WEBページ管理_資料請求'
              }
            },
            {
              path: 'all',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/documentrequest/documentAll/documentAll.vue'
                ),
              meta: {
                title: 'WEBページ管理_資料請求'
              }
            }
          ]
        },
        {
          path: 'old-customer',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.oldCustomer'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/oldcustomer/oldCustomerList'
                ),
              meta: {
                title: 'WEBページ管理_資料請求'
              }
            }
          ]
        },
        {
          path: 'visit-reservation',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.VisitReservation'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/VisitReservation/VisitReservationList.vue'
                ),
              meta: {
                title: 'WEBページ管理_来店予約'
              }
            }
          ]
        },
        {
          path: 'notice',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.notice'),
              component: () =>
                import('@/views/dashboard/webPageManage/notice/noticeList.vue'),
              meta: {
                title: 'WEBページ管理_お知らせ'
              }
            }
          ]
        },
        {
          path: 'experience',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.experience'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/experience/experienceList'
                ),
              meta: {
                title: 'WEBページ管理_体験宿泊'
              }
            },
          ]
        },
        {
          path: 'facility',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.facility'),
              component: () =>
                import('@/views/dashboard/webPageManage/facility/facilityList'),
              meta: {
                title: 'WEBページ管理_体験宿泊'
              }
            }
          ]
        },
        {
          path: 'facility-setting',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.facilitySetting'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/facilitySetting/facilitySettingList'
                ),
              meta: {
                title: 'WEBページ管理_施設設定'
              }
            }
          ]
        },
        {
          path: 'web-document',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.webDocument'),
              component: () =>
                import(
                  '@/views/dashboard/webPageManage/webDocument/webDocumentList'
                ),
              meta: {
                title: 'WEBページ管理_会員WEB資料'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/confirm-annual',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          component: () => import('@/views/confirmAnnual/confirmAnnual'),
          meta: {
            title: '年会費請求'
          }
        }
      ]
    },
    {
      path: 'customer',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: 'all-customers',
          component: () => import('@/views/customer/applicant/applicantAll'),
          meta: {
            title: '顧客/会員'
          }
        },
        {
          path: 'prospect-list',
          name: 'response-all',
          meta: {
            title: '顧客/会員'
          },
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () => import('@/views/customer/response/responseAll'),
              meta: {
                title: '顧客/会員'
              }
            },
            {
              path: 'experience-based-accommodation-customers',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () => import('@/views/customer/response/ExperienceBasedAccommodationCustomers/responseAllExperienceBasedAccommodationCustomers.vue'),
              meta: {
                title: '顧客/会員'
              }
            },
            {
              path: 'face-to-face-customer',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () => import('@/views/customer/response/FaceToFaceCustomer/responseAllFaceToFaceCustomer.vue'),
              meta: {
                title: '顧客/会員'
              }
            }
          ]
        },
        {
          path: 'member-list',
          name: 'member-list',
          component: () => import('@/views/customer/member/memberAll'),
          meta: {
            title: '顧客/会員'
          }
        },
        // ---
        {
          path: 'vm-contract-usage/:id',
          name: 'vm-contract-usage',
          component: () =>
            import(
              '@/views/customer/response/VMTemporaryContract/VMContractUsage.vue'
            ),
        }
      ]
    },
    {
      path: 'vm-temporary-contract/:id',
      name: 'vm-temporary-contract',
      component: () =>
        import(
          '@/views/customer/response/VMTemporaryContract/VMTemporaryContract.vue'
        )
    },
    // Customer detail
    {
      path: 'customer-details',
      component: { render: c => c('router-view') },
      children: [
        {
          path: ':id',
          name: 'customer-details',
          component: () =>
            import(
              '@/views/customer/response/detail/Overview/CustomerDetailsOverview.vue'
            ),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'member-ship-contract/:id',
          name: 'member-ship-contract',
          component: () =>
            import(
              '@/views/customer/response/detail/MembershipContract/CustomerMembershipContract'
            ),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'prospect-information/:id',
          name: 'prospect-information',
          component: () =>
            import(
              '@/views/customer/response/detail/ProspectInformation/ProspectInformation'
            ),
          meta: {
            title: "",
            noSideBar: true
          }
        },
        {
          path: 'basic-information/:id',
          name: 'basic-information-customer-detail',
          component: () =>
            import('@/views/customer/response/detail/BasicInformation'),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'credit-card/:id',
          name: 'credit-card-customer-detail',
          component: () =>
            import('@/views/customer/response/detail/CustomerCreditCardTab'),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'activities/:id',
          name: 'activities-customer-detail',
          component: () =>
            import('@/views/customer/response/detail/Activities/Activities'),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'membership-billing-history/:id',
          name: 'membership-billing-history',
          component: () =>
            import(
              '@/views/customer/response/detail/MembershipBillingHistory/MembershipBillingHistory'
            ),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'change-history/:id',
          name: 'change-history-customer-detail',
          component: () =>
            import(
              '@/views/customer/response/detail/changeHistory/changeHistory'
            ),
          meta: {
            title: '',
            noSideBar: true
          }
        },
        {
          path: 'delete/:id',
          name: 'delete-custome-detail',
          component: () => import('@/views/customer/response/detail/delete'),
          meta: {
            title: '',
            noSideBar: true
          }
        },
      ]
    },
    // Persionnal report
    {
      path: '/personal-report',
    },
    {
      path: '/personal-report',
      component: {
        render: c => c('router-view')
      },
      children: [
        {
          path: 'my-page',
          name: 'my-page',
          component: () => import('@/views/myPage/MyPage/MypageList'),
          meta: {
            title: 'マイページ'
          }
        },
        {
          path: 'customer-in-charge',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () =>
                import(
                  '@/views/myPage/CustomerInCharge/PurchaseEvaluation.vue'
                ),
              meta: {
                title: 'マイページ'
              }
            },
            {
              path: 'additional-purchase',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () =>
                import(
                  '@/views/myPage/CustomerInCharge/AdditionalPurchase.vue'
                ),
              meta: {
                title: 'マイページ'
              }
            },
            {
              path: 'update-evaluation',
              name: i18n.t('webPageManage.routeName.documentRequest'),
              component: () =>
                import('@/views/myPage/CustomerInCharge/UpdateEvaluation.vue'),
              meta: {
                title: 'マイページ'
              }
            }
          ]
        },
        {
          path: 'experience',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: 'personal-report',
              component: () =>
                import(
                  '@/views/myPage/ExperienceLodging/ExperienceLodging.vue'
                ),
              meta: {
                title: 'マイページ'
              }
            }
          ]
        },
        {
          path: 'meeting',
          component: { render: c => c('router-view') },
          children: [
            {
              path: '',
              name: 'personal-report',
              component: () => import('@/views/myPage/Meeting/Meeting.vue'),
              meta: {
                title: 'マイページ'
              }
            }
          ]
        }
      ]
    },
    // Advertising
    {
      path: 'advertising',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: i18n.t('advertising.routeName.advertising'),
          component: () => import('@/views/advertising/advertisingPoliciesTab'),
          meta: {
            title: '広告施策'
          }
        },
      ]
    },
    // corporate-ticket
    {
      path: 'corporate-ticket',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: i18n.t('corporateTicket.routeName.corporateTicket'),
          component: () => import('@/views/corporateticket/corporateTicket'),
          meta: {
            title: '法人チケット'
          }
        },
        {
          path: 'create-booking',
          component: () =>
            import('@/views/corporateticket/ticketBookingCreate'),
          meta: {
            title: '法人チケット',
            noSideBar: true
          }
        }
      ]
    },
    // contract
    {
      path: '/contract',
      component: { render: c => c('router-view') },
      children: [
        {
          path: '',
          name: '契約',
          component: () => import('@/views/contract/contractTab'),
        },
        {
          path: 'usage-situation-sp-pw/:id/:type',
          name: 'usage-situation-sp-pw',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/UsageSituation/SPUsageStatus.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'usage-situation-mw/:id/:type',
          name: 'usage-situation-mw',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/UsageSituation/MWUsageStatus.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'usage-situation-vm/:id/:type',
          name: 'usage-situation-vm',
          component: () =>
            import(
              '@/views/contract/contractDetail/UsageSituationVm/index.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'reservation_point_affiliated_facility/:id/:type/:point',
          name: 'reservation_point_affiliated_facility',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/UsageSituation/ReservationPointAffiliatedFacility.vue'
            ),
          meta: {
            title: 'CRM',
            noSideBar: true,
            type: 'contract'
          }
        },
        {
          path: 'contract-time-information/:id/:type',
          name: 'contract-time-information',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/ContractTimeInformation/ContractTimeInformation'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'contract-cancellation/:id/:type',
          name: 'contract-cancellation',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/ContractCancellation/ContractCancellation.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'basic-infomation/:id/:type',
          name: 'basic-infomation-contract',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/BasicInformation/BasicInformation'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },

        // VS
        {
          path: 'vs-vs/:id/:type',
          name: 'vs-vs',
          component: () =>
            import(
              '@/views/contract/contractDetail/VS/usageStatusVS.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },
        {
          path: 'contract-transfer/:id/:type',
          name: 'contract-transfer',
          component: () =>
            import(
              '@/views/contract/contractDetail/MW/UsageSituationMW/MWcontract.vue'
            ),
          meta: {
            title: 'CRM',
            type: 'contract',
            noSideBar: true
          }
        },

        {
          path: 'create-exchange-reservation',
          name: 'create-exchange-reservation',
          component: () =>
            import(
              '@/views/contract/contractDetail/Sp_Ep/UsageSituation/createExchangeReservation'
            ),
          meta: {
            title: '契約',
            noSideBar: true
          }
        },
      ]
    },
    // report
    {
      path: '/report',
      name: i18n.t('sidebar.report'),
      component: () => import('@/views/report/reportTab'),
      meta: {
        title: '帳票・CSV'
      }
    },
    // gift-ticket
    {
      path: '/gift-ticket/:tab?',
      name: i18n.t('sidebar.giftTicket'),
      component: () => import('@/views/giftTicket/index'),
      meta: {
        title: 'ギフトチケット'
      }
    },
    // manager
    {
      path: '/manager',
      name: i18n.t('manager.manager'),
      component: () => import('@/views/manager/UserTab'),
      meta: {
        title: '担当者'
      }
    },
    // mail
    {
      path: '/mail',
      name: 'mail',
      component: () => import('@/views/mail/AutomaticMail'),
      meta: {
        title: '自動メール'
      }
    },
    {
      path: '/batch/:tab?',
      name: 'batch',
      component: () => import('@/views/batch/index'),
      meta: {
        title: '一括処理'
      }
    },
  ]
}

export default dashboardRouter