export const CHANGE_TVP = `
mutation (
  $clientId: Int! 
  $pointChange: Int! 
  $remarks: String 
) {
  changeTVP(
    clientId: $clientId
    pointChange: $pointChange
    remarks: $remarks
  ) {
    pointsAfter
  }
}
`;