export const SEARCH_CLIENT_LIST_FACE_TO_FACE_CUSTOMER = `
query ($filter: ClientFilterSearchInput!, $orderBy: [OrderByInput!], $pagination: PaginationInput!) {
  searchClientList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
    items {
      id
      isCompany
      name
      pic {
        name
        __typename
      }
      lastClientActionHistory {
        actionAt
        participants
        actionMethodId
      }
    }
    total
  }
}

`;
