import { apolloClient } from "@/plugins/apollo";
import { ENUM_CLIENT_CHILD_TYPE_LIST } from "@/api/graphql/enum/enumClientChildTypeList.js";
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async enumClientChildTypeList({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${ENUM_CLIENT_CHILD_TYPE_LIST}`,

                })
                .then(data => {
                    commit('setEnumClientChildTypeList', data.data.enumClientChildTypeList)
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, ENUM_CLIENT_CHILD_TYPE_LIST, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    }
}