import actions from './actions'

const createBookingTicket = {
  state: {
    itemSelect: null
  },
  getters: {
    getSelectItem (state) {
      return state.itemSelect
    }
  },
  mutations: {
    saveSelectItem (state, payload) {
      if (payload.itemSelect.length > 0){
        state.itemSelect = payload.itemSelect
      } else state.itemSelect  = []
    }
  },
  actions
}

export default createBookingTicket
