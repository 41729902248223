import actions from './actions';

const exception = {
  state: {
    exceptionData: {
    }
  },
  getters: {
    getException(state) {
      return state.contractId;
    },

    getValidException(state) {
      return state.exceptionData
    }
  },
  mutations: {
    setContractIdException(state, payload) {
      state.contractId = payload
    },
    setValidException(state, payload) {
      state.exceptionData = payload
    }

  },
  actions,
};

export default exception;
