import { apolloClient } from '@/plugins/apollo';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import { SHOW_RED_DOT_DUPLICATED_CLIENT_LIST } from '@/api/graphql/dashboard/webPageManage/pageManageBase/index.js';
import gql from 'graphql-tag'

export default {
  actionShowRedDotDuplicatedClientList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SHOW_RED_DOT_DUPLICATED_CLIENT_LIST}`,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
        //   commit('setEnumEmploymentTypeList', data.data.enumEmploymentTypeList);
          resolve(data.data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SHOW_RED_DOT_DUPLICATED_CLIENT_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
