export const POINT_EXCHANGE_LIST = `
query (
  $pagination: PaginationInput
) {
  pointExchangeList(
    pagination:$pagination
  ) {
    total
    items {
      id 
      client {
        id
        type  
        name 
        isCompany 
      }
      contract { productTypeId }
      tvpPoint
      spPoint
      fpPoint
      pointExchangeProduct {
        name
      }
      quantity 
      confirmedAt 
      emailHistory { 
        sentAt 
      }
      postalCode 
      prefecture 
      locality 
      address1 
      address2
      toName
    }
  }
}

`