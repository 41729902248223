import { apolloClient } from "@/plugins/apollo";
import {
  FACILITY, FACILITY_LIST, 
  UPDATE_PUBLIC_FACILITY_INFO
} from "@/api/graphql/dashboard/webPageManage/facilitySetting/facilitySetting.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async facilitySettingAction({ commit, state }, id) {
    const finishData = {
      id: id
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${FACILITY}`,
          variables: finishData
        }).then(data => {
          commit('setFacilitySetting', data.data.facility)
          resolve(data.data.facility)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, FACILITY, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    })
  },
  async facilityListAction({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${FACILITY_LIST}`,
        }).then(data => {
          commit('setFacilityDs', data.data.facilityList)
          resolve(data.data.facilityList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, FACILITY_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    })
  },

  async actionUpdateFacilitySetting({ commit, state, rootState }, id) {
    const roomTypes = [] 
    state.dataUpdate.roomTypes.map(item => roomTypes.push({id: item.id, bookingRemarks: item.bookingRemarks}))
    const finishData = {
      id: id,
      nameWeb: state.dataUpdate.nameWeb,
      nameAbbr: state.dataUpdate.nameAbbr,
      facilityHpUrl: state.dataUpdate.facilityHpUrl,
      tags: state.dataUpdate.tags,
      mainImageUrl: state.dataUpdate.mainImageUrl,
      roomTypes,
      otherNotes: state.dataUpdate.otherNotes
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_PUBLIC_FACILITY_INFO}`,
          variables: finishData,
        })
        .then(data => {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_PUBLIC_FACILITY_INFO, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

}