import { apolloClient } from '@/plugins/apollo'
import {
  ADD_STAFFS_TO_GROUP, REMOVE_STAFFS_TO_GROUP
} from '@/api/graphql/manager/managementTabUser/managementTabUser.js'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async managementTabUser ({ commit, rootState, dispatch }, dataManagement) {
    const finishData = {
      staffGroupId: dataManagement.staffGroupId,
      staffIds: dataManagement.staffIds
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ADD_STAFFS_TO_GROUP}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('salesRepresentativeList')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true }
          )
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ADD_STAFFS_TO_GROUP, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
  async managementTabUserRemove ({ commit, state,dispatch,rootState }, dataRemoveManagement) {
    const finishData = {
      groupId: dataRemoveManagement.groupId,
      staffIds: dataRemoveManagement.staffIds
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${REMOVE_STAFFS_TO_GROUP}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          // dispatch('groupList')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.deleteSuccess,
            { root: true }
          )
          dispatch('salesRepresentativeList')
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, REMOVE_STAFFS_TO_GROUP, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }
}
