import actions from './actions';

const contractCancellation = {
  state: {
    data: {
      // contractId: null,
      // reason: 'Terminate'
    }
  },
  getters: {
    getValidCancelContract(state) {
      return state.data
    }
  },
  mutations: {
    setValidCancelContract(state, payload) {
      state.data = payload
    },
    setReason1(state, payload) {
      state.data = payload
    }

  },
  actions,
};

export default contractCancellation;
