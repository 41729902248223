export const DUPLICATED_CLIENT_LIST = `
  query($pagination: PaginationInput) {
    duplicatedClientList(pagination: $pagination) {
      total
      items {
        type
        baseInfo {
          isCompany
          name
          email
          birthday
          tel
          telMobile
          memberId
          postalCode
          prefecture
          locality
          address1
          address2
          sex
        }
        duplicatedItems {
          duplicatedFields {
            name
          }
          client {
            id
            name
            createdAt
            type
          }
        }
        id
        createdAt
        vsApplication {
          id
          signupBonus {
            name
            vsPromoCode
          }
        }
        webRequest {
          id
        }
      }
    }
  }
`;

// addToDuplicateCustomers type not VS
export const ASSIGN_CLIENT_WEB_REQUEST = `
  mutation($webRequestId: Int!, $clientId: Int!) {
    assignClientWebRequest(webRequestId: $webRequestId, clientId: $clientId) {
      __typename
    }
  }
`;

// addToDuplicateCustomers type is VS
export const VS_SIGN_UP_TRANSFER_CONTRACT_OWNER_SHIP = `
  mutation($vsApplicationId: Int!, $clientId: Int!) {
    vsSignupTransferContractOwnership(
      vsApplicationId: $vsApplicationId
      newClientId: $clientId
    ) {
      __typename
    }
  }
`;

// doNotAddToDuplicateCustomers type not VS
export const CREATE_CLIENT_WEB_REQUEST = `
  mutation($webRequestId: Int!) {
    createClientWebRequest(webRequestId: $webRequestId) {
      __typename
    }
  }
`;

// doNotAddToDuplicateCustomers is VS
export const VS_SIGN_UP_DUPLICATE_CHECK = `
  mutation($vsApplicationId: Int!) {
    vsSignupDuplicateCheck(vsApplicationId: $vsApplicationId) {
      __typename
    }
  }
`;

export const VS_SIGN_UP_DISMISS = `
  mutation($vsApplicationId: Int!) {
    dismissVsSignup(vsApplicationId: $vsApplicationId) {
      __typename
    }
  }
`;

