export const OTHER_PUBLIC_DOCLIST_OTHER_MATERIALS  = `
query {
    otherPublicDocList {
      id
      docType {
        name 
      }
      fileName 
      url 
    }
  }
`;

export const DELETE_OTHER_MATERIALS  = `
mutation (
  $id: Int!
) {
  deleteOtherPublicDoc(id: $id)
}
`;

export const GET_URL_IMG_OTHER_MATERIALS = `
mutation (
  $contentType: String!
  $contentLength: Int!
  $type: String! ,
  $fileName: String
) {
  generateUploadUrl(
    type: $type
    contentType: $contentType
    contentLength: $contentLength
    fileName: $fileName
  ) {
    uploadUrl
    fileUrl 
  }
}
`;

export const CREATE_OTHER_PUBLIC_DOC_OTHER_MATERIALS = `
mutation (
  $category: String! 
  $fileName: String!
  $url: String! 
) {
  createOtherPublicDoc(data: {
    docTypeText: $category
    fileName: $fileName
    url: $url
  }) {
    __typename
    id
    docType{
      name
    }
    fileName 
    url
  }
}
`;

export const OTHER_PUBLIC_DOCTYPE_LIST_OTHER_MATERIALS = `
query {
  otherPublicDocTypeList{
    id
    name
  }
 }
`;
