import actions from "./actions";

const mail = {
  state: {
    emailTemplateListMail:[],
    contentMail:{}
  },
  getters: {
    emailTemplateListMail(state){
        return state.emailTemplateListMail
    },
    contentMail(state){
        return state.contentMail
    }
  },
  mutations: {
    setEmailTemplateListMail(state, data){
        state.emailTemplateListMail = data
    },
    setContentMail(state, data){
        state.contentMail = data
    }
  },
  actions
};

export default mail;
