import actions from './actions';

const advertisingMeasuresCustomers = {
  state: {
    campaignList: [],
    enumHasAttachment: [
      { id: null, name: '指定なし' },
      { id: true, name: 'あり' },
      { id: false, name: '無し' },
    ],
    hasAttachmentSelected: null,
  },
  getters: {
    getCampaignList(state) {
      return state.campaignList;
    },
    getEnumHasAttachment(state) {
      return state.enumHasAttachment;
    },
    getHasAttachmentSelected(state) {
      return state.hasAttachmentSelected;
    },
  },
  mutations: {
    setCampaignList(state, data) {
      state.campaignList = data;
    },
    setHasAttachmentSelected(state, data) {
      state.hasAttachmentSelected = data;
    },
  },
  actions,
};

export default advertisingMeasuresCustomers;
