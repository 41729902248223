import actions from './actions';

const contractAll = {
    state: {
        contractAll: {
            contractList: [],
            productTypeIds: [],
            ended: null,
            productTypeIdSelected: null,
            endedSelected: null,
            skip: 0,
            take: 0,
            total: 0
        },
        enumProductTypeList: []

    },
    getters: {
        getProductTypeIds(state) {
            return state.contractAll.productTypeIds;
        },
        getProductTypeIdSelected(state) {
            return state.contractAll.productTypeIdSelected;
        },
        getEnded(state) {
            return state.contractAll.ended;
        },
        getEndedSelected(state) {
            return state.contractAll.endedSelected;
        },
        getEnumProductTypeList(state) {
            return state.enumProductTypeList;
        },
        getTakeContractAll(state) {
            return state.contractAll.take;
        },
        getSkipContractAll(state) {
            return state.contractAll.skip;
        },
        getTotalContractAll(state) {
            return state.contractAll.total;
        },
    },
    mutations: {
        setTakeContractAll(state, val) {
            state.contractAll.take = val;
        },
        setSkipContractAll(state, val) {
            state.contractAll.skip = val;
        },
        setTotalContractAll(state, val) {
            state.contractAll.total = val;
        },
        setProductTypeIds(state, arr) {
            state.contractAll.productTypeIds = arr;
        },
        setProductTypeIdSelected(state, arr) {
            state.contractAll.productTypeIdSelected = arr;
        },
        setEnded(state, arr) {
            state.contractAll.ended = arr;
        },
        setEndedSelected(state, arr) {
            state.contractAll.endedSelected = arr;
        },
        setEnumProductTypeList(state, arr) {
            state.enumProductTypeList = arr;
        },

    },
    actions,
};

export default contractAll;
