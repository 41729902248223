import actions from './actions';

const webNotification = {
  state: {
    total: null,
    webNotificationNotPublishedList: [],
    webNotificationPublishedList: []
  },
  getters: {
    getTotalNotice (state) {
      return state.total
    },
    webNotificationNotPublishedList (state) {
      return state.webNotificationNotPublishedList
    },
    webNotificationPublishedList (state) {
      return state.webNotificationPublishedList
    }
  },
  mutations: {
    setWebNotificationNotPublishedList (state, arr) {
      state.webNotificationNotPublishedList = arr
    },
    setWebNotificationPublishedList (state, arr) {
      state.webNotificationPublishedList = arr
    },
    setTotalNotice(state, data) {
      state.total = data
    }
  },
  actions
}

export default webNotification