import actions from './actions';

const basicInfors = {
    state: {
        basicInfors: {
            basicInfors: [],
            id: null,
        },
        enum: [],
        dataUpdate: {
          
        }
    },
    getters: {
        getBasicInfors(state) {
            return state.basicInfors.basicInfors;
        },
        getId(state) {
            return state.basicInfors.id;
        },
        getEnum(state) {
            return state.enum;
        },
    },
    mutations: {
        setDataUpdateBasicInfor (state, payload) {
            state.dataUpdate = payload.dataUpdate
        },

        setBasicInfors(state, payload) {
            state.basicInfors.basicInfors = payload
        },
        setId(state, arr) {
            state.basicInfors.id = arr;
        },
        setEnum(state, arr) {
            state.enum = arr;
        },
    },
    actions,
};

export default basicInfors;
