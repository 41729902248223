export const WEB_REQUEST_LIST_VISITRESERVATION  = `
query (
    $keyword: String 
    $handled: Boolean 
    $take: Int
    $skip: Int
    $type: WebRequestType! 
    $orderBy: [OrderByInput!]
  ) {
    webRequestList(
      orderBy: $orderBy
      filter:{
        type: $type
        handled: $handled
        keyword: $keyword
      }
      pagination:{take: $take skip: $skip}
    ) {
      total
      hasMore
      items {
        client {
          type
        }
        id 
        email
        handledAt 
        createdAt 
        name #String 
        tel #String 
        firstDate 
        firstTime 
        secondDate 
        secondTime 
        clientId
        remarks
        wasExistingClient 
      }
    }
  }
`;


export const WEB_REQUEST_EDIT_VISITRESERVATION  = `
query ($id: Int!) {
  webRequest(
    id: $id
  ) {
    id
    createdAt
    medias {
      mediaType
    }
    client { 
      picId
      type
    }
    forVm 
    forVs 
    facilities 
    seasons 
    actionRemarks 
    purchaseRate { 
      name 
    }
    handledAt 
    firstDate 
    firstTime 
    secondDate 
    secondTime 
    inquiry 
    otherDateTime
    clientId 
    firstFacility { 
      name 
    }
    secondFacility {
      name
    }
    isCompany 
    lastName 
    firstName 
    lastKana
    firstKana 
    birthday 
    sex 
    postalCode 
    prefecture 
    locality 
    address1 
    address2 
    tel 
    telMobile 
    email
    remarks
  }
}
`;


export const WEB_REQUEST_UPDATE_VISITRESERVATION  = `
mutation (
  $id: Int!
  $remarks: String #備考
  $status: WebRequestStatus # ステータス、 Handled:対応済, New: 未対応 として下さい
) {
  updateWebRequest(
    webRequestId: $id
    data: {
      remarks: $remarks
      status: $status
    }
  ) {
    __typename
  }
}
`;

export const WEB_REQUEST_CREATE_VISIT_RESERVATION  = `
mutation (
  $id: Int!
) {
  createClientWebRequest(
    webRequestId: $id
  ) {
    id
  }
}
`





