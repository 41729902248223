import actions from './actions';

const giftTicket = {
  state: {
    pointExList: [],
  },
  getters: {
    getPointExchangeList(state) {
      return state.pointExList;
    },
  },
  mutations: {
    setPointExchangeList(state, arr) {
      state.pointExList = arr;
    },
  },
  actions,
};

export default giftTicket;
