import actions from './actions';

const addUseCancel = {
    state: {
        addUseCancel: {
            clientId: null,
            pointChange: null,
            remarks: '',
            pointsAfter: null
        }

    },
    getters: {
        getChangeTVP(state) {
            return state.pointsAfter;
        },
        getPointChange(state) {
            return state.pointChange;
        },
        getRemarksAddNew(state) {
            return state.remarks;
        },
    },
    mutations: {
        setChangeTVP(state, payload) {
            state.addUseCancel = payload.addUseCancel
        },
        setPointChange(state, payload) {
            state.pointChange = payload;
        },
        setRemarks(state, payload) {
            state.remarks = payload;
        },
    },
    actions,
};

export default addUseCancel;
