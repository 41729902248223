import { apolloClient } from '@/plugins/apollo';
import { CHANGE_TVP } from '@/api/graphql/customer/addUseCancel/addUseCancel.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async changeTVP({ dispatch, commit, state, rootState }) {
    const finishData = {
      clientId: state.addUseCancel.clientId,
      pointChange: state.addUseCancel.pointChange,
      remarks: state.addUseCancel.remarks,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CHANGE_TVP}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
          commit('setFlagTvphistoryListCustomerMc')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CHANGE_TVP, finishData, error.graphQLErrors)
          // commit('setAlertError', rootState.alert.error.messages.createError, {
          //   root: true,
          // });
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
