import { apolloClient } from '@/plugins/apollo';
import { 
  TRANSFER_CONTRACT, 
  CLIENT_BASIC_INFO 
} from '@/api/graphql/contract/contractDetail/MW/transferContract/transferContract.js';
// import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async transferContractMW({ commit, state, rootState }, changeData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${TRANSFER_CONTRACT}`,
          variables: changeData
        })
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, TRANSFER_CONTRACT, changeData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async clientBasicInfoList({ commit, state }, id) {
    const finishData = {
      id: parseInt(id)
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_BASIC_INFO}`,
          // clientId: 1
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setClientBasicInfoTra', data.data.clientBasicInfo);
          resolve(data.data.clientBasicInfo);
        })
        .catch(error => {
          // setCopyErrorTextCommit(commit, CLIENT_BASIC_INFO, finishData, error.graphQLErrors)
          // handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
