import gql from 'graphql-tag';

export const CONTRACT_LIST = `
  query(
    $clientId: Int
    $pagination: PaginationInput
  ) {
    contractList(
      filter: { clientId: $clientId }
      pagination : $pagination
      ) {
      items {
        id
        clientId
        status
        productType {
          code
        }
        contractNumber
        phase {
          name
        }
        startDate
        endDate
        computedFacility {
          facilityName
        }
      }
      total
    }
  }
`;

export const CREATE_CLIENT_COMMENT_CUSTOMER_DETAIL = gql`
  mutation($clientId: Int!, $comment: String!) {
    createClientComment(comment: $comment, clientId: $clientId) {
      id
      commentById
    }
  }
`;

export const PAGINATE_CLIENT_COMMENT = `
  query($clientId: Int!, $pagination: PaginationInput!) {
    paginateClientComment(clientId: $clientId, pagination: $pagination) {
      total
      items {
        id
        commentBy {
          name
          profileImgUrl
          mentionName
        }
        commentById
        likes
        likedByStaff{__typename}
        likesByStaffs {staff { id name}}
        createdAt
        id
        clientId
        comment
      }
    }
  }
`;

export const CLIENT_BASIC_INFO = `
  query($id: Int!) {
    clientBasicInfo(id: $id) {
      name
      isCompany
      id
      name
      nameKana
      sex
      birthday
      mainContact {
        prefecture
        tel
        locality 
      }
      employerName
      employmentType {
        name
      }
      childType {
        name
      }
      incomeLevel {
        name
      }
      remarks
      companyName
      companyNameKana
      companyPicName
      companyPicKana
      companyPicTitle
      companySize
      companyPicDepartment
    }
  }
`;

export const CLIENT_MEMBER_INFO = `
  query($id: Int!) {
    clientMemberInfo(id: $id) {
      id
      subMembers {
        id
        subMemberType {
          name
        }
        name
        nameKana
        relationship {
          name
        }

        birthday
      }

      referrerClient {
        name #String! 紹介者の名前
      }
    }
  }
`;

export const CLIENT_CHANGE_HISTORY_LIST = `
  query($clientId: Int, $pagination: PaginationInput) {
    clientChangeHistoryList(
      filter: { clientId: $clientId, fieldName: "営業担当者" },
      pagination: $pagination
    ) {
      total
      items {
        valueAfter
        changedAt
      }
    }
  }
`;

export const BOOKING_LIST = `
  query($clientId: Int, $pagination: PaginationInput, $orderBy: [OrderByInput!]) {
    bookingList(
      filter: { clientId: $clientId }
      orderBy: $orderBy
      pagination: $pagination
    ) {
      total
      items {
        id
        computed {
          status
        }
        computedForList {
          adults
          children
          bedShares
          hasParking
          hasRentalItem
        }
        bookingType {
          id
          code
        }
        room {
          roomType {
            facility {
              name
            }
            name
          }
          name
        }
        bookingPlan {
          title
        }
        checkInDate
        checkOutDate
        representative {
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const CANCEL_WAITLIST = `
  query($clientId: Int!, $pagination: PaginationInput) {
    cancelWaitListList(filter: { clientId: $clientId, includeCancelled: true }, pagination: $pagination) {
      items {
        id
        notifyToType
        notifyToName
        createdAt
        roomType {
          name
          facility {
            name
          }
        }
        checkInDate
        checkOutDate
        status
        lastUpdatedAt
      }
    }
    facilityParkingCancelWaitlistList(
      filter: { clientId: $clientId, includeCancelled: true }
    ) {
      items {
        id
        notifyToType
        notifyToName
        createdAt
        booking {
          id
          room {
            roomType {
              name
              facility {
                name
              }
            }
          }
          checkInDate
          checkOutDate
        }
        fromDate
        toDate
        status
        lastUpdatedAt
      }
    }
  }
`;

// bebin api vsinterest VS資料請求のみ

export const WAITLIST_BROCHURE = `
  query($clientId: Int!) {
    webRequestList ( #資料請求の一覧
      filter: {
        clientId: $clientId
        type: Brochure #資料請求タイプ
      }
    ) {
      items {
        id #Int! ID
        isCompany
        createdAt #Date 受信日時
        forVs #Boolean VS資料請求なら true
        forVm # Boolean VM資料請求なら true
        remarks # String 備考
        # 広告施策は、以下の方法で取得した campaignsを使用する
        # const campaigns = item.medias.filter(media => media.campaign).map(media => media.campaign)
        # if (item.campaign) campaigns.push(item.campaign);
        # const 広告施策col = campaigns.map(c => c.name).join(', ');
        # const 広告施策詳細用のID = campaigns.map(c => c.id);
        # const 広告施策適応方法 = campaingns.length ? (item.campaign ? '手動' : '自動') : 'なし';
        campaign { #広告施策 nullable
          id #Int! ID
          name #String! 広告施策の名称、これを「広告施策」列に表示する
        }
        medias { # array
          campaign { #広告施策 nullable
            id #Int! ID
            name #String! 広告施策の名称、これを「広告施策」列に表示する
          }
        }
      }
    }
  }
`;

export const ADVERTISING_MEASURES = `
  query($clientId: Int!) {
    campaignApplyResponseList(
      filter: {clientId: $clientId}
    ) {
      items {
        id
        campaignId
        campaign {
          id
          name #広告施策名
          groups #グループ名、String[]なので .join(', ')で表示
          note #説明
          attachment1 #発送資料１（ダウンロードはできないリンク。campaignApplyResponseで取得する attachment1などはダウンロード可能なので、それを実行してダウンロードすること
          attachment2 #発送資料２
          attachment3 #発送資料３
        }
        responseAt #反応日時、nullの場合は 反応無、 not null なら 反応有 と表示
        createdAt #追加日
      }
    }
  }
`;

export const CREATE_CAMAPP_VSINTEREST = `
 mutation (
    $campaignId: Int! #施策ID
    $clientId: Int! #顧客ID
    $hasResponse: Boolean! #反応有りで追加はTrue、実施済みで追加はfalse
  ) {
    createCampaignApplyResponse(
      clientId: $clientId
      campaignId: $campaignId
      response: $hasResponse
    ) {
      id
    }
  }
`;
export const UPDATE_CAMAPP_VSINTEREST = `
 mutation (
    $id: Int! #施策ID
    $hasResponse: Boolean! #反応有りで追加はTrue、実施済みで追加はfalse
  ) {
    updateCampaignApplyResponse(
      id: $id
      response: $hasResponse
    ) {
      id
    }
  }
`;

export const DELETE_CAMAPP_VSINTEREST = `
  mutation(
    $id: Int! #削除対象のID
  ) {
    deleteCampaignApplyResponse(id: $id) {
      __typename
    }
  }
`;
// end api vsinterest VS資料請求のみ

export const SEND_CC_REGISTER_REQUEST = `
  mutation ($clientId: Int!) {
    sendCCRegisterRequest(clientId: $clientId)
  }
`


