export const SUBTRACT_CURRENT_CONTRACT_POINT = `
mutation (
  $contractId: Int!
  $points: Int!
  $reason: String! #利用履歴向け備考
  $consumeDate: DateString!
) {
  subtractCurrentContractPoint(
    contractId: $contractId
    exceptionalReason: $reason
    points: $points
    consumeDate: $consumeDate
  )
}
`

export const SUBTRACT_CONTRACT_POINT = `mutation subtract_point(
  $contractId: Int!,
  $contractPointId: Int!,
  $exceptionalReason: String!,
  $points: Int!
) {
  subtractContractPoint(
    contractId: $contractId
    contractPointId: $contractPointId
    exceptionalReason: $exceptionalReason
    points: $points
  )
}`
