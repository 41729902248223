import actions from './actions';

const searchClientList = {
  state: {
    searchClientList: [],
    furtherPurchaseRate: [{ id: null, name: '購入評価' }],
    pic: [{ id: null, name: '佐々木剛' }],
    lastCampaignApplyResponse: [{ id: null, name: '指定なし' }],
    // select
    furtherPurchaseRateSelected: null,
    picSelected: parseInt(localStorage.getItem('id')),
    lastCampaignApplyResponseSelected: null,
  },
  getters: {
    searchClientList(state) {
      return state.searchClientList;
    },
    furtherPurchaseRate(state) {
      return state.furtherPurchaseRate;
    },
    pic(state) {
      return state.pic;
    },
    lastCampaignApplyResponse(state) {
      return state.lastCampaignApplyResponse;
    },
    furtherPurchaseRateSelected(state) {
      return state.furtherPurchaseRateSelected;
    },
    picSelected(state) {
      return state.picSelected;
    },
    lastCampaignApplyResponseSelected(state) {
      return state.lastCampaignApplyResponseSelected;
    },
  },
  mutations: {
    searchClientList(state, arr) {
      state.searchClientList = arr;
    },
    setFurtherPurchaseRateSelected(state, val) {
      state.furtherPurchaseRateSelected = val;
    },
    setPicSelected(state, val) {
      state.picSelected = val;
    },
    setLastCampaignApplyResponseSelected(state, val) {
      state.lastCampaignApplyResponseSelected = val;
    },
  },
  actions,
};

export default searchClientList;
