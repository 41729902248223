export const VMCONTRACT_USAGE = `
query (
    $contractId: Int!
  ) {
    vmContractUsage(contractId:$contractId) {
      status	
      id
      clientId
      client {
        name
        type
      }
      tentative
      vmRoomType {
        id
        name
        facility {
          id
          name
        }
      }
      rights {
        year #年
        vmStayRights {
          stayDate
          price
        }
        bookingGroup {
          bookings {
            bookingGroupId
            bookingTypeId
            computed {
              status
            }
            id # Int!
            room {
              roomType {
                name
                facility {
                  name
                }
              }
            }
            checkInDate
            checkOutDate
            stayConfirmedAt
          }
        }
      }
    }
  }
`;
export const CONVERT_INTO_CONFIRMED_CONTRACT = `
mutation (
  $contractId: Int!
  $roomTypeId: Int!
  $years: Int!
  $bookings: [CreateVmBookingInput!]!
  $tvpPaymentAmount: Int!
) {
  convertIntoConfirmedContract(
    contractId: $contractId
    roomTypeId: $roomTypeId
    years: $years
    vmBookings: $bookings
    tvpPaymentAmount: $tvpPaymentAmount
  ) {
    __typename
  }
}
`;
export const GET_CLIENT_BASIC_CONTRACT_ID = `
mutation (
            $clientId: Int!
       ) {
           createVmTentativeContract(clientId: $clientId) {
               id
              startDate
              createdAt
          }
      }
`;