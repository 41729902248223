export const STAFF = `
  query($id: Int!) {
    staff(id: $id) {
      name
    }
  }
`;

export const ENUM_PARTICIPANT_TYPE_LIST = `
  query {
    enumParticipantTypeList {
      name
    }
  }
`;

export const ENUM_CLIENT_ACTION_METHOD_LIST = `
  query {
    enumClientActionMethodList {
      id
      name
    }
  }
`;

export const GET_CLIENT_ACTION_HISTORY = `
  query($id: Int!) {
    getClientActionHistory(id: $id) {
      actionPlannedAt
      participants
      priority
      actionMethod {
        id
        name
      }
      actionMethodId
      action
      actionAt
      purchaseRateId
      furtherPurchaseRateId
      renewPurchaseRateId
      negotiationExitReason
      clientActionTypeId
      remarks
      client {
        purchaseRate {
          name
        }
        furtherPurchaseRate {
          name
        }
        renewPurchaseRate {
          name
        }
      }
      isComplaint
    }
  }
`;

export const CREATE_CLIENT_ACTION_HISTORY = `
  mutation(
    $clientId: Int!
    $actionPlannedAt: DateTime
    $participants: [String!]!
    $priority: Int
    $actionMethodId: Int
    $action: String
  ) {
    createClientActionHistory(
      data: {
        clientId: $clientId
        actionPlannedAt: $actionPlannedAt
        participants: $participants
        priority: $priority
        actionMethodId: $actionMethodId
        action: $action
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CLIENT_ACTION_HISTORY = `
  mutation(
    $id: Int!
    $actionPlannedAt: DateTime
    $participants: [String!]!
    $priority: Int
    $actionMethodId: Int
    $action: String
  ) {
    updateClientActionHistory(
      id: $id
      data: {
        actionPlannedAt: $actionPlannedAt
        participants: $participants
        priority: $priority
        actionMethodId: $actionMethodId
        action: $action
      }
    ) {
      id
    }
  }
`;

export const ACTIVITY_HISTORY_CREATE = `
  mutation($data: CreateClientActionHistoryInput!) {
    createClientActionHistory(data: $data) {
      id
    }
  }
`;

export const ACTIVITY_HISTORY_REGISTRATION = `
  mutation($id: Int!, $data: UpdateClientActionHistoryInput!) {
    updateClientActionHistory(id: $id, data: $data) {
      id
    }
  }
`;

export const ACTIVITY_CLIENT_ACTION_REGISTRATION = `
mutation (
  $contractId: Int!
  $year: Int!
  $reason: GrantReason!
  $price: Int!
  $points: Int!
  $remarks: String
) {
  purchasePoints(
    contractId: $contractId
    pointPriceOverride: $price
    points: $points
    year: $year
    reason: $reason
    remarks: $remarks
  )
}
`;

export const CREATE_ACTIVITY_HISTORY_REGISTRATION = `
mutation (
  $clientId: Int!
  $actionAt: DateString!
  $actionMethodId: Int
  $participants: [String!]!
  $isComplaint: Boolean!
  $exitReason: String
  $actionTypeId: Int
  $remarks: String
  $kujiraCrmId: String
  $kujiraCrmTel: String
  $kujiraCrmDate: String
) {
  createClientActionHistory(data: {
    clientId: $clientId
    actionAt: $actionAt
    actionMethodId: $actionMethodId
    participants: $participants
    isComplaint: $isComplaint
    negotiationExitReason: $exitReason
    clientActionTypeId: $actionTypeId
    remarks: $remarks
    kujiraCrmId: $kujiraCrmId
    kujiraCrmTel: $kujiraCrmTel
		kujiraCrmDate:  $kujiraCrmDate
  }) {
    id
    isComplaint
    negotiationExitReason
    purchaseRateId
  }
}
`;
export const ACTION_SPLIT_MW_RIGHT = `
mutation (
  $bookingGroupId: Int!
  $bookings: [SplitMwRightBookingInput!]!
  #{
  #  checkInDate: DateString! #分割日（チェックイン日）
  #  representativeName: Name! #代表者名
  #}[]
  $contractId: Int!
) {
  splitMwRight(data: {
    bookingGroupId: $bookingGroupId
    bookings: $bookings
    contractId: $contractId
  }) {
    __typename
  }
}
`;

export const DELETE_CLIENT_ACTION_HISTORY = `
  mutation ($id: Int!) {
    deleteClientActionHistory (id: $id)
  }
`