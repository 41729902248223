import actions from './actions';

const addNewDocReq = {
    state: {
        addNewDocReq: {
            clientId: null,
            remarks: '',
            forVs: false,
            forVm: false,
        }
    },
    getters: {
        getRemarks(state) {
            return state.remarks;
        },
    },
    mutations: {
        setNewDoc(state, payload) {
            state.addNewDocReq = payload.addNewDocReq
        },
        setRemarks(state, payload) {
            state.remarks = payload;
        },
    },
    actions,
};

export default addNewDocReq;
