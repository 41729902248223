import { apolloClient } from '@/plugins/apollo'
import { SIGN_UP_BONUS_LIST, SIGN_UP_BONUS, MARK_AS_HANDLED_SIGNUP_BONUS_APPLICATION, SIGN_UP_BONUS_APPLICATION_LIST } from '@/api/graphql/advertising/advertising'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  fetchSignUpBonusList({ commit, state }, obj) {
    const finishData = {
      keyword: obj.keyword,
      status: obj.status ? null : 'Active',
      take: obj.pagi.take,
      skip: obj.pagi.skip,
      orderBy: obj.orderBy,
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SIGN_UP_BONUS_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => { 
          commit('setSignUpBonusList', data.data.signupBonusList.items)
          commit('setTotalOfSignUpBonusAppList', data.data.signupBonusList.total)
          commit('setLoadingOverlayHide', { root: true })
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SIGN_UP_BONUS_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true })
          handlError(commit, error.graphQLErrors)
          reject(error)
        });
    })
  },

  async fetchSignUpBonusDetail({ commit }, signupBonusId) {
    const finishData = {
      signupBonusId: parseInt(signupBonusId)
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient
    .query({
      query: gql`${SIGN_UP_BONUS}`,
      variables: finishData,
      fetchPolicy: 'no-cache'
    })
    .then(data => {
      commit('setSignUpBonusDetail', data.data.signupBonus)
      commit('setLoadingOverlayHide', { root: true })
    })
    .catch(error => {
      setCopyErrorTextCommit(commit, SIGN_UP_BONUS, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    });
  },

  async fetchSignUpBonusApplicationList({ commit }, obj) {
    const finishData = {
      signupBonusId: parseInt(obj.id),
      handled: obj.status,
      skip: obj.skip,
      take: obj.take,
      orderBy: obj.orderBy
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient
    .query({
      query: gql`${SIGN_UP_BONUS_APPLICATION_LIST}`,
      variables: finishData,
      fetchPolicy: 'no-cache'
    })
    .then(data => {
      commit('setSignUpBonusAppList', data.data.signupBonusApplicationList)
      commit('setLoadingOverlayHide', { root: true })
    })
    .catch(error => {
      setCopyErrorTextCommit(commit, SIGN_UP_BONUS_APPLICATION_LIST, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    });
  },

  async markAsHandledSignupBonusApplication({ commit, rootState }, obj) {
    const finishData = {
      id: parseInt(obj.id),
      status: obj.status
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient
    .mutate({
      mutation: gql`${MARK_AS_HANDLED_SIGNUP_BONUS_APPLICATION}`,
      variables: finishData
    })
    .then(data => {
      commit('setLoadingOverlayHide', { root: true })
      commit(
        'setAlertSuccess',
        rootState.alert.success.messages.updateSuccess,
        { root: true },
      );
    })
    .catch(error => {
      setCopyErrorTextCommit(commit, MARK_AS_HANDLED_SIGNUP_BONUS_APPLICATION, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    });
  },

};