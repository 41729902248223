import router from '../router'

export function getBookingPath (bookingId, { block } = { block: false }) {
  if (block) {
    return `${process.env.VUE_APP_URL_PMS}/booking/Block-Reservation?bookingId=${bookingId}&tab=Block-Reservation&fromPage=crm`
  }
  return `${process.env.VUE_APP_URL_PMS}/reservation/contract-detail?id=${bookingId}&tab=basic-information&fromPage=crm`
}

export function getClientPath (clientId) {
  return `/customer-details/${clientId}`
}

const routeHelpers = {
  booking: getBookingPath,
  client: getClientPath
}

function isExternalUrl(url) {
  return /^http(s)?:\/\//.test(url)
}

export const goToRoute = Object.keys(routeHelpers).reduce((result, key) => {
  const goToFunc = (...args) => {
    const path = routeHelpers[key](...args)
    if (isExternalUrl(path)) {
      location.replace(path)
    } else {
      router.push(path)
    }
  }
  result[key] = goToFunc
  return result
}, {})

export const openRoute = Object.keys(routeHelpers).reduce((result, key) => {
  const openFunc = (...args) => {
    const path = routeHelpers[key](...args)

    if (isExternalUrl(path)) {
      window.open(path, '_blank')
    } else {
      const routeData = router.resolve({ path })
      window.open(routeData.href, '_blank')
    }
  }
  result[key] = openFunc
  return result
}, {})
