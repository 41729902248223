import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import * as filters from './utils/filters';
import { i18n } from './plugins/i18n';
import { createProvider, apolloHelperPlugin } from './plugins/apollo';
import '@fortawesome/fontawesome-free/css/all.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import * as rules from './plugins/rules';
import * as messages from './plugins/messages'
import TvosJpy from './components/DataItem/TvosJpy';
import ToThousands from './components/DataItem/ToThousands';
import IntInput from './components/Input/IntInput'
import { goToRoute, openRoute } from './utils/routeHelper'
import FormatSpan from './components/shared/FormatSpan'
import TvosBtn from './components/shared/TvosBtn'
import './plugins/shortcut'
import { getISODate } from './utils/dateUtil';
import { ProductTypeIds } from './constants/contractProductType';

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use({
  install (Vue) {
    Vue.prototype.$rules = rules
    Vue.prototype.$messages = messages
  }
})

Vue.use({
  install (Vue) {
    Vue.prototype.$windowClose = function () {
      window.close();
    };
    Vue.prototype.$reloadRoute = function () {
      router.go()
    }
    Vue.prototype.$today = function () {
      return getISODate(new Date())
    }
    Vue.prototype.$goToRoute = goToRoute
    Vue.prototype.$openRoute = openRoute
    Vue.prototype.$confirm = payload => store.dispatch('confirmDialog', { ...payload, type: 'confirm' })
    Vue.prototype.$alert = payload => store.dispatch('confirmDialog', { ...payload, type: 'alert' })
    Vue.prototype.$download = url => window.open(url)
    Vue.prototype.$env = {
      isProd: process.env.NODE_ENV === 'production',
      isStg: process.env.NODE_ENV === 'staging',
      isDev: process.env.NODE_ENV === 'development',
    }
    Vue.prototype.$productTypeIds = ProductTypeIds
  }
})

Vue.use(apolloHelperPlugin)
Vue.component('tvos-thousands', ToThousands)
Vue.component('tvos-jpy', TvosJpy)
Vue.component('tvos-int-input', IntInput)
Vue.component('tvos-format-span', FormatSpan)
Vue.component('tvos-btn', TvosBtn)

const createVueApp = async () => {
  const tokenLogin = localStorage.getItem('token') || ''
  if (tokenLogin) {
    const decodeTokenUser = jwt_decode(tokenLogin)
    store.commit('setRoleAdminUser', decodeTokenUser.admin)
    if (Object.keys(decodeTokenUser).length !== 0) {
      store.commit('setPermissionUser', decodeTokenUser.rolePermissions)
    } else {
      store.commit('setPermissionUser', {})
    }
  }
  new Vue({
    router,
    store,
    apolloProvider: createProvider(),
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app');
  
}
createVueApp()