import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import { WEB_NOTIFICATION_LIST } from '@/api/graphql/webNotification'
import gql from 'graphql-tag'

export default {
  async fetchWebNotificationList ({ commit }, obj) {
    commit('setLoadingOverlayShow', { root: true })
    const includeHidden = obj.includeHidden ? null : false
    const finishData = {
      includeHidden,
      published: obj.published,
      take: obj.pagination.take,
      skip: obj.pagination.skip,
      orderBy: obj.orderBy
    }
    await apolloClient.query({
      query: gql`${WEB_NOTIFICATION_LIST}`,
      variables: finishData,
      fetchPolicy: 'no-cache'
    }).then(data => {
      commit('setTotalNotice', data.data.webNotificationList.total)
      commit('setWebNotificationPublishedList', data.data.webNotificationList.items)
      commit('setLoadingOverlayHide', { root: true })
    }).catch(error => {
      setCopyErrorTextCommit(commit, WEB_NOTIFICATION_LIST, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  },

  async fetchWebNotificationPublishedList ({ commit }, obj) {
    commit('setLoadingOverlayShow', { root: true })
    const includeHidden = obj.includeHidden ? null : false
    const finishData = {
      includeHidden,
      published: obj.published,
      take: 500,
      skip: 0,
      orderBy: obj.orderBy
    }
    await apolloClient.query({
      query: gql`${WEB_NOTIFICATION_LIST}`,
      variables: finishData,
      fetchPolicy: 'no-cache'
    }).then(data => {
      commit('setWebNotificationNotPublishedList', data.data.webNotificationList.items)
      commit('setLoadingOverlayHide', { root: true })
    }).catch(error => {
      setCopyErrorTextCommit(commit, WEB_NOTIFICATION_LIST, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  }
}
