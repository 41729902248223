export const STAFF_LIST = `
  query {
    # showDisabled:true
    staffList(crmRep: true) {
      id
      name
    }
  }
`;

export const CLIENT_BASIC_INFOR = `
  query($id: Int!) {
    clientBasicInfo(id: $id) {
      id
      name
      type
      doNotContact
      purchaseRateId
      furtherPurchaseRateId
      renewPurchaseRateId
      lastMeetDate
      lastMeetMethod
      lastMeetAttendant
      lastResponseDate
      seasonsInterested
      # facilityFeePayment
      facilitiesInterested
      negotiationExitReason
      pic {
        id
        name
      }
      picId
    }
  }
`;
export const ENUM_PRURCHASE_RATE_LIST = `
  query {
    enumPurchaseRateList {
      id
      name
    }
  }
`;

export const ENUM_SEASON_INTERST_LIST = `
  query {
    enumSeasonInterestList {
      id
      name
    }
  }
`;
export const FACILITY_LIST = `
  query {
    facilityList {
      id
      name
    }
  }
`;
export const ENUM_NEGOTIATION_EXIT_REASON_LIST = `
  query {
    enumNegotiationExitReasonList {
      id
      name
    }
  }
`;
export const CLIENT_CHANGE_HISTORY_LIST = `
  query(
    $filter: ClientChangeHistoryListFilterInput
    $pagination: PaginationInput!
  ) {
    clientChangeHistoryList(filter: $filter, pagination: $pagination) {
      total
      items {
        valueAfter
        changedAt
      }
    }
  }
`;

export const UPDATE_CLIENT = `
  mutation($clientId: Int!, $data: UpdateClientInput!) {
    updateClient(id: $clientId, data: $data) {
      id
    }
  }
`;

// export const UPDATE_CLIENT = `
//   mutation(
//     $clientId: Int! # 顧客ID
//     $doNotContact: Boolean! #折衝不可
//     $purchaseRateId: Int #購入評価ID
//     $furtherPurchaseRateId: Int #買い増し評価ID
//     $renewPurchaseRateId: Int #更新評価ID
//     $negotiationExitReason: String #検討離脱理由
//     $picId: Int #営業担当者ID
//     $lastResponseDate: DateString #反響日
//     $seasonsInterested: [String!] #希望シーズン
//     $facilitiesInterested: [String!] #興味のある施設
//   ) {
//     updateClient(
//       id: $clientId
//       data: {
//         doNotContact: $doNotContact
//         purchaseRateId: $purchaseRateId
//         furtherPurchaseRateId: $furtherPurchaseRateId
//         renewPurchaseRateId: $renewPurchaseRateId
//         negotiationExitReason: $negotiationExitReason
//         picId: $picId
//         lastResponseDate: $lastResponseDate
//         seasonsInterested: $seasonsInterested
//         facilitiesInterested: $facilitiesInterested
//       }
//     ) {
//       id
//     }
//   }
// `;

export const BROCHURES = `
  query($filter: WebRequestListFilterInput, $pagination: PaginationInput) {
    brochures: webRequestList(filter: $filter pagination: $pagination) {
      items {
        id
        createdAt
        forVs
        forVm
        remarks
        campaign {
          id
          name
        }
        medias {
          campaign {
            id
            name
          }
        }
      }
    }
  }
`;

export const VISITS = `
  query($filter: WebRequestListFilterInput, $pagination: PaginationInput) {
    visits: webRequestList(filter: $filter pagination: $pagination) {
      items {
        id
        createdAt
        handledAt
        firstDate
        firstTime
        secondDate
        secondTime
      }
    }
  }
`;

export const ONLINES = `
  query($filter: WebRequestListFilterInput, $pagination: PaginationInput) {
    onlines: webRequestList(filter: $filter pagination: $pagination) {
      items {
        id
        createdAt
        handledAt
        firstDate
        firstTime
        secondDate
        secondTime
      }
    }
  }
`;

export const TRIALS = `
  query($filter: WebRequestListFilterInput, $pagination: PaginationInput) {
    trials: webRequestList(filter: $filter pagination: $pagination) {
      items {
        id
        createdAt
        handledAt
        firstDate
        firstTime
        firstCheckInDate
        firstStayDays
        secondCheckInDate
        secondStayDays
        firstFacility {
          name
        }
        secondDate
        secondTime
        secondFacility {
          name
        }
      }
    }
  }
`;

export const LIST_SELECT_TABLE_END = `
  query($filter: CampaignApplyResponseListFilterInput!) {
    campaignApplyResponseList(filter: $filter) {
      items {
        campaign {
          name
          id
        }
      }
    }
  }
`;

export const LIST_TABLE_END = `
  query($filter: CampaignApplyResponseListFilterInput!, $pagination: PaginationInput) {
    campaignApplyResponseList(filter: $filter pagination: $pagination) {
      items {
        id
        campaign {
          id
          name
          groups
          note
          attachment1
          attachment2
          attachment3
          attachment1Url
          attachment2Url
          attachment3Url
        }
        responseAt
        createdAt
      }
    }
  }
`;

export const CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT = `
  mutation(
    $clientId: Int!
    $forVs: Boolean!
    $forVm: Boolean!
    $remarks: String
  ) {
    createWebBrochureRequestToClient(
      clientId: $clientId
      forVs: $forVs
      forVm: $forVm
      remarks: $remarks
    ) {
      importMethod
    }
  }
`;

export const CREATE_CAMPAIGN_APPLY_RESPONSE = `
  mutation($campaignId: Int!, $clientId: Int!, $hasResponse: Boolean!) {
    createCampaignApplyResponse(
      clientId: $clientId
      campaignId: $campaignId
      response: $hasResponse
    ) {
      id
      responseAt
      createdAt
      campaign {
        id
        name
        groups
        note
        attachment1
        attachment2
        attachment3
        attachment1Url
        attachment2Url
        attachment3Url
      }
    }
  }
`;
