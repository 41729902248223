import actions from './actions';

const spuSageStatus = {
    state: {
        statusUpdateDepositMwBooking: false,
        contractId: null,
        contractType: null,
        contractPointSummary: [],
        contractPointMovementList: [],
        pwContractUsageList:[],
        mwContractUsageList:[],
        PointInput: null,
        DialogSuccess:false,
        dataExChange: {},
        yearSelected: null,
        typeSelected: null,
    },
    getters: {
        getStatusUpdateDepositMwBooking (state) {
            return state.statusUpdateDepositMwBooking
        },
        getContractId(state) {
            return state.contractId;
        },
        getContractType(state) {
            return state.contractType;
        },
        getContractPointSummary(state) {
            return state.contractPointSummary;
        },
        getContractPointMovementList(state) {
            return state.contractPointMovementList;
        },
        getPointInput(state){
            return state.PointInput;
        },
        getpwContractUsageList(state){
            return state.pwContractUsageList
        },
        getmwContractUsageList(state){
            return state.mwContractUsageList
        },
        getDialogSuccess(state){
            return state.DialogSuccess
        },

        getDataToExchangeReservation (state) {
            return state.dataExChange
        },
        getYearSelected(state){
            return state.yearSelected
        },
        getTypeSelected(state){
            return state.typeSelected
        }
    },
    mutations: {
        setStatusUpdateDepositMwBooking (state, payload) {
            state.statusUpdateDepositMwBooking = !state.statusUpdateDepositMwBooking
        },
        setContractId(state, payload) {
            state.contractId = payload
        },
        setContractType(state, payload) {
            state.contractType = payload
        },
        setContractPointSummary(state, payload) {
            state.contractPointSummary = payload
        },

        setContractPointMovementList(state, payload) {
            state.contractPointMovementList = payload
        },
        setPointInput(state,payload){
            state.PointInput = payload;
        },
        setpwContractUsageList(state, payload){
            state.pwContractUsageList = payload
        },
        setmwContractUsageList(state, payload){
            state.mwContractUsageList = payload
        },
        setDialogSuccess(state, payload){
            state.DialogSuccess = !state.DialogSuccess
        },

        setDataToExchangeReservation (state, payload) {
            state.dataExChange = payload.dataExChange
        },
        setYearSelected(state, payload){
            state.yearSelected = payload
        },
        setTypeSelected(state,payload){
            state.typeSelected = payload
        }
    },
    actions,
};

export default spuSageStatus;
