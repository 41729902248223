export const CONTRACT_LIST = `
query($filter: ContractListFilterInput, $pagination: PaginationInput, $orderBy: [OrderByInput!]) {
    contractList(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      items {
        id
        clientId
        productTypeId
        status #ステータス
        productTypeId
        productType { name code} #契約商品
        contractNumber #契約番号
        phase { name } #フェーズ
        endDate #契約満了日
        years #契約年数
        client {
          id
          type
          isCompany #個人・法人
          name #顧客名
          pic {name} #担当者
        }
      }
      total
    }
  }
`;
export const ENUM_PRODUCT_TYPE_LIST = `
query{
    enumProductTypeList {
      id
      name
      code
    }
  }
`;