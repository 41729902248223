export const WEBREQUEST_LIST_EXPERIENCE  = `
query (
    $keyword: String 
    $handled: Boolean 
    $take: Int
    $skip: Int
    $type: WebRequestType!
    $orderBy: [OrderByInput!]
  ) {
    webRequestList(
      orderBy: $orderBy
      filter:{
        type: $type
        handled: $handled
        keyword: $keyword
      }
      pagination:{take: $take skip: $skip}
    ) {
      total
      items {
        client{
          type
        }
        type
        id 
        email
        handledAt 
        createdAt 
        name #String 
        tel #String 
        firstDate 
        firstTime 
        secondDate 
        secondTime 
        clientId 
        wasExistingClient 
      }
    }
  }
`;

export const WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION = `
query (
  $id: Int!
) {
  webRequest(id: $id) {
    id
    medias {
      mediaType 
    }
    client { 
      picId 
      type
    }
    forVm 
    forVs 
    facilities 
    seasons 
    actionRemarks 
    purchaseRate { 
      name 
    }
    handledAt 
    createdAt
    clientId 
    firstDate 
    firstTime 
    secondDate 
    secondTime 
    inquiry 
    otherDateTime
    firstCheckInDate 
    firstStayDays 
    secondCheckInDate 
    secondStayDays 
    firstFacility { 
      name 
    }
    secondFacility { 
      name 
    }
    name
    isCompany 
    lastName 
    firstName 
    lastKana 
    firstKana 
    birthday 
    sex 
    postalCode 
    prefecture 
    locality 
    address1 
    address2 
    tel 
    telMobile 
    email 
    remarks 
    companyName 
    companyNameKana 
    businessType { 
      name 
    } 
    companyPicName 
    companyPicKana 
    companyPicDepartment 
    companyPicTitle 
    companySize 
    workStyleReform 
    appliedCampaigns { 
      id 
      name 
      groups 
      createdAt 
      note 
      attachment1Url 
      attachment2Url
      attachment3Url
    }
  }
}
`;

export const UPDATE_WEBREQUEST_OC  = `
mutation (
  $id: Int!
  $status: WebRequestStatus 
  $remarks: String 
) {
  updateWebRequest(
    webRequestId: $id
    data: {
      remarks: $remarks
      status: $status
    }
  ) {
    __typename
  }
}
`;







