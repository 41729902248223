import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST_FACE_TO_FACE_CUSTOMER } from '@/api/graphql/customer/response/FaceToFaceCustomer/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionSearchClientListFaceToFaceCustomer({ commit, state }, data) {
    commit('setLoadingOverlayShow', { root: true })
    const finishData = {
      filter: data.filter,
      orderBy: { fieldName: 'client.id', direction: 'DESC' },
      pagination: data.pagination,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST_FACE_TO_FACE_CUSTOMER}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(response => {
          commit('setLoadingOverlayHide', { root: true })
          commit(
            'setSearchClientListFaceToFaceCustomer',
            response.data.searchClientList.items,
          );
          commit('setTotalSearchClientListFaceToFaceCustomer',response.data.searchClientList.total)
          resolve(true)
        })
        .catch(error => {
          commit('setLoadingOverlayHide', { root: true })
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST_FACE_TO_FACE_CUSTOMER, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
