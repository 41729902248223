import actions from './actions';

const managementTabUser = {
  state: {
    staffListSales: [],
    dataManagement: {
      staffGroupId: null,
      staffIds: []
    },
    dataRemoveManagement:{
      staffGroupId: null,
      staffIds: []
    }
   
  },
  getters: {
    getStaffListSalesManagement(state) {
      return state.staffListSales;
    },
    // getDataManagement(state) {
    //   return state.dataManagement;
    // },
    // getDataRemoveManagement(state) {
    //   return state.dataRemoveManagement;
    // },
  
  },
  mutations: {
    setStaffListSales(state, arr) {
      state.staffListSales = arr;
    },
    setDataManagement(state, arr) {
      state.dataManagement = arr;
    },
    setDataRemoveManagement(state, arr) {
      state.dataRemoveManagement = arr;
    },

  },
  actions,
};

export default managementTabUser;
