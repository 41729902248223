import actions from './actions';

const settingLink = {
  state: {
    clientId: null,
    status: false,
    objReset: {}
  },
  getters: {
    getStatus(state) {
        return state.status
    }
},
  mutations: {
    setSettingLink(state, payload) {
      state.objReset = payload.objReset
    },
    setStatus (state, payload) {
      state.status = !state.status
    }
  },
  actions,
};

export default settingLink;
