export const CREATE_CANCEL_WAIT_LIST_FREE_NOTIFICATION_REGISTRATION = `
mutation (
  $clientId: Int! 
  $subMemberId: Int 
  $roomTypeId: Int! 
  $checkInDate: DateString! 
  $checkOutDate: DateString! 
	$type: CancelWaitListType!
) {
  createCancelWaitList(
    roomTypeId: $roomTypeId
    checkInDate: $checkInDate
    checkOutDate: $checkOutDate
    clientId: $clientId
    subMemberId: $subMemberId
    type: $type 
  ) {
    id
  }
}
`;

export const CLIENT_CHANGE_HISTORY_LIST = `
query($clientId: Int!) {
  clientChangeHistoryList(
    filter: { clientId: $clientId, fieldName: "営業担当者" }
    pagination: { take: null, skip: null }
  ) {
    items {
      valueAfter
      changedAt
      fieldName
    }
  }
}
`;

export const FACILITY_LIST_NOTIFICATION_REGISTRATION = ` 
query{
    facilityList{
      id
      name
      type
    }
  }
`;

export const FACILITY_LIST = ` 
query { facilityList(filter: { types: [TVS] }) { id name } }
`;

export const ROOM_TYPE_LIST_NOTIFICATION_REGISTRATION = `
query($facilityId: Int!) {
    roomTypeList(
        facilityId: $facilityId,
    ) {
        id 
        name 
    }
  }
`;
export const CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION = `
query ($id: Int!) {
  clientMemberInfo(id: $id) {
    id 
    name 
    subMembers {
      id 
      name 
    }
  }
}`;
export const FACILITY_LIST_NOTIFICATION_REGISTRATION_BY_PT = ` 
query {
  facilityList(
    filter: {types: [PT]}
  ) {
    id name
  }
}
`;
export const CLIENT_MEMBER_INFO_NOTIFICATION_REGISTRATION_BY_PT = `
query ($id: Int!){
  clientMemberInfo(id: $id) 
  {
      id
      name
      memberId
      nameKana
      mainContact {
        tel
        email
        prefecture
        locality
        address1
        address2
        postalCode
      }
        subMembers {
            id
            name
            nameKana
            address1
            address2
            postalCode
            email
            tel
            locality
        }
  }
  
}`;
export const ACTION_CREATE_BOOKING_V4 = `
mutation(
  $bookingTypeId: Int!
  $contractId: Int!
  $points: Int!
  $roomTypeId: Int! 
  $checkInDate: DateString!
  $createdClientId: Int!
  $createdSubMemberId: Int
  $bookingBarNote: String
  $representativeClientId: Int
  $representativeSubMemberId: Int
  $representativeName: String 
  $representativeKana: String
  $representativeTel: String
  $representativeEmail: String
  $representativeAddress: String
  $numberOfAdults: Int!
  $representativeCountry: String,
  $representativePostalCode: String,
  $numberOfChildren: Int!
  $numberOfChildrenWithBedShare: Int!
  $days: [BookingDayInput!]!
) {
  createBookingV4(
    bookingTypeId: $bookingTypeId
    contractId: $contractId
    representativeCountry: $representativeCountry,
    representativePostalCode: $representativePostalCode,
    roomTypeId: $roomTypeId
    checkInDate: $checkInDate
    createdClientId: $createdClientId
    createdSubMemberId: $createdSubMemberId
    bookingBarNote: $bookingBarNote
    representativeClientId: $representativeClientId
    representativeSubMemberId: $representativeSubMemberId
    representativeName: $representativeName
    representativeKana: $representativeKana
    representativeTel: $representativeTel
    representativeEmail: $representativeEmail
    representativeAddress1: $representativeAddress
    numberOfAdults: $numberOfAdults
    numberOfChildren: $numberOfChildren
    numberOfChildrenWithBedShare: $numberOfChildrenWithBedShare
    days: $days
    accommodationPoint: $points
  ) {
    id
  }
}`;