import { apolloClient } from '@/plugins/apollo';
import { ENUM_EMPLOYMENT_TYPE_LIST } from '@/api/graphql/enum/enumEmploymentTypeList.js';
import { ENUM_PIC_IDS } from '@/api/graphql/enum/enumPicIds.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'
import { ENUM_RESPONSE_SOURCE_LIST } from '@/api/graphql/enum/enumResponseSourceList.js';
import {
  ENUM_CONTRACT_PURCHASE_TYPE_LIST,
  ENUM_CONTRACT_PURCHASE_TYPE_DETAIL_LIST,
  ENUM_STAFF_LIST,
  ENUM_CONTRACT_PURCHASE_REASON_LIST,
  ENUM_CONTRACT_PURCHASE_PURPOSE_LIST,
  ENUM_CONTRACT_APPLICATION_TYPE_LIST,
  ENUM_CONTRACT_PURCHASE_PAYMENT_METHOD_LIST,
  ENUM_CAMPAIGN_APPLY_RESPONSE_LIST,
} from '@/api/graphql/enum/index.js';

export default {
  enumEmploymentTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_EMPLOYMENT_TYPE_LIST}`,
        })
        .then(data => {
          commit('setEnumEmploymentTypeList', data.data.enumEmploymentTypeList);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_EMPLOYMENT_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  actionEnumPicIds({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PIC_IDS}`,
        })
        .then(data => {
          const newPicIdsList = [];
          data.data.searchClientList.items.forEach(element => {
            if (element.pic) {
              newPicIdsList.push(element.pic);
            }
          });
          commit('setEnumPicIds', newPicIdsList);
          resolve(newPicIdsList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PIC_IDS, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  actionEnumResponseSourceList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_RESPONSE_SOURCE_LIST}`,
        })
        .then(data => {
          // commit('setEnumResponseSourceList', data.data.enumResponseSourceList);
          resolve(data.data.enumResponseSourceList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_RESPONSE_SOURCE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  actionEnumContractPurchaseTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PURCHASE_TYPE_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractPurchaseTypeList', data.data.enumContractPurchaseTypeList);
          resolve(data.data.enumContractPurchaseTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PURCHASE_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumContractPurchaseTypeDetailList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PURCHASE_TYPE_DETAIL_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractPurchaseTypeDetailList', data.data.enumContractPurchaseTypeDetailList);
          resolve(data.data.enumContractPurchaseTypeDetailList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PURCHASE_TYPE_DETAIL_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionStaffList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_STAFF_LIST}`,
        })
        .then(data => {
          // commit('setStaffList', data.data.staffList);
          resolve(data.data.staffList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_STAFF_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumContractPurchaseReasonList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PURCHASE_REASON_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractPurchaseReasonList', data.data.enumContractPurchaseReasonList);
          resolve(data.data.enumContractPurchaseReasonList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PURCHASE_REASON_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumContractPurchasePurposeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PURCHASE_PURPOSE_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractPurchasePurposeList', data.data.enumContractPurchasePurposeList);
          resolve(data.data.enumContractPurchasePurposeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PURCHASE_PURPOSE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumContractApplicationTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_APPLICATION_TYPE_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractApplicationTypeList', data.data.enumContractApplicationTypeList);
          resolve(data.data.enumContractApplicationTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_APPLICATION_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumContractPurchasePaymentMethodList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PURCHASE_PAYMENT_METHOD_LIST}`,
        })
        .then(data => {
          // commit('setEnumContractPurchasePaymentMethodList', data.data.enumContractPurchasePaymentMethodList);
          resolve(data.data.enumContractPurchasePaymentMethodList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PURCHASE_PAYMENT_METHOD_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionEnumCampaignApplyResponseList({ commit, state }, clientId) {
    const finishData = {
      clientId: clientId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CAMPAIGN_APPLY_RESPONSE_LIST}`,
          variables: finishData,
        })
        .then(data => {
          // commit('setEnumCampaignApplyResponseList', data.data.campaignApplyResponseList.items);
          resolve(data.data.campaignApplyResponseList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CAMPAIGN_APPLY_RESPONSE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
