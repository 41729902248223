import actions from './actions';

const customerMembershipContract = {
  state: {
    totalClientAnnualfeeListCustomerMembershipContract: null,
    totalPointexchangelistCustomerMc: null,
    clientId: null,
    clientAnnualfeeListCustomerMembershipContract: [],
    contractlistCustomerMembershipContract: [],
    clientMemberInfoCustomerMembershipContract: {},
    clientMemberInfoCustomerMCSubmember: [],
    clientMemberInfoCustomerMCFamily: [],
    emailCustomerMC: '',
    requestMemberMagazineMailCustomerMC: false,
    requestBrochureMailCustomerMC: false,
    enumPersonrelationshipListCustomerMc: [],
    enumPrefectureListCustomerMc: [],
    tvphistoryListCustomerMc: [],
    quantityCustomerMc: null,
    productIdCustomerMc: 0,
    monthCustomerMc: 0,
    annualFeeCustomerMc: null,
    membershipFeeCustomerMc: 0,
    managementFeeCustomerMc: null,
    hotspringFeeCustomerMc: null,
    remarksCustomerMc: '',
    defaultYearSelected: 0,
    pointexchangelistCustomerMc: [],
    facilityFeePayment: '',
    otherServicePayment: '',
    taxPayment: '',
    optionalCleaningPayment: '',
    setupFeePayment: '',
    dataSubmembers: [],
    statusSaveData: false,
    subMemberTypeIdCustomerMc: null,
    IDVmTentativeContractCustomerMc: null,
    IDVsTentativeContractCustomerMc: null,
    currentURL:'',
    flagTvphistoryListCustomerMc:false
  },
  getters: {
    getTotalClientAnnualfeeListCustomerMembershipContract (state) {
      return state.totalClientAnnualfeeListCustomerMembershipContract
    },
    getTotalPointexchangelistCustomerMc (state) {
      return state.totalPointexchangelistCustomerMc
    },
    clientAnnualfeeListCustomerMembershipContract(state) {
      return state.clientAnnualfeeListCustomerMembershipContract
    },
    contractlistCustomerMembershipContract(state) {
      return state.contractlistCustomerMembershipContract
    },
    clientMemberInfoCustomerMembershipContract(state) {
      return state.clientMemberInfoCustomerMembershipContract
    },
    emailCustomerMC(state) {
      return state.emailCustomerMC
    },
    enumPersonrelationshipListCustomerMc(state) {
      return state.enumPersonrelationshipListCustomerMc
    },
    enumPrefectureListCustomerMc(state) {
      return state.enumPrefectureListCustomerMc
    },
    tvphistoryListCustomerMc(state) {
      return state.tvphistoryListCustomerMc
    },
    productIdCustomerMc(state) {
      return state.productIdCustomerMc
    },
    quantityCustomerMc(state) {
      return state.quantityCustomerMc
    },
    clientMemberInfoCustomerMCSubmember(state) {
      return state.clientMemberInfoCustomerMCSubmember
    },
    clientMemberInfoCustomerMCFamily(state) {
      return state.clientMemberInfoCustomerMCFamily
    },
    monthCustomerMc(state) {
      return state.monthCustomerMc
    },
    annualFeeCustomerMc(state) {
      return state.annualFeeCustomerMc
    },
    membershipFeeCustomerMc(state) {
      return state.membershipFeeCustomerMc
    },
    managementFeeCustomerMc(state) {
      return state.managementFeeCustomerMc
    },
    hotspringFeeCustomerMc(state) {
      return state.hotspringFeeCustomerMc
    },
    remarksCustomerMc(state) {
      return state.remarksCustomerMc
    },
    pointexchangelistCustomerMc(state) {
      return state.pointexchangelistCustomerMc
    },
    statusSaveData(state) {
      return state.statusSaveData
    },
    IDVmTentativeContractCustomerMc(state) {
      return state.IDVmTentativeContractCustomerMc
    },
    IDVsTentativeContractCustomerMc(state) {
      return state.IDVsTentativeContractCustomerMc
    },
    currentURL(state) {
      return state.currentURL
    },
    getFlagTvphistoryListCustomerMc(state){
      return state.flagTvphistoryListCustomerMc
    },
  },
  mutations: {
    setFlagTvphistoryListCustomerMc(state, data){
      state.flagTvphistoryListCustomerMc = !state.flagTvphistoryListCustomerMc
    },

    setTotalClientAnnualfeeListCustomerMembershipContract (state, data) {
      state.totalClientAnnualfeeListCustomerMembershipContract = data
    },

    setTotalPointexchangelistCustomerMc (state, data) {
      state.totalPointexchangelistCustomerMc = data
    },
    // setClientMemberInfo(state, clientId) {
    //   state.clientId = clientId;
    // },
    // set clientAnnualfeeListCustomerMc
    setClientAnnualfeeListCustomerMembershipContract(state, data) {
      data.forEach(element => {
        if (element !== null) state.clientAnnualfeeListCustomerMembershipContract = data
      });
    },
    setContractlistCustomerMembershipContract(state, data) {
      state.contractlistCustomerMembershipContract = data
    },
    setClientMemberInfoCustomerMembershipContract(state, data) {
      state.clientMemberInfoCustomerMembershipContract = {
        ...data,
        email: data.memberUser ? data.memberUser.email : '',
      }
      state.clientMemberInfoCustomerMCSubmember = []
      state.clientMemberInfoCustomerMCFamily = []
      data.subMembers.forEach(e => {
        if (e.subMemberType.id === 1) {
          state.clientMemberInfoCustomerMCSubmember.push(e)
        } else {
          state.clientMemberInfoCustomerMCFamily.push(e)
        }
      });
    },
    setClientMemberInfoCustomerMCFamily (state, val) {
      state.clientMemberInfoCustomerMCFamily = val
    },
    setEmailCustomerMC(state, val) {
      state.emailCustomerMC = val
    },
    setEnumPersonrelationshipListCustomerMc(state, data) {
      state.enumPersonrelationshipListCustomerMc = data
    },
    setEnumPrefectureListCustomerMc(state, data) {
      state.enumPrefectureListCustomerMc = data
    },
    setTvphistoryListCustomerMc(state, data) {
      state.tvphistoryListCustomerMc = data
    },
    setQuantityCustomerMc(state, data) {
      state.quantityCustomerMc = data
    },
    setProductIdCustomerMc(state, data) {
      state.productIdCustomerMc = data
    },
    setMonthCustomerMc(state, data) {
      state.monthCustomerMc = data
    },
    setAnnualFeeCustomerMc(state, data) {
      state.annualFeeCustomerMc = parseInt(data)
    },
    // setAnnualFeeTaxCustomerMc(state, data) {
    //   state.annualFeeTaxCustomerMc = data
    // },
    setMembershipFeeCustomerMc(state, data) {
      state.membershipFeeCustomerMc = parseInt(data)
    },
    // setMembershipFeeTaxCustomerMc(state, data) {
    //   state.membershipFeeTaxCustomerMc = data
    // },
    setManagementFeeCustomerMc(state, data) {
      state.managementFeeCustomerMc = parseInt(data)
    },
    // setManagementFeeTaxCustomerMc(state, data) {
    //   state.managementFeeTaxCustomerMc = data
    // },
    setHotspringFeeCustomerMc(state, data) {
      state.hotspringFeeCustomerMc = parseInt(data)
    },
    // setHotspringFeeTaxCustomerMc(state, data) {
    //   state.hotspringFeeTaxCustomerMc = data
    // },
    setRemarksCustomerMc(state, data) {
      state.remarksCustomerMc = data
    },
    setDefaultYearSelected(state, data) {
      state.defaultYearSelected = data
    },
    setPointexchangelistCustomerMc(state, data) {
      state.pointexchangelistCustomerMc = data
    },
    setRequestMemberMagazineMailCustomerMC(state, data) {
      state.requestMemberMagazineMailCustomerMC = data
    },
    setRequestBrochureMailCustomerMC(state, data) {
      state.requestBrochureMailCustomerMC = data
    },
    setAccommodationFeePayment(state, data) {
      state.accommodationFeePayment = data
    },
    setFacilityFeePayment(state, data) {
      state.facilityFeePayment = data
    },
    setOtherServicePayment(state, data) {
      state.otherServicePayment = data
    },
    setTaxPayment(state, data) {
      state.taxPayment = data
    },
    setOptionalCleaningPayment(state, data) {
      state.optionalCleaningPayment = data
    },
    setSetupFeePayment(state, data) {
      state.setupFeePayment = data
    },
    setDataSubmembers(state, data) {
      state.dataSubmembers = data ? data.map(e => ({
        id: e.id, //
        name: e.name, //
        nameKana: e.nameKana, //
        birthday: e.birthday,
        relationshipId: parseInt(e.relationship),
        sex: e.sex,
        postalCode: e.postalCode,
        prefecture: e.prefecture,
        locality: e.locality,
        address1: e.address1,
        address2: e.address2,
        tel: e.tel,
        // loginEmail: e.memberUser.email, // ログインメールアドレス
        remarks: e.remarks,
        email: e.email, // メールアドレス
        loginEmail: e.memberUser?.email
      })) : []
    },
    setStatusSaveData(state) {
      state.statusSaveData = !state.statusSaveData
    },
    setSubMemberTypeIdCustomerMc(state, val) {
      state.subMemberTypeIdCustomerMc = val
    },
    setIDVmTentativeContractCustomerMc(state, val) {
      state.IDVmTentativeContractCustomerMc = val
    },
    setIDVsTentativeContractCustomerMc(state, val) {
      state.IDVsTentativeContractCustomerMc = val
    },
    setCurrentURL(state, val){
      state.currentURL = val
    }
  },
  actions,
};

export default customerMembershipContract;
