
import { apolloClient } from '@/plugins/apollo';
import { 
    VMOCCUPANCYLIST_TEM_CONTRACT,
    FACILITY_LIST,
    ROOMTYPELIST_TEM_CONTRACT
} from '@/api/graphql/customer/response/detail/VMTemporaryContract/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'
// import moment from 'moment';

export default {
  async getVmoccupancylistTemContract({ state, commit }) {
    const finishData = {
      fromYear: state.yearsTemContract,
      years: parseInt(state.numYearTemContract) + parseInt(3),
      roomTypeId: state.roomtypeIdTemContract,
      fromMonth: state.fromMonthTemContract,
      fromDate: state.fromDateTemContract,
      toMonth: state.toMonthTemContract,
      toDate: state.toDateTemContract,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${VMOCCUPANCYLIST_TEM_CONTRACT}`,
          variables: finishData,
        })
        .then(data => {
          commit('setVmoccupancylistTemContract', data.data.vmOccupancyList);
          resolve(data.data.vmOccupancyList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, VMOCCUPANCYLIST_TEM_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createVmTentativeBookings({ state, commit }, id) {
    var data = []
    state.stateSelectedReservationRooms.forEach(e => {
      var arr = []
      e.data.inventoryList.forEach(el => {
        arr.push({
          inventoryTypeId: el.inventoryTypeId,
          stayRightPrice: 10,
        })
      });
      data.push({
        days: arr,
        checkInDate: e.checkInDate,
        checkOutDate: e.checkOutDate
      })
    });
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`mutation (
            $contractId: Int!
            $roomTypeId: Int! 
            $years: Int! 
            $bookings: [CreateVmBookingInput!]! 
          ) {
            createVmTentativeBookings(
              contractId: $contractId
              roomTypeId: $roomTypeId
              years: $years
              vmBookings: $bookings
            ) {
              __typename
            }
          }`,
          variables: {
            contractId: parseInt(id),
            roomTypeId: state.roomtypeIdTemContract,
            years: parseInt(state.numYearTemContract),
            bookings: data
          },
        })
        .then(data => {
          resolve(true)
          // commit('setVmoccupancylistTemContract', data.data.vmOccupancyList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getFacilityListTemContract({ commit, state }, take) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${FACILITY_LIST}`
        })
        .then(data => {
          commit(
            'setFacilityListTemContract',
            data.data.facilityList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, FACILITY_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getRoomtypelistTemContract({ commit, state }, facilityId) {
    commit('setLoadingOverlayShow');
    const finishData = {
      facilityId:facilityId
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ROOMTYPELIST_TEM_CONTRACT}`,
          variables: finishData
        })
        .then(data => {
          commit(
            'setRoomtypelistTemContract',
            data.data.roomTypeList,
          );
          commit('setLoadingOverlayHide');
        })
        .catch(error => {
          commit('setLoadingOverlayHide');
          setCopyErrorTextCommit(commit, ROOMTYPELIST_TEM_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  
};

