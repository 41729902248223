export const GET_URL_IMG_USER_MANUAL = `
mutation (
  $contentType: String!
  $contentLength: Int!
  $type: String!
  $fileName: String
) {
  generateUploadUrl(
    type: $type
    contentType: $contentType
    contentLength: $contentLength
    fileName: $fileName
  ) {
    uploadUrl
    fileUrl 
  }
}
`;

export const CREATE_USER_GUIDE_USER_MANUAL = `
mutation (
    $year: Int!
    $url: String!
  ) {
    createUserGuide(data: {
      year: $year
      url: $url
    }) {
      id
    }
  }
`;

export const USER_GUIDE_LIST_USER_MANUAL = `
query {
  userGuideList {
   id 
   year 
   url 
 }
}
`;

export const DELETE_USER_GUIDE = `
mutation (
  $id: Int!
) {
  deleteUserGuide(id: $id)
}
`;


