import actions from './actions';

const searchUpdateEvaluationList = {
  state: {
    searchUpdateEvaluationList: [],
    renewPurchaseRate: [{ id: null, name: '購入評価' }],
    picUpdateEvaluation: [{ id: null, name: '佐々木剛' }],
    lastCampaignApplyResponseUpdateEvaluation: [
      { id: null, name: '指定なし' },
    ],
    // select
    renewPurchaseRateSelected: null,
    picUpdateEvaluationSelected: parseInt(localStorage.getItem('id')),
    lastCampaignApplyResponseUpdateEvaluationSelected: null,
  },
  getters: {
    searchUpdateEvaluationList(state) {
      return state.searchUpdateEvaluationList;
    },
    renewPurchaseRate(state) {
      return state.renewPurchaseRate;
    },
    picUpdateEvaluation(state) {
      return state.picUpdateEvaluation;
    },
    lastCampaignApplyResponseUpdateEvaluation(state) {
      return state.lastCampaignApplyResponseUpdateEvaluation;
    },
    renewPurchaseRateSelected(state) {
      return state.renewPurchaseRateSelected;
    },
    picUpdateEvaluationSelected(state) {
      return state.picUpdateEvaluationSelected;
    },
    lastCampaignApplyResponseUpdateEvaluationSelected(state) {
      return state.lastCampaignApplyResponseUpdateEvaluationSelected;
    },
  },
  mutations: {
    searchUpdateEvaluationList(state, arr) {
      state.searchUpdateEvaluationList = arr;
    },
    setRenewPurchaseRateSelected(state, val) {
      state.renewPurchaseRateSelected = val;
    },
    setPicUpdateEvaluationSelected(state, val) {
      state.picUpdateEvaluationSelected = val;
    },
    setLastCampaignApplyResponseUpdateEvaluationSelected(state, val) {
      state.lastCampaignApplyResponseUpdateEvaluationSelected = val;
    },
  },
  actions,
};

export default searchUpdateEvaluationList;
