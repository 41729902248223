export const CONTRACT_POINT_LIST = `
query (
    $contractId: Int!
  ) {
    contractPointList(
      contractId: $contractId
    ) {
      id 
      grantedAt 
      pointPrice 
      pointRemains 
      validFrom 
      validThru 
      remarks 
    }
  }
`;