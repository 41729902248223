import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import alert from './modules/alert'
import memberShipContract from './modules/customer/response/detail/memberShipContract'
import changehistory from './modules/customer/response/detail/changehistory'
import AdditionalPurchase from './modules/myPage/CustomerInCharge/AdditionalPurchase'
import UpdateEvaluation from './modules/myPage/CustomerInCharge/UpdateEvaluation'
import experienceLodging from './modules/experienceLodging'
import Meeting from './modules/myPage/Meeting'
import PurchaseEvaluation from './modules/myPage/CustomerInCharge/PurchaseEvaluation'
// import customerDetailsActivities from './modules/customer/response/detail/activities';
import basicInformation from './modules/basicInformation'
import enumEmploymentTypeList from './modules/enum'
import enumClientChildTypeList from './modules/enumClientChild'
import enumEnumLevelList from './modules/enumLevelList'
// import settingLink from './modules/customer/settingLink';
import FreeNotificationRegistration from './modules/customer/response/detail/overview/FreeNotificationRegistration/index.js'
import enumPrefectureList from './modules/enumPrefectureList'
import settingLink from './modules/customer/response/detail/settingLink'
import Applicant from './modules/customer/applicant/index.js'
import activities from './modules/customer/response/detail/activities'
import ActivitiesList from './modules/customer/response/detail/activities/activities/index.js'
import ResponseAll from './modules/customer/response/responseAll'
import customerMembershipContract from './modules/customer/response/detail/memberShipContract/CustomerMembershipContract/index'
import changeMember from './modules/customer/response/detail/memberShipContract/ChangeMember/index'
import addUseCancel from './modules/customer/response/detail/addUse'
import addNewDocReq from './modules/customer/response/detail/addNewDocReq'
import ticketsBuy from './modules/customer/response/detail/ticketsBuy'
import prospectInformation from './modules/customer/response/detail/prospectInformation'
import deleteDatail from './modules/customer/response/detail/delete'
import basicInfors from './modules/contract/contractDetail/sp_ep/basicInformation'
import vmcontractUsage from './modules/contract/contractDetail/sp_ep/VmTemporaryContract'
import membershipBillingHistory from './modules/customer/response/detail/membershipBillingHistory'
import advertisingMeasuresCustomers from './modules/customer/response/AdvertisingMeasuresCustomers'
import contractAll from './modules/contract/contractAll'
import contractUndigestedList from './modules/contract/contractUndigestedList'
import spuSageStatus from './modules/contract/contractDetail/sp_ep/sPUsageStatus'
import breakdownList from './modules/contract/contractDetail/sp_ep/breakdown'
import UploadDocuments from './modules/contract/contractDetail/sp_ep/UploadDocuments'
import openBrowers from './modules/contract/contractDetail/sp_ep/OpenBrowser'
import changePeriod from './modules/contract/contractDetail/sp_ep/changePeriod'
import contractCancellation from './modules/contract/contractDetail/sp_ep/ContractCancellation'
import carryForward from './modules/contract/contractDetail/sp_ep/carryForward'
import carryOverService from './modules/contract/contractDetail/sp_ep/carry-overService'
import exception from './modules/contract/contractDetail/sp_ep/exception'
import transferContract from './modules/contract/contractDetail/MW/transferContract'
import VacationLife from './modules/dashboard/webPageManage/webDocument/vacationLife/index.js'
import otherMaterials from './modules/dashboard/webPageManage/webDocument/otherMaterials/index.js'
import VisitReservation from './modules/dashboard/webPageManage/VisitReservation/index.js'
import userManual from './modules/dashboard/webPageManage/webDocument/userManual/index.js'
import FaceToFaceCustomer from './modules/customer/response/FaceToFaceCustomer/index.js'
import DuplicateExistingCustomers from './modules/dashboard/webPageManage/DuplicateExistingCustomers/index.js'
import experience from './modules/dashboard/webPageManage/experience/index.js'
import Facility from './modules/dashboard/webPageManage/facility/index.js'
import MyPage from './modules/myPage/myPage/index.js'
import Member from './modules/customer/member/index.js'
import FacilitySetting from './modules/dashboard/webPageManage/facilitySetting/index.js'
import CreateNewCustomer from './modules/customer/CreateNewCustomer/index.js'
import customerDetail from './modules/customerDetail'
import ContractTimeInformation from './modules/contract/contractDetail/sp_ep/ContractTimeInformation/index.js'
import WebPageManageInfo from './modules/dashboard/webPageManage/info/index.js'
import DocumentRequest from './modules/dashboard/webPageManage/documentRequest/index.js'
import VMTemporaryContract from './modules/customer/response/VMTemporaryContract/index.js'
import bookingTicket from './modules/bookingTicket'
import advertising from './modules/advertising'
import giftTicket from './modules/giftTicket'
import exChangeReservation from './modules/contract/contractDetail/sp_ep/exChangeReservation'
import confirmAnnual from './modules/confirmAnnual/index.js'
import webRequest from './modules/webRequest'
import salesRepresentative from './modules/manager/salesRepresentative'
import staffGroupList from './modules/manager/group'
import managementTabUser from './modules/manager/managementTabUser'
import webNotification from './modules/webNotification';
import groupCreateAdd from './modules/manager/groupAdd'
import mail from './modules/mail'
import groupDetail from './modules/manager/groupDetail'
import corporateTicket from './modules/corporateTicket'
import notice from './modules/dashboard/webPageManage/notice'
import report from './modules/report';
import signupBonus from './modules/signupBonus';
import experienceBasedAccommodationCustomers from './modules/customer/response/ExperienceBasedAccommodationCustomers';
import createBookingTicket from './modules/createBookingTicket'
import PageManageBase from './modules/dashboard/webPageManage/PageManageBase/index.js'
import confirmDialog from './modules/confirmDialog'
import tvpBatch from './modules/tvp-batch/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    alert,
    changehistory,
    AdditionalPurchase,
    memberShipContract,
    Meeting,
    UpdateEvaluation,
    experienceLodging,
    PurchaseEvaluation,
    basicInformation,
    enumEmploymentTypeList,
    enumClientChildTypeList,
    enumEnumLevelList,
    enumPrefectureList,
    settingLink,
    Applicant,
    activities,
    // customerDetailsActivities,
    ResponseAll,
    ActivitiesList,
    customerMembershipContract,
    changeMember,
    FreeNotificationRegistration,
    addUseCancel,
    addNewDocReq,
    ticketsBuy,
    basicInfors,
    contractAll,
    contractUndigestedList,
    membershipBillingHistory,
    advertisingMeasuresCustomers,
    VacationLife,
    otherMaterials,
    VisitReservation,
    spuSageStatus,
    userManual,
    breakdownList,
    FaceToFaceCustomer,
    experience,
    Facility,
    Member,
    DuplicateExistingCustomers,
    CreateNewCustomer,
    MyPage,
    UploadDocuments,
    prospectInformation,
    ContractTimeInformation,
    openBrowers,
    customerDetail,
    deleteDatail,
    changePeriod,
    WebPageManageInfo,
    DocumentRequest,
    transferContract,
    contractCancellation,
    VMTemporaryContract,
    carryForward,
    carryOverService,
    exception,
    vmcontractUsage,
    bookingTicket,
    advertising,
    exChangeReservation,
    confirmAnnual,
    giftTicket,
    webRequest,
    salesRepresentative,
    staffGroupList,
    corporateTicket,
    managementTabUser,
    groupCreateAdd,
    mail,
    groupDetail,
    webNotification,
    FacilitySetting,
    notice,
    report,
    signupBonus,
    experienceBasedAccommodationCustomers,
    createBookingTicket,
    PageManageBase,
    confirmDialog,
    tvpBatch,
  },
  getters: getters
})

export default store
