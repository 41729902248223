export const UPDATE_CONTRCTPOINT_DURATIONG = `
mutation (
  $id: Int! 
  $validFrom: DateString!
  $validThru: DateString! 
) {
  updateContractPointDuration(
    id: $id
    validFrom: $validFrom
    validThru: $validThru
  )
}
`