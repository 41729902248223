export const UPDATE_ANNUALFEE_CHANGE_MEMBER = `
mutation (
  $id: Int!
  $remarks: String!
  $year: Int!
  $month: Int!
  $annualFee: Int!
  $membershipFee: Int!
  $managementFee: Int!
  $hotspringFee: Int!
) {
  updateAnnualFee(
    id: $id
    remarks: $remarks
    year: $year
    month: $month
    annualFee: $annualFee
    membershipFee: $membershipFee
    managementFee: $managementFee
    hotspringFee: $hotspringFee
  ) {
    id 
        year 
        month 
        processedAt 
        remarks 
        annualFee 
        annualFeeTax 
        membershipFee 
        membershipFeeTax 
        managementFee 
        managementFeeTax 
        hotspringFee 
        hotspringFeeTax
  }
}`;