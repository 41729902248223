import actions from './actions';

const openBrowers = {
  state: {
    contractId: null,
    fileKey: '',
    fileName: ''
  },
  getters: {
    getFileKeyOpen(state) {
      return state.fileKey
    },
    getFileNameOpen(state) {
      return state.fileName
    }

  },
  mutations: {
    setContractId(state, payload) {
      state.contractId = payload
    },

    setFileKeyOpen(state, payload) {
      state.fileKey = payload
    },

    setFileNameOpen(state, payload) {
      state.fileName = payload
    },

  },
  actions,
};

export default openBrowers;
