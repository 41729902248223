export const ROLLOVER_POINT = `
mutation (
  $contractId: Int! #契約ID
  $year: Int! #一覧APIから取得する year （年度）
  $points: Int! #移動ポイント数
  $remarks: String #備考
  $totalFee: Int! #請求金額（税抜き） 単価の部分は300円を表示し、単価×ポイント数をデフォルトで表示すること
) {
  rolloverPoints(
    year: $year
    contractId: $contractId
    points: $points
    totalFeeOverride: $totalFee
    remarks: $remarks
  )
}
`