import actions from './actions';

const carryOverService = {
  state: {
    carryOverData: {

    }
  },
  getters: {
    getContractIdCarryOver(state) {
      return state.contractId;
    },

    getValidCarryOver(state) {
      return state.carryOverData
    }
  },
  mutations: {
    setContractIdCarryOver(state, payload) {
      state.contractId = payload
    },
    setValidCarryOver(state, payload) {
      state.carryOverData = payload
    }

  },
  actions,
};

export default carryOverService;
