export const TVP_BULK_PROCESS_LIST = `
query (
  $pagination: PaginationInput!
) {
  tvpBulkProcessList(
    pagination:$pagination
  ) {
    total
    items {
      id
      name
      type
      point
      status
      createdStaff { name }
      createdAt
      executedAt
      memberCount
    }
  }
}
`

export const GET_TVP_BULK_PROCESS = `
query (
  $id: Int!
) {
  tvpBulkProcess (id: $id) {
    id
    name
    type
    point
    createdStaff { name }
    createdAt
    executedAt
    remarks
    clients {
      id
      memberId
      clientId
      isValid
      errorReason
    }
  }
}
`

export const CREATE_TVP_BULK_PROCESS = `
mutation (
  $name: String!
  $type: TvpBulkProcessType!
  $point: Int!
  $remarks: String
) {
  createTVPBulkProcess(
    name: $name
    type: $type
    point: $point
    remarks: $remarks
  ) {
    id
  }
}
`

export const UPDATE_TVP_BULK_PROCESS = `
mutation (
  $id: Int!
  $name: String!
  $point: Int!
  $remarks: String
) {
  updateTVPBulkProcess(
    id: $id
    name: $name
    point: $point
    remarks: $remarks
  ) {
    id
  }
}
`

export const ADD_CLIENT_TVP_BULK_PROCESS = `
mutation (
  $id: Int!
  $memberIds: [String!]!
) {
  addClientsTVPBulkProcess(
    id: $id
    memberIds: $memberIds
  ) {
    id
  }
}
`

export const EXECUTE_TVP_BULK_PROCESS = `
mutation (
  $id: Int!
) {
  executeTVPBulkProcess(id: $id) {
    id
  }
}
`
