import { apolloClient } from '@/plugins/apollo';
import {
  CLIENT_SEARCH_PAGINATED_RESPONSE,
  ENUM_PURCHASERATE_LIST,
  CAMPAIGN_LIST_APPLICANT,
  CLIENT_ACTION_METHOD_LIST,
  BOOKING_TYPES_LIST_APPLICANT,
  ENUM_CAMPAIGN_GROUP_LIST,
  ENUM_PREFECTURE_LIST,
  OTHER_PUBLIC_DOC_TYPE_LIST
} from '@/api/graphql/customer/applicant/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientSearchPaginatedResponseApplicant({ commit, state }, {data, orderBy}) {
    const finishData = {
      filter: {
        name: data.name,
        nameKana: data.nameKana,
        clientIds: data.clientIds,
        includeCompany: data.includeCompany,
        includeIndividual: data.includeIndividual,
        companyRepresentativeName: data.companyRepresentativeName,
        contactAddress1: data.contactAddress1,
        contactAddress2: data.contactAddress2,
        contactTel: data.contactTel,
        contactMobile: data.contactMobile,
        contactFax: data.contactFax,
        contactEmail: data.contactEmail,
        includeDoNotContact: data.includeDoNotContact,
        contactPostalCodes: data.contactPostalCodes,
        contactPrefectures: data.contactPrefectures,
        contactLocalities: data.contactLocalities,
        purchaseRates: data.purchaseRates,
        hasTrialStay: data.hasTrialStay,
        hasActionWithActionType: data.hasActionWithActionType,
        remarks: data.remarks.toString().replace('\\n', '\n'),
        // campaignIds: state.arrCampaignListApplicant,
        campaignIds: state.listCampaignListApplicant,
        campaignGroups: data.campaignGroups,
        campaignTimes: data.campaignTimes,
        hasActionMethodIdsType: data.hasActionMethodIdsType,
        hasActionMethodIds: data.hasActionMethodIds,
        lastActionDateFrom: data.lastActionDateFrom || null,
        lastActionDateTo: data.lastActionDateTo || null,
        hasProductTypeIdsIncludeExpired: data.hasProductTypeIdsIncludeExpired,
        hasProductExpiringFromDate: data.hasProductExpiringFromDate || null,
        hasProductExpiringToDate: data.hasProductExpiringToDate || null,
        hasProductTypeIdsType: data.hasProductTypeIdsType,
        hasProductTypeIds: data.hasProductTypeIds,
        membershipCancellationDateFrom: data.membershipCancellationDateFrom || null,
        membershipCancellationDateTo: data.membershipCancellationDateTo || null,
        membershipStatus: data.membershipStatus,
        memberIds: data.memberIds,
        picIds: data.picIds,
        furtherPurchaseRates: data.furtherPurchaseRates,
        renewPurchaseRates: data.renewPurchaseRates,
        clientContactType: data.clientContactType,
      },
      pagination: {
        take: state.listFilter.take,
        skip: state.listFilter.skip
      },
      orderBy
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_SEARCH_PAGINATED_RESPONSE}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit(
            'setClientSearchPaginatedResponseApplicant',
            response.data.searchClientList.items,
          );
          commit('setTotalBookinglist', response.data.searchClientList.total)
          resolve()
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_SEARCH_PAGINATED_RESPONSE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumPurchaseRateListApplicant({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PURCHASERATE_LIST}`,
        })
        .then(response => {
          commit(
            'setEnumPurchaseRateListApplicant',
            response.data.enumPurchaseRateList,
          );
          resolve(response.data.enumPurchaseRateList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PURCHASERATE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getCampaignListApplicant({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CAMPAIGN_LIST_APPLICANT}`,
        })
        .then(response => {
          commit(
            'setCampaignListApplicant',
            response.data.enumCampaignMediaTypeList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CAMPAIGN_LIST_APPLICANT, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumClientActionMethodList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_ACTION_METHOD_LIST}`,
        })
        .then(data => {
          commit(
            'setEnumClientActionMethodListApplicant',
            data.data.enumClientActionMethodList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_ACTION_METHOD_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getBookingTypesListApplicant({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_TYPES_LIST_APPLICANT}`,
        })
        .then(data => {
          commit('setBookingTypesListApplicant', data.data.enumProductTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_TYPES_LIST_APPLICANT, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumCampaignGroupListApplicant({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CAMPAIGN_GROUP_LIST}`,
        })
        .then(data => {
          commit(
            'setEnumCampaignGroupListApplicant',
            data.data.enumCampaignGroupList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CAMPAIGN_GROUP_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumPrefectureList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PREFECTURE_LIST}`,
        })
        .then(data => {
          commit(
            'setEnumPrefeList',
            data.data.enumPrefectureList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PREFECTURE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async publicDocTypeList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${OTHER_PUBLIC_DOC_TYPE_LIST}`,
        })
        .then(data => {
          commit(
            'setOtherPublicDocTypeList',
            data.data.otherPublicDocTypeList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, OTHER_PUBLIC_DOC_TYPE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
