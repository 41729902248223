import { apolloClient } from '@/plugins/apollo';
import { POINT_EXCHANGE_LIST } from '@/api/graphql/giftTicket/giftTicket.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async giftTicketList({ commit, state }, obj) {
    commit('setLoadingOverlayShow');
    const finishData = {
      pagination: {
        take: obj.take,
        skip: obj.skip
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${POINT_EXCHANGE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setPointExchangeList', data.data.pointExchangeList.items);
          resolve(data.data.pointExchangeList.total);
          commit('setLoadingOverlayHide');
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, POINT_EXCHANGE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
          commit('setLoadingOverlayHide');
        });
    });
  },
};
