import { apolloClient } from "@/plugins/apollo";
import { 
    OTHER_PUBLIC_DOCLIST_OTHER_MATERIALS,
    DELETE_OTHER_MATERIALS,
    CREATE_OTHER_PUBLIC_DOC_OTHER_MATERIALS,
    OTHER_PUBLIC_DOCTYPE_LIST_OTHER_MATERIALS
} from "@/api/graphql/dashboard/webPageManage/webDocument/otherMaterials/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getOtherPublicDoclistOtherMaterials({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${OTHER_PUBLIC_DOCLIST_OTHER_MATERIALS}`,
                    fetchPolicy: 'network-only'
                }).then(data => {
                    commit('setOtherPublicDoclistOtherMaterials',data.data.otherPublicDocList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, OTHER_PUBLIC_DOCLIST_OTHER_MATERIALS, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    async deleteOtherPublicDocOtherMaterials({ commit, state,dispatch },id) {
        const finishData = {
            id:id
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${DELETE_OTHER_MATERIALS}`,
                    variables: finishData,
                }).then(data => {
                    commit('setAlertSuccess', '削除しました。', {
                        root: true,
                    });
                    dispatch('getOtherPublicDoclistOtherMaterials')
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, DELETE_OTHER_MATERIALS, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    async createOtherPublicDocOtherMaterials({ commit, state,dispatch, rootState }) {
        const finishData = {
            category: state.docTypeOtherMaterials.toString(),
            fileName: state.fileNameOtherMaterials.toString(),
            url: state.fileUrlOtherMaterials.toString(),
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${CREATE_OTHER_PUBLIC_DOC_OTHER_MATERIALS}`,
                    variables: finishData,
                }).then(data => {
                    commit(
                        'setAlertSuccess',
                        rootState.alert.success.messages.updateSuccess,
                        { root: true },
                      );
                    dispatch('getOtherPublicDoclistOtherMaterials')
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CREATE_OTHER_PUBLIC_DOC_OTHER_MATERIALS, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async getOtherPublicDoctypeListOtherMaterials({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${OTHER_PUBLIC_DOCTYPE_LIST_OTHER_MATERIALS}`,
                }).then(data => {
                    commit('setOtherPublicDoctypeListOtherMaterials',data.data.otherPublicDocTypeList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, OTHER_PUBLIC_DOCTYPE_LIST_OTHER_MATERIALS, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}

