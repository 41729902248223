import { apolloClient } from '@/plugins/apollo';
import {
  CONTRACT,
  UPDATE_CONTRACT,
  ENUM_CONTRACT_PHASE_LIST,
} from '@/api/graphql/contract/contractDetail/sp_ep/basicInformation/basicInformation.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async contractInfoForRedirect({commit}, id) {
    const finishData = {id}
    const query = `query ($id: Int!) {
      contract(id: $id) {
        id
        clientId
        productTypeId
        tentative
      }
    }`
    try {
      const data = await apolloClient.query({
        query: gql`${query}`,
        variables: finishData,
        fetchPolicy: 'no-cache',
      });
      return data.data.contract;
    } catch (error) {
      setCopyErrorTextCommit(commit, query, finishData, error.graphQLErrors)
      handlError(commit, error.graphQLErrors);
      throw error;
    }
  },
  async basicInformationList({ commit, state }, id) {
    const currentId = id || (router.currentRoute.params.id)
    const finishData = {
      id: parseInt(currentId),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setBasicInfors', data.data.contract);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async basicInformationContractPhaseList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CONTRACT_PHASE_LIST}`,
        })
        .then(data => {
          commit('setEnum', data.data.enumContractPhaseList);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CONTRACT_PHASE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async basicInformationUpdate({ commit, state, rootState }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
      data: {
        phaseId: parseInt(state.dataUpdate.phaseId),
        basePoint: parseInt(state.dataUpdate.basePoint),
        additionalPointPrice: parseInt(
          state.dataUpdate.additionalPointPrice,
        ),
        mwExchangeFee: parseInt(state.dataUpdate.mwExchangeFee),
        loginAllowedDate: state.dataUpdate.loginAllowedDate,
        remarks: state.dataUpdate.remarks,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CONTRACT}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
          commit(
            'setAlertSuccess',
            '保存しました',
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CONTRACT, finishData, error.graphQLErrors)
          commit('setAlertError', rootState.alert.error.messages.createError, {
            root: true,
          });
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
