import actions from './actions'

const groupDetail = {
  state: {
    dataUpdateGroup: {
      id: null,
      name: ''
    },
    dataDeleteGroup: {
      id: null,
      name: ''
    }

  },
  getters: {
    getUpdate (state) {
      return state.dataUpdateGroup
    }
  },
  mutations: {
    setUpdate (state, payload) {
      state.dataUpdateGroup = payload
    },
    setDete (state, payload) {
      state.dataDeleteGroup = payload
    }
  },
  actions
}

export default groupDetail
