<template>
  <main-layout v-if="currentRoute !== '/notpage'">
    <router-view></router-view>
  </main-layout>
  <router-view v-else></router-view>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'

export default {
  components: { MainLayout },
  data () {
    return {
      currentRoute: ''
    }
  },
  mounted() {
    this.currentRoute = this.$router.currentRoute.path
  },
}
</script>
