import actions from './actions';

const enumClientChildTypeList = {
    state: {
        enum: [],

    },
    getters: {
        getEnumClientChildTypeList(state) {
            return state.enum;
        },
    },
    mutations: {
        setEnumClientChildTypeList(state, arr) {
            state.enum = arr;
        },
    },
    actions,
};

export default enumClientChildTypeList;
