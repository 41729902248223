import actions from './actions';

const defaultState = () => ({
  facilityListTemContract:[],
  roomtypelistTemContract:[],
  facilityIdTemContract:null,
  fromMonthTemContract:null,
  fromDateTemContract:null,
  toMonthTemContract:null,
  toDateTemContract:null,
  numYearTemContract:null,
  vmoccupancylistTemContract:[],
  roomtypeIdTemContract:null,
  yearsTemContract:null,
  stayDateTemContract:[],
  stateSelectedReservationRooms: []
})
const VMTemporaryContract = {
  state: defaultState(),
  getters:{
    facilityListTemContract(state){
      return state.facilityListTemContract
    },
    facilityIdTemContract(state){
      return state.facilityIdTemContract
    },
    roomtypelistTemContract(state){
      return state.roomtypelistTemContract
    },
    fromMonthTemContract(state){
      return state.fromMonthTemContract
    },
    fromDateTemContract(state){
      return state.fromDateTemContract
    },
    toMonthTemContract(state){
      return state.toMonthTemContract
    },
    toDateTemContract(state){
      return state.toDateTemContract
    },
    vmoccupancylistTemContract(state){
      return state.vmoccupancylistTemContract
    },
    roomtypeIdTemContract(state){
      return state.roomtypeIdTemContract
    },
    yearsTemContract(state){
      return state.yearsTemContract
    },
    numYearTemContract(state){
      return state.numYearTemContract
    },
    stayDateTemContract(state){
      return state.stayDateTemContract
    },
    stateSelectedReservationRooms(state){
      return state.stateSelectedReservationRooms
    },
  },
  mutations: {
    resetVmContractCreate(state) {
      const newData = defaultState();
      Object.keys(newData).forEach(key => {
        state[key] = newData[key]
      })
    },
    setVmoccupancylistTemContract(state,data){
      state.vmoccupancylistTemContract = data
    },
    setFacilityListTemContract(state,data){
      state.facilityListTemContract = data
    },
    setRoomtypelistTemContract(state,data){
      state.roomtypelistTemContract = data
    },
    setFacilityIdTemContract(state,val){
      state.facilityIdTemContract = val
    },
    setFromMonthTemContract(state,val){
      state.fromMonthTemContract = val
    },
    setFromDateTemContract(state,val){
      state.fromDateTemContract = val
    },
    setToMonthTemContract(state,val){
      state.toMonthTemContract = val
    },
    setToDateTemContract(state,val){
      state.toDateTemContract = val
    },
    setRoomtypeIdTemContract(state,val){
      state.roomtypeIdTemContract = val
    },
    setYearsTemContract(state,val){
      state.yearsTemContract = val
    },
    setNumYearTemContract(state,val){
      state.numYearTemContract = val
    },
    setStayDateTemContract(state){
      var arr = [];
      state.vmoccupancylistTemContract.years[0].days.forEach(e => {
        arr.push(e.stayDate);
      });
      state.stayDateTemContract = arr
    },
    setStateSelectedReservationRooms(state,val){
      state.stateSelectedReservationRooms = val
    },
    setStateSelectedReservationRoomsByID(state, data){
      state.stateSelectedReservationRooms[data.id].bookings = data.value
    },
  },
  actions,
};

export default VMTemporaryContract;
