import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/pageNotFound'
import dashboardRouter from './modules/dashboard'
import store from '../store';
import { checkRouterPrior, checkRouterPathRoot, checkRedirect403, hasAccess } from '@/utils/permissions'
import
{
  PERSONAL_REPORT_ROUTER_SUB,
  PERSONAL_REPORT_PATH,
  PERSONAL_REPORT_PATH_SUB,
  WEB_MANAGE_PATH,
  WEB_MANAGE_PATH_SUB,
  WEB_MANAGE_ROUTER_SUB,
 BATCH_PATH, BATCH_PATH_SUB , BATCH_ROUTER_SUB , GIFT_TICKET_PATH, GIFT_TICKET_PATH_SUB, GIFT_TICKET_ROUTER_SUB }
  from '@/constants/enum'
import AltRoute from '@/utils/alt-route'

Vue.use(VueRouter)

const routes = [
  dashboardRouter,
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/auth/signin.vue'),
    beforeEnter(to, from, next)
    {
      if (localStorage.getItem('token'))
      {
        next('/')
      } else
      {
        next()
      }
    }
  },

  {
    path: '/notpage',
    component: PageNotFound,
    name: 'NotPage',
    hidden: true,
    beforeEnter(to, from, next)
    {
      if (!localStorage.getItem('token'))
      {
        next('/signin')
      } else
      {
        next()
      }
    }
  },
  {
    path: '/change-password',
    component: () =>
      import(
        '@/views/auth/changePassword.vue'
      ),
    beforeEnter: (to, from, next) =>
    {
      if (store.state.user.emailSave)
        next()
      else
        next({ path: 'signin' })

    }
  },
  {
    path: '/confirm-mfa-staff',
    name: 'confirmMfaStaff',
    component: () =>
      import(
        '@/views/auth/confirmMFAStaff'
      ),
    beforeEnter: (to, from, next) =>
    {
      if (store.state.user.emailSave)
        next()
      else
        next({ path: 'signin' })

    }

  },
  {
    path: '/reset-password-staff',
    name: 'reset-password-staff',
    component: () =>
      import(
        '@/views/auth/resetPasswordStaff'
      ),
  },
  {
    path: '*',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// check redirect role,permission
router.beforeEach((to, from, next) =>
{
  let routerPathRoot = ''
  let countPath = 0
  // begin check redirect route path root: "/" with permission, role
  if (to.path === '/')
  {
    countPath = checkRedirect403()
    if (countPath === 0)
    {
      // rediect page 403
      return next({ name: 'NotPage', query: { show: true } })
    } else
    {
      routerPathRoot = checkRouterPathRoot()
      if (routerPathRoot)
      {
        return next(routerPathRoot)
      }
    }
  }
  // end check redirect route path root: "/" with permission, role

  // begin check router prior with permission, role
  let routerCurrent = ''
  if (to.path === PERSONAL_REPORT_PATH || to.path === PERSONAL_REPORT_PATH_SUB)
  {
    routerCurrent = checkRouterPrior(PERSONAL_REPORT_PATH, PERSONAL_REPORT_ROUTER_SUB)
    if (routerCurrent)
    {
      return next(routerCurrent)
    }
  }
  if (to.path === WEB_MANAGE_PATH || to.path === WEB_MANAGE_PATH_SUB)
  {
    routerCurrent = checkRouterPrior(WEB_MANAGE_PATH, WEB_MANAGE_ROUTER_SUB)
    if (routerCurrent)
    {
      return next(routerCurrent)
    }
  }
  if (to.path === BATCH_PATH || to.path === BATCH_PATH_SUB)
  {
    routerCurrent = checkRouterPrior(BATCH_PATH, BATCH_ROUTER_SUB)
    if (routerCurrent)
    {
      return next(routerCurrent)
    }
  }
  if (to.path === GIFT_TICKET_PATH || to.path === GIFT_TICKET_PATH_SUB)
  {
    routerCurrent = checkRouterPrior(GIFT_TICKET_PATH, GIFT_TICKET_ROUTER_SUB)
    if (routerCurrent)
    {
      return next(routerCurrent)
    }
  }
  if (!hasAccess(to))
  {
    return next(getAltRoute(to))
  }
  // end check router prior with permission, role
  return next()
})

export function isRouteAnyOf (routes, currentRoute) {
  if (!currentRoute) {
    currentRoute = router.currentRoute
  }

  for (let route of routes) {
    route = router.resolve(route).route
    if (route.path === currentRoute.path) {
      return true
    }
  }
  return false;
}

export function getAltRoute (baseRoute) {
  const altRoute = AltRoute[baseRoute.name]
  if (altRoute) {
    return {
      name: altRoute,
      params: { ...baseRoute.params },
      query: { ...baseRoute.query }
    }
  } else {
    return { name: 'NotPage' }
  }
}

export function pushAltRoute () {
  const route = getAltRoute(router.currentRoute)
  router.push(route)
}

export default router
