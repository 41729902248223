export const TRANSFER_CONTRACT = `
mutation (
  $contractId: Int! #契約ID
  $toClientId: Int! #譲渡先の顧客ID
  $reason: TransferContractReason! #理由、「相続」のボタンが押されたら "Inherit"、「譲渡」の場合は"Transfer"
) {
  transferContract(
    contractId: $contractId
    toClientId: $toClientId
    reason: $reason
  )
}
`

export const CLIENT_BASIC_INFO = `
query(
  $id: Int!
) {
  clientBasicInfo(
    id: $id
  ) {
    name
    id
    }
}
`