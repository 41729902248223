import actions from "./action";

const Facility = {
    state: {
      totalWebRequestlist: 0,
      listFilter: {
        skip: null,
        take: null
      },
      webrequestlistFacility:[],
      keywordFacility:'',
      handledFacility:false,
      statusFacility: false,
      webReqEditFacility: {
        client: {
          type: null
        }
      }
    },
    getters: {
      getTotalWebRequestList (state) {
        return state.totalWebRequestlist
      },
      webrequestlistFacility(state){
        return state.webrequestlistFacility
      },
      keywordFacility(state){
        return state.keywordFacility
      },
      handledFacility(state){
        return state.handledFacility
      },
      webReqEditFacility(state){
        return state.webReqEditFacility
      },
    },
    mutations: {

      setWebRequestListTake (state, take) {
        state.listFilter.take = take
      },
      setWebRequestListSkip (state, skip) {
        state.listFilter.skip = skip
      },
      setTotalWebRequestList (state, total) {
        state.totalWebRequestlist = total
      },

      setWebrequestlistFacility(state,data){
        state.webrequestlistFacility = data
      },
      setKeywordFacility(state,val){
        state.keywordFacility = val
      },
      setHandledFacility(state,val){
        state.handledFacility = val
        if(val) {
          state.statusFacility = null
        } else {
          state.statusFacility = false
        }
      },
      setWebReqEditFacility(state,data){
        state.webReqEditFacility = data
    },
    },
    actions,
}

export default Facility;
