import { apolloClient , viewMutate } from '@/plugins/apollo';
import {
  CREATE_POINT_EXCHANGE,
  POINT_EXCHANGE_PRODUCT_LIST,
 GET_POINT_EXCHANGE_PRODUCT , CREATE_POINT_EXCHANGE_PRODUCT, DELETE_POINT_EXCHANGE_PRODUCT, UPDATE_POINT_EXCHANGE_PRODUCT } from '@/api/graphql/customer/response/detail/ticketsBuy/ticketsBuy.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async ticketsBuy({ commit, rootState }, data) {
    const finishData = {
      clientId: data.clientId,
      contractId: data.contractId,
      name: data.name,
      memberUserId: data.memberUserId,
      orders: {
        productId: data.productId,
        quantity: data.quantity,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_POINT_EXCHANGE}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data);
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
        })
        .catch(error => {
          // commit('setAlertError', rootState.alert.error.messages.createError, {
          //   root: true,
          // });
          setCopyErrorTextCommit(commit, CREATE_POINT_EXCHANGE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async pointExchangeProductList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${POINT_EXCHANGE_PRODUCT_LIST}`,
          fetchPolicy: 'no-cache',
          variables: {
            onSale: data?.onSale ?? true
          }
        })
        .then(data => {
          commit(
            'setPointExchangeProductList',
            data.data.pointExchangeProductList,
          );
          resolve(data.data.pointExchangeProductList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, POINT_EXCHANGE_PRODUCT_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getPointExchangeProduct({commit} , id) {
    commit('setLoadingOverlayShow');
    try {
      const result = await apolloClient
        .query({
          query: gql`${GET_POINT_EXCHANGE_PRODUCT}`,
          variables: { id },
          fetchPolicy: 'no-cache'
        })

      return result.data.pointExchangeProduct
    } finally {
      commit('setLoadingOverlayHide');
    }
  },

  async createPointExchangeProduct({commit}, data) {
    commit('setLoadingOverlayShow');
    try {
      const result = await apolloClient
        .query({
          query: gql`${CREATE_POINT_EXCHANGE_PRODUCT}`,
          variables: {
            name: data.name,
            showMember: data.showMember,
            tvpPoint: data.tvpPoint,
            spPoint: data.spPoint,
            fpPoint: data.fpPoint,
            email: data.emailAddress,
            companyName: data.companyName,
            saleStartAt: data.saleStartAt,
            saleEndAt: data.saleEndAt,
          },
          fetchPolicy: 'no-cache'
        })

      return result.data.createPointExchangeProduct
    } finally {
      commit('setLoadingOverlayHide');
    }
  },

  async updatePointExchangeProduct({commit}, data) {
    return await viewMutate({
      query: gql`${UPDATE_POINT_EXCHANGE_PRODUCT}`,
      variables: {
        id: data.id,
        showMember: data.showMember,
        email: data.emailAddress,
        companyName: data.companyName,
        saleStartAt: data.saleStartAt,
        saleEndAt: data.saleEndAt,
      },
      returnKey: 'updatePointExchangeProduct'
    });
  },

  async deletePointExchangeProduct({commit}, id) {
    return await viewMutate({
      query: gql`${DELETE_POINT_EXCHANGE_PRODUCT}`,
      variables: { id },
      returnKey: 'deletePointExchangeProduct'
    });
  }
};
