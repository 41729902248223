import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST } from '@/api/graphql/ExperienceLodging/experienceLodging.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getSearchClientListExp({ commit, state }, {take, skip}) {
    const finishData = {
      filter: {
        trialStay: {
          fromDate: state.fromDateSelected,
          isCancelled: state.canceledReservationSelected,
        },
        picIds: state.picSelect,
      },
      orderBy: {
        fieldName: 'client.id',
        direction: 'DESC',
      },
      pagination: {
        take,
        skip,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('searchClientListExp', data.data.searchClientList.items);
          resolve(data.data.searchClientList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
