import { apolloClient } from '@/plugins/apollo';
import {
  WEB_REQUEST_IDS_LIST,
  SUGGEST_CLIENT_PIC,
  CONFIRM_CLIENT_PIC,
} from '@/api/graphql/dashboard/webPageManage/documentrequest/DuplicateExistingCustomers/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionWebRequestIds({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${WEB_REQUEST_IDS_LIST}`,
        })
        .then(data => {
          const webRequestIds = [];

          data.data.webRequestList.items.forEach(element => {
            webRequestIds.push(element.id);
          });
          commit('setWebRequestIds', webRequestIds);
          resolve(webRequestIds);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, WEB_REQUEST_IDS_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionSuggestClientPic({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${SUGGEST_CLIENT_PIC}`,
          variables: data,
        })
        .then(data => {
          // commit('setSuggestClientPIC', data.data.suggestClientPIC);
          //   commit('setAlertSuccess', '成功した活動履歴の登録', { root: true });
          resolve(data.data.suggestClientPIC);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SUGGEST_CLIENT_PIC, data, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionConfirmClientPic({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CONFIRM_CLIENT_PIC}`,
          variables: data,
        })
        .then(data => {
          commit('setAlertSuccess', '更新しました。', { root: true });
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONFIRM_CLIENT_PIC, data, error.graphQLErrors)
          // if (error.graphQLErrors.length === 0) {
          //   commit('setAlertError', '営業担当決定失敗した。', { root: true });
          // }
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
