export const UPDATE_STAFF_GROUP = `
mutation (
  $id: Int!
  $name: String!
) {
  updateStaffGroup(id: $id, name: $name) {
    __typename
  }
}
`
export const DELETE_STAFF_GROUP = `
mutation ($id: Int!) {
  deleteStaffGroup(id: $id) {
    __typename
  }
}
`
