import actions from "./actions";

const DuplicateExistingCustomers = {
    state: {
      total: null,
      webRequestlistDocumentRequestlist: [],
      webRequestlistOldCustomerlist: [],
      hasPicDocumentReq:false,
      hasPicOldCustomerReq:false,
      webRequestlistDocumentRequestlistNull: [],
      webRequestlistOldCustomerlistNull: [],
      webRequestlistDocumentRequestlistFalse: [],
      webRequestlistOldCustomerlistFalse: [],
      webrequestNotIscopanyDocumentRequestInfo:[],
      webrequestNotIscopanyOldCustomerInfo:[],
      picNameDocumentRequest:[],
      picNameOldCustomer:[],
      clientRemarks:null,
      clientPicID:null
    },
    getters: {
      getTotal (state) {
        return state.total
      },
      webRequestlistDocumentRequestlistNull(state){
        return state.webRequestlistDocumentRequestlistNull
      },
      getClientRemarks(state){
        return state.clientRemarks
      },
      getClientPicID(state){
        return state.clientPicID
      },
      webRequestlistDocumentRequestlistFalse(state){
        return state.webRequestlistDocumentRequestlistFalse
      },
      webRequestlistOldCustomerlistFalse(state){
        return state.webRequestlistOldCustomerlistFalse
      },
      hasPicDocumentReq(state){
        return state.hasPicDocumentReq
      },
      HasPicOldCustomerReq(state){
        return state.hasPicOldCustomerReq
      },
      webrequestNotIscopanyDocumentRequestInfo(state){
        return state.webrequestNotIscopanyDocumentRequestInfo
      },
      WebrequestNotIscopanyOldCustomerList(state,data){
        return state.webrequestNotIscopanyOldCustomerInfo
      },
      picNameDocumentRequest(state){
        return state.picNameDocumentRequest
      },
      picNameOldCustomerRequest(state){
        return state.picNameOldCustomer
      },
    },
    mutations: {
      setWebRequestlistDocumentRequestlist(state,data){
        state.webRequestlistDocumentRequestlist = data
        const dataFalse = []
        data.forEach(element => {
          if(!element.client.pic) dataFalse.push(element)
        });
        state.webRequestlistDocumentRequestlistNull = data
        state.webRequestlistDocumentRequestlistFalse = dataFalse
      },
      setClientRemarks(state, data){
        state.clientRemarks = data
      },
      setClientPicID(state, data){
        state.clientPicID = data
      },
      setWebRequestlistDocumentRequestListNull(state, data){
        state.webRequestlistDocumentRequestlistNull = data
      },
      setWebRequestlistDocumentRequestListFalse(state, data){
        state.webRequestlistDocumentRequestlistFalse = data
      },
      setTotal(state, data) {
        state.total = data
      },
      setWebRequestlistOldCustomerlist(state,data){
        // state.webRequestlistOldCustomerlist = data
        // state.webRequestlistOldCustomerlistNull = data
        state.webRequestlistOldCustomerlistFalse = data
        // state.picNameOldCustomer = data
      },
      setHasPicDocumentReq(state,data){
        state.hasPicDocumentReq = data
      },
      setHasPicOldCustomerReq(state,data){
        state.hasPicOldCustomerReq = data
      },
      setWebrequestNotIscopanyDocumentRequestList(state,data){
        state.webrequestNotIscopanyDocumentRequestInfo = data
      },
      setWebrequestNotIscopanyOldCustomerList(state,data){
        state.webrequestNotIscopanyOldCustomerInfo = data
      }
    },
    actions,
}

export default DuplicateExistingCustomers;