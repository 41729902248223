import actions from './actions';

const memberShipContract = {
  state: {
    clientId: null,
  },
  mutations: {
    setClientMemberInfo(state, clientId) {
      state.clientId = clientId;
    },
  },
  actions,
};

export default memberShipContract;
