import actions from './actions';

const vmcontractUsage = {
    state: {
       VmContractUsageList : [],
       VMConvertIntoConfirmedContract:false,
       listContractUsage: [],
    },
    getters: {
        getVmContractUsageList(state) {
            return state.VmContractUsageList;
        },
        getVMConvertIntoConfirmedContract(state) {
            return state.VMConvertIntoConfirmedContract;
        },
        getListContractUsage(state) {
            return state.listContractUsage;
        },
    },
    mutations: {
        setVmContractUsageList(state, payload) {
            state.VmContractUsageList = payload
        },
        setVMConvertIntoConfirmedContract(state, payload) {
            state.VMConvertIntoConfirmedContract = payload
        },
        setListContractUsage(state, data) {
            state.listContractUsage = data
        },
    },
    actions,
};

export default vmcontractUsage;
