import gql from 'graphql-tag';

export const CONTRACTLIST_CUSTOMER_MEMBERSHIP_CONTRACT = `
query( $filter: ContractListFilterInput, $pagination: PaginationInput, $orderBy: [OrderByInput!]){
  contractList(
    filter: $filter
    orderBy: $orderBy
    pagination: $pagination
  ) {
    total
    items {
      id
      status 
      productType { 
        name
        code
      } 
      contractNumber 
      phase { name } 
      startDate 
      endDate
      computedFacility {
        facilityName
      }
    }
  }
}`;

export const CLIENT_ANNUALFEE_LIST_CUSTOMER_MEMBERSHIP_CONTRACT = `
query($filter:ClientAnnualFeeListFilterInput!, $pagination: PaginationInput){
    clientAnnualFeeList(
      filter: $filter
      pagination: $pagination
    ) {    
      total
      items {
        id 
        year 
        month 
        processedAt 
        remarks 
        annualFee 
        annualFeeTax 
        membershipFee 
        membershipFeeTax 
        managementFee 
        managementFeeTax 
        hotspringFee 
        hotspringFeeTax
      }
    }
  }`;

export const CREATESUBMEMBER_CUSTOMER_MEMBERSHIP_CONTRACT = `
  mutation(
    $clientId: Int!
    $data: CreateSubMemberInput!
  ) {
    createSubMember(
      clientId: $clientId
      data: $data
    ){
      id
      subMemberType {id name}
      name 
      nameKana 
      birthday 
      relationship { 
        id 
        name 
      }
      sex 
      memberUser { 
        email 
      }
      postalCode 
      prefecture 
      locality 
      address1 
      address2 
      tel 
      email 
      remarks 
    }
  }`;

export const DELETE_SUB_MEMBER = `
  mutation ($id: Int!) {
    deleteSubMember(id: $id)
  }
`;

export const SEND_NEW_FAMILY_MEMBER_REGISTERED_EMAIL = `
  mutation ($subMemberId: Int!) {
    sendNewFamilyMemberRegisteredEmail(subMemberId: $subMemberId)
  }
`
export const SUB_MEMBER_DELETE_WARNING = `
  query ($subMemberId: Int!) {
    subMemberDeleteWarning(subMemberId: $subMemberId) {
      warnings { message }
    }
  }
`

export const CLIENTMEMBERINFO_CUSTOMER_MEMBERSHIP_CONTRACT = `
query($clientId: Int!) {
  clientMemberInfo(
    id: $clientId
  ) {
    id
    isCompany 
    memberId 
    memberUser { 
      email
    } 
    requestMemberMagazineMail 
    requestBrochureMail
    accommodationFeePayment
    facilityFeePayment 
    setupFeePayment 
    optionalCleaningPayment 
    taxPayment
    otherServicePayment
    subMembers { 
      id 
      subMemberType {
        id
        name
      } 
      name 
      nameKana 
      birthday 
      relationship { 
        id 
        name 
      }
      sex 
      memberUser { 
        email 
      }
      postalCode 
      prefecture 
      locality 
      address1 
      address2 
      tel 
      email 
      remarks 
    }
  }
}`;

export const ENUM_PERSONRELATIONSHIP_LIST_CUSTOMER_MC = `
query{
  enumPersonRelationshipList {
    id
    name
  }
}`;

export const ENUM_ENUMPREFECTURE_LIST_CUSTOMER_MC = `
query{
  enumPrefectureList{
    id
    name
  }
}
`;

export const TVPHISTORYLIST_LIST_CUSTOMER_MC = `
query ($clientId: Int!, $pagination: PaginationInput) {
  tvpHistoryList (
    clientId: $clientId
    pagination: $pagination
  ) {
    items {
      remarks
      createdAt 
      changeReason 
      pointChange
      pointsAfter
      checkInDate
      checkOutDate
      facilityName
    }
    total 
    hasMore 
  }
}
`;

export const CHANGETVP_CUSTOMER_MC = gql`
query ($clientId: Int!) {
  tvpHistoryList (
    clientId: $clientId
  ) {
    items {
      createdAt 
      changeReason 
      pointChange
      pointsAfter 
    }
    total 
    hasMore 
  }
}
`;

export const CREATE_POINT_EXCHANGE_CUSTOMER_MC = `
mutation(
  $clientId: Int! 
  $orders: [CreatePointExchangeOrderInput!]!
) {
  createPointExchange(
    clientId: $clientId
    orders: $orders
  )
}
`;

export const DELETEANNUALFEE_CUSTOMER_MC = `
mutation (
  $id: Int!
) {
  deleteAnnualFee(id: $id) {
    id
    processedAt
    remarks
    year
    month
    annualFee
    membershipFee
    managementFee
    hotspringFee
    annualFeeTax
    membershipFeeTax
    managementFeeTax
    hotspringFeeTax
  }
}
`;

export const POINTEXCHANGELIST_CUSTOMER_MC = `
query (
  $filter: PointExchangeListFilterInput
  $pagination: PaginationInput
) {
  pointExchangeList(
    filter: $filter
    pagination: $pagination
  ) {
    total
    items {
      id
      name
      pointExchangeProduct {
        name 
      }
      quantity 
      confirmedAt 
      emailHistory {
        sentAt
      } 
    }
  }
}
`;

export const CREATEANNUALFEE_CUSTOMER_MC = `
mutation (
  $clientId: Int!
  $remarks: String!
  $year: Int!
  $month: Int!
  $annualFee: Int!
  $membershipFee: Int!
  $managementFee: Int!
  $hotspringFee: Int!
) {
  createAnnualFee(
    clientId: $clientId
    remarks: $remarks
    year: $year
    month: $month
    annualFee: $annualFee
    membershipFee: $membershipFee
    managementFee: $managementFee
    hotspringFee: $hotspringFee
  ) {
    id
    processedAt
    remarks
    year
    month
    annualFee
    membershipFee
    managementFee
    hotspringFee
    annualFeeTax
    membershipFeeTax
    managementFeeTax
    hotspringFeeTax
  }
}`;

export const UPDATEMEMBERUSER_CUSTOMER_MC = `
mutation (
  $clientId: Int! 
  $mainUser: MainUserInput!
  $clientInfo: ClientInfoInput!
  $subMembers: [SubMemberInput!]!
) {
  updateMemberUser(
    clientId: $clientId
    mainUser: $mainUser
    clientInfo: $clientInfo
    subMembers: $subMembers
  ) {
    __typename
  }
}
`
export const CREATE_VM_TENTATIVE_CONTRACT = `
mutation (
  $clientId: Int!
) {
  createVmTentativeContract(clientId: $clientId) {
    id
    tentative
  }
}
`;

export const CREATE_VS_TENTATIVE_CONTRACT = `
mutation (
    $clientId: Int!
  ) {
    createVsContract(
      clientId: $clientId
  ){
    id
  }
}
`

export const UPDATE_MEMBER_USER = `
  mutation (
    $clientId: Int!
    $loginEmail: String
    $requestMemberMagazineMail: Boolean!
    $requestBrochureMail: Boolean!
    $facilityFeePayment: FeePaymentType
    $setupFeePayment: FeePaymentType
    $optionalCleaningPayment: FeePaymentType
    $taxPayment: FeePaymentType
    $otherServicePayment: FeePaymentType
    $subMembers: [SubMemberInput!]!
  ) {
    updateMemberUser(
      clientId: $clientId
      mainUser: {
        email: $loginEmail
      }
      clientInfo: {
        requestMemberMagazineMail: $requestMemberMagazineMail
        requestBrochureMail: $requestBrochureMail
        facilityFeePayment: $facilityFeePayment
        setupFeePayment: $setupFeePayment
        optionalCleaningPayment: $optionalCleaningPayment
        taxPayment: $taxPayment
        otherServicePayment: $otherServicePayment
      }
      subMembers: $subMembers
    ) {
      __typename
    }
  }
`
export const SALES_TAX = `
query (
  $price: Int!
  $taxType: TaxType!
  $date: DateString
) {
  salesTax(
    price: $price
    salesTaxType: $taxType
    chargeDate: $date
  ) {
    totalPrice
    salesTax
    priceWithoutSalesTax
  }
}`











