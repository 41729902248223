export const EX_CHANGE_DEPOSITED_RIGHT = `
mutation (
	$data: ExchangeDepositedRightInput!
) {
  exchangeDepositedRight(data: $data) {
    __typename
    booking { id }
  }
}
`

export const MW_WEEK_CALENDAR_LIST = `
query ($fromDate:DateString, $toDate:DateString, $roomTypeId: Int!) {
	mwWeekCalendarList(fromDate: $fromDate, toDate: $toDate, roomTypeId: $roomTypeId) {
    weekNo
    facilityId
  }
}
`