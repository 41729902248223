import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST } from '@/api/graphql/myPage/CustomerInCharge/AdditionalPurchase';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getSearchClientList({ commit, state }, {take, skip, orderBy}) {
    const finishData = {
      filter: {
        furtherPurchaseRates: state.furtherPurchaseRateSelected,
        picIds: state.picSelected,
        lastCampaignId: state.lastCampaignApplyResponseSelected,
      },
      load: {
        loadLastAction: true,
        loadLastCampaign: true,
      },
      orderBy: orderBy,
      pagination: {
        take,
        skip
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('searchClientList', data.data.searchClientList.items);
          resolve(data.data.searchClientList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumAdditionalPurchase({ commit, state }) {
    const finishData = {
      filter: {
        furtherPurchaseRates: [21, 31, 41, 11, 51, 61],
        picIds: null,
        lastCampaignId: null,
      },
      load: {
        loadLastAction: true,
        loadLastCampaign: true,
      },
      orderBy: {
        fieldName: 'client.id',
        direction: 'DESC',
      },
      pagination: {
        take: null,
        skip: null,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          const arr = data.data.searchClientList.items
          resolve(arr);
        })
        .catch(error => {
          // setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          // handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
