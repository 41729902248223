import actions from './actions';

const clientSearchPaginatedResponse = {
  state: {
    clientSearchPaginatedResponse:[],
    clientChangeHistoryListChangeHistory: []
  },
  getters:{
    clientSearchPaginatedResponse(state){
      return state.clientSearchPaginatedResponse; 
    },
    clientChangeHistoryListChangeHistory(state){
      return state.clientChangeHistoryListChangeHistory; 
    }
  },
  mutations: {
    clientSearchPaginatedResponse(state, arr) {
      state.clientSearchPaginatedResponse = arr;
    },
    setClientChangeHistoryList(state, data) {
      state.clientChangeHistoryListChangeHistory = data
    },
  },
  actions,
};

export default clientSearchPaginatedResponse;
