import jwtDecode from 'jwt-decode';
import actions from './actions';

const user = {
  state: {
    email: localStorage.getItem('email') || '',
    name: localStorage.getItem('name') || '',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    refreshTokenSave: localStorage.getItem('refreshToken') || '',
    tokenSave:'',
    emailSave:'',
    roleAdminUser: false,
    permissionUser: {}
  },

  getters: {
    getName(state) {
      return state.name;
    },
    getEmail(state) {
      return state.email;
    },
    getToken(state) {
      return state.token;
    },
    getRoleUser (state) {
      return state.roleAdminUser
    },
    getPermissionUser (state) {
      return state.permissionUser
    },
    tokenPayload (state) {
      return state.token && jwtDecode(state.token)
    },
    getUserId: (_state, getters) => getters.tokenPayload.id
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      localStorage.setItem('token', token);
      Object.assign(state, { token });
    },
    SET_RFTOKEN: (state, refreshToken) => {
      localStorage.setItem('refreshToken', refreshToken);
      state.refreshTokenSave = refreshToken
      Object.assign(state, { refreshToken });
    },
    SET_USER: (state, payload) => {
      localStorage.setItem('email', payload.email);
      Object.assign(state, payload);
    },
    SET_TOKEN_AND_EMAIL(state, payload){
      state.tokenSave = payload.token
      state.emailSave = payload.email
    },
    setRoleAdminUser (state, roleAdminUser) {
      state.roleAdminUser = roleAdminUser
    },
    setPermissionUser (state, permissionUser) {
      state.permissionUser = permissionUser
    }
  },
  actions,
};

export default user;
