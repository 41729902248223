import gql from 'graphql-tag';

export const STAFF_LIST = gql`
query {
  # showDisabled:true
  staffList(crmRep: true) {id name}
}
`

export const ENUM_PRURCHASE_RATE_LIST = gql`
query{
  enumPurchaseRateList{
    id
    name
  }
}
`

export const ENUM_SEASON_INTERST_LIST = gql`
query  {
  enumSeasonInterestList{
    id
    name
  }
}
`
export const FACILITY_LIST = `
query { facilityList(filter: { types: [TVS, CNV] }) { id name type} }
`

export const ENUM_NEGOTIATION_EXIT_REASON_LIST = gql`
query  {
  enumNegotiationExitReasonList{
    id
    name
  }
}
`
export const CLIENT_CHANGE_HISTORY_LIST = gql`
query (
  $filter: ClientChangeHistoryListFilterInput
  $pagination: PaginationInput!
) {
  clientChangeHistoryList(
    filter: $filter
    pagination: $pagination
  ) {
    items {
      valueAfter 
      changedAt 
    }
  }
}
`
export const UPDATE_CLIENT = gql`
mutation (
  $clientId: Int! # 顧客ID
  $doNotContact: Boolean! #折衝不可
  $purchaseRateId: Int #購入評価ID
  $furtherPurchaseRateId: Int #買い増し評価ID
  $renewPurchaseRateId: Int #更新評価ID
  $negotiationExitReason: String #検討離脱理由
  $picId: Int #営業担当者ID
  $lastResponseDate: DateString #反響日
  $seasonsInterested: [String!] #希望シーズン
  $facilitiesInterested: [String!] #興味のある施設
) {
  updateClient(
    id: $clientId, 
    data: {
      doNotContact: $doNotContact
      purchaseRateId: $purchaseRateId
      furtherPurchaseRateId: $furtherPurchaseRateId
      renewPurchaseRateId: $renewPurchaseRateId
      negotiationExitReason: $negotiationExitReason
      picId: $picId
      lastResponseDate: $lastResponseDate
      seasonsInterested: $seasonsInterested
      facilitiesInterested: $facilitiesInterested
    }
  ) {
    id
  }
}
 `

export const BROCHURES = gql`
query ($filter: WebRequestListFilterInput)
{
  brochures: webRequestList (
    filter: $filter
  ) {
    items {
      id 
      createdAt
      forVs 
      forVm 
      remarks 
      campaign { 
        id 
        name
      }
      medias {
        campaign { 
          id 
          name 
        }
      }
    }
  }

}
`

export const VISITS = gql`
query ($filter: WebRequestListFilterInput)
{
  visits: webRequestList (
    filter: $filter
  ) {
    items {
      id
      createdAt 
      handledAt 
      firstDate
      firstTime 
      secondDate 
      secondTime 
    }
  }
}
`

export const ONLINES = gql`
query ($filter: WebRequestListFilterInput)
{
  onlines: webRequestList ( 
    filter: $filter
  ) {
    items { 
      id 
      createdAt 
      handledAt 
      firstDate 
      firstTime 
      secondDate 
      secondTime
    }
  }
}
`

export const TRIALS = gql`
query ($filter: WebRequestListFilterInput)
{
  trials: webRequestList ( 
    filter: $filter
  ) {
    items {
      id 
      createdAt
      handledAt 
      firstDate 
      firstTime 
      firstCheckInDate 
			firstStayDays 
			secondCheckInDate 
			secondStayDays 
      firstFacility {
        name 
      }
      secondDate 
      secondTime 
      secondFacility {
        name 
      }
    }
  }
}
`

export const LIST_SELECT_TABLE_END = gql`
query ($filter: CampaignApplyResponseListFilterInput!)         {
  campaignApplyResponseList (filter: $filter) {
    items {
      campaign {name id} 
    }
  }
}
`

export const LIST_TABLE_END = gql`
query ($filter: CampaignApplyResponseListFilterInput!)         {
  campaignApplyResponseList (filter: $filter)  {
    items {
      campaign {
        id
        name 
        groups 
        note
        attachment1 
        attachment2 
        attachment3 
        attachment1Url 
        attachment2Url 
        attachment3Url
      }
      responseAt 
      createdAt 
    }
  }
}
`

export const CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT = gql`
mutation (
  $clientId: Int! 
  $forVs: Boolean! 
  $forVm: Boolean! 
  $remarks: String 
) {
  createWebBrochureRequestToClient(
    clientId: $clientId
    forVs: $forVs
    forVm: $forVm
    remarks: $remarks
  ) {
    importMethod
  }
}
`
export const VMOCCUPANCYLIST_TEM_CONTRACT = `
query (
  $fromYear: Int! 
  $years: Int! 
  $roomTypeId: Int! 
  $fromMonth: Int! 
  $fromDate: Int! 
  $toMonth: Int! 
  $toDate: Int! 
) {
  vmOccupancyList(
    fromYear: $fromYear
    years: $years
    roomTypeId: $roomTypeId
    fromMonth: $fromMonth
    fromDate: $fromDate
    toMonth: $toMonth
    toDate: $toDate
  ) {
    years { 
      year 
      days { 
        stayDate 
        roomsCount 
        availableRoomsCount 
        bookedVmRoomsCount 
        ratio 
      }
    }
  }
}
`
export const ROOMTYPELIST_TEM_CONTRACT = `
query($facilityId: Int!){
  roomTypeList(
    facilityId: $facilityId
  ){
    id
    name
  }
}
`
