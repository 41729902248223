const getters = {
  email: state => state.user.email,
  name: state => state.user.name,
  addNewDocReq: state => state.addNewDocReq.addNewDocReq,
  addUseCancel: state => state.addUseCancel.addUseCancel,
  ticketsBuy: state => state.ticketsBuy.ticketsBuy,
  alertSuccess: state => state.alert.success.type,
  alertError: state => state.alert.error.type,
  errorDialog: state => state.alert.errorDialog.type,
  basicInfors: state => state.basicInfors.basicInfors,
  contractAll: state => state.contractAll.contractAll,
  customerDetailListAll: state => state.customerDetail.list,
  bookingTicketTypeList: state => state.bookingTicket.bookingTicketTypeList,
  loadingOverlay: state => state.alert.loading,
  bookingTicketTypeDetail: state => state.bookingTicket.bookingTicketTypeDetail,
  roleAdminUserCurrent: state => state.user.roleAdminUser,
  permissionUserCurrent: state => state.user.permissionUser,
  hasReadPermission: state => key => ['R', 'W'].includes(state.user.permissionUser[key]?.toUpperCase())
  // contractPointSummary: state => state.spuSageStatus.contractPointSummary,
  // contractPointMovementList: state => state.spuSageStatus.contractPointSummary,
};
export default getters;
