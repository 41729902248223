import actions from './actions'

const groupCreateAdd = {
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions
}

export default groupCreateAdd
