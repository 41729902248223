import { apolloClient } from '@/plugins/apollo';
import {
  CONTRACT_LIST,
  BOOKING_LIST,
  CLIENT_CHANGE_HISTORY_LIST,
  CLIENT_MEMBER_INFO,
  CLIENT_BASIC_INFO,
  CANCEL_WAITLIST,
  PAGINATE_CLIENT_COMMENT,
  // CREATE_CLIENT_COMMENT_CUSTOMER_DETAIL,
} from '@/api/graphql/customer/CustomerDetail';
import {
  CREATE_CLIENT_COMMENT_MY_PAGE
} from "@/api/graphql/myPage/myPage/index.js"

import { BOOKING_TICKET_TYPE_LIST } from '@/api/graphql/bookingTicketType';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async fetchBookingList({ commit }, {clientId, take, skip, orderBy}) {
    const finishData = {
      clientId,
      pagination: {
        take,
        skip
      },
      orderBy: orderBy,
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          commit('setCustomerDetailBookingList', data.data.bookingList.items);
          resolve(data.data.bookingList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchContractList({ commit }) {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      pagination: {
        take: 500,
        skip: 0
      }
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          commit('setCustomerDetailContractList', data.data.contractList.items);
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchBookingTicketTypeList({ commit }) {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      take: 20,
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${BOOKING_TICKET_TYPE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          resolve(data);
          commit(
            'setCustomerDetailBookingTicketTypeList',
            data.data.bookingTicketTypeList.items,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, BOOKING_TICKET_TYPE_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchClientBasicInfo({ commit }) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id),
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_BASIC_INFO}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          commit('setCustomerDetailClientBasicInfo', data.data.clientBasicInfo);
          resolve(data.data.clientBasicInfo);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_BASIC_INFO, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchClientMemberInfo({ commit }) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id),
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_MEMBER_INFO}`,
          variables: finishData,
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          resolve(data);
          commit(
            'setCustomerDetailClientMemberInfo',
            data.data.clientMemberInfo,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_MEMBER_INFO, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchClientChangeHistoryList({ commit }, {take, skip}) {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      pagination: {
        take,
        skip
      }
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_CHANGE_HISTORY_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          commit(
            'setCustomerDetailClientChangeHistoryList',
            data.data.clientChangeHistoryList.items,
          );
          resolve(data.data.clientChangeHistoryList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_CHANGE_HISTORY_LIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async fetchCancelWaitList({ commit }) {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      pagination: {
        take: 500,
        skip: 0
      }
    }
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CANCEL_WAITLIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          resolve(data);
          commit('setCustomerDetailCancelWaitList', data.data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CANCEL_WAITLIST, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async getPaginateClientComment({ commit }, finishData) {
    commit('setLoadingOverlayShow', { root: true });
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${PAGINATE_CLIENT_COMMENT}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setLoadingOverlayHide', { root: true });
          commit(
            'setPaginateClientComment',
            data.data.paginateClientComment.items,
            );
          commit('setTotalCmt', data.data.paginateClientComment.total)
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, PAGINATE_CLIENT_COMMENT, finishData, error.graphQLErrors)
          commit('setLoadingOverlayHide', { root: true });
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async createClientCommentCustomerDetail({ dispatch, commit, state }, id) {
    const finishData = {
      clientId: parseInt(id),
      comment: state.list.commentCustomerDetail,
    }
    commit('setLoadingOverlayShow', { root: true });
    return apolloClient
      .mutate({
        mutation: gql`${CREATE_CLIENT_COMMENT_MY_PAGE}`,
        variables: finishData,
      })
      .then(data => {
        commit('setLoadingOverlayHide', { root: true });
        // dispatch('getPaginateClientComment', id);
      })
      .catch(error => {
        setCopyErrorTextCommit(commit, CREATE_CLIENT_COMMENT_MY_PAGE, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true });
        handlError(commit, error.graphQLErrors);
      });
  },
};
