import { apolloClient } from '@/plugins/apollo';
import { CLIENT_MEMBER_INFO } from '@/api/graphql/customer/response/detail/membershipContract/contract.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientMemberInfo({ state, commit, rootState }, clientId) {
    const finishData = {
      clientId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_MEMBER_INFO}`,
          variables: finishData,
        })
        .then(data => {
          resolve(data.data.clientMemberInfo);
          // commit(
          //   'setAlertSuccess',
          //   rootState.alert.success.messages.createSuccess,
          //   { root: true },
          // );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_MEMBER_INFO, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
