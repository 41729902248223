import { apolloClient } from '@/plugins/apollo'
import {
  CREATE_STAFF_GROUP
} from '@/api/graphql/manager/groupAdd/groupAdd.js'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async groupCreate ({ commit, state, rootState,dispatch }, dataAddGroup) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_STAFF_GROUP}`,
          variables: dataAddGroup,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          dispatch('groupList')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true }
            )
          resolve(data)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_STAFF_GROUP, dataAddGroup, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }

}
