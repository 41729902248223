import actions from './actions';

const giftTicket = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions,
};

export default giftTicket;
