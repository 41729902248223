import gql from 'graphql-tag';

export const FACILITY = `
query (
  $id: Int!
) {
  facility(id: $id) {
    facilityHpUrl # String HP施設情報画面URL
    nameWeb #String 表示施設名
    nameAbbr #String 施設名（略称）
    tags # [String!]! タグ、 nullable!
    mainImageUrl #String メイン画像URL
    roomTypes {
      id #Int! 部屋タイプID
      name
      bookingRemarks #String 予約時備考
    }
    otherNotes #String その他メモ
  }
}
`

export const FACILITY_LIST = `
query{
  facilityList{
    id
    name
  }
}
`

export const UPDATE_PUBLIC_FACILITY_INFO = `
mutation (
  $id: Int! 
  $nameWeb: String 
  $nameAbbr: String 
  $facilityHpUrl: String 
  $tags: [String!] 
  $mainImageUrl: String
  $roomTypes: [UpdatePublicRoomTypesInput!]! 
  $otherNotes: String 
) {
  updatePublicFacilityInfo(
    facilityId: $id 
    data: {
      nameWeb: $nameWeb
      nameAbbr: $nameAbbr
      tags: $tags
      mainImageUrl: $mainImageUrl
      facilityHpUrl: $facilityHpUrl
      roomTypes: $roomTypes
      otherNotes: $otherNotes
    }
  ) {
    __typename
  }
} 
`
       
export const GENERATE_UPLOAD_URL = gql`
mutation (
  $contentType: String!
  $contentLength: Int!
  $fileName: String
) {
  generateUploadUrl(
    type: "facility/mainImage"
    contentType: $contentType
    contentLength: $contentLength
    fileName: $fileName
  ) {
    uploadUrl
    fileUrl #このURLがメイン画像のURLなので送って下さい
  }
}
`
