import actions from './actions'

const exChangeReservation = {
  state: {
    exChangeReservation: {},
    selectedReservationRooms: {}
  },
  getters: {
    getSelectedReservationRooms(state) {
      return state.selectedReservationRooms
    }
  },
  mutations: {
    setExChangeReservation (state, payload) {
      state.exChangeReservation = payload.exChangeReservation
    },

    setSelectedReservationRooms (state, payload) {
      state.selectedReservationRooms = payload.selectedReservationRooms
    }
  },
  actions
}

export default exChangeReservation
