import { apolloClient } from '@/plugins/apollo';
import { CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT,DELETE_DUPLICATED_CLIENT } from '@/api/graphql/customer/addedNewDocumentRequest/addNewDocReq.js';
// import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async createWebBrochureRequestToClient({ state, commit, rootState, dispatch }) {
    const finishData = {
      clientId: state.addNewDocReq.clientId,
      forVs: state.addNewDocReq.forVs,
      forVm: state.addNewDocReq.forVm,
      remarks: state.addNewDocReq.remarks,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('proBrochures')
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT, finishData, error.graphQLErrors)
          // commit('setAlertError', rootState.alert.error.messages.createError, {
          //   root: true,
          // });
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async deleteDuplicatedClient({ state, commit, rootState, dispatch },data) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_DUPLICATED_CLIENT}`,
          variables: data,
        })
        .then(data => {
          dispatch('proBrochures')
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_DUPLICATED_CLIENT, data, error.graphQLErrors)
          // commit('setAlertError', rootState.alert.error.messages.createError, {
          //   root: true,
          // });
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
