export const ENUM_PIC_IDS = `
  query {
    searchClientList(
      filter: {}
      orderBy: { fieldName: "pic.id", direction: "ASC" }
      pagination: { take: null, skip: null }
    ) {
      items {
        pic {
          id
          name
        }
      }
    }
  }
`;
