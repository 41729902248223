import actions from './actions';

const Applicant = {
  state: {
    totalBookinglist: 0,
    listFilter: {
      skip: null,
      take: null
    },
    clientSearchPaginatedResponseApplicant: [],
    actionWithActionType:null,
    nameApplicant: '',
    nameKanaApplicant: '',
    clientIdsApplicant: [],
    includeCompanyApplicant: true,
    includeIndividualApplicant: true,
    companyRepresentativeNameApplicant: '',
    contactAddress1Applicant: '',
    contactAddress2Applicant: '',
    contactTelApplicant: '',
    contactMobileApplicant: '',
    contactFaxApplicant: '',
    contactEmailApplicant: '',
    purchaseRatesApplicant: '',
    includeDoNotContactApplicant: null,
    picIdsApplicant: [],
    arrpicIdsApplicant: null,
    listPicIdsApplicant: [],
    contactPostalCodesApplicant: [],
    contactPrefecturesApplicant: [],
    contactLocalitiesApplicant: [],
    enumPurchaseRateListApplicant: [],
    // PurchaseRates select
    arrPurchaseRatesApplicant: [],
    listPurchaseRatesApplicant: [],
    // furtherPurchaseRates select
    arrFurtherPurchaseRates: [],
    listFurtherPurchaseRates: [],
    // renewPurchaseRates select
    arrRenewPurchaseRatesApplicant: [],
    listRenewPurchaseRatesApplicant: [],
    hasTrialStayApplicant: null,
    remarksApplicant: '',
    // campaign
    campaignListApplicant: [],
    campaignListGroupsApplicant: [],
    // --
    arrCampaignListApplicant: [],
    listCampaignListApplicant: [],
    // --
    listCampaignGroupsApplicant: [],
    itemCampaignTimesApplicant: null,
    hasActionMethodIdsTypeApplicant: 'Any',
    hasActionMethodIdsApplicant: [],
    // enumClientActionMethodList
    enumClientActionMethodListApplicant: [],
    arrEnumClientActionMethodListApplicant: null,
    listEnumClientActionMethodListApplicant: [],
    lastActionDateToApplicant: null,
    lastActionDateFromApplicant: null,
    hasProductTypeIdsIncludeExpiredApplicant: null,
    hasProductExpiringFromDateApplicant: null,
    hasProductExpiringToDateApplicant: null,
    hasProductTypeIdsTypeApplicant: 'All',
    bookingTypesListApplicant: [],
    arrBookingTypesListApplicant: null,
    listBookingTypesListApplicant: [],
    membershipCancellationDateFromApplicant: null,
    membershipCancellationDateToApplicant: null,
    membershipStatusApplicant: 'All',
    memberIdsApplicant: [],
    enumCampaignGroupListApplicant: [],
    clientContactTypeApplicant: null,
    customerLengthPDF: 0,
    enumPrefectureList: [],
    otherPublicDocTypeList: []
  },
  getters: {
    getApplicantListTake (state) {
      return state.listFilter.take
    },
    getTotalBookinglist (state) {
      return state.totalBookinglist
    },
    clientSearchPaginatedResponseApplicant(state) {
      return state.clientSearchPaginatedResponseApplicant;
    },
    // return name
    nameApplicant(state) {
      return state.nameApplicant;
    },
    // return name kana
    nameKanaApplicant(state) {
      return state.nameKanaApplicant;
    },
    // return clientIds
    clientIdsApplicant(state) {
      return state.clientIdsApplicant;
    },
    // return include company
    includeCompany(state) {
      return state.includeCompanyApplicant;
    },
    // return companyRepresentativeNameApplicant
    companyRepresentativeNameApplicant(state) {
      return state.companyRepresentativeNameApplicant;
    },
    // return contactAddress1Applicant
    contactAddress1Applicant(state) {
      return state.contactAddress1Applicant;
    },
    // return contactAddress2Applicant
    contactAddress2Applicant(state) {
      return state.contactAddress2Applicant;
    },
    // return contactTelApplicant
    contactTelApplicant(state) {
      return state.contactTelApplicant;
    },
    // return contactMobileApplicant
    contactMobileApplicant(state) {
      return state.contactMobileApplicant;
    },
    // return contactFaxApplicant
    contactFaxApplicant(state) {
      return state.contactFaxApplicant;
    },
    // return contactEmailApplicant
    contactEmailApplicant(state) {
      return state.contactEmailApplicant;
    },
    // return purchaseRates
    purchaseRates(state) {
      return state.purchaseRates;
    },
    // return includeDoNotContactApplicant
    includeDoNotContactApplicant(state) {
      return state.includeDoNotContactApplicant;
    },
    // return picIds
    picIdsApplicant(state) {
      return state.picIdsApplicant;
    },
    // return contactPostalCodesApplicant
    contactPostalCodesApplicant(state) {
      return state.contactPostalCodesApplicant;
    },
    // return contactPrefectures
    contactPrefecturesApplicant(state) {
      return state.contactPrefecturesApplicant;
    },
    // return contactLocalitiesApplicant
    contactLocalitiesApplicant(state) {
      return state.contactLocalitiesApplicant;
    },
    // ------
    enumPurchaseRateListApplicant(state) {
      return state.enumPurchaseRateListApplicant;
    },
    arrPurchaseRatesApplicant(state) {
      return state.arrPurchaseRatesApplicant;
    },
    arrFurtherPurchaseRates(state) {
      return state.arrFurtherPurchaseRates;
    },
    arrRenewPurchaseRatesApplicant(state) {
      return state.arrRenewPurchaseRatesApplicant;
    },
    hasTrialStayApplicant(state) {
      return state.hasTrialStayApplicant;
    },
    remarksApplicant(state) {
      return state.remarksApplicant;
    },
    campaignListApplicant(state) {
      return state.campaignListApplicant;
    },
    listCampaignListApplicant(state) {
      return state.listCampaignListApplicant;
    },
    campaignListGroupsApplicant(state) {
      return state.campaignListGroupsApplicant;
    },
    listCampaignGroupsApplicant(state) {
      return state.listCampaignGroupsApplicant;
    },
    itemCampaignTimesApplicant(state) {
      return state.itemCampaignTimesApplicant;
    },
    // return hasActionMethodIdsType
    hasActionMethodIdsTypeApplicant(state) {
      return state.hasActionMethodIdsTypeApplicant;
    },
    // return hasActionMethodIdsApplicant
    hasActionMethodIdsApplicant(state) {
      return state.hasActionMethodIdsApplicant;
    },
    // return enumClientActionMethodList
    enumClientActionMethodListApplicant(state) {
      return state.enumClientActionMethodListApplicant;
    },
    // return listEnumClientActionMethodListApplicant
    listEnumClientActionMethodListApplicant(state) {
      return state.listEnumClientActionMethodListApplicant;
    },
    // return lastActionDateToApplicant
    lastActionDateToApplicant(state) {
      return state.lastActionDateToApplicant;
    },
    // return lastActionDateFromApplicant
    lastActionDateFromApplicant(state) {
      return state.lastActionDateFromApplicant;
    },
    // return hasProductTypeIdsIncludeExpiredApplicant
    hasProductTypeIdsIncludeExpiredApplicant(state) {
      return state.hasProductTypeIdsIncludeExpiredApplicant;
    },
    // return hasProductExpiringFromDateApplicant
    hasProductExpiringFromDateApplicant(state) {
      return state.hasProductExpiringFromDateApplicant;
    },
    // return hasProductExpiringToDateApplicant
    hasProductExpiringToDateApplicant(state) {
      return state.hasProductExpiringToDateApplicant;
    },
    // return hasProductTypeIdsTypeApplicant
    hasProductTypeIdsTypeApplicant(state) {
      return state.hasProductTypeIdsTypeApplicant;
    },
    // return hasProductTypeIdsTypeApplicant
    bookingTypesListApplicant(state) {
      return state.bookingTypesListApplicant;
    },
    // return listBookingTypesListApplicant
    listBookingTypesListApplicant(state) {
      return state.listBookingTypesListApplicant;
    },
    // return membershipCancellationDateFromApplicant
    membershipCancellationDateFromApplicant(state) {
      return state.membershipCancellationDateFromApplicant;
    },
    // return hasProductExpiringToDateApplicant
    membershipCancellationDateToApplicant(state) {
      return state.membershipCancellationDateToApplicant;
    },
    // return membershipStatusApplicant
    membershipStatusApplicant(state) {
      return state.membershipStatusApplicant;
    },
    // return memberIdsApplicant
    memberIdsApplicant(state) {
      return state.memberIdsApplicant;
    },
    // return clientContactType
    clientContactTypeApplicant(state) {
      return state.clientContactTypeApplicant;
    },
    // return enumCampaignGroupListApplicant
    enumCampaignGroupListApplicant(state) {
      return state.enumCampaignGroupListApplicant;
    },
    customerLengthPDF(state) {
      return state.customerLengthPDF;
    },
    getEnumPrefeList(state){
      return state.enumPrefectureList
    },
    getActionWithActionType(state){
      return state.actionWithActionType
    },
    getOtherPublicDocTypeList(state){
      return state.otherPublicDocTypeList
    }
  },
  mutations: {

    setBookingListTake (state, take) {
      state.listFilter.take = take
    },
    setBookingListSkip (state, skip) {
      state.listFilter.skip = skip
    },
    setTotalBookinglist (state, total) {
      state.totalBookinglist = total
    },

    setPicIdsApplicant(state, data) {
      state.picIdsApplicant = data
    },

    setActionWithActionType(state, data) {
      state.actionWithActionType = data
    },

    setClientSearchPaginatedResponseApplicant(state, data) {
      state.clientSearchPaginatedResponseApplicant = data;
    },
    setEnumPurchaseRateListApplicant(state, data) {
      state.enumPurchaseRateListApplicant = [];
      data.forEach(element => {
        var objPurchaseRates = {
          id: element.id,
          name: element.name,
        };
        state.enumPurchaseRateListApplicant.push(objPurchaseRates);
      });
    },
    setCampaignListApplicant(state, data) {
      state.campaignListApplicant = [];
      // state.campaignListGroupsApplicant = []
      data.forEach(element => {
        if (element !== null) {
          var objcampaignList = {
            id: element.id,
            name: element.name,
          };
          state.campaignListApplicant.push(objcampaignList);
          // element.groups.forEach(e => {
          //     if (e !== null) {
          //         state.campaignListGroupsApplicant.push(e)
          //     }
          // });
        }
      });
    },
    setEnumClientActionMethodListApplicant(state, data) {
      state.enumClientActionMethodListApplicant = data;
    },
    setBookingTypesListApplicant(state, data) {
      state.bookingTypesListApplicant = [];
      data.forEach(element => {
        if (element !== null) {
          var objBookingTypes = {
            id: element.id,
            name: element.code,
          };
          state.bookingTypesListApplicant.push(objBookingTypes);
        }
      });
    },
    setEnumCampaignGroupListApplicant(state, data) {
      state.enumCampaignGroupListApplicant = [];
      data.forEach(element => {
        if (element !== null) {
          var objGroupList = {
            name: element.name,
          };
          state.enumCampaignGroupListApplicant.push(objGroupList);
        }
      });
    },
    // set name
    setName(state, val) {
      state.nameApplicant = val;
    },
    // set namekana
    setNameKana(state, val) {
      state.nameKanaApplicant = val;
    },
    // set include company
    setIncludeCompany(state, val) {
      if(val === null){
        state.includeCompanyApplicant = true
        state.includeIndividualApplicant = true
      }
      else if(val === true){
        state.includeCompanyApplicant = true
        state.includeIndividualApplicant = false
      }
      else{
        state.includeCompanyApplicant = false
        state.includeIndividualApplicant = true
      }
    },
    // set companyRepresentativeNameApplicant
    setCompanyRepresentativeNameApplicant(state, val) {
      state.companyRepresentativeNameApplicant = val;
    },
    // set contactAddress1Applicant
    setContactAddress1Applicant(state, val) {
      state.contactAddress1Applicant = val;
    },
    // set contactAddress2Applicant
    setContactAddress2Applicant(state, val) {
      state.contactAddress2Applicant = val;
    },
    // set contactTelApplicant
    setContactTelApplicant(state, val) {
      state.contactTelApplicant = val;
    },
    // set contactMobileApplicant
    setContactMobileApplicant(state, val) {
      state.contactMobileApplicant = val;
    },
    // set contactFaxApplicant
    setContactFaxApplicant(state, val) {
      state.contactFaxApplicant = val;
    },
    // set contactEmailApplicant
    setContactEmailApplicant(state, val) {
      state.contactEmailApplicant = val;
    },
    // set purchaseRates
    setPurchaseRates(state, val) {
      state.purchaseRates = val;
    },
    // set includeDoNotContactApplicant
    setIncludeDoNotContactApplicant(state, val) {
      state.includeDoNotContactApplicant = val;
    },
    // set clientIds
    setClientIdsApplicant(state, val) {
      state.clientIdsApplicant = val;
    },
    // set contactPostalCodesApplicant
    setContactPostalCodesApplicant(state, val) {
      state.contactPostalCodesApplicant = val;
    },
    // set contactPrefecturesApplicant
    setContactPrefecturesApplicant(state, val) {
      state.contactPrefecturesApplicant = val;
    },
    // set contactLocalitiesApplicant
    setContactLocalitiesApplicant(state, val) {
      state.contactLocalitiesApplicant = val;
    },
    // set PurchaseRates
    setPurchaseRatesApplicantSelect(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumPurchaseRateListApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrPurchaseRatesApplicant = arr1;
      state.listPurchaseRatesApplicant = arr2;
    },
    // set furtherPurchaseRates
    setFurtherPurchaseRatesApplicantSelect(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumPurchaseRateListApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrFurtherPurchaseRates = arr1;
      state.listFurtherPurchaseRates = arr2;
    },
    // set furtherPurchaseRates
    setRenewPurchaseRatesApplicantSelect(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumPurchaseRateListApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrRenewPurchaseRatesApplicant = arr1;
      state.listRenewPurchaseRatesApplicant = arr2;
    },
    setHasTrialStayApplicant(state, val) {
      state.hasTrialStayApplicant = val;
    },
    setRemarksApplicant(state, val) {
      state.remarksApplicant = val;
    },
    setListCampaignListApplicant(state, val) {
      // var arr1 = [];
      // var arr2 = [];
      // state.campaignListApplicant.forEach(element => {
      //   if (val.includes(element.id)) {
      //     arr1.push(element.id);
      //     arr2.push(element);
      //   }
      // });
      // state.arrCampaignListApplicant = arr1;
      // state.listCampaignListApplicant = arr2;
      state.listCampaignListApplicant = val;
    },
    setListCampaignGroupsApplicant(state, val) {
      state.listCampaignGroupsApplicant = val;
    },
    setItemCampaignTimesApplicant(state, val) {
      state.itemCampaignTimesApplicant = val;
    },
    // set hasActionMethodIdsType
    setHasActionMethodIdsTypeApplicant(state, val) {
      state.hasActionMethodIdsTypeApplicant = val;
    },
    // set ListEnumClientActionMethodListApplicant
    setListEnumClientActionMethodListApplicant(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.enumClientActionMethodListApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      if (arr1.length === 0) {
        state.arrEnumClientActionMethodListApplicant = null;
      } else {
        state.arrEnumClientActionMethodListApplicant = arr1;
      }
      state.listEnumClientActionMethodListApplicant = arr2;
    },
    // set lastActionDateToApplicant
    setLastActionDateToApplicant(state, val) {
      state.lastActionDateToApplicant = val;
    },
    // set lastActionDateFromApplicant
    setLastActionDateFromApplicant(state, val) {
      state.lastActionDateFromApplicant = val;
    },
    // set hasProductTypeIdsIncludeExpiredApplicant()
    setHasProductTypeIdsIncludeExpiredApplicant(state, val) {
      state.hasProductTypeIdsIncludeExpiredApplicant = val;
    },
    // set hasProductExpiringFromDateApplicant
    setHasProductExpiringFromDateApplicant(state, val) {
      state.hasProductExpiringFromDateApplicant = val;
    },
    // set hasProductExpiringToDateApplicant
    setHasProductExpiringToDateApplicant(state, val) {
      state.hasProductExpiringToDateApplicant = val;
    },
    // set hasProductTypeIdsTypeApplicant
    setHasProductTypeIdsTypeApplicant(state, val) {
      state.hasProductTypeIdsTypeApplicant = val;
    },
    // set listBookingTypesListApplicant
    setListBookingTypesListApplicant(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.bookingTypesListApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrBookingTypesListApplicant = arr1;
      state.listBookingTypesListApplicant = arr2;
    },
    // set membershipCancellationDateFromApplicant
    setMembershipCancellationDateFromApplicant(state, val) {
      state.membershipCancellationDateFromApplicant = val;
    },
    // set membershipCancellationDateToApplicant
    setMembershipCancellationDateToApplicant(state, val) {
      state.membershipCancellationDateToApplicant = val;
    },
    // set membershipStatusApplicant
    setMembershipStatusApplicant(state, val) {
      state.membershipStatusApplicant = val;
    },
    // set memberIdsApplicant
    setMemberIdsApplicant(state, val) {
      state.memberIdsApplicant = val;
    },
    // set listPicIdsApplicant
    setListPicIdsApplicant(state, val) {
      var arr1 = [];
      var arr2 = [];
      state.picIdsApplicant.forEach(element => {
        if (val.includes(element.id)) {
          arr1.push(element.id);
          arr2.push(element);
        }
      });
      state.arrpicIdsApplicant = arr1;
      state.listPicIdsApplicant = arr2;
    },
    // set clientContactTypeApplicant
    setClientContactTypeApplicant(state, val) {
      state.clientContactTypeApplicant = val;
    },
    setCustomerLengthPDF(state, val) {
      state.customerLengthPDF = val;
    },
    setEnumPrefeList(state, val){
     state.enumPrefectureList = val
    },
    setOtherPublicDocTypeList(state, val){
      state.otherPublicDocTypeList = val
    }
  },
  actions,
};

export default Applicant;
