import actions from './actions'
import moment from 'moment'
import { exportTimeJapan } from '@/constants/functions';

const MyPage = {
    state: {
        clientActionHistoryListMyPage: [],
        dateToMyPage: new Date().toISOString().substr(0, 10),
        calendarListMyPage:[],
        commentMyPage: '',
        select: 5,
        take: 5,
        skip: 0,
        totalCmtMypage: 0,
        dateFromCalendarMyPage:"",
        dateToCalendarMyPage:new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 14)).toISOString().substr(0, 10),
        commentClientMyPage: [],
        commetListMyPage:[],
    },
    getters: {
        getSkipPaginateMyPage(state){
            return state.skip
        },
        getTotalComment(state){
            return state.totalCmtMypage
        },
        getSelectPaginateMyPage(state){
            return state.select
        },
        clientActionHistoryListMyPage(state){
            return state.clientActionHistoryListMyPage
        },
        dateToMyPage(state){
            return state.dateToMyPage
        },
        dateToCalendarMyPage(state){
            return state.dateToCalendarMyPage
        },
        calendarListMyPage(state){
            return state.calendarListMyPage
        },
        commetListMyPage(state){
            return state.commetListMyPage
        },
        commentMyPage(state){
            return state.commentMyPage
        },
        dateFromCalendarMyPage(state){
            return state.dateFromCalendarMyPage
        },
        commentClientMyPage(state){
            return state.commentClientMyPage
        },
    },
    mutations: {
        setSkipPaginateMyPage(state, val){
            state.skip = val
        },
        setSelectPaginateMyPage (state, val) {
            state.take = val
            state.select = val
        },
        setTotalComment(state, val) {
            state.totalCmtMypage = val
        },
        setClientActionHistoryListMyPage (state, data) {
            state.clientActionHistoryListMyPage = data
        },
        setDateToMyPage(state,val){
            state.dateToMyPage = val
        },
        setCalendarListMyPage(state,data){
            var arr = []
            data.forEach(e =>
            {       
                if (e.actionAt === null)
                {
                    var startDate = exportTimeJapan(e.actionPlannedAt);
                    var endDate = exportTimeJapan(moment(new Date(startDate)).add(30, 'm').toDate());
                    arr.push({
                        id: e.id,
                        title: e.clientActionType ? e.clientActionType.name : null,
                        name: e.client.name,
                        action: e.action,
                        priority: e.priority,
                        start: startDate.replace("  ","T"),
                        end: endDate.replace("  ","T"),
                    })
                }
            });
            state.calendarListMyPage = arr
        },
        setCommetListMyPage(state,data){
            state.commetListMyPage = data
        },
        setCommentMyPage(state,val){
            state.commentMyPage = val
        },
        setDateToCalendarMyPage(state,val){
            state.dateToCalendarMyPage = val
        },
        getMonCurrentWeek(state){
            const d = new Date()
            const day = d.getDay()
            const diff = d.getDate() - day + (day === 0 ? -6 : 1)
            state.dateFromCalendarMyPage = new Date(d.setDate(diff)).toISOString().substr(0, 10)
        },
        setDateFromCalendarMyPage(state,val){
            state.dateFromCalendarMyPage = val
        },
        setCommentClientMyPage(state,data){
            // state.commentClientMyPage = []
            state.commentClientMyPage = data
        },
    },
    actions,
}

export default MyPage;