export const BOOKING_TICKET_TYPE_LIST = `
  query(
    $clientId: Int
    $ticketNo: String
    $isAvailable: Boolean
    $hasIntendedClient: Boolean
    $skip: Int
    $take: Int
    $orderBy: [OrderByInput!]
  ) {
    bookingTicketTypeList(
      filter: {
        clientId: $clientId
        isAvailable: $isAvailable
        ticketNo: $ticketNo
        hasIntendedClient: $hasIntendedClient
      }
      pagination: { take: $take, skip: $skip }
      orderBy: $orderBy
    ) {
      total
      items {
        createdAt
        id
        availableToDate
        intendedClient {
          name
        }
        computed {
          issuedCount
          usedCount
          availableCount
        }
        name
        roomTypes {
          roomType {
            facility {
              name
            }
          }
        }
        cancelDeadline
        bookingFromDate
        bookingFromDays
        bookingFromMonths
      }
    }
  }
`;

export const BOOKING_TICKET_TYPE = `
  query($id: String!) {
    bookingTicketType(id: $id, loadTikcets: true) {
      id
      name
      intendedClientId
      intendedClient {
        name
        type
      }
      roomTypes {
        roomType {
          id
          name
          facility {
            id
            name
          }
        }
      }
      availableFromDate
      availableToDate
      exceptDates {
        exceptDate
      }
      availableMonday
      availableTuesday
      availableWednesday
      availableThursday
      availableFriday
      availableSaturday
      availableSunday
      availableHoliday
      toothbrush
      razor
      nightwear
      rentalItem
      accommodationPrice
      bookCallCenter
      bookSalesRep
      cancelDeadline
      bookingFromDate
      bookingFromMonths
      bookingFromDays
      assetValue
      notes
      createdAt
      availableToDate
      createdBy {
        name
      }

      computed {
        issuedCount
        usedCount
        availableCount
      }

      bookingTickets {
        isUsed
        bookingId
        id
        secret
        #email
        booking {
          id
          computed { status }
          bookingGroupId
          bookingTypeId
          checkInDate
          representative {
            email
            name
            tel
            address1
          }
          createdAt
          room {
            roomType {
              facility {
                name
              }
            }
          }
        }
        isAvailable
        disabled
      }
    }
  }
`;

export const DISABLE_BOOKING_TICKET = `
  mutation($ticketId: String!, $disabled: Boolean!) {
    disableBookingTicket(ticketId: $ticketId, disabled: $disabled) {
      disabled
    }
  }
`;

export const BOOKING_TYPE_LIST = `
  query {
    bookingTypesList {
      id
      code
    }
  }
`;

export const BOOKING_TICKET_CSV = `
  mutation($id: String!) {
    bookingTicketCsv(id: $id) {
      files {
        fileName
        downloadUrl
      }
    }
  }
`;
