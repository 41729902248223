import { apolloClient } from '@/plugins/apollo';
import {
  CLIENT_SEARCH_PAGINATED_RESPONSE_ALL,
  ENUM_PURCHASERATE_LIST_RESPONSE_ALL,
  CAMPAIGN_LIST_RESPONSE_ALL,
  ENUM_CAMPAIGN_GROUP_LIST_RESPONSE_ALL,
} from '@/api/graphql/customer/response/responseAll/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientSearchPaginatedResponseAll({ commit, state },{take, skip, orderBy, filter}) {
    const finishData = {
      filter: {
        includeDoNotContact: state.includeDoNotContactResponseAll,
        purchaseRates: state.arrPurchaseRatesSelectResponseAll,
        picIds: state.arrPicIdsResponseAll,
        lastResponseDateFrom: state.lastResponseDateFromResponseAll || null,
        lastResponseDateTo: state.lastResponseDateToResponseAll || null,
        membershipStatus: state.membershipStatusResponseAll,
        membershipCancellationDateFrom:
        state.membershipCancellationDateFromResponseAll || null,
        membershipCancellationDateTo:
        state.membershipCancellationDateToResponseAll || null,
        lastActionDateFrom: state.lastActionDateFromResponseAll || null,
        lastActionDateTo: state.lastActionDateToResponseAll || null,
        hasActionMethodIds:
        state.arrEnumClientActionMethodListResponseAll,
        hasActionMethodIdsType: state.hasActionMethodIdsTypeResponseAll,
        hasActionWithActionType: state.actionWithActionTypeResponseAll,
        campaignIds: state.listCampaignListResponseAll,
        campaignGroups: state.listCampaignGroupsResponseAll,
        campaignTimes: state.itemCampaignTimesResponseAll,
        hasTrialStay: state.hasTrialStayResponseAll,
        remarks: state.remarksResponseAll.toString().replace('\\n', '\n'),
        clientContactType: state.clientContactTypeResponseAll,
        contactPostalCodes: state.contactPostalCodesResponseAll,
        contactPrefectures: state.contactPrefecturesResponseAll,
        contactLocalities: state.contactLocalitiesResponseAll,
        brochureRequestType: state.brochureRequetTypeResponseAll,
        includeCompany: state.includeCompanyResponseAll,
        includeIndividual: state.includeIndividualResponseAll,
        clientIds: state.clientIdsResponseAll,
        gender: state.sexResponseAll,
        ageFrom: state.ageFromResponseAll,
        ageTo: state.ageToResponseAll,
        ...filter,
      },
      pagination: {
        take,
        skip,
      },
      orderBy
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_SEARCH_PAGINATED_RESPONSE_ALL}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit(
            'setClientSearchPaginatedResponseAll',
            response.data.searchClientList.items,
          );
          resolve(response.data.searchClientList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_SEARCH_PAGINATED_RESPONSE_ALL, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumPurchaseRateListResponseAll({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PURCHASERATE_LIST_RESPONSE_ALL}`,
        })
        .then(response => {
          commit(
            'setEnumPurchaseRateListResponseAll',
            response.data.enumPurchaseRateList,
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PURCHASERATE_LIST_RESPONSE_ALL, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getCampaignListResponseAll({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CAMPAIGN_LIST_RESPONSE_ALL}`,
        })
        .then(response => {
          commit(
            'setCampaignListResponseAll',
            response.data.enumCampaignMediaTypeList,
          );
          resolve(response.data.enumCampaignMediaTypeList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CAMPAIGN_LIST_RESPONSE_ALL, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumCampaignGroupListResponseAll({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_CAMPAIGN_GROUP_LIST_RESPONSE_ALL}`,
        })
        .then(data => {
          commit(
            'setEnumCampaignGroupListResponseAll',
            data.data.enumCampaignGroupList,
          );
          resolve(data.data.enumCampaignGroupList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_CAMPAIGN_GROUP_LIST_RESPONSE_ALL, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
