export const CLIENT_ANNUALFEE_LIST_CF_ANNUAL = `
query(
  $filter: ClientAnnualFeeListFilterInput!
  $pagination: PaginationInput
  $orderBy: [OrderByInput!]
  ){
    clientAnnualFeeList(
      filter: $filter
      pagination: $pagination
      orderBy: $orderBy
    ) {
      total
      items {
        id 
        processedAt 
        hasError 
        client {
          id
          name
          isCompany
          contracts{
            id
          }
          type
          memberUser {
            email
          }
        }
        remarks
        year
        month 
        vsAnnualFee 
        vsAnnualFeeTax 
        annualFee 
        annualFeeTax 
        membershipFee 
        membershipFeeTax 
        managementFee 
        managementFeeTax 
        hotspringFee 
        hotspringFeeTax 
      }
    }
  }
`;