import { apolloClient } from '@/plugins/apollo';
import {
  CLIENT_BASIC_INFO,
  UPDATE_CLIENT
} from '@/api/graphql/basicInformation/basicInformation.js';
import router from '@/router';

import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  clientBasicInfo({ commit, state }) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_BASIC_INFO}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setClientBasicInfo', data.data.clientBasicInfo);
          resolve(data.data.clientBasicInfo);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_BASIC_INFO, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },


  async UpdateBasicInfomation({ commit, rootState }, dataUpdate) {
    var contacts = []
    if (dataUpdate.contacts) {
      dataUpdate.contacts.forEach(e => {
        var obj = {
          type: e.type,
          labelName: e.labelName,
          postalCode: e.postalCode || null,
          prefecture: e.prefecture || null,
          locality: e.locality,
          address1: e.address1,
          address2: e.address2,
          tel: e.tel,
          fax: e.fax,
          email: e.email || null,
          mobile: e.mobile,
        }
        contacts.push(obj)
      });
    }
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      lastName: dataUpdate.lastName,
      firstName: dataUpdate.firstName,
      lastKana: dataUpdate.lastKana,
      firstKana: dataUpdate.firstKana,
      birthday: dataUpdate.birthday || null,
      firstContactAge: dataUpdate.firstContactAge,
      sex: dataUpdate.sex,
      contacts: contacts,
      employerName: dataUpdate.employerName,
      employerKana: dataUpdate.employerKana,
      employmentTypeId: dataUpdate.employmentTypeId,
      childTypeId: dataUpdate.childTypeId,
      incomeLevelId: dataUpdate.incomeLevelId,
      topCardNumber: dataUpdate.topCardNumber,
      remarksForStaff: dataUpdate.remarksForStaff,
      referrerClientId: parseInt(dataUpdate.referrerClientId),
      referrerGroupEmployeeName: dataUpdate.referrerGroupEmployeeName,
      firstAccessDate: dataUpdate.firstAccessDate,
      membershipStarted: dataUpdate.membershipStarted,
      membershipCancellationDate: dataUpdate.membershipCancellationDate,
      membershipCancellationReason: dataUpdate.membershipCancellationReason,
      remarks: dataUpdate.remarks,
      mailPreference: dataUpdate.mailPreference,
      telPreference: dataUpdate.telPreference,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CLIENT}`,
          variables: finishData
        })
        .then(data => {
          resolve(data);
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
          );
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT, finishData, error.graphQLErrors)
          reject(error);
          handlError(commit, error.graphQLErrors);
        });
    });
  },

  async UpdateBasicInfoGroup({ commit, rootState }, newFinishData) {
    var contacts = []
    newFinishData.contacts.forEach(e => {
      var obj = {
        type: e.type,
        labelName: e.labelName,
        postalCode: e.postalCode || null,
        prefecture: e.prefecture || null,
        locality: e.locality,
        address1: e.address1,
        address2: e.address2,
        tel: e.tel,
        fax: e.fax,
        email: e.email || null,
        mobile: e.mobile,
      }
      contacts.push(obj)
    });
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      contacts: contacts,
      companyName: newFinishData.companyName,
      companyNameKana: newFinishData.companyNameKana,
      companyPicName: newFinishData.companyPicName,
      companyPicKana: newFinishData.companyPicKana,
      companyRepresentativeName: newFinishData.companyRepresentativeName,
      companyRepresentativeKana: newFinishData.companyRepresentativeKana,
      companyPicDepartment: newFinishData.companyPicDepartment,
      companyPicTitle: newFinishData.companyPicTitle,
      companySize: parseInt(newFinishData.companySize),
      remarksForStaff: newFinishData.remarksForStaff,
      mailPreference: newFinishData.mailPreference,
      telPreference: newFinishData.telPreference,
      referrerClientId: parseInt(newFinishData.referrerClientId),
      referrerGroupEmployeeName: newFinishData.referrerGroupEmployeeName,
      firstAccessDate: newFinishData.firstAccessDate,
      membershipCancellationDate: newFinishData.membershipCancellationDate,
      remarks: newFinishData.remarks,
      membershipCancellationReason: newFinishData.membershipCancellationReason,
      membershipStarted: newFinishData.membershipStarted,
      topCardNumber: newFinishData.topCardNumber,
      // }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CLIENT}`,
          variables: finishData
        })
        .then(data => {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
            );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
