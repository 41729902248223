export const UPDATE_WEB_NOTIFICATION = `
mutation (
    $id: Int! 
    $title: String! 
    $body: String! 
    $imageUrl: String 
    $showFromDate: DateString! 
    $showToDate: DateString! 
    $important: Boolean! 
    $published: Boolean! 
  ) {
    updateWebNotification(id:$id, data: {
      title: $title
      body: $body
      imageUrl: $imageUrl
      showFromDate: $showFromDate
      showToDate: $showToDate
      important: $important
      published: $published
    }) {
      __typename
    }
  }
`;

export const CREATE_WEB_NOTIFICATION = `
mutation (
  $title: String! 
  $body: String! 
  $imageUrl: String 
  $showFromDate: DateString! 
  $showToDate: DateString! 
  $important: Boolean! 
  $published: Boolean! 
) {
  createWebNotification(data: {
    title: $title
    body: $body
    imageUrl: $imageUrl
    showFromDate: $showFromDate
    showToDate: $showToDate
    important: $important
    published: $published
  }) {
    __typename
  }
}
`;

export const DELETE_WEB_NOTIFICATION = `
mutation ($id: Int!) {
  deleteWebNotification(id: $id)
}
`;

export const HIDDEN_WEB_NOTIFICATION = `
mutation (
  $id: Int! #ID
) {
  hiddenWebNotification(id:$id)
}
`;



