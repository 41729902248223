import actions from './actions';

const enumEmploymentTypeList = {
  state: {
    enum: [],
    // id: null,
    // name: ''
    enumPicIds: [],
  },
  getters: {
    getEnumEmploymentTypeList(state) {
      return state.enum;
    },
    getEnumPicIds(state) {
      return state.enumPicIds;
    },
  },
  mutations: {
    setEnumEmploymentTypeList(state, arr) {
      state.enum = arr;
    },
    setEnumPicIds(state, arr) {
      state.enumPicIds = arr;
    },
  },
  actions,
};

export default enumEmploymentTypeList;
