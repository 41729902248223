import gql from 'graphql-tag';

export const CAMPAIGN_LIST = `
  query($filter: ListCampaignFilterInput, $pagination: PaginationInput, $orderBy: [OrderByInput!]) {
    campaignList(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      total
      items {
        status
        id
        name
        groups
        computed {
          id
          responseCount
          contractCount
        }
        detailComputed {
          id
          newClientCount
          vsContractCount
          vmContractCount
          paymentTotal
        }
        budget
        startDate
        endDate
        attachment1
        attachment2
        attachment3
      }
    }
  }
`;

export const CONTRACT_CAMPAIGN_LIST = gql`
  query { campaignList(filter: { finished:false }) { items { id name } } }
`

export const CAMPAIGN_LIST_NO_FILTER = gql`
  query ($clientId: Int!)         {
    campaignApplyResponseList (filter: {
      clientId: $clientId
    }) {
      items {
        campaign {
          name 
          id
        }
      }
    }
  }
`;

export const ENUM_CAMPAIGN_GROUP_LIST = `
  query {
    enumCampaignGroupList {
      name
    }
  }
`;
export const ENUM_CAMPAIGN_MEDIA_TYPE_LIST = `
  query {
    enumCampaignMediaTypeList {
      id
      name
    }
  }
`;

export const CREATE_CAMPAIGN_ADV_POLICIES = `
  mutation(
    $status: CampaignStatus! # ステータス Active:対応中, Ended: 終了
    $name: String! #名称
    $groups: [String!]! #広告施策タグ名。選択肢は enumCampaignGroupList{ name } で取得
    $startDate: DateString! #期間開始日
    $endDate: DateString! #期間終了日
    $budget: Int! #実施広告費用
    $target20s: Boolean! #ターゲット２０代
    $target30s: Boolean! #ターゲット３０代
    $target40s: Boolean! #ターゲット４０代
    $target50s: Boolean! #50
    $target60s: Boolean! #60
    $target70s: Boolean! #70
    $targetFemale: Boolean! #ターゲット女性
    $targetMale: Boolean! #男性
    $purposeRecognition: Boolean! #目的、認知拡大
    $purposePromote: Boolean! #利用促進
    $purposePersuade: Boolean! #刈取施策
    $methodDm: Boolean! #手段DM
    $methodEmail: Boolean! #メルマガ
    $methodBillboard: Boolean! #看板
    $methodTv: Boolean! # TV
    $methodSns: Boolean! #SNS
    $methodWeb: Boolean! #Web
    $note: String #説明
    $attachment1: String #資料１ （アップロードは上参照）
    $attachment2: String #資料２
    $attachment3: String #資料３
    $mediaTypeIds: [Int!]! #対象反響媒体選択 選択肢は enumCampaignMediaTypeList {id name}
  ) {
    createCampaign(
      campaignData: {
        status: $status
        name: $name
        groups: $groups
        startDate: $startDate
        endDate: $endDate
        budget: $budget
        target20s: $target20s
        target30s: $target30s
        target40s: $target40s
        target50s: $target50s
        target60s: $target60s
        target70s: $target70s
        targetFemale: $targetFemale
        targetMale: $targetMale
        purposeRecognition: $purposeRecognition
        purposePromote: $purposePromote
        purposePersuade: $purposePersuade
        methodDm: $methodDm
        methodBillboard: $methodBillboard
        methodEmail: $methodEmail
        methodTv: $methodTv
        methodSns: $methodSns
        methodWeb: $methodWeb
        note: $note
        attachment1: $attachment1
        attachment2: $attachment2
        attachment3: $attachment3
        mediaTypeIds: $mediaTypeIds
      }
    ) {
      id
      name
    }
  }
`;

export const EDIT_CAMPAIGN_ADV_POLICIES = `
  mutation(
    $campaignId: Int!
    $status: CampaignStatus! # ステータスActive:対応中, Ended: 終了
    $name: String! #名称
    $groups: [String!]! #広告施策タグ名。選択肢は enumCampaignGroupList{ name } で取得
    $startDate: DateString! #期間開始日
    $endDate: DateString! #期間終了日
    $budget: Int! #実施広告費用
    $target20s: Boolean! #ターゲット２０代
    $target30s: Boolean! #ターゲット３０代
    $target40s: Boolean! #ターゲット４０代
    $target50s: Boolean! #50
    $target60s: Boolean! #60
    $target70s: Boolean! #70
    $targetFemale: Boolean! #ターゲット女性
    $targetMale: Boolean! #男性
    $purposeRecognition: Boolean! #目的、認知拡大
    $purposePromote: Boolean! #利用促進
    $purposePersuade: Boolean! #刈取施策
    $methodDm: Boolean! #手段DM
    $methodEmail: Boolean! #メルマガ
    $methodBillboard: Boolean! #看板
    $methodTv: Boolean! # TV
    $methodSns: Boolean! #SNS
    $methodWeb: Boolean! #Web
    $note: String #説明
    $attachment1: String #資料１ （アップロードは上参照）
    $attachment2: String #資料２
    $attachment3: String #資料３
    $mediaTypeIds: [Int!]! #対象反響媒体選択 選択肢は enumCampaignMediaTypeList {id name}
  ) {
    updateCampaign(
      id: $campaignId
      campaignData: {
        status: $status
        name: $name
        groups: $groups
        startDate: $startDate
        endDate: $endDate
        budget: $budget
        target20s: $target20s
        target30s: $target30s
        target40s: $target40s
        target50s: $target50s
        target60s: $target60s
        target70s: $target70s
        targetFemale: $targetFemale
        targetMale: $targetMale
        purposeRecognition: $purposeRecognition
        purposePromote: $purposePromote
        purposePersuade: $purposePersuade
        methodDm: $methodDm
        methodBillboard: $methodBillboard
        methodEmail: $methodEmail
        methodTv: $methodTv
        methodSns: $methodSns
        methodWeb: $methodWeb
        note: $note
        attachment1: $attachment1
        attachment2: $attachment2
        attachment3: $attachment3
        mediaTypeIds: $mediaTypeIds
      }
    ) {
      __typename
    }
  }
`;

export const GENERATE_UPLOAD_URL = `
  mutation($contentType: String!, $contentLength: Int!, $fileName: String!) {
    generateUploadUrl(
      type: "campaign/attachment"
      contentType: $contentType
      contentLength: $contentLength
      fileName: $fileName
    ) {
      fileUrl
      uploadUrl
      key #これを次の書類追加APIで送ること
    }
  }
`;
export const DETAIL_CAMPAIGN_ADV_POLICIES = `
  query($campaignId: Int!) {
    campaign(id: $campaignId, load: { applyResponses: true, contracts: true }) {
      status #ステータス 詳細はリストを参考
      id #ID
      name
      groups #タグ
      startDate #開始日
      endDate #終了日
      budget #施策広告費用
      attachment1 #資料１のファイルKey、ファイル名はリストと同様に取得する
      attachment1Url #資料１のダウンロードURL
      attachment2 #資料2
      attachment2Url #資料2のダウンロードURL
      attachment3 #資料3
      attachment3Url #資料3のダウンロードURL
      computed {
        responseCount #Int! 反応総数
        #反応CPAは budget / responseCount
        contractCount #Int! 成約数
        #成約CPAは budget / responseCount
      }
      detailComputed {
        newClientCount # Int! 新規 の数
        # 再反応は responseCount - newClientCount
        vsContractCount #Int! VS の数
        vmContractCount # Int! V の数
        paymentTotal # Int! 成約金額合計
      }
      # ターゲット、翻訳は作成のAPIの資料を参考
      target20s
      target30s
      target40s
      target50s
      target60s
      target70s
      targetMale
      targetFemale
      # 目的、翻訳は作成のAPIの資料を参考
      purposeRecognition
      purposePersuade
      purposePromote
      # 手段、翻訳は作成のAPIの資料を参考
      methodDm
      methodEmail
      methodBillboard
      methodTv
      methodSns
      methodWeb
      mediaTypes {
        #array
        id
        name #対象反響媒体選択の名前、 .join(', ')で表示
      }
      note #String 備考
      applyResponses {
        #array
        id
        responseAt #DateTime 反応 反応のあった日時、nullならなし
        client {
          id #Int!顧客ID
          type
          isCompany # Boolean! true:法人, false: 個人
          name #String 顧客名
          companyPicName #String 法人窓口担当者
          companyPicTitle #String 法人窓口担当者役職名
          lastClientActionHistory {
            #nullable, 最新活動履歴
            remarks #String 最新活動履歴のText、枠に収まる範囲で表示する
          }
          pic {
            # nullable
            name #営業担当
          }
        }
        createdAt #追加日時
      }

      contracts {
        id
        createdAt #追加日
        status #ステータス
        productType {
          code #String!契約商品
        }
        phase {
          name
        } #String! フェーズ
        salesStaff {
          #nullable
          name #String!  販売担当
        }
        client {
          id #Int!顧客ID
          type
          isCompany # Boolean! true:法人, false: 個人
          name #String 顧客名
          companyPicName #String 法人窓口担当者
          companyPicTitle #String 法人窓口担当者役職名
        }
      }
    }
  }
`;

export const SIGN_UP_BONUS_LIST = `
  query($keyword: String, $status: SignupBonusStatus, $take: Int, $skip: Int, $orderBy: [OrderByInput!]) {
    signupBonusList(
      filter: { keyword: $keyword, status: $status }
      pagination: { skip: $skip, take: $take }
      orderBy: $orderBy
    ) {
      total
      items {
        id
        status
        name
        startDate
        endDate
        description
        type
        amount
        vsBonuses
        vsBonusValues
        computed {
          clientCount
          contractCount
        }
      }
    }
  }
`;

export const SIGN_UP_BONUS = `
  query($signupBonusId: Int!) {
    signupBonus(id: $signupBonusId) {
      id
      name
      status
      startDate
      endDate
      vsBonuses
      vsPromoCode
      vsBonusValues
      type
      amount
      description
      vsPromoTVP
      vsPromoDiscount
      canUseInMemberWeb
      canUseInPromoUrl
    }
  }
`;

export const SIGN_UP_BONUS_APPLICATION_LIST = `
  query($signupBonusId: Int!, $handled: Boolean, $take: Int, $skip: Int, $orderBy: [OrderByInput!]) {
    signupBonusApplicationList(
      filter: { signupBonusId: $signupBonusId, handled: $handled }
      pagination: { take: $take, skip: $skip }
      orderBy: $orderBy
    ) {
      total
      items {
        id
        handledAt
        createdAt
        contract {
          id
          status
          productType {
            code
          }
          phase {
            name
          }
          salesStaff {
            name
          }
          client {
            type
            id
            isCompany
            name
            companyPicName
            companyPicTitle
          }
        }
      }
    }
  }
`;

export const MARK_AS_HANDLED_SIGNUP_BONUS_APPLICATION = `
  mutation($id: Int!, $status: SignupBonusApplicationMarkStatus!) {
    markAsHandledSignupBonusApplication(id: $id, status: $status) {
      id
      handledById
    }
  }
`;
export const UPDATE_CAMAPP_APPLY_RESPONSE = `
  mutation(
    $id: Int! #施策ID
    $hasResponse: Boolean! #反応有りで追加はTrue、実施済みで追加はfalse
  ) {
    updateCampaignApplyResponse(id: $id, response: $hasResponse) {
      id
    }
  }
`;

export const DELETE_CAMAPP_APPLY_RESPONSE = `
  mutation(
    $id: Int! #施策ID
  ) {
    deleteCampaignApplyResponse(id: $id) {
      __typename
    }
  }
`;

export const CREATE_BULK_CAMAPP_APPLY_RESPONSE = `
  mutation($clientIds: [Int!]!, $campaignId: Int!, $status: Boolean!) {
    createBulkCampaignApplyResponse(
      clientIds: $clientIds
      campaignId: $campaignId
      response: $status
    ) {
      __typename
    }
  }
`;
