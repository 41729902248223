import actions from './actions';

const confirmAnnual = {
  state: {
    clientAnnualfeeListCFAnnual:[],
    yearCFAnnual: new Date().getFullYear(),
    monthCFAnnual: new Date().getMonth() + 1,
    hasProcessedCFAnnual: false,
    totalClientAnnualfeeListCFAnnual: null
  },
  getters:{
    totalClientAnnualfeeListCFAnnual(state) {
      return state.totalClientAnnualfeeListCFAnnual
    },
    clientAnnualfeeListCFAnnual(state){
      return state.clientAnnualfeeListCFAnnual
    },
    yearCFAnnual(state){
      return state.yearCFAnnual
    },
    monthCFAnnual(state){
      return state.monthCFAnnual
    },
    hasProcessedCFAnnual(state){
      return state.hasProcessedCFAnnual
    },
  },
  mutations: {
    setTotalClientAnnualfeeListCFAnnual(state, data) {
      state.totalClientAnnualfeeListCFAnnual = data
    },
    setClientAnnualfeeListCFAnnual(state,data){
      state.clientAnnualfeeListCFAnnual = data
    },
    setYearCFAnnual(state,val){
      state.yearCFAnnual = val
    },
    setMonthCFAnnual(state,val){
      state.monthCFAnnual = val
    },
    setHasProcessedCFAnnual(state,val){
      state.hasProcessedCFAnnual = val
    },
  },
  actions
}

export default confirmAnnual
