export function getContractTypeNameByProductTypeId(productTypeId) {
  switch(productTypeId) {
    case 1: return 'MW';
    case 2: return 'SP';
    case 3: return 'FP';
    case 4: return 'VS';
    case 5: return 'VM';
    case 6: return 'PW';
    default: return null;
  }
}

export const ProductTypeIds = {
  MW: 1,
  SP: 2,
  FP: 3,
  VS: 4,
  VM: 5,
  PW: 6
};
