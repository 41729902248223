import { apolloClient } from '@/plugins/apollo';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import {
  UPDATE_SIGNUP_BONUS,
  CREATE_SIGNUP_BONUS,
  GENERATE_SIGNUP_BONUS_VS_PROMO_CODE
} from '@/api/graphql/signupBonus';
import gql from 'graphql-tag'

export default {
  async createSignupBonus({ commit, rootState, state, dispatch }, obj) {
    const finishData = {
      status: obj.status,
      name: obj.name,
      startDate: obj.startDate,
      endDate: obj.endDate,
      type: obj.type,
      description: obj.description,
      amount: parseInt(obj.amount),
      code: obj.code,
      vsTvp: parseInt(obj.vsTvp),
      vsDiscount: parseInt(obj.vsDiscount),
      canUseInMemberWeb: obj.canUseInMemberWeb,
      canUseInPromoUrl: obj.canUseInPromoUrl,
    }
    commit('setLoadingOverlayShow', { root: true });
    await apolloClient
      .mutate({
        mutation: gql`${CREATE_SIGNUP_BONUS}`,
        variables: finishData
      })
      .then(data => {
        commit('setLoadingOverlayHide', { root: true });
        commit(
          'setAlertSuccess',
          rootState.alert.success.messages.createSuccess,
          { root: true },
        );
        dispatch('fetchSignUpBonusList')
      })
      .catch(error => {
        setCopyErrorTextCommit(commit, CREATE_SIGNUP_BONUS, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true });
        handlError(commit, error.graphQLErrors);
      });
  },
  async updateSignupBonus({ commit, rootState }, obj) {
    const finishData = {
      id: parseInt(obj.id),
      status: obj.status,
      name: obj.name,
      startDate: obj.startDate,
      endDate: obj.endDate,
      type: obj.type,
      description: obj.description,
      amount: parseInt(obj.amount),
      code: obj.code,
      vsTvp: parseInt(obj.vsTvp),
      vsDiscount: parseInt(obj.vsDiscount),
      canUseInMemberWeb: obj.canUseInMemberWeb,
      canUseInPromoUrl: obj.canUseInPromoUrl,
    }
    commit('setLoadingOverlayShow', { root: true });
    await apolloClient
      .mutate({
        mutation: gql`${UPDATE_SIGNUP_BONUS}`,
        variables: finishData,
      })
      .then(data => {
        commit('setLoadingOverlayHide', { root: true });
        commit(
          'setAlertSuccess',
          rootState.alert.success.messages.updateSuccess,
          { root: true },
        );
      })
      .catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_SIGNUP_BONUS, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true });
        handlError(commit, error.graphQLErrors);
      });
  },

  async generateSignupBonusVsPromoCode({ commit }) {
    commit('setLoadingOverlayShow', { root: true });
    await apolloClient.mutate({
      mutation: gql`${GENERATE_SIGNUP_BONUS_VS_PROMO_CODE}`
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true });
      commit('setSignupBonusVsPromoCode', data.data.generateSignupBonusVsPromoCode);
    })
    .catch(error => {
      setCopyErrorTextCommit(commit, GENERATE_SIGNUP_BONUS_VS_PROMO_CODE, null, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true });
      handlError(commit, error.graphQLErrors);
    });
  }
};