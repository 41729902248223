import { apolloClient } from '@/plugins/apollo';
import { CREATE_CONTRACT_ATTACHMENT } from '@/api/graphql/contract/contractDetail/sp_ep/openBrowser/openBrowser.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async createContractAttachmentOpen({ commit, state, rootState, dispatch }) {
    const finishData = {
      contractId: parseInt(router.currentRoute.params.id),
      // fileName: "55555",
      fileKey: state.fileKey
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CONTRACT_ATTACHMENT}`,
          variables: finishData,
        })
        .then(data => {
          dispatch('actionContractTimeInformation')
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.createSuccess,
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          // commit('setAlertError', rootState.alert.error.messages.createError, {
          //   root: true,
          // });
          setCopyErrorTextCommit(commit, CREATE_CONTRACT_ATTACHMENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
}