import { apolloClient } from "@/plugins/apollo";
import
{
    WEBREQUEST_LIST_EXPERIENCE,
    UPDATE_WEBREQUEST_OC,
    // WEBREQUEST_LIST_EXPERIENCE_ONLINECONSULTATION
} from "@/api/graphql/dashboard/webPageManage/experience/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getWebrequestlistExperience({ commit, state }, variables)
    {
        let finishData
        if (variables.orderBy !== [] && variables.orderBy !== [null])
        {
            finishData = {
                keyword: variables.filter.keyword,
                handled: variables.filter.handled,
                take: variables.pagination.take,
                skip: variables.pagination.skip,
                type: "OnlineCounseling",
                orderBy: variables.orderBy
            }
        } else
        {
            finishData = {
                keyword: variables.filter.keyword,
                handled: variables.filter.handled,
                take: variables.pagination.take,
                skip: variables.pagination.skip,
                type: "OnlineCounseling"
            }
        }
        return new Promise((resolve, reject) =>
        {
            apolloClient
                .query({
                    query: gql`${ WEBREQUEST_LIST_EXPERIENCE }`,
                    variables: finishData,
                    fetchPolicy: 'no-cache',
                }).then(data =>
                {
                    commit('setTotalWebRequestExperienceList', data.data.webRequestList.total)
                    commit('setWebrequestlistExperience', data.data.webRequestList.items)
                    resolve(true)
                })
                .catch(error =>
                {
                    setCopyErrorTextCommit(commit, WEBREQUEST_LIST_EXPERIENCE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async updateWebrequestOC({ dispatch, commit, state }, id)
    {
        const finishData = {
            id: parseInt(id),
            status: state.statusOC,
            remarks: state.remarksOC,
        }
        return new Promise((resolve, reject) =>
        {
            apolloClient
                .mutate({
                    mutation: gql`${ UPDATE_WEBREQUEST_OC }`,
                    variables: finishData
                }).then(data =>
                {
                    // dispatch('getWebrequestlistExperience')
                    // dispatch('getWebrequestlistFacility')
                    dispatch('proOnlines')
                    dispatch('proTrials')
                    commit('setAlertSuccess', '更新しました。', {
                        root: true,
                    });
                    resolve(data)
                })
                .catch(error =>
                {
                    setCopyErrorTextCommit(commit, UPDATE_WEBREQUEST_OC, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}