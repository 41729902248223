import { apolloClient } from '@/plugins/apollo';
import { CLIENT_CHANGE_HISTORY_LIST } from '@/api/graphql/customer/response/detail/changeHistory/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientChangeHistoryList({ commit, state },id) {
    const finishData = {
      filter: {
        clientId: id,
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_CHANGE_HISTORY_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          // resolve(data);
          commit('setClientChangeHistoryList', data.data.clientChangeHistoryList.items)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_CHANGE_HISTORY_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
