export const CLIENT_BASIC_INFO = `
query ($id: Int!) {
  
    clientBasicInfo(id: $id) {
      id 
      isCompany 
      lastName 
      firstName
      lastKana 
      firstKana 
      birthday 
      firstContactAge 
      sex 
      employerName 
      employerKana 
      employmentTypeId
      childTypeId 
      incomeLevelId 
      remarksForStaff
      mailPreference 
      telPreference 
      companyName
      companyNameKana
      companyPicName
      companyPicKana
      companyRepresentativeName
      companyRepresentativeKana
      companyPicDepartment
      companyPicTitle
      companySize
      contacts { 
        type 
        labelName 
        postalCode
        prefecture 
        locality 
        address1
        address2 
        tel 
        mobile 
        fax 
        email 
      }
      topCardNumber 
      registeredCC { 
        id 
      }
      createdAt
      referrerClientId
      referrerClient { name } 
      referrerGroupEmployeeName 
      firstAccessDate 
      membershipCancellationDate
      tbosClientId
      remarks 
      membershipCancellationReason
      membershipStarted
    }
  }
`;

export const CLIENT_BASIC_INFO_CRRENT = `
  query ($id: Int!) {
    clientBasicInfo(id: $id) {
      id
      name
      type
      doNotContact
      purchaseRateId
      furtherPurchaseRateId
      renewPurchaseRateId
      lastMeetDate
      lastMeetMethod
      lastMeetAttendant
      lastResponseDate
      seasonsInterested
      facilityFeePayment
      negotiationExitReason
      pic{
        id
        name
      } 
    }
    savedCard (clientId: $id) {
      expire
    }
  }
`;

export const UPDATE_CLIENT = `
mutation (
  $clientId: Int!
  $lastName: String
  $firstName: String
  $lastKana:String
  $firstKana:String
  $birthday: DateString
  $firstContactAge: Int
  $sex: Gender
  $employerName: String
  $employerKana: String
  $employmentTypeId: Int
  $childTypeId: Int
  $incomeLevelId: Int
  $remarksForStaff: String
  $topCardNumber: String
  $referrerClientId: Int
  $referrerGroupEmployeeName: String
  $firstAccessDate: DateString
  $membershipStarted: DateString 
  $membershipCancellationDate: DateString
  $membershipCancellationReason: String
  $remarks: String
  $mailPreference: ClientContactType
  $telPreference: ClientContactType
  $contacts: [UpdateContactInput!]

  $companyName: String
  $companyNameKana: String
  $companyPicName: String
  $companyPicKana: String
  $companyRepresentativeName: String
  $companyRepresentativeKana: String
  $companyPicDepartment: String
  $companyPicTitle: String
  $companySize: Int
) {
  updateClient(
    id: $clientId
    contacts: $contacts
    data: {
      lastName: $lastName
      firstName: $firstName
      lastKana: $lastKana
      firstKana: $firstKana
      birthday: $birthday
      firstContactAge: $firstContactAge
      sex: $sex
      employerName: $employerName
      employerKana: $employerKana
      employmentTypeId: $employmentTypeId
      childTypeId: $childTypeId
      incomeLevelId: $incomeLevelId
      remarksForStaff: $remarksForStaff
      topCardNumber: $topCardNumber
      referrerClientId: $referrerClientId
      referrerGroupEmployeeName: $referrerGroupEmployeeName
      firstAccessDate: $firstAccessDate
      membershipStarted: $membershipStarted
      membershipCancellationDate: $membershipCancellationDate
      membershipCancellationReason: $membershipCancellationReason
      remarks: $remarks
      mailPreference: $mailPreference
      telPreference: $telPreference
      companyName: $companyName
      companyNameKana: $companyNameKana
      companyPicName: $companyPicName
      companyPicKana: $companyPicKana
      companyRepresentativeName: $companyRepresentativeName
      companyRepresentativeKana: $companyRepresentativeKana
      companyPicDepartment: $companyPicDepartment
      companyPicTitle: $companyPicTitle
      companySize: $companySize
    }
  ) {
    __typename
  }
}
`;