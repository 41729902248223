import actions from './actions';

const membershipBillingHistory = {
  state: {
    clientChargeList: [],
    showMembers: [
      { showMemberStatus: false, name: '非表示' },
      { showMemberStatus: true, name: '表示' },
    ],
    paymentMethods: [
      { id: 'GMO', name: 'GMO支払い' },
      { id: 'TOP', name: 'TOPカード支払い' },
      { id: 'INVOICE', name: '請求書支払い' },
    ],
    paymentMethodsSelected: null,
  },
  getters: {
    // getClientChargeList
    getClientChargeList(state) {
      return state.clientChargeList;
    },
    getShowMembers(state) {
      return state.showMembers;
    },
    getPaymentMethods(state) {
      return state.paymentMethods;
    },
    getPaymentMethodsSelected(state) {
      return state.paymentMethodsSelected;
    },
  },
  mutations: {
    // setClientChargeList
    setClientChargeList(state, data) {
      state.clientChargeList = data;
    },
    setPaymentMethodsSelected(state, data) {
      state.paymentMethodsSelected = data;
    },
  },
  actions,
};

export default membershipBillingHistory;
