export const CAMPAIGN_LIST = `
  query {
    campaignList {
      items {
        status
        id
        name
        groups
        computed {
          responseCount
          contractCount
        }
        budget
        startDate
        endDate
        attachment1
        attachment2
        attachment3
      }
    }
  }
`;

export const SEARCH_CAMPAIGN_LIST = `
  query($filter: ListCampaignFilterInput!, $pagination: PaginationInput, $orderBy: [OrderByInput!]) {
    campaignList(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
      total
      items {
        status
        id
        name
        groups
        computed {
          responseCount
          contractCount
        }
        budget
        startDate
        endDate
        attachment1
        attachment2
        attachment3
      }
    }
  }
`;
