import actions from './actions';

const changePeriod = {
  state: {
    data: {
      id: null,
      validFrom: '',
      validThru: ''
    }
  },
  getters: {
    getContractIdChange(state) {
      return state.contractId;
    },

    getValid (state) {
      return state.data
    }
  },
  mutations: {
    setContractId(state, payload) {
      state.contractId = payload
    },
    setValid (state, payload) {
      state.data = payload
    }

  },
  actions,
};

export default changePeriod;
