export const FACLILITY_LIST = `
  query {
    facilityList {
      id
      name
      shortName
      type
      roomTypes {
        id
        name
        backgroundColor
      }
    }
  }
`;

export const CREATE_BOOKING_TICKETS = `
  mutation ($ticketData: CreateBookingTicketInput!) {
    createBookingTickets(ticketData: $ticketData) {
      id
    }
  }
`;
