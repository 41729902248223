import { apolloClient } from "@/plugins/apollo";
import { 
    VACATION_LIFE_LIST_VACATIONLIFE,
    CREATE_VACATIONLIFE,
    DELETE_VACATIONLIFE
} from "@/api/graphql/dashboard/webPageManage/webDocument/vacationLife/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getVacationLifeListVacationLife({ commit, state }) {
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${VACATION_LIFE_LIST_VACATIONLIFE}`,
                    fetchPolicy: 'network-only'
                }).then(data => {
                    commit('getVacationLifeListVacationLife',data.data.vacationLifeList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, VACATION_LIFE_LIST_VACATIONLIFE, null, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async createVacationlife({ commit,rootState, state,dispatch }, finishData) {
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${CREATE_VACATIONLIFE}`,
                    variables: finishData
                }).then(data => {
                    commit(
                        'setAlertSuccess',
                        rootState.alert.success.messages.updateSuccess,
                        { root: true },
                      );
                    dispatch('getVacationLifeListVacationLife')
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CREATE_VACATIONLIFE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async deleteVacationlife({ commit, state,dispatch },id) {
        const finishData = {
            id: id
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${DELETE_VACATIONLIFE}`,
                    variables: finishData,
                }).then(data => {
                    commit('setAlertSuccess', '削除しました。', {
                        root: true,
                    });
                    dispatch('getVacationLifeListVacationLife')
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, DELETE_VACATIONLIFE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}