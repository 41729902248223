import actions from './actions';

const deleteDatail = {
  state: {
    deteleDatail: null,
  },
  getters: {
   
    getDeteleDatail(state) {
      return state.deteleDatail;
    },
  },
  mutations: {
    
    setDeteleDatail(state, data) {
      state.deteleDatail = data;
    },
  },
  actions,
};

export default deleteDatail;
