import store from '../store'
import { 
  MENU_SIDEBAR_PATH,
  NAME_ROUTER_CUSTOMER_PATH,
  NAME_ROUTER_CONTRACT_PATH,
  NAME_ROUTER_CUSTOMER_DETAIL_PATH,
  NAME_ROUTER_VM_TEMPORARY_CONTRACT_PATH,
  PERSONAL_REPORT_PATH,
  PERSONAL_REPORT_PATH_SUB,
  WEB_MANAGE_PATH,
  WEB_MANAGE_PATH_SUB,
} 
from '@/constants/enum'
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import router, { isRouteAnyOf, pushAltRoute } from '../router';

export const checkPermissionUserCurrent = (routerInfo = {}) => {
  const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
  const permissionUserCurrent = store.getters.permissionUserCurrent
  if (roleAdminUserCurrent) {
    return true
  }
  if (Object.keys(permissionUserCurrent).length !== 0 && !roleAdminUserCurrent) {
    let isPath = false
    let isTab = false
    let fullPermission = false
    for (const [key, value] of Object.entries(permissionUserCurrent)) {
      if (key.toString() === (routerInfo.path)) {
        isPath = true
        if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
          isTab = true
        }
        if (value === 'W' || value === 'w') {
          fullPermission = true
        }
      } else {
        const nameCustomer = checkPermissionNameRouter(NAME_ROUTER_CUSTOMER_PATH, routerInfo.name)
        const nameContract = checkPermissionNameRouter(NAME_ROUTER_CONTRACT_PATH, routerInfo.name)
        const nameCustomerDetail = checkPermissionNameRouter(NAME_ROUTER_CUSTOMER_DETAIL_PATH, routerInfo.name)
        const nameTemporary = checkPermissionNameRouter(NAME_ROUTER_VM_TEMPORARY_CONTRACT_PATH, routerInfo.name)
        if (key.toString().includes(nameCustomer)) {
          isPath = true
          if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
            isTab = true
          }
          if (value === 'W' || value === 'w') {
            fullPermission = true
          }
        }
        
        if (key.toString().includes(nameContract)) {
          isPath = true
          if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
            isTab = true
          }

          var arrayOtherContract = ['usage-situation-mw', 'reservation_point_affiliated_facility', 'contract-time-information', 'contract-cancellation', 'contract-transfer', 'vs-vs']

          if (key.includes('usage-situation-sp-pw')) {
            const arrType = ['FP', 'SP', 'PW', 'MW']
            arrType.forEach(item => {
              if (routerInfo.path.includes(item) && key.includes(item)) {
                if (value === 'W' || value === 'w') {
                  fullPermission = true
                }
              }
            })
          } else if (key.includes('usage-situation-vm')) {
            const arrType = ['PW', 'VM']
            arrType.forEach(item => {
              if (routerInfo.path.includes(item) && key.includes(item)) {
                if (value === 'W' || value === 'w') {
                  fullPermission = true
                }
              }
            })
          } else if (key.includes('contract/vs-vs')) {
            if (value === 'W' || value === 'w') {
              fullPermission = true
            }
          } else if (key.includes('basic-infomation') && !key.includes('85/VM')) {
            if (value === 'W' || value === 'w') {
              fullPermission = true
            }
          }
            
          else if (arrayOtherContract.some(e => key.includes(e))) {
            arrayOtherContract.forEach(item => {
              if (key.includes(item) && routerInfo.path.includes(item)) {
                if (value === 'W' || value === 'w') {
                  fullPermission = true
                }
              }
            })
          }
        }

        if (key.toString().includes(nameCustomerDetail)) {
          if (routerInfo.params.id) {
            var pathReplace = routerInfo.path.replace(routerInfo.params.id, '')
          } else pathReplace = routerInfo.path
          if(key === pathReplace) {
          isPath = true
            if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
              isTab = true
            }
            if (value === 'W' || value === 'w') {
              fullPermission = true
            }
          }
        }
        if (key.toString().includes(nameTemporary)) {
          isPath = true
          if (routerInfo.query.tab && key.toString().includes(routerInfo.query.tab)) {
            isTab = true
          }
          if (value === 'W' || value === 'w') {
            fullPermission = true
          }
        }
      }
    }
    if (isPath && isTab && fullPermission) {
      return true
    }
    if (isPath && fullPermission) {
      return true
    }
}
  return false
}

export const checkPermissionTab = (itemTab = []) => {
  if (itemTab.length > 0) {
    const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
    const permissionUserCurrent = store.getters.permissionUserCurrent
    if (roleAdminUserCurrent) {
      return itemTab
    }
    if (Object.keys(permissionUserCurrent).length !== 0 && !roleAdminUserCurrent) {
      const arrPath = Object.keys(permissionUserCurrent)
      itemTab.map((item) => {
        if (!arrPath.includes(item.pathRoot)) {
          const arrItemTmp = itemTab.filter((rmIte) => rmIte.pathRoot !== item.pathRoot)
          itemTab = arrItemTmp
        }
        return item
      })
      return itemTab
    }
  }
  return []
}

export function filterTabs (tabArray, toKey = 'to') {
  const result = []
  for (const tab of tabArray) {
    const route = router.resolve(tab[toKey]).route
    if (hasAccess(route)) {
      result.push(tab)
    }
  }
  return result;
}

export const checkRouterPrior = (pathRoute = null , routerSub = [], checkPathRoot = null) => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return routerSub[0]
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0 ) {
      let routeTmp = ''
      for (const nameRouteSub of routerSub) {
        if (Object.keys(permissionUserCurrentPri).includes(nameRouteSub)) {
          routeTmp = nameRouteSub
          break
        }
      }
      return routeTmp
    }
  }
  return ''
}
export const checkRouterPathRoot = () => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return `/${MENU_SIDEBAR_PATH[0]}`
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0) {
      let routeTmp = ''
      for (const nameRouteSub of MENU_SIDEBAR_PATH) {
        for (const [key] of Object.entries(permissionUserCurrentPri)) {
          const arrPathPerUser = key.split('/')
          const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
          if (!checkArrPath && arrPathPerUser[1].includes(nameRouteSub)) {
            routeTmp = `/${nameRouteSub}`
            break
          }
        }
        if (routeTmp) {
          break
        }
      }
      return routeTmp
    }
  }
  return ''
}

export const checkRedirect403 = () => {
  const roleAdminUserCurrentPri = store.getters.roleAdminUserCurrent
  const permissionUserCurrentPri = store.getters.permissionUserCurrent
  if (roleAdminUserCurrentPri) {
    return MENU_SIDEBAR_PATH.length
  } else {
    if (Object.keys(permissionUserCurrentPri).length !== 0) {
      let routeTmp = 0
      for (const nameRouteSub of MENU_SIDEBAR_PATH) {
        for (const [key] of Object.entries(permissionUserCurrentPri)) {
          const arrPathPerUser = key.split('/')
          const checkArrPath = arrPathPerUser.every(element => element === null || element === '')
          // if (!checkArrPath && arrPathPerUser[1].includes(nameRouteSub)) {
          //   routeTmp = routeTmp + 1
          // }
          if (!checkArrPath && arrPathPerUser[1] === nameRouteSub) {
            routeTmp = routeTmp + 1
          }
        }
        
      }
      return routeTmp
    }
    return 0
  }
}
export const checkPermissionNameRouter = (arrNameRouter, nameRouterCurrent) => {
  const arrNameRouterTmp =  arrNameRouter.filter((item) => item.name === nameRouterCurrent)
  if (arrNameRouterTmp.length > 0) {
    return arrNameRouterTmp[0].path
  }
  return false
}

export const decodeToken = () => {
  const tokenLogin = localStorage.getItem('token') || ''
  if (tokenLogin) {
    return jwt_decode(tokenLogin)
  }
  return {}
}

export const matchTokenPermissions = (prev, next) => {
  if (prev.admin !== next.admin) return false
  if (Object.keys(prev.rolePermissions).some(key => prev.rolePermissions[key] !== next.rolePermissions[key]
  )) {
    return false
  }
  if (Object.keys(next.rolePermissions).some(key => next.rolePermissions[key] !== prev.rolePermissions[key]
  )) {
    return false
  }
  return true
}

export function hasAccess(routerInfo)
{
  if (routerInfo.path === PERSONAL_REPORT_PATH || routerInfo.path === PERSONAL_REPORT_PATH_SUB)
  {
    return true
  }
  if (routerInfo.path === WEB_MANAGE_PATH || routerInfo.path === WEB_MANAGE_PATH_SUB)
  {
    return true
  }
  const roleAdminUserCurrent = store.getters.roleAdminUserCurrent
  const permissionUserCurrent = store.getters.permissionUserCurrent
  if (
    roleAdminUserCurrent ||
    routerInfo.path === '/notpage' ||
    routerInfo.path === '/signin' ||
    routerInfo.path === '/confirm-mfa-staff' ||
    routerInfo.path === 'reset-password-staff' ||
    routerInfo.path?.startsWith('/get/')
  )
  {
    return true
  }
  if (Object.keys(permissionUserCurrent).length !== 0)
  {

    let isPath = false
    for (const [key] of Object.entries(permissionUserCurrent))
    {
      if (key.toString() === (routerInfo.path))
      {
        isPath = true
        break
      } else
      {
        const nameCustomer = checkPermissionNameRouter(NAME_ROUTER_CUSTOMER_PATH, routerInfo.name)
        const nameContract = checkPermissionNameRouter(NAME_ROUTER_CONTRACT_PATH, routerInfo.name)
        const nameCustomerDetail = checkPermissionNameRouter(NAME_ROUTER_CUSTOMER_DETAIL_PATH, routerInfo.name)
        const nameTemporary = checkPermissionNameRouter(NAME_ROUTER_VM_TEMPORARY_CONTRACT_PATH, routerInfo.name)
        if (key.toString().includes(nameCustomer))
        {
          isPath = true
          break
        }
        if (key.toString().includes(nameContract))
        {
          var arrayOtherContract = ['usage-situation-mw', 'reservation_point_affiliated_facility', 'contract-time-information', 'contract-cancellation', 'contract-transfer', 'vs-vs']

          if (key.includes('usage-situation-sp-pw'))
          {
            const arrType = ['FP', 'SP', 'PW', 'MW']
            arrType.forEach(item =>
            {
              if (routerInfo.path.includes(item) && key.includes(item))
              {
                isPath = true
              }
            })
          } else if (key.includes('usage-situation-vm'))
          {
            const arrType = ['PW', 'VM']
            arrType.forEach(item =>
            {
              if (routerInfo.path.includes(item) && key.includes(item))
              {
                isPath = true
              }
            })
          } else if (key.includes('contract/vs-vs'))
          {
            isPath = true
          } else if (key.includes('basic-infomation') && !key.includes('85/VM'))
          {
            isPath = true
          }
          else if (arrayOtherContract.some(e => key.includes(e)))
          {
            arrayOtherContract.forEach(item =>
            {
              if (key.includes(item) && routerInfo.path.includes(item))
              {
                isPath = true
              }
            })
          }
        }
        if (key.toString().includes(nameCustomerDetail))
        {
          if (routerInfo.params.id)
          {
            var pathReplace = routerInfo.path.replace(routerInfo.params.id, '')
          } else pathReplace = routerInfo.path
          if (pathReplace.includes(key))
          {
            isPath = true
          }
        }
        if (key.toString().includes(nameTemporary))
        {
          isPath = true
          break
        }
      }
    }
    if (!isPath)
    {
      return false
    }
  }
  return true
}

export function toAltRouteIfTabMissing (tabs, toKey = 'to') {
  const routes = tabs.map(tab => tab[toKey])
  if (!isRouteAnyOf(routes, router.currentRoute)) {
    pushAltRoute()
  }
}
