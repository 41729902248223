export const GENERATE_UPLOAD_URL = `
mutation (
  $contentType: String!
  $contentLength: Int!,
  $fileName: String!
) {
  generateUploadUrl(
    type: "contract/attachment"
    contentType: $contentType
    contentLength: $contentLength
    fileName: $fileName
  ) {
    fileUrl
    uploadUrl
    key #これを次の書類追加APIで送ること
  }
}
`

export const CREATE_CONTRACT_ATTACHMENT = `
mutation(
  $contractId: Int!
  $fileKey: String! #すぐ上のUpload用URL作成のMutationを使い、Keyを送ること
  $fileName: String! #ファイル名
) {
  createContractAttachment(
    contractId: $contractId
    fileKey: $fileKey
    fileName: $fileName
  ) {
    id
  }
}
`

