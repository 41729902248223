export const CLIENT_SEARCH_PAGINATED_RESPONSE_ALL = `
query($filter: ClientFilterSearchInput!,$pagination: PaginationInput!, $orderBy: [OrderByInput!]) {
    searchClientList(
        filter: $filter,
        pagination: $pagination
        orderBy: $orderBy
        useNewMainContact: true
    ) {
      items{
        id 
        type
        isCompany 
        name 
        birthday 
        purchaseRate {name} 
        doNotContact 
        memberId 
        membershipCancellationDate 
        pic {
          id
          name
        } 
        mainContact {
          labelName 
          postalCode 
          prefecture 
          locality 
          address1 
          address2
          tel 
        }
      }
      total 
      hasMore 
    }
  }
`;

export const ENUM_PURCHASERATE_LIST_RESPONSE_ALL = `
query {
  enumPurchaseRateList {
    id
    name
  }
}`;

export const CAMPAIGN_LIST_RESPONSE_ALL = `
query {
  enumCampaignMediaTypeList {
    id
    name
  }
}`;

export const ENUM_CAMPAIGN_GROUP_LIST_RESPONSE_ALL = `
query {
  enumCampaignGroupList {
      name
  }
}`;