import actions from './actions';

const searchPurchaseEvaluationList = {
  state: {
    searchPurchaseEvaluationList: [],
    purchaseRate: [{ id: null, name: '購入評価' }],
    picPurchaseEvaluation: [{ id: null, name: '佐々木剛' }],
    
    lastCampaignApplyResponsePurchaseEvaluation: [
      { id: null, name: '指定なし' },
    ],
    // select
    purchaseRateSelected: null,
    picPurchaseEvaluationSelected: parseInt(localStorage.getItem('id')),
    lastCampaignApplyResponsePurchaseEvaluationSelected: null,
  },
  getters: {
    searchPurchaseEvaluationList(state) {
      return state.searchPurchaseEvaluationList;
    },
    purchaseRate(state) {
      return state.purchaseRate;
    },
    picPurchaseEvaluation(state) {
      return state.picPurchaseEvaluation;
    },
    lastCampaignApplyResponsePurchaseEvaluation(state) {
      return state.lastCampaignApplyResponsePurchaseEvaluation;
    },
    purchaseRateSelected(state) {
      return state.purchaseRateSelected;
    },
    picPurchaseEvaluationSelected(state) {
      return state.picPurchaseEvaluationSelected;
    },
    lastCampaignApplyResponsePurchaseEvaluationSelected(state) {
      return state.lastCampaignApplyResponsePurchaseEvaluationSelected;
    },
  },
  mutations: {
    searchPurchaseEvaluationList(state, arr) {
      state.searchPurchaseEvaluationList = arr;
    },
    setPicPurchaseEvaluation(state, payload){
      state.picPurchaseEvaluation = payload
    },
    setPurchaseRateSelected(state, val) {
      state.purchaseRateSelected = val;
    },
    setPicPurchaseEvaluationSelected(state, val) {
      state.picPurchaseEvaluationSelected = val;
    },
    setLastCampaignApplyResponsePurchaseEvaluationSelected(state, val) {
      state.lastCampaignApplyResponsePurchaseEvaluationSelected = val;
    },
  },
  actions,
};

export default searchPurchaseEvaluationList;
