export const ADD_STAFFS_TO_GROUP = `
mutation (
  $staffGroupId: Int!
  $staffIds: [Int!]!
) {
  addStaffsToGroup(
    staffGroupId: $staffGroupId
    staffIds: $staffIds
  ) {
    __typename
  }
}
`
export const REMOVE_STAFFS_TO_GROUP = `
mutation (
  $groupId: Int!
  $staffIds: [Int!]! #選択されたスタッフID array
) {
  removeStaffsToGroup(
    staffGroupId: $groupId
    staffIds: $staffIds
  ) {
    __typename
  }
}
`
