import actions from './actions'

const userManual = {
    state: {
      userGuideListUserManual: [],
      fileUrlUserManual:'',
      yearUserManual:null,
      yearUseUserManual:[]
    },
    getters: {
      yearUserManual(state){
        return state.yearUserManual
      },
      yearUseUserManual(state){
        return state.yearUseUserManual
      },
      userGuideListUserManual(state){
        return state.userGuideListUserManual
      },
    },
    mutations: {
      setFileUrlUserManual(state,val){
        state.fileUrlUserManual = val
      },
      setYearUserManual(state,val){
        state.yearUserManual = val
      },
      setUserGuideListUserManual(state,data){
        state.yearUserManual = data
        state.userGuideListUserManual = data
        var arr = []
        state.yearUserManual.forEach(e => {
          if(!arr.includes(e.year)){
            arr.push(e.year)
          }
        });
        state.yearUseUserManual = arr
      },
    },
    actions,
}

export default userManual;