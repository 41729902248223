import actions from './actions'

const FreeNotificationRegistration = {
  state: {
    clientIdNreg:'',
    facilityListNotificationRegistration:[],
    facilityListNotificationRegistrationByPT:[],
    idFacilityNReg:0,
    roomTypeListNReg:[],
    idRoomTypeNReg:0,
    checkInDateCRWaitList:'',
    checkOutDateCRWaitList:'',
    clientMemberInfoNotificationRegistration:[],
    clientMemberInfoNotificationRegistrationByPT:[],
    idClientInfoNReg:0,
    alertSuccessFreeNotificationRegistration:'', 
    alertErrorFreeNotificationRegistration:'', 
  },
  getters: {
    // return facilityListNotificationRegistration
    facilityListNotificationRegistration(state){
      return state.facilityListNotificationRegistration
    },
    // return facilityListNotificationRegistration BY PT
    facilityListNotificationRegistrationByPT(state){
      return state.facilityListNotificationRegistrationByPT
    },
    // return idFacilityNReg
    idFacilityNReg(state){
      return state.idFacilityNReg
    },
    // return roomTypeListNReg
    roomTypeListNReg(state){
      return state.roomTypeListNReg
    },
    // return idRoomTypeNReg
    idRoomTypeNReg(state){
      return state.idRoomTypeNReg
    },
    // return checkInDateCRWaitList
    checkInDateCRWaitList(state){
      return state.checkInDateCRWaitList
    },
    // return clientMemberInfoNotificationRegistration
    clientMemberInfoNotificationRegistration(state){
      return state.clientMemberInfoNotificationRegistration
    },
    // return clientMemberInfoNotificationRegistration
    clientMemberInfoNotificationRegistrationByPT(state){
      return state.clientMemberInfoNotificationRegistrationByPT
    },
    // return idClientInfoNReg
    idClientInfoNReg(state){
      return state.idClientInfoNReg
    },
    alertSuccessFreeNotificationRegistration(state){
      return state.alertSuccessFreeNotificationRegistration
    },
    alertErrorFreeNotificationRegistration(state){
      return state.alertErrorFreeNotificationRegistration
    }
  },
  mutations: {
    setFacilityListNotificationRegistration(state,data){
      state.facilityListNotificationRegistration = data
    },
    setFacilityListNotificationRegistrationByPT(state,data){
      state.facilityListNotificationRegistrationByPT = data
    },
    setIdFacilityNReg(state,val){
      state.idFacilityNReg = Number(val)
    },
    // set setRoomTypeListNReg
    setRoomTypeListNReg(state,data){
      state.roomTypeListNReg = data
    },
    // set setIdRoomTypeNReg
    setIdRoomTypeNReg(state,data){
      state.idRoomTypeNReg = Number(data)
    },
    // set CheckInDateCRWaitList
    setCheckInDateCRWaitList(state,val){
      state.checkInDateCRWaitList = val
    },
    // set checkOutDateCRWaitList
    setCheckOutDateCRWaitList(state,val){
      if(state.checkInDateCRWaitList !== ''){
        var result = new Date(state.checkInDateCRWaitList);
        result.setDate(result.getDate() + Number(val));
        state.checkOutDateCRWaitList = result.toISOString().slice(0, 10)
      }
    },
    // set clientIdNreg
    setClientIdNreg(state,val){
      state.clientIdNreg = val
    },
    // set setClientMemberInfoNotificationRegistration
    setClientMemberInfoNotificationRegistration(state,data){
      state.clientMemberInfoNotificationRegistration = data
    },
    // set setClientMemberInfoNotificationRegistrationByPT
    setClientMemberInfoNotificationRegistrationByPT(state,data){
      state.clientMemberInfoNotificationRegistrationByPT = data
    },
    // set idClientInfoNReg
    setIdClientInfoNReg(state,data){
      state.idClientInfoNReg = data
    },
    // success
    setAlertSuccessFreeNotificationRegistration(state,val){
      state.alertSuccessFreeNotificationRegistration = val
    },
    // error
    setAlertErrorFreeNotificationRegistration(state,val){
      state.alertErrorFreeNotificationRegistration = val
    },
  },
  actions,
}

export default FreeNotificationRegistration