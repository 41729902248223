import actions from './actions';

const changeMember = {
  state: {
    flagUpdate: false,
    clientId: null,
    idChangeMember:0,
    clientAnnualfeeListChangeMember: [],
    monthChangeMember: '',
    annualFeeChangeMember: '',
    annualFeeTaxChangeMember: '',
    membershipFeeChangeMember: 0,
    membershipFeeTaxChangeMember: '',
    managementFeeChangeMember: '',
    managementFeeTaxChangeMember: '',
    hotspringFeeChangeMember: '',
    hotspringFeeTaxChangeMember: '',
    remarksChangeMember:''
  },
  getters: {
    getFlagUpdate (state) {
      return state.flagUpdate
    },
    clientAnnualfeeListChangeMember(state) {
      return state.clientAnnualfeeListChangeMember
    },
    monthChangeMember(state) {
      return state.monthChangeMember
    },
    annualFeeChangeMember(state) {
      return state.annualFeeChangeMember
    },
    annualFeeTaxChangeMember(state) {
      return state.annualFeeTaxChangeMember
    },
    membershipFeeChangeMember(state) {
      return state.membershipFeeChangeMember
    },
    membershipFeeTaxChangeMember(state) {
      return state.membershipFeeTaxChangeMember
    },
    managementFeeChangeMember(state) {
      return state.managementFeeChangeMember
    },
    managementFeeTaxChangeMember(state) {
      return state.managementFeeTaxChangeMember
    },
    hotspringFeeChangeMember(state) {
      return state.hotspringFeeChangeMember
    },
    hotspringFeeTaxChangeMember(state) {
      return state.hotspringFeeTaxChangeMember
    },
    remarksChangeMember(state) {
      return state.remarksChangeMember
    },
  },
  mutations: {
    setFlagUpdate (state, payload) {
      state.flagUpdate = !state.flagUpdate
    },

    setDataChangeMember(state, data) {
      state.idChangeMember = data.id
      state.monthChangeMember = data.month
      state.annualFeeChangeMember = data.annualFee
      state.annualFeeTaxChangeMember = data.annualFeeTax
      // state.membershipFeeChangeMember = data.membershipFee
      state.membershipFeeTaxChangeMember = data.membershipFeeTax
      state.managementFeeChangeMember = data.managementFee
      state.managementFeeTaxChangeMember = data.managementFeeTax
      state.hotspringFeeChangeMember = data.hotspringFee
      state.hotspringFeeTaxChangeMember = data.hotspringFeeTax
      state.remarksChangeMember = data.remarks
    },
    // set clientAnnualfeeListChangeMember
    setClientAnnualfeeListChangeMember(state, data) {
      state.clientAnnualfeeListChangeMember = data
    },
    setMonthChangeMember(state, data) {
      state.monthChangeMember = data
    },
    setAnnualFeeChangeMember(state, data) {
      state.annualFeeChangeMember = parseInt(data)
    },
    setAnnualFeeTaxChangeMember(state, data) {
      state.annualFeeTaxChangeMember = data
    },
    setMembershipFeeChangeMember(state, data) {
      state.membershipFeeChangeMember = parseInt(data)
    },
    setMembershipFeeTaxChangeMember(state, data) {
      state.membershipFeeTaxChangeMember = data
    },
    setManagementFeeChangeMember(state, data) {
      state.managementFeeChangeMember = parseInt(data)
    },
    setManagementFeeTaxChangeMember(state, data) {
      state.managementFeeTaxChangeMember = data
    },
    setHotspringFeeChangeMember(state, data) {
      state.hotspringFeeChangeMember = parseInt(data)
    },
    setHotspringFeeTaxChangeMember(state, data) {
      state.hotspringFeeTaxChangeMember = data
    },
    setRemarksChangeMember(state, data) {
      state.remarksChangeMember = data
    },
    setClientIdChangeMember(state, data) {
      state.clientIdChangeMember = data
    },
  },
  actions,
};

export default changeMember;
