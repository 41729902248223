import actions from './actions';

const notice = {
  state: {
    IDNotice: null,
    titleNotice: '',
    bodyNotice: '',
    imageUrlNotice: '',
    showFromDateNotice: '',
    showToDateNotice: null,
    importantNotice: false,
    publishedNotice: false,
    isReload: false,
  },
  getters: {
    titleNotice(state){
        return state.titleNotice
    },
    bodyNotice(state){
        return state.bodyNotice
    },
    imageUrlNotice(state){
        return state.imageUrlNotice
    },
    showFromDateNotice(state){
        return state.showFromDateNotice
    },
    showToDateNotice(state){
        return state.showToDateNotice
    },
    checkReload(state){
        return state.isReload
    },
  },
  mutations: {
    setReload(state,val){
        state.isReload = !state.isReload
    },
    setIDNotice(state,val){
        state.IDNotice = val
    },
    setTitleNotice(state,val){
        state.titleNotice = val
    },
    setBodyNotice(state,val){
        state.bodyNotice = val
    },
    setImageUrlNotice(state,val){
        state.imageUrlNotice = val
    },
    setShowFromDateNotice(state,val){
        state.showFromDateNotice = val
    },
    setShowToDateNotice(state,val){
        state.showToDateNotice = val
    },
    setFileUrlNotice(state,val){
        state.imageUrlNotice = val
    },
    setImportantNotice(state,val){
        state.importantNotice = val
    },
    setPublishedNotice(state,val){
        state.publishedNotice = val
    },
    
  },
  actions,
};

export default notice;
