export const CLIENT_ACTION_HISTORY_LIST_MY_PAGE = `
query($data:ListClientActionHistoryInput) 
{
    clientActionHistoryList(data: $data) {
        items {
            id 
            actionPlannedAt 
            client {
              id 
              name
              type 
            } 
            participants 
            actionMethod {name} 
            priority 
            action 
        }
    }
}`;

export const CALENDAR_MY_PAGE = `
query($data: ListClientActionHistoryInput) {
    clientActionHistoryList(data: $data) {
      items {
        id 
        actionAt 
        action
        actionPlannedAt 
        client { name }
        priority
        clientActionType { name } 
      }
    }
}`;

export const COMMENT_MY_PAGE = `
query($mentionedStaffId: Int!, $pagination: PaginationInput!) {
    clientCommentMentionList(
      mentionedStaffId: $mentionedStaffId
      pagination: $pagination
    ) {
      items {
        id
        clientComment {
          id
          client {
            id
            name
            type
          } 
          commentBy {
            id
            profileImgUrl
            name 
            mentionName
          }
          likes
          likedByStaff{__typename}
          likesByStaffs {staff { id name}}
          comment 
          createdAt 
        }
      }
      total
    }
  }`;

export const CREATE_CLIENT_COMMENT_MY_PAGE = `
mutation (
  $clientId: Int! 
  $comment: String! 
) {
  createClientComment(
     comment: $comment
     clientId: $clientId
  ) {
    id
    commentById
  }
}
`;
