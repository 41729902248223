export const SEARCH_CLIENT_LIST = `
  query ($filter: ClientFilterSearchInput!, $orderBy: [OrderByInput!], $pagination: PaginationInput!) {
    searchClientList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
      total
      items {
          id
          isCompany
          name
          type
          lastBooking {
            checkedOutAt
            bookingGroupId
            bookingTypeId
            id
            checkInDate
            checkOutDate
            room { roomType { facility { name } } }
            computed {
                status
            }
          }
          pic { name}
      }
    }
  }
`;
