import { apolloClient } from '@/plugins/apollo';
import { SEARCH_CLIENT_LIST, STAFF_LIST, GET_PIC } from '@/api/graphql/myPage/CustomerInCharge/PurchaseEvaluation';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getSearchPurchaseEvaluationList({ commit, state }, {take, skip, orderBy}) {
    const finishData = {
      filter: {
        purchaseRates: state.purchaseRateSelected,
        picIds: state.picPurchaseEvaluationSelected,
        lastCampaignId:
          state.lastCampaignApplyResponsePurchaseEvaluationSelected,
      },
      load: {
        loadLastAction: true,
        loadLastCampaign: true,
      },
      orderBy: orderBy,
      pagination: {
        take,
        skip,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit(
            'searchPurchaseEvaluationList',
            data.data.searchClientList.items,
          );
          resolve(data.data.searchClientList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getEnumPurchaseEvaluation({ commit, state }) {
    const finishData = {
      filter: {
        purchaseRates: [21, 31, 41, 11, 51, 61],
        picIds: null,
        lastCampaignId: null,
      },
      load: {
        loadLastAction: true,
        loadLastCampaign: true,
      },
      orderBy: {
        fieldName: 'client.id',
        direction: 'DESC',
      },
      pagination: {
        take: null,
        skip: null,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          const arr = data.data.searchClientList.items
          resolve(arr);
        })
        .catch(error => {
          // setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          // handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async getActionStaffList({ commit, state , dispatch}, {take, skip}) {
    const finishData = {}
    return new Promise((resolve, reject) => {
        apolloClient
            .query({
                query: gql`${STAFF_LIST}`,
                variables: finishData,
                fetchPolicy: 'no-cache'
            }).then(data => {
                dispatch('proClientChangeHistoryList', {take, skip})
                commit('setPicPurchaseEvaluation', data.data.staffList)
                resolve(data.data.staffList)
            })
            .catch(error => {
              setCopyErrorTextCommit(commit, STAFF_LIST, finishData, error.graphQLErrors)
                handlError(commit, error.graphQLErrors);
                reject(error);
            });
    })
  },

  async getPic({ commit, state}) {
    const finishData = {    
      clientId: parseInt(localStorage.getItem('id'))
    }
    return new Promise((resolve, reject) => {
        apolloClient
            .query({
                query: gql`${GET_PIC}`,
                variables: finishData,
                fetchPolicy: 'no-cache'
            }).then(data => {
                resolve(data.data.clientBasicInfo.pic)
            })
            .catch(error => {
              setCopyErrorTextCommit(commit, GET_PIC, finishData, error.graphQLErrors)
              handlError(commit, error.graphQLErrors);
              reject(error);
            });
    })
  }
};
