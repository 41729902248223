export const CREATE_CLIENT = `
  mutation($data: ManualCreateClientInput!) {
    createClient(data: $data) {
      id
      type
    }
  }
`;

export const SEARCH_CLIENT_LIST = `
query(
  $pagination : PaginationInput!
  $filter : ClientFilterSearchInput!
  $orderBy : [OrderByInput!]
  ) {
  searchClientList(
    pagination: $pagination
    filter: $filter
    orderBy: $orderBy
  ) {
    items {
      id
      isCompany
      companyName
      companyNameKana
      lastName
      firstName
      birthday
      contacts {
        tel
        postalCode
      }
    }
    total
  }
}
`;

export const POSSIBLY_DUPLICATED_CLIENT_LIST = `
  query(
    $isCompany: Boolean!
    $firstName: String
    $lastName: String
    $companyName: String
    $tel: String
    $birthday: DateString
    $postalCode: String
  ) {
    possiblyDuplicatedClientList(data: {
      isCompany: $isCompany
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      tel: $tel
      birthday: $birthday
      postalCode: $postalCode
    }) { # array. If the length > 0 then there is a possible duplicated client
      id
    }
  }
`