<template>
  <span class="thousands"><span class="prefix" v-if="prefix">{{prefix}}</span>{{text}}</span>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    prefix: String
  },
  computed: {
    numberValue() {
      if (typeof this.value === 'number') {
        return this.value;
      } else if (typeof this.value === 'string') {
        // get the number from the string.
        // ignore certain chars
        const num = this.value.replace(/[^0-9.]/g, '');
        return Number(num);
      } else {
        // no input (maybe null?)
        return undefined
      }
    },
    text() {
      if (typeof this.numberValue === 'number' && !isNaN(this.numberValue)) {
        return this.$options.filters.toThousands(Math.round(this.numberValue))
      } else {
        return '-'
      }
    }
  }
}
</script>
