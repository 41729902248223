import { getUserInfo } from '@/api/login'
import router from '@/router'

export default {
  async resetToken ({ commit }, userInfo) {
    try {
      commit('SET_TOKEN_AND_EMAIL', {
        email: userInfo.email,
        token: userInfo.token

      })
      return true
    } catch (e) {
      return false
    }
  },

  async setTokenAndEmail ({ commit }, userInfo) {
    try {
      commit('SET_TOKEN_AND_EMAIL', {
        email: userInfo.email,
        token: userInfo.token
      })
      return true
    } catch (e) {
      return false
    }
  },

  async LoginByUsername ({ commit,state },token) {
    try {
      commit('SET_USER', {
        email: state.emailSave,
      })
      commit('SET_TOKEN', token.token)
      commit('SET_RFTOKEN', token.refreshToken)
      return true
    } catch (e) {
      return false
    }
  },

  async logOut ({ commit, state }) {
    if (!window.localStorage.getItem('rememberID'))
    {
      window.localStorage.removeItem('email')
    }
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('id')
    window.localStorage.removeItem('name')
    router.push({ name: 'signin' });
  },

  // async handleResetToken({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //       apolloClient
  //           .mutate({
  //               mutation: REFRESH_STAFF_TOKEN,
  //               variables: {
  //                 refreshToken: state.refreshToken
  //               }
  //           }).then(data => {
  //             commit('SET_TOKEN',data.data.refreshStaffToken.accessToken)
  //             commit('SET_RFTOKEN',data.data.refreshStaffToken.refreshToken)
  //           })
  //           .catch(error => {
  //             console.log(error);
  //           });
  //   })
  // },

  async GetUserInfo ({ commit, state, dispatch }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const userData = await getUserInfo(state.token)
      commit('SET_USER', userData)
      return userData
    } catch (e) {
      // dispatch('handleResetToken')
      console.log(e);
    }
  }
}
