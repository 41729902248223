<template>
  <v-text-field
    v-model="localValue"
    :rules="intRules"
    @change="commit"
    :prefix="intPrefix"
    :disabled="disabled"
    :readonly="readonly"
    v-bind="$attrs"
  />
</template>

<script>
import {translateZenkakuNumbers} from '@/utils/japanese'
export default {
  props: {
    value: [Number, String],
    prefix: String,
    rules: Array,
    negative: Boolean,
    jpy: Boolean,
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    allowZero: Boolean,
  },
  data() {
    return {
      localValue: null,
    };
  },
  computed: {
    numberLocalValue() {
      let number = null;
      if (this.localValue) {
        const numberText = translateZenkakuNumbers(this.localValue);
        number = Number(numberText.replace(/[^0-9.-]/g, ''));

        if (isNaN(number)) {
          number = null
        } else {
          number = Math.floor(number)
        }
      }
      return number;
    },
    intRules() {
      const rules = [
        v => typeof this.numberLocalValue !== 'number' || this.numberLocalValue <= 2147483647 || '最大値は2,147,483,647です',
        v => this.negative || typeof this.numberLocalValue !== 'number' || this.numberLocalValue >= 0 || '0以上の値を入力下さい',
        v => !this.negative || typeof this.numberLocalValue !== 'number' || this.numberLocalValue >= -2147483648 || '最小値は-2,147,483,648です',
      ].concat(this.rules ?? []);

      if(this.required) {
        rules.unshift(this.$rules.required)
      }
      return rules;
    },
    intPrefix() {
      return this.prefix ?? (this.jpy ? '¥' : undefined)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if ((this.allowZero && typeof v === 'number' ) || v) {
          const num = Number(v);
          this.localValue = isNaN(num) ? null : num.toString()
          this.formatValue();
        } else {
          this.localValue = null;
        }
      }
    }
  },
  methods: {
    formatValue() {
      if (this.numberLocalValue) {
        this.localValue = this.$options.filters.toThousands(this.numberLocalValue)
      }
    },
    commit() {
      this.$emit('input', this.numberLocalValue);
      this.$emit('change', this.numberLocalValue);
      this.formatValue();
    }
  }
};
</script>

<style lang="scss" scoped></style>
