import actions from './actions';
import moment from "moment";

const experienceLodging = {
  state: {
    searchClientListExp: [],
    fromDate: '0001-01-01',
    fromDateSelected: moment().add(1, 'days').format('YYYY-MM-DD'),
    toDateSelected: moment().add(2, 'days').format('YYYY-MM-DD'),
    canceledReservation: [
      { id: null, name: '指定なし' },
      { id: true, name: 'キャンセルのみ' },
      { id: false, name: 'キャンセルを除く' },
    ],
    canceledReservationSelected: null,
    pic: [{ id: null, name: '佐々木剛' }],
    picSelect: parseInt(localStorage.getItem('id')),
  },
  getters: {
    searchClientListExp(state) {
      return state.searchClientListExp;
    },
    canceledReservation(state) {
      return state.canceledReservation;
    },
    canceledReservationSelected(state) {
      return state.canceledReservationSelected;
    },
    picEx(state) {
      return state.pic;
    },
    picSelect(state) {
      return state.picSelect;
    },
    fromDate(state) {
      return state.fromDate;
    },
    fromDateSelected(state) {
      return state.fromDateSelected;
    },
    toDateSelected(state) {
      return state.toDateSelected;
    },
  },
  mutations: {
    searchClientListExp(state, arr) {
      state.searchClientListExp = arr;
      arr.forEach(element => {
        if (element.pic !== null) {
          var obj1 = {
            id: element.pic.id,
            name: element.pic.name,
          };
          state.pic.push(obj1);
          const nameLocal = state.pic.find(e => e.name === window.localStorage.getItem('name'));
          if (nameLocal) {
            this.commit('setpicSelect', nameLocal.id ?? null)
          }
        }
        // if (element.canceledReservation !== null) {
        //   var obj = {
        //     id: element.id,
        //     name: element.name,
        //   };
        //   state.canceledReservation.push(obj);
        // }

        if (element.fromDate !== null) {
          state.fromDate = element.fromDate;
        }
      });
    },
    setcanceledReservationSelected(state, val) {
      state.canceledReservationSelected = val;
    },
    setpicSelect(state, val) {
      state.picSelect = val;
    },
    setfromDateSelected(state, val) {
      state.fromDateSelected = val;
    },
    setToDateSelected(state, val) {
      state.toDateSelected = val;
    },
  },
  actions,
};

export default experienceLodging;
