import actions from './actions';

const uploadDocuments = {
  state: {
    contractId: null,
    fileKey: '',
    fileName: ''
  },
  getters: {
    getFileKey(state) {
      return state.fileKey
    },
    getFileName(state) {
      return state.fileName
    }

  },
  mutations: {
    setContractId(state, payload) {
      state.contractId = payload
    },

    setFileKey(state, payload) {
      state.fileKey = payload
    },

    setFileName(state, payload) {
      state.fileName = payload
    },

  },
  actions,
};

export default uploadDocuments;
