import actions from './actions'
import { i18n } from '@/plugins/i18n'

const alert = {
  state: {
    success: {
      type: null,
      messages: {
        createSuccess: i18n.t('messages.successfulNewCreation'),
        updateSuccess: i18n.t('messages.successfulUpdate'),
        deleteSuccess: i18n.t('messages.deletedSuccessfully'),
        successSend: i18n.t('messages.successSend')
      }
    },
    error: {
      type: null,
      messages: {
        tokenExpires: i18n.t('messages.tokenExpires'),
        updateError: i18n.t('messages.updateError'),
        createError: i18n.t('messages.createError'),
        passwordError: i18n.t('messages.passwordError'),
      }
    },
    errorDialog: {
      type: null,
      messages: {
        duplicateDates: i18n.t('messages.duplicateDates')
      }
    },
    loading: false,
    copyErrorText: null
  },
  getters: {
    getCopyErrorText(state) {
      return state.copyErrorText
    },
  },
  mutations: {
    setAlertSuccess(state, type) {
      state.success.type = type
    },
    setAlertError(state, type) {
      state.error.type = type
    },
    setCopyErrorText(state, text) {
      state.copyErrorText = text
    },
    setErrorDialog(state, type) {
      state.errorDialog.type = type
    },
    setLoadingOverlayShow (state) {
      state.loading = true
    },
    setLoadingOverlayHide (state) {
      state.loading = false
    }
  },
  actions
}

export default alert
