export default {
  messages: {
    successfulNewCreation: 'Created',
    successfulUpdate: 'Has been updated.',
    successSend: 'Send',
    deletedSuccessfully: 'It has been deleted.',
    tokenExpires: 'Token expiration date',
    updateError: 'Update failed',
    createError: 'Unable to create new one',
    duplicateDates: 'Duplicate dates !!',
    passwordError: 'The password could not be set.',
    salesRepresentativeDecisionError: 'Sales representative decision failed.',
    searchFailed: 'Search failed',
    emailExists: 'E-mail already exists.'
  },
  auth: {
    login: 'Login',
    username: 'Username',
    password: 'Password',
  },
  sidebar: {
    myPage: 'My page',
    advertising: 'Advertising measures',
    customer: 'Customer',
    contract: 'Contract',
    corporate: 'Corporate ticket',
    inCharge: 'In charge',
    report: 'Report・CSV',
    webPageManage: 'WEB page management',
    user: 'User',
    membershipFeeConfirmation: 'Annual membership fee confirmation',
    giftTicket: 'Gift ticket',
  },
  routeTitles: {
    dashboard: 'Dashboard',
    calendar: 'Calendar',
    booking: 'Booking',
    feesPoints: 'Fees / Points',
    externalCooperation: 'External cooperation',
    report: 'Report',
    facility: 'Facility',
    editingFacility: 'Editing facility',
    roomTypesSettings: "Rooms' Types Settings",
    roomTypeEditing: "Room's Type Editing",
    roomsSettings: 'Rooms Settings',
    roomsEditing: "Room's Editing",
    loans: 'Loans',
    keySettings: 'Key Settings',
    printingForm: 'Printing form',
    pageNotFound: 'Page not found',
    facilitySettings: 'Facility settings',
    loanProducts: 'Loan Products',
    carPark: 'Car Park',
    parkingTax: 'Parking Tax',
    roomTypeInfo: 'Room type information',
    roomInfo: 'Room information',
    mwUsageStatus: 'MW contract',
    RCIUsageStatus: 'RCI usage status',
  },
  common: {
    btnAddBase: 'Add base',
    inDevelopment: 'In Development',
    title: 'Title',
    search: 'Search',
    status: 'Status',
    contract: 'Contract',
    facility: 'Facility',
    roomType: 'Room type',
    room: 'Room',
    roomNumber: 'Room number',
    checkIn: 'Check in',
    checkOut: 'Check out',
    numOfDays: 'Days',
    representative: 'Representative',
    adult: 'Adult',
    children: 'children',
    email: 'Email',
    cases: 'Cases',
    showing: 'showing',
    noResults: 'No results',
    toDay: 'Today',
    kindName: 'Kind name',
    fileDownload: 'File download',
    notIn: 'Not in',
    notOut: 'Not out',
    unpaid: 'Unpaid',
    member: 'Member',
    bedSharing: 'Bed sharing',
    large: 'Large',
    small: 'Smail',
    landing: 'Landing',
    block: 'block',
    confirmed: 'confirmed',
    none: 'None',
    memberName: 'Member name',
    group: 'Group',
    remark: 'Remark',
    city: 'City',
    country: 'Country',
    amountOfMoney: 'Amount of Money',
    numberOfSheets: 'Number of sheets',
    close: 'Close',
    save: 'Save',
    notices: 'Notices',
    dateOfOccurrence: 'Accrual date',
    unitPrice: 'Unit price',
    startOfDeadline: 'Start of Deadline',
    endOfDeadline: 'End of deadline',
    subjectName: 'Subject name',
    unit: 'Unit',
    points: 'Points',
    quantity: 'Quantity',
    order: 'Order',
    VAT: 'Consumption tax',
    subtotal: 'Subtotal',
    total: 'Total',
    night: 'Night',
    cash: 'Cash',
    settlementMethod: 'Settlement method',
    cardType: 'Card type',
    unionPay: 'UnionPay',
    membershipRegisteredCard: 'Member registration card',
    beforeChange: 'Before change',
    proviso: 'Proviso',
    provisoCut: 'Proviso cut',
    fullName: 'Full name',
    page: 'Page',
    target: 'Target',
    totalUse: 'Total usage',
    checked: 'Checked',
    remarksContentSearch: 'Remarks Content search',
    numOfPersons: 'Number of people',
    unaccompanied: 'Non-sleeping',
    accompanied: 'Cosleeping',
    occupation: 'Profession',
    age: 'Age',
    nationality: 'Nationality',
    payableDate: 'Reception date',
    reservation: 'Reserve',
    time: 'Times of day',
    question: 'Question',
    questionOrder: 'Choices',
    questionContent: 'Contents of question',
    freeText: 'Free text',
    option: 'option',
    requiredAnswer: 'Required answer',
    optionalAnswer: 'Optional answer',
    author: 'Author',
    checkInDate: 'Check-in date',
    start: 'Start',
    end: 'End',
    noSet: 'Not set',
    set: 'Set',
    discount: 'Pull',
    yen: 'Circle',
    overNight: 'Over night',
    untilNight: 'Until night',
    period: 'Period',
    months: 'Months',
    item: 'Item',
    startPeriod: 'Start period',
    endPeriod: 'End period',
    date: 'Date',
    number: 'Number',
    credit: 'Credit',
    point: 'Point',
    subItem: 'Sub-item',
    day: 'Day',
    icon: 'Icon',
    enable: 'Enable',
    disable: 'Disable',
    image: 'Image',
    noSmoking: 'No smoking',
    smoking: 'Smoking',
    roomTypeTitle: 'Pets',
    paymentUnit: 'Payment unit',
    totalAmount: 'Total amount',
    stay: 'Stay',
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    holidays: 'Holidays',
  },

  commons: {
    cancel: 'Cancel',
    action: 'Action',
    status: 'Status',
    date: 'Date',
    setting: 'Setting',
    gender: 'Gender',
    age: 'Age',
    furigana: 'Furigana',
    aboutUs: 'About us',
    zipCode: 'Zip code',
    townStreet: 'Street・Address',
    buildingName: 'Building name',
    campaign: 'Campaign',
    cost: 'Cost',
    cases: 'Cases',
    showing: 'Showing',
    all: 'All',
    customerName: 'Customer name',
    customerCode: 'Customer code',
    evaluation: 'Evaluation',
    phone: 'Phone',
    buildingNameCondominium: 'Building name, condominium name...',
    expirationDate: 'Expiration date',
    start: 'Start',
    end: 'End',
    contact: 'Contact',
    privateCorporation: 'Private corporation',
    period: 'Period',
    item: 'Item',
    comment: 'Comment',
    onesHome: "One's home",
    total: 'Total',
    empty: 'Empty',
    contractStatus: 'Status',
    facility: 'Facility',
    year: 'Year',
    week: 'Week',
    remark: 'Remarks',
    dateAndTime: 'Date and time',
    unitPrice: 'Unit price',
    processing: 'Processing',
    point: 'Point',
    actualDate: '発生日(優先度)',
    startDate: '期限開始',
    endDate: '期限終了',
    unitPoint: 'ポイント単価',
    target: 'Target',
    male: 'Male',
    female: 'Female',
    purpose: 'Purpose',
    ageUnit: 'years old',
    browsing: 'Browsing',
    webSearch: 'Web Search',
    magazine: 'Magazine',
    television: 'Television',
    program: 'Program',
    introduction: 'Introduction',
    newspaper: 'Newspaper',
    implementation: 'Implementation',
    reaction: 'Reaction',
    reacted: 'Reacted',
    latestActivityHistory: 'Lastest activity history',
    dateAdded: 'Date added',
    contractProduct: 'Contract product',
    phase: 'Phase',
    contractDetail: 'Contract details',
    yen: 'Yen',
    reReaction: 'Re-reaction',
    implemented: 'Implemented',
    none: 'None',
    benefits: 'Benefits',
    amount: 'Amount',
    miles: 'Miles',
    usageContract: 'Usage contract',
    checkIn: 'Check-in',
    checkOut: 'Check-out',
    nights: 'Nights',
    adult: 'Adult',
  },
  buttons: {
    retainAndAdd: 'Retain and add',
    registerLoanItems: 'Register additional items for loan',
    registerNewParkingLot: 'Register new parking lot',
    add: 'Add',
    login: 'Login',
    createNewFacility: 'Create a new facility',
    eachRoomSettings: 'Each room settings',
    createNewRoomType: 'Create a new room type',
    createNewRoom: 'Create a new room',
    edit: 'Edit',
    activityScheduleRegistration: 'Activity scheduleregistration',
    moveToActivityHistory: 'Move to activity history',
    editCustomerInformation: 'Edit customer information',
    formPrinting: 'Form printing',
    reservationHistory: 'Reservation history',
    newRoomReservation: 'New room reservation',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    close: 'Close',
    addMember: 'Add member',
    addPersonInCharge: 'Add person in charge',
    searchOtherCondition: 'Search other conditions',
    search: 'Search',
    labelSelectionItems: 'Label selection items',
    detail: 'Detail',
    csvOutput: 'CSV output of selected items',
    adaptingAdvertisingMeasures: 'Adapting advertising measures',
    run: 'Run',
    change: 'Change',
    update: 'Update',
    done: 'Done',
    confirmationWithPMS: 'Confirmation with PMS',
    createNewAdvertisingPolicies: 'Create new advertising policies',
    editAdvertisingPolicyContent: 'Edit advertising policy content',
    advertisingPolicyEdit: 'Advertising policy edit',
    createNewContractBenefits: 'Create new contract benefits',
    bookingRoom: 'Booking room',
    importFromCalendar: 'Import from calendar',
    exchange: 'Exchange',
    create: 'Create',
  },
  rules: {
    nameIsRequired: 'Name is required',
    minimumNCharacter: 'Must be minimum {value} character',
    maximumNCharacter: 'Content must be less than {value} characters',
    minimumN: 'Must be minimum {value}',
    maximumNumberInt32: 'Must be less than or equal to 2147483647',
    includeNCharacters: 'Must include {value} characters',
    loanItemsRequired: 'Loan item is required',
    totalQuantityRequired: 'Total quantity is required',
    totalQuantityMoreThanN: 'Total quantity must be more than {value}',
    typeRequired: 'Type is required',
    numberInvalid: 'The number is invalid.',
    requiredField: 'Required field.',
    emailIsInvalid: 'E-mail is invalid.',
    characters255: 'Within 255 characters'
  },
  webPageManage: {
    infoRequest: {
      DuplicateCustomerName: 'Duplicate customer name',
      DuplicateCustomerDetails: 'Duplicate customer details',
      MatchContent: 'Match content',
      ReceivedDate: 'Received date',
      WEBInquiryContents: 'WEB inquiry contents',
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      document: '資料請求内容',
      remark: '備考',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    infoRequestDetail: {
      back: '資料請求一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'アンケート',
      interest: '興味のある施設',
      learn: '東急バケーションズを知った経緯',
    },
    documentRequest: {
      medium: 'Medium',
      customerSalesStaff: 'Customer sales staff',
      receiveDate: 'Receive date',
      CustomerDetails: 'Customer details',
      customer: 'Corporation/Individual',
      companyName: 'Name/Company name',
      BillingMaterial: 'Billing material',
      importMethod: 'Import method',
      detail: 'Detail',
      btnLeft: 'No sales representative',
      btnRight: 'All',
      btnCsv: 'CSV upload',
      btnTable: 'Person in charge allocation to selection items',

      date: 'Date',
      AdvertisingMeasures: 'Advertising measures',
    },
    booking: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    bookingDetail: {
      back: 'ご来店予約一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容',
    },
    consultation: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    consultationDetail: {
      back: 'オンライン相談一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: 'ご来店希望日・ご相談内容',
      firstHope: '第一希望日',
      secondHope: '第二希望日',
      content: 'ご相談内容',
    },
    experience: {
      search: '検索',
      displaySupport: '対応済も表示',
      status: 'ステータス',
      date: '受信日',
      name: '名前',
      phone: '電話番号',
      email: 'メール',
      firstHope: '第一希望',
      secondHope: '第二希望',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
      salesRepresentative: '営業担当者',
      numOfTimes: '回数',

      AccommodationExperience: 'Accommodation Experience',
    },
    experienceDetail: {
      back: '体験宿泊申込一覧に戻る',
      status: 'ステータス',
      date: '受信日：',
      customerInfo: '反響顧客情報',
      detail: '詳細',
      title1: 'お客様情報',
      requestDetail: '資料請求内容',
      name: 'お名前',
      Frigana: 'フリガナ',
      age: '年齢',
      postCode: '郵便番号',
      address: 'ご住所',
      contact: 'ご連絡先',
      homePhone: '自宅電話番号',
      cellPhone: '携帯電話番号',
      email: 'メールアドレス',
      remark: '備考',
      update: '更新',
      title2: '体験宿泊希望日・施設',
      firstHope: '第一希望',
      secondHope: '第二希望',
      content: 'ご相談内容',
    },
    notice: {
      search: '検索',
      add: '新規お知らせ',
      status: 'ステータス',
      date: '受信日',
      creator: '作成者',
      category: 'カテゴリー',
      title: 'タイトル',
      content: '内容',
      support: '対応済',
      notSupport: '未対応',
      detail: '詳細',
      customerDetail: '顧客詳細',
      customerCreate: '顧客作成',
    },
    noticeDetail: {
      back: 'お知らせ一覧に戻る',
      date: '作成日：',
      title: 'タイトル',
      checkbox: '重要',
      text: '本文',
      delete: '削除',
      update: '保存',
      push: '掲載',
    },
    routeName: {
      info: 'WEBページ管理_資料請求',
      infoDetail: 'WEBページ管理_資料請求_今津康平',
      booking: 'WEBページ管理_来店予約',
      bookingDetail: 'WEBページ管理_来店予約_今津康平',
      consultation: 'WEBページ管理_オンライン相談',
      consultationDetail: 'WEBページ管理_オンライン相談_今津康平',
      notice: 'WEBページ管理_お知らせ',
      noticeDetail: 'WEBページ管理_お知らせ_新型コロナウイルス感染',
      experience: 'WEBページ管理_体験宿泊',
      experienceDetail: 'WEBページ管理_体験宿泊_今津康平',
      customer: '東急太郎',
      webDocument: 'WEB page management Member WEB materials',
      facilitySetting:'WEB page management_Facility settings'
    },
  },
  customerDetail: {
    tabs: {
      basicInformation: 'Basic information',
      comment: 'Comment',
      activities: 'Activities',
      changeHistory: 'Change history',
    },
    activity: {
      scheduledActionDate: 'Scheduled action date',
      personInCharge: 'Person in charge',
      means: 'Means',
      opponent: 'Opponent',
      priority: 'Priority',
      negotiationDate: 'Negotiation date',
      cti: 'CTI',
      complaintHandling: 'Complaint handling',
      activityHistoryRegistration: 'Activity history registration',
      equalizationDay: 'Equalization day',
      registrationCti: 'Activity registration via CTI',
    },
    changeHistory: {
      beforeChange: 'Before change',
      afterChange: 'After change',
    },
    infoEdit: {
      customerInfo: 'Customer info',
      customerRecordType: 'Customer record type',
      customerLastName: 'Customer (last name)',
      customerName: 'Customer (name)',
      furiganaLastName: 'Furigana (last name)',
      furiganaName: 'Furigana (name)',
      membershipNumber: 'Membership number',
      customerNumber: 'Customer number',
      contractDate: 'Contract date',
      withdrawal: 'withdrawal',
      withdrawalDate: 'Withdrawal date',
      reasonForWithdrawal: 'Reason for withdrawal',
      totalCustomer: 'Total number of customers',
      country: 'Country',
      zipCode: 'Zip code',
      prefecture: 'Prefecture',
      city: 'City',
      townStreet: 'Town name / street number',
      buildingName: 'Building name, condominium name',
      personalBudget: 'Personal budget',
      overallBudget: 'Overall budget',
      annualIncome: 'Annual income',
      paymentDay: 'Payment day',
      depositDate: 'Deposit date',
      depositAmount: 'Deposit amount',
      depositType: 'Deposit type',
      depositAmountDifferent: 'Deposit amount different',
      faceToFaceMethod: 'Face to face method',
      faceToFacepartner: 'Face to face partner',
      shareWithStaff: 'Items to be shared with other staff',
      memberInfo: 'Member information',
      givenNames: 'Given names',
      relationship: 'Relationship',
      familyMember: 'Family member',
      deputyMember: 'Deputy member',
      personInCharge: 'Person in charge',
      saleStaff: 'sale staffs',
      responsibleStartDate: 'Responsible start date',
      contactInCharge: 'Contact in charge',
      introducer: 'Introducer',
      contractInfo: 'Contract information',
      numberOfContract: 'Number of contract',
      contractAmount: 'Contract amount',
      contractUnitPrice: 'Contract unit price',
      PWSelectionFacility1: 'PW selection facility①',
      PWSelectionFacility2: 'PW selection facility②',
      pointUtilizationRate: 'Point utilization rate',
      totalNumberOfPoints: 'Total number of points',
      totalPointUtilizationRate: 'Total point utilization rate',
      renewalRate: 'Renewal rate(year)',
      updateRate: 'Update rate (Monday)',
      renewalRateByDivide: 'Renewal rate (by product/number of points)',
      numberContracts: 'Number contracts',
      reservationInformation: 'Reservation information',
      visitDay: 'Visit day',
      experienceFacilityName: 'Experience facility name',
      occupancyRate: 'Occupancy rate by weekday, weekend, and top season',
      reservationLeadTime: 'Reservation lead time',
      reverberationInformation: 'Reverberation information',
      echoSource: 'Echo source',
      echoDay: 'Echo day',
      numberOfReverberations: 'Number of reverberations',
      contractRate: 'Contract rate',
      facilitiesOfInterest: 'Facilities of interest',
      reasonWithdrawalConsideration: 'Reason for withdrawal from consideration',
      reasonForWithdrawalOther:
        'Reason for withdrawal from consideration (others)',
      individualBusinessPartner:
        'Individual business partner: Age (at the time of inquiry)',
      responseAcquisition: 'Response acquisition unit price',
      purposeOfUse: 'Purpose of use',
      mainPurposeOfUse: 'Main purpose of use',
      purposeOfUseOfSub: 'Purpose of use of sub',
      workNameKanji: 'Work name (Kanji)',
      personalBusinessFuriganaLastName:
        'Personal business partner: Furigana (last name)',
      personalBusinessFuriganaName:
        'Personal business partner: Furigana (name)',
      salesPerson: 'Sales person',
      streetBunch: 'Street Bunch',
      campaignPeriod: 'Campaign period',
      pointForSP: 'Number of points used annually for SP',
      possessionContract: 'Possession contract',
      subContract: 'Sub contract',
    },
  },
  myPage: {
    title: 'My Page',
    titleTab: {
      myPage: 'My Page',
      customerEvaluation: 'Customer evaluation',
      trialStay: 'Trial stay',
      faceToFace: 'Face-to-face',
    },
    trialStay: {
      dateTrialStayAndVisit: 'Date of trial stay and visit',
      salesRepresentative: 'Sales representative',
      customerNumber: 'Customer number',
      customerName: 'Customer name',
      expFacilityName: 'Experience facility name',
      numOfNight: 'Mumber of nights',
      cancellation: 'Cancellation',
      details: 'Details',
      VSEnrollmentDuplication: 'VS Enrollment Duplication',
    },
  },
  personalReport: {
    title: '個人レポート',
    headerList: {
      searchTitle: '顧客/契約/活動検索',
      responseNum: '営業担当者別反響件数',
      latest: '最新',
      usageStatus: '体験宿泊利用状況',
    },
    titleTab: {
      GLCList: 'GLC List',
      experience: 'Experience accommodation',
      meeting: 'Meeting',
    },
    GLCList: {
      search: '検索',
      total: '総計',
      responseDay: '反響日',
      lastActiveDay: '最終活動日',
      evaluation: '評価',
      surname: '姓',
      name: '名',
      surnameFurigana: 'フリガナ（姓）',
      nameFurigana: 'フリガナ（名）',
      postalCode: '郵便番号',
      prefectures: '都道府県',
      cityOrCounty: '市区郡',
      streetBunch: '町名・番地',
      building: 'ビル・マンション号室',
      latestCampaign: '最新キャンペーン',
      latestActivityStatus: '最新活動状況',
      shareItem: '他のスタッフへの要共有事項',
      customerNumber: '顧客番号',
      detail: '詳細',
    },
    experience: {
      customerId: '顧客番号',
      customerName: '顧客名',
      comingDate: '体験宿泊日・見学日',
      comingLocation: '体験施設名',
      detail: '詳細',
    },
    meeting: {
      total: '顧客の対面件数 ',
      meetingInfo: '対面情報',
      contractInfo: '契約情報',
      experienceInfo: '体験情報',
      comingDate: '体験宿泊日・見学日',
      comingLocation: '体験施設名',
      productName: '商品名（システム用）',
      pointByYear: 'SP年間ポイント数',
      customerType: '顧客レコードタイプ',
      customerName: '顧客名',
      personalAge: '個人引取先・年齢',
      address: '等道府県（自宅）',
      responseSource: '反響ソース',
      applyMethod: '申込方法',
      saleAmount: '営業売上金額',
      salePerson: '販売担当者',
      meetingDate: '対面日',
      meetingMethod: '対面方法',
      meetingPartner: '対面相手',
      detail: '詳細',
    },
  },
  advertising: {
    title: '広告施策',
    search: '検索',
    detail: '詳細',
    buttonAdd: '新規広告施策',
    lastUpdate: '最終更新者',
    stage: '期間',
    personInCharge: '担当者',
    addCustomer: '対象顧客追加',
    note:
      '※ 顧客情報または契約情報が存在しない場合のみ、下部の削除ボタンより削除することができます',
    routeName: {
      advertising: '広告施策',
      advertisingPoliciesDetail: 'インターネット検索',
      /* policy -.- */
      contractSpecialDetail: '東急グループ社員　紹介特典',
      /* advertising > contractSpecialDetail */
    },
    tabs: {
      policies: '広告施策リスト',
      contractSpecial: '契約特典リスト',
    },
    policies: {
      advertisingName: '広告施策名',
      policiesName: '契約特典名',
      customerNum: '広告施策顧客数',
      contractRate: '成約率 (成約数/反響数)',
      cost: '費用',
      contractAmount: '成約金額',
      advertisingPeriod: '広告施策期間',
      advertisingData: '発送資料データ',
      back: 'Return to advertising policy list',
      feedbackQuantity: 'Number of repercussions',
      numOfStatedTrading: 'Number of responses already started trading',
      customerQuantity: 'Number of customers',
      description: 'Description',
      customerName: 'Customer name',
      address: 'Address',
      phone: 'Phone',
      age: 'Age',
      evalution: 'Evalution',
      lastestStatus: 'Lastest status',
      contractPoint: 'Contract point',
      salePrice: 'Sale price',
      salesRepresentative: 'Sales representative',
      uploadFile: 'File upload',
      alsoShowsSupported: 'Also shows supported',
      detail: 'Advertising policy details',
      groupName: 'Advertising policy group name',
      implemenCost: 'Implementation advertising costs',
      overYearsOld: 'years old and above',
      awarenessExpansion: 'Awareness expansion',
      harvestingPolicy: 'Harvesting policy',
      promotionOfUse: 'Promotion of use',
      method: 'Method',
      signboard: 'Signboard',
      emailNewsletter: 'Email newsletter',
      createNewDescription:
        'The main targets are those who selected Internet search as the trigger for requesting information from the website, and those who made inquiries by phone after viewing the website. If you were able to find out the reason why they looked at the website, please include it in the history or remarks.',
      uploadShippingMaterialData: 'Upload shipping material data',
      selectTargetResponseMedia:
        'Select the target response media (for automatic linking of information requests)',
      trainAdvertisement: 'Train advertisement',
      tokyuInCarAdvertisement: 'Tokyu Line in-car advertisement',
      tokyuStationAdvertisement: 'Tokyu Line station yard advertisement',
      official: 'Official',
      others: 'Others',
      JAJVacations: 'JAL Vacations',
      detailNotice:
        'The main targets are those who selected Internet search as the trigger for requesting information from the website, and those who made inquiries by phone after viewing the website. If you were able to hear the reason why they looked at the website, please write it in the history or remarks.',
      detailFirstHeading: 'Customers who implement advertising measures',
      detailSecondHeading: 'Contracts linked to advertising measures',
      implementationOrReaction: 'Implementation / reaction to be displayed',
      corporateContactPerson: 'Corporate contact person',
      corporateContactPersonTitle: 'Corporate contact person title',
      contractStatus: 'Contract status',
      advertisingPolicyGroup: 'Advertising policy group',
      totalReactions: 'Total reactions',
      newCustomer: 'New customer',
      targetEchoMediumSelection: 'Target echo medium selection',
    },
    contractSpecial: {
      benefitItems: 'Benefit items',
      targetCustomerNum: 'Target customers quantity',
      back: 'Back',
      contractPrivilegePeriod: 'Contract privilege period',
      content: 'Content',
      targetPersons: 'Target people and conditions',
      newContractBenefits: 'New contract benefits',
      contractPrivilegeName: 'Contract privilege name',
      thanksForSupport: 'Thank you for your support',
      contractName: 'Contract name',
      contractID: 'Contract ID',
      contractor: 'Contractor',
      company: 'Private corporation',
      contractDate: 'Contract date',
      contractPeriod: 'Contract Period',
      paymentDate: 'Payment date',
      deposit: 'Deposit amount',
      echoPath: 'Echo path',
      applicationDate: 'Application date',
      salesRepresentative: 'Sales representative',
      exchangePoints: 'Exchange points',
      benefitName: 'Benefit name',
      numericType: 'Numeric type',
      numericalValue: 'Numerical value',
      automaticAfterJoiningVS: 'Automatic privilege grant after joining VS',
      pointOrFee: 'Points/Fees',
      numberOfContracts: 'Number of contract',
      contractBenefitsDetails: 'Contract benefits details',
      contractBenefits: 'Contract benefits',
      fillChecbox: 'Fill in the amount/number of points/miles',
      entryValue: 'Entry value',
      issueCodeAfterJoiningVS:
        'Issue an automatic benefit grant code after joining VS',
      benefitCode: 'Benefit code',
      discountMembership: 'Annual membership fee O yen discount',
      tokyuPointsGranted: 'Tokyu Vacations points granted',
    },
  },
  corporateTicket: {
    customerDetail: 'Customer details',
    TicketDetails: 'Ticket details',
    CSVuOtputfOTicketNumber: 'CSV output of ticket number',
    applicationDate: 'Application date',
    EnabledDisabled: 'Enabled / disabled',
    RepresentativeAddress: 'Representative address',
    RepresentativePhoneNumber: 'Representative phone number',
    RepresentativeName: "Representative's name",
    AuthenticationNumber: 'Authentication number',
    lastUpdate: 'Last updated',
    ExpirationaDte: 'Expiration date',
    remarks: 'remarks',
    AvailableNumberOfSheets: 'Available number of sheets',
    NumberOfSheetsUsed: 'Number of sheets used',
    RightSettingFixedAmount: 'Right setting fixed amount',
    ReservationPeriod: 'Reservation period',
    ReservationStartDate: 'Reservation start date',
    ExpirationDate: 'Expiration date',
    ReservationRoute: 'Reservation route',
    UsageAmount: 'Usage amount',
    WhetherToUse: 'Whether to use',
    ExclusionDate: 'Exclusion date',
    DayOfTheƯeek: 'day of week',
    contractStatus: 'status',
    date: 'date',
    roomTypeInfo: 'Room type information',
    placeHoderFormSearch: 'Ticket No. Search',
    title: 'Corporate ticket',
    nameSearch: 'Display',
    dateRange: '発行日',
    nameCompanyCustomer: 'Date of issue',
    numberTicket: 'Ticket No.',
    numberProblem: 'Number of issues',
    name: 'name',
    basis: 'Facility',
    expirationDate: 'Expiration date',
    bookingDate: 'Reservation start date',
    bookingTime: 'Reservation period',
    numberOfSheetUsed: 'Number of sheets used',
    available: 'Available',
    corporateTicketBookingCreation: 'Corporate ticket booking creation',
    selectAFacility: 'Select a facility',
    childNonSleeping: 'Child (non-sleeping)',
    childCoSleeping: 'Child (co-sleeping)',
    remark: 'Remarks to be displayed on the reservation bar',
    lodgingRepresentative: 'Lodging representative',
    lodgingRepresentativeYomigana: 'Lodging representative Yomigana',
    selectACountry: 'Select a country',
    routeName: {
      corporateTicket: 'Corporate ticket',
    },
  },
  customerMember: {
    title: '顧客/会員',
    searchTitle: '顧客/契約/活動検索',
    list: {
      corporateIndividualClassification: 'Corporate individual classification',
      usageRightYear: 'Usage right year',
      purchaseFacility: 'Purchase facility',
      roomType: 'Room type',
      numberOfWords: 'Number of words',
      itemsInPossession: 'Items in possession',
      yearEachProduct: 'Years for each product',
      membershipDeadline: 'Membership deadline',
      deadlineLastProduct: 'Deadline for the last product',
      shippingAddress: 'Shipping address',
      purchaseRank: 'Purchase rank',
      advertisingMeasures: 'Advertising measures',
    },
    detail: '詳細',
    tabNames: {
      applicant: '願客リスト',
      response: '反響リスト',
      member: '会員リスト',
    },
    tabChild: {
      all: '全て',
      responseDM: '既反響DM',
      followCustomer: 'D客フォロー',
      takenPlace: '体験宿泊',
      face2Face: '対面',
    },
    experienceLodging: {
      facilityDate: '体験宿泊日・見学日',
      facilityName: '体験施設名',
    },
    headerList: {
      productName: '商品名',
      totalCustomerInCharge: '担当顧客総計',
      numberOfResponses: '営業担当者別反響件数',
      residence: '居住地',
      pastEchoCampaign: '過去反響キャンペーン',
      lastEchoDay: '最終反響日',
      faceToFaceCorrespondence: '対面対応',
    },
    reactionDM: {
      createdDate: '作成日',
      membershipNumber: '会員番号',
      customerName: '顧客名',
      age: '個人引取先・年齢（問い合わせ時)',
      lastestCampaign: '最新キャンペーン',
      detailAddress: 'ビル名・マンション名など（自宅)',
      lastestActivity: '最新活動状況',
      paymentStatus: '退会',
      businessPartner: '個人取引先（資料送付NG)',
      lastActivityDate: '最終活動日',
      lastUpdated: '最終更新日',
      customerNumber: '顧客番号',
    },
    customerFollowList: {
      echoDay: '反響日',
      membershipNumber: '会員番号',
      lastActivityDate: '最終活動日',
      listShare: '他のスタッフへの要共有事項',
      lastestStatus: '最新活動状況',
      customerNumber: '顧客番号',
      detailAddress: 'ビル名、マンション名など（自宅)',
    },
    meetingList: {
      meetingInfo: '対面情報',
      contractInfo: '契約情報',
      experienceInfo: '体験情報',
      tourDate: '体験宿泊日・見学日',
      facilityDate: '体験施設日',
      productName: '商品名（システム用)',
      annualPoints: 'SP年間ポイント数',
      recorderType: '顧客レコーダタイプ',
      age: '個人引取先：年齢',
      echoSource: '反響ソース',
      applyMethod: '申込方法',
      salesAmount: '営業売上金額',
      salesPerson: '販売担当者',
      meetingDate: '対面日',
      meetingMethod: '対面方法',
      meetingPartner: '対面相手',
    },
    member: {
      tabChild: {
        all: '全て',
        resignation: '退会・元会員',
        expiredMember: '満了会員',
        multipleEntry: '複数口',
        introduces: '紹介者',
      },
      introducesList: {
        memberNum: '会員番号',
        customerName: '顧客名',
        productName: '商品名（システム用）',
        introducer: '紹介者',
      },
      expiredMemberList: {
        memberNum: '会員番号',
        productName: '商品名',
        sysProductName: '商品名（システム用）',
        SPAnnualPoint: 'SP年間ポイント数',
        expirationDate: '契約満了日',
        chargePerson: '担当者',
        narrowDownProducts: 'Narrow down products',
      },
      multipleEntryList: {
        memberNum: '会員番号',
        customerName: '顧客名',
        sysProductName: '商品名（システム用）',
        expirationDate: '契約満了日',
        contractNum: '契約口数',
      },
      resignationList: {
        customerInfo: '顧客情報',
        contractInfo: '契約情報',
        resignation: '退会',
        resignationDate: '退会日',
        resignationNum: '退会番号',
        customerName: '顧客名',
        sysProductName: '商品名（システム用）',
        contractExDate: '契約満了日',
        reasonResignation: '退会理由',
      },
    },
  },
  contract: {
    routeName: {
      contract: 'Contract',
      sp: '野田健太:SP契約',
      mw: '野田 健太:MW契約',
      pw: '野田健太:PW契約',
    },
    contractList: {
      createNewContract: 'Create a new contract',
    },
    usageStatus: {
      usageSituation: 'Usage situation',
      mwContract: 'MW Contract',
      reservationId: 'Reservation ID',
      accommodationStartDate: 'Accommodation start date',
      accommodationEndDate: 'Accommodation end date',
      cashEquivalent: 'MW cash equivalent',
      rightsFacility: 'Rights facility',
      depositCashEquivalent: 'Deposit cash equivalent',
      expiredMWContract: 'Expired MW contract',
      contractChangeHistory: 'Contract change history',
      returnPoints: 'Return points',
      pointId: 'Point ID',
      changer: 'Changer',
      SPTotal: 'SP合計',
    },
    contractInformation: {
      basicInformation: 'SP contract basic information',
      detailInformation: 'SP contract detailed information',
      contractNumber: 'Contract number',
      salesCategory: 'sales category',
      contractYearStartDate: 'Contract year start date',
      contractYears: 'Contract years',
      priceInformation: 'Price information',
      basicPoints: 'Basic points',
      additionalPoint: 'Additional point unit price at the time of contract',
      membershipFee: 'Membership fee at the time of enrollment',
      membershipFeeConsumption:
        'Membership fee consumption tax at the time of enrollment',
      carryoverFee: 'Carryover fee',
    },
  },
  report: {
    dowloadCSVNotice: 'Select the button to download CSV',
    linkDownload: {
      weeklyReportList: 'WeeKly report list',
      listByCampaign: 'List by campaign',
      campaignList: 'Campaign list',
      detailedListByCampaign: 'Detail list by campaign',
      numberOfMonthlyContracts: 'Number of monthly contract',
      purchaseMotivationRatio: 'Purchase motivation ratio',
      listOfPurchaseMotives: 'List of purchase motives',
      purposeOfUseRatio: 'Purporse of use ratio',
      numberOfContractsByRegion: 'Number of contract by region',
      newMembersByYear: 'New members by year',
      genderRatio: 'Gender ratio',
      numberOfContractPoints: 'Number of contract points',
      individualOrCorporateRatio: 'Individual / Corporate ratio',
      reasonForWithdrawal: 'Reason for widthdrawal',
      averageUnitPriceByProduct: 'Average unit price by product',
      howToApplyForAContract: 'How to apply for a contact',
      numberOfOvernightStaysByMonth: 'Numnber of overnight stays by month',
      numberOfOvernightStaysByFacility: 'Number of overnight stays by facility',
      contractAndExperienceInformation:
        'Contract of overnight stay by facility',
      contractRateFromExperience: 'Contract rate from experience',
      contractRateForEachFacility: 'Contract Rate For Each Facility',
      faceToFaceContractRateWithRate:
        'Face to face contract rate / Face to face rate',
      contractBenefitSummaryReport: 'Contract benefit summary report',
      tallyOfFacilitiesOfInterestAtTheTimeOfResponse:
        'Tally of facilities of interest at the time of response',
      salesRatioToBudget: 'Sales ratio to budget (amount, number of units)',
      numberOfDaysToCloseReport: 'Number of days to close report',
      annualRevenueReport: 'Annual Revenue Report',
      memberPointUsageRate: 'Member Point Usage Rate',
      facilityOccupancyRate: 'Facility occupancy rate',
      facilityUtilizationRateByMember: 'Facility utilization rate by member',
      corporateTicketUsageData: 'Corporate ticket usage data',
      paymentProcessingHistory: 'Payment processing history',
      withdrawalProcessingHistory: 'Withdrawal processing history',
      usageRateOfRentalItems: 'Usage rate of rental items',
      renewalRate: 'Renewal rate',
      listOfProductUsageResults: 'List of product usage results',
      customerVoiceList: "Customer's Voice List",
      salesFigures: 'Sales figures',
      listOfMembersAndNumberOfContracts:
        'List of members and number of contracts',
    },
  },
  giftTicket: {
    orderConfirmationList: 'Order confirmation list',
    orderNumber: 'Order number',
    applicant: 'Aplicant',
    corporateOrIndividual: 'Corporate / Individual',
    applicationService: 'Application service',
    numberOfCase: 'Number of case',
    ticketIssuerEmailOpenConfirmation: 'Ticket issuer email open confirmation',
    dateTimeSending: 'Message sending date and time',
  },
  invitationsAndCoupons: {
    backToInvitationList: '招待券・利用券一覧に戻る',
    dateOfIssue: '発行日',
    invitationTicketNo: '招待券・利用券 No.',
    ticketIssueNum: '発行 枚数',
    expirationDate: '失効期限',
    reservationStartDate: '予約開始日',
    deadlineForUse: '利用期限',
    reservationPeriod: '予約可能期間',
    sheetsUsedNum: '利用 枚数',
    available: '利用 可能',
    authenticationNum: '認証用番号',
    customerName: '顧客名',
    enabledOrDisable: '有効/無効',
    applicationDate: '申込日',
    exportToCSV: '選択項目をCSV出力',
    copyAndCreate: 'コピーして新規作成',
    createNewInvitationTitle: '新規招待券・利用券発行',
    reservedPath: '予約経路',
    dayOfWeek: '曜日',
    exclusionDay: '除外日',
    userOrNot: '利用有無',
    nightwear: 'ナイトウェア',
    toothbrush: '歯ブラシ',
    shaving: '髭剃り',
    itemAvailableRent: '貸出品利用可能',
    amountUsed: '利用金額',
    free: '無料',
    fixedFree: '固定料金',
    taxInclude: '(税込)',
    cancelPolicy: 'キャンセルポリシー',
    amountRightReserved: '権利設定額',
    fixedAmountRightSetting: '権利設定定額',
    numSheetUser: '利用枚数',
    numSheetAvailable: '利用可能枚数',
    lastUpdatedBy: '最終更新者',
    callCenter: 'コールセンター',
    sales: '営業',
    note:
      '※ 招待券・利用券が未使用の場合は、下記の「削除」より削除することができます。',
    invitationNo: '利用券・招待券 No.',
    numSheetIssued: '発行枚数',
    ticketNo: 'チケットNo.',
    representativeName: '代表者名',
    ticketDetail: 'チケット詳細',
    exportTicketNumToCSV: 'チケット番号をCSV出力',
    editInvitation: '招待券・利用券編集',
  },
  manager: {
    list: 'List',
    management: 'Management',
    manager: 'Manager',
    group: 'Group',
    CreateNewGroup: 'Create new group',
    SelectAGroup: 'Select a group',
    AddTheCheckedPersonToTheSelectedGroup:
      'Add the checked person to the selected group',
    LastLogin: 'Last login',
    SalesRepresentative: 'Sales representative',
    ShowDisabledUsers: 'Show disabled users',
    salesRepresentative: 'sales representative',
    DuringContractProcedure: 'During contract procedure',
    Good: 'Good',
    Cool: 'Cool',
    Dead: 'Dead',
    NextActivitySchedule: 'Next activity schedule',
    NextActivityScheduledTime: 'Next activity scheduled time',
    UserID: 'User ID',
    groupName: 'Group name',
    numOfPersons: 'Num of persons',
    member: 'Member',
    GroupEditing: 'Group editing',
    delete: 'Delete',
    save: 'Save',
    close: 'Close',
  },
};
