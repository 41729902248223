export const CONTRACT_POINT_SUMMARY = `
query ($contractId: Int!){
    contractPointSummary(contractId: $contractId) {
      year
      validFrom
      validTo
      pointRemains
    }
}
`

export const CONTRACT_POINT_MOVEMENT_LIST = `
query ( $contractId: Int!) {
    contractPointMovementList(contractId: $contractId) {
      changedAt
      reason
      validFrom
      validThru
      pointChange
      remarks
      stayDate
      pointExchangeId
      bookingId
      booking {
        room {
          roomType {
            facility {
              name
            }
          }
        }
      }
    }
  }
`

export const PWCONTRACT_USAGE_LIST = `
query (
  $contractId: Int!
) {
  contract(id: $contractId) {
    mwPwRoomType {
            facility {
        name
      }
    }
    clientId
    client{
      type
    }
    mwPwWeekNumber
    pointPrice
    createdAt
    startDate
    endDate
  }
  pwContractUsage(contractId: $contractId) {
    rights {
      validity {
        year
        validFrom
        validThru
      }
      bookingGroup {

        bookings {

          computed {
            status
          }
          id
          room {
            roomType {
              name
              facility {
                name
              }
            }
          }
          checkInDate
          checkOutDate
          stayConfirmedAt
          spAccommodationPoint
        }
      }
    }
  }
}
`
export const UPDATE_PWCONTRACT_USAGE_LIST = `
mutation (
  $bookingId: Int! #予約ID
  $undo: Boolean
) {
  confirmStay(bookingId: $bookingId, undo: $undo) {
    __typename
  }
}
`
export const DEPOSIT_MW_BOOKING = `
mutation (
  $contractId: Int!
  $year: Int!
  $firstHalf: Boolean!
  $latterHalf: Boolean!
) {
  depositMwBooking(
    contractId: $contractId
    year: $year
    firstHalf: $firstHalf
    latterHalf: $latterHalf
  ) {
    __typename
  }
}
`
export const MWCONTRACT_USAGE_LIST = `
query(
  $contractId: Int!
) {
  mwContractUsage (contractId: $contractId) {
    id 
    clientId
    mwPwRoomType {
      name
      facility {
      name
      }
    }
    client{
      type
      id
      email
      nameKana
      name
      telPreference
      type

      subMembers{
        postalCode
        nameKana
        address1
        tel
        name
      }
      name
    }
    mwRank
    mwPwWeekNumber
    years
    rights {
      year
      weekInfo {
        fromDate
        toDate
        firstHalfFromDate
        firstHalfToDate
        latterHalfFromDate
        latterHalfToDate 
      }
      splitInfo {
        ...selectSplitInfo
      }
      actions {
        ...selectMwActions
      }
      firstHalf {
        ...selectMwRights
      }
      latterHalf {
        ...selectMwRights
      }
    }
  }
}
  
fragment selectSplitInfo on MwSplitInfo {
  bookingGroupId
  firstCheckInDate
  lastCheckOutDate
}

fragment selectMwActions on MwRightActions {
  canSplit 
  canDeposit 
  canExchange 
  canRevokeForRCI
  depositBy
  depositExpireDate
}

fragment selectMwRights on MwHalfRightSummary {
  actions {...selectMwActions} 
  splitInfo {
    ...selectSplitInfo
  }
  status
  rights {
    status
    days
    depositAt 
    exchangedAt
    roomType {
      name 
      facility {
        id
        name
      }
    }
    bookWeekNo
    bookYear
    newBookingGroupId
    newBookingGroup {
      createdAt
      bookings {
        id
        computed {
          status
        }
        stayConfirmedAt
        checkInDate
        checkOutDate
      }
    }
  }
}
`

export const CLIENT_MEMBER_INFO = `
query ($id: Int!) {
  clientMemberInfo(id: $id) {
    id
    name
    nameKana
    mainContact {
      tel
      email
      prefecture
      locality
      address1
      address2
    }
    subMembers{
      locality
      postalCode
      id
      name
      nameKana
      tel
      email
      prefecture
      locality
      address1
      address2
    }
  }
}
  `

export const MWCALCULATE_BOOKING_CANCEL_PENALTY = `
  query (
    $bookingId: Int!
  ) {
    calculateBookingCancelPenalty(bookingId: $bookingId) {
      penalties {
        stayDate
        penaltyRate
        spPoint
        penaltySpPoint
        penaltyPrice
        penaltyVmRightPrice
        vmRightPrice
      }
    }
  }
  `
export const ACTION_CALCULATE_BOOKING_CANCEL_PENALTY = `
mutation (
  $bookingId: Int!
  $penaltyRates: [CancelPenaltyRateInput!]!
) {
  cancelBooking(
    bookingId: $bookingId
    penaltyRates: $penaltyRates
  ) {
    __typename
  }
}
`

export const REVOKE_MW_RCI = `
mutation (
  $contractId: Int!
  $year: Int!
  $firstHalf: Boolean!
  $latterHalf: Boolean!
) {
  revokeMwRightForRCI (
    contractId: $contractId
    year: $year
    firstHalf: $firstHalf
    latterHalf: $latterHalf
  )
}
`
