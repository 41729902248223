export const WEBREQUEST_LIST_FACILITY  = `
query (
  $keyword: String 
  $handled: Boolean 
  $take: Int
  $skip: Int
  $type: WebRequestType! 
  $orderBy: [OrderByInput!]
) {
  webRequestList(
    filter:{
      type: $type
      handled: $handled
      keyword: $keyword
    }
    pagination:{take: $take skip: $skip}
    orderBy: $orderBy
  ) {
    total
    items {
      times
      client {
        id
        type
      }
      firstFacility{
        id
        name
      }
      secondFacility{
        id
        name
      }
      firstCheckInDate
      firstStayDays
      secondCheckInDate
      secondStayDays
      type
      id 
      email
      handledAt 
      createdAt 
      name 
      tel 
      firstDate
      firstTime 
      secondDate 
      secondTime 
      clientId 
      wasExistingClient 
    }
  }
}
`;

export const WEB_REQUEST_EDIT_FACILITY  = `
query ($id: Int!) {
  webRequest(
    id: $id
  ) {
    id
    createdAt
    medias {
      mediaType
    }
    client { 
      picId 
      type
    }
    forVm 
    forVs 
    facilities 
    seasons 
    actionRemarks 
    purchaseRate { 
      name 
    }
    handledAt 
    firstDate 
    firstTime 
    secondDate 
    secondTime 
    inquiry 
    clientId 
    firstStayDays
    secondStayDays
    firstFacility { 
      name 
    }
    secondFacility {
      name
    }
    isCompany 
    lastName 
    firstName 
    lastKana
    firstKana 
    birthday 
    sex 
    postalCode 
    prefecture 
    locality 
    address1 
    address2 
    tel 
    telMobile 
    email
    remarks
  }
}
`;





