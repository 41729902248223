import actions from './actions'

const VacationLife = {
    state: {
        monthVacationLifeVal: '',
        vacationLifeListVacationLife:[],
        fileUrlVacationLife:'',
        yearVacationLife:null,
        monthVacationLife:'',
        vacationLifeYearMonthList:[]
    },
    getters: {
        vacationLifeListVacationLife(state){
            return state.vacationLifeListVacationLife
        },
        fileUrlVacationLife(state){
            return state.fileUrlVacationLife
        },
        yearVacationLife(state){
            return state.yearVacationLife
        },
        monthVacationLife(state){
            return state.monthVacationLife
        },
        vacationLifeYearMonthList(state){
            return state.vacationLifeYearMonthList
        },
    },
    mutations: {
        getVacationLifeListVacationLife(state,data){
            state.vacationLifeListVacationLife = data
            var arr = []
            data.forEach(e => {
                var obj = {
                    year: e.year,
                    month: e.month
                }
                arr.push(obj)
            });
            state.vacationLifeYearMonthList = arr
        },
        setFileUrlVacationLife(state,val){
            state.fileUrlVacationLife = val
        },
        setYearVacationLife(state,val){
            state.yearVacationLife = val
        },
        setMonthVacationLife(state,val){
            if(val) {
            state.monthVacationLifeVal = (val.name)
            }
        },
    },
    actions,
}

export default VacationLife;