export const GET_EMAIL_TEMPLATE_LIST = `
query {
    emailTemplateList { 
      orderId
      key 
      category1 
      category2 
      category3 
      subject 
    }
  }
`

export const UPDATE_EMAIL_TEMPLATE = `
mutation(
  $key: String!
  $subject: String!
  $body: String!
  $remarks: String
) {
  updateEmailTemplate(
    key: $key
    subject: $subject
    body: $body
    remarks: $remarks
  ) {
    __typename
  }
}
`

