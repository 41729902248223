export const ENUM_CONTRACT_PURCHASE_TYPE_LIST = `
  query {
    enumContractPurchaseTypeList {
      id
      name
    }
  }
`;

export const ENUM_CONTRACT_PURCHASE_TYPE_DETAIL_LIST = `
  query {
    enumContractPurchaseTypeDetailList {
      id
      name
    }
  }
`;

export const ENUM_STAFF_LIST = `
  query {
    staffList(crmRep: true showDisabled:true) {
      id
      name
    }
  }
`;
export const ENUM_CONTRACT_PURCHASE_REASON_LIST = `
  query {
    enumContractPurchaseReasonList {
      id
      name
    }
  }
`;
export const ENUM_CONTRACT_PURCHASE_PURPOSE_LIST = `
  query {
    enumContractPurchasePurposeList {
      id
      name
    }
  }
`;
export const ENUM_CONTRACT_APPLICATION_TYPE_LIST = `
  query {
    enumContractApplicationTypeList {
      id
      name
    }
  }
`;
export const ENUM_CONTRACT_PURCHASE_PAYMENT_METHOD_LIST = `
  query {
    enumContractPurchasePaymentMethodList {
      id
      name
    }
  }
`;
export const ENUM_CAMPAIGN_APPLY_RESPONSE_LIST = `
  query($clientId: Int!) {
    campaignApplyResponseList(filter: { clientId: $clientId }) {
      items {
        campaign {
          name
          id
        }
      }
    }
  }
`;
