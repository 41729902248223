import { CREATE_BOOKING_TICKETS } from '@/api/graphql/corporateTicket/new-ticket'
import { BOOKING_TICKET_CSV } from '@/api/graphql/bookingTicketType';
import { downloadFile } from '@/utils/download';
import { apolloClient } from '@/plugins/apollo'
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async createBookingTickets ({ dispatch, state, commit, rootState }) {
    const finishData = {
      ticketData: state.invitationTicket
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${CREATE_BOOKING_TICKETS}`,
        variables: finishData
      }).then(data => {
        commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
        commit('setLoadingOverlayHide', { root: true })
        dispatch('fetchBookingTicketTypeList')
        resolve(data)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CREATE_BOOKING_TICKETS, finishData, error.graphQLErrors)
        commit('setLoadingOverlayHide', { root: true })
        handlError(commit, error.graphQLErrors)
        reject(error)
      })
    })
  },

  async bookingTicketCsv({ commit }, id) {
    const finishData = {
      id
    }
    commit('setLoadingOverlayShow', { root: true })
    await apolloClient.mutate({
      mutation: gql`${BOOKING_TICKET_CSV}`,
      variables: finishData
    }).then(data => {
      commit('setLoadingOverlayHide', { root: true })
      const fileList = data.data.bookingTicketCsv.files
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          downloadFile(
            fileList[i].downloadUrl,
            fileList[i].fileName
          )
        }
      }
    }).catch(error => {
      setCopyErrorTextCommit(commit, BOOKING_TICKET_CSV, finishData, error.graphQLErrors)
      commit('setLoadingOverlayHide', { root: true })
      handlError(commit, error.graphQLErrors)
    })
  }
}
