import actions from './actions';

const customerDetail = {
  state: {
    list: {
      contractList: [],
      totalCmt: 0,
      bookingList: [],
      bookingTicketTypeList: [],
      clientMemberInfo: {},
      clientBasicInfo: {},
      clientChangeHistoryList: [],
      cancelWaitList: [],
      facilityParkingCancelWaitList: [],
      paginateClientComment: [],
      commentCustomerDetail: '',
    },
    listBrochureVsinterest: [],
    ListAdvertisingMeasures: [],
    arrCampaignApplyResponseListVsinterest: []
  },
  getters: {
    getCustomerDetailContractList(state) {
      return state.list.contractList;
    },
    getListBrochureVsinterest(state) {
      return state.listBrochureVsinterest;
    },
    getListAdvertisingMeasures(state) {
      return state.ListAdvertisingMeasures;
    },
    getArrCampaignApplyResponseListVsinterest(state) {
      return state.arrCampaignApplyResponseListVsinterest;
    },
    getCustomerDetailClientBasicInfo(state) {
      return state.list.clientBasicInfo;
    },
    getTotalCmt(state) {
      return state.list.totalCmt;
    },
  },
  mutations: {
    setCustomerDetailContractList(state, arr) {
      state.list.contractList = [...arr];
    },
    setCustomerDetailBookingList(state, arr) {
      state.list.bookingList = [...arr];
    },
    setCustomerDetailBookingTicketTypeList(state, arr) {
      state.list.bookingTicketTypeList = [...arr];
    },
    setCustomerDetailClientMemberInfo(state, obj) {
      state.list.clientMemberInfo = obj;
    },
    setCustomerDetailClientBasicInfo(state, obj) {
      state.list.clientBasicInfo = obj;
    },
    setCustomerDetailClientChangeHistoryList(state, arr) {
      state.list.clientChangeHistoryList = [...arr];
    },
    setCustomerDetailCancelWaitList(state, obj) {
      state.list.cancelWaitList = [...obj.cancelWaitListList.items];
      state.list.facilityParkingCancelWaitList = [
        ...obj.facilityParkingCancelWaitlistList.items,
      ];
    },
    setPaginateClientComment(state, data) {
      state.list.paginateClientComment = data;
    },
    setTotalCmt(state, data) {
      state.list.totalCmt = data;
    },
    setCommentCustomerDetail(state, val) {
      state.list.commentCustomerDetail = val;
    },
    setListBrochureVsinterest (state, data) {
      state.listBrochureVsinterest = data.listBrochureVsinterest
    },
    setListAdvertisingMeasures (state, data) {
      state.ListAdvertisingMeasures = data.ListAdvertisingMeasures
      state.arrCampaignApplyResponseListVsinterest = data.ListAdvertisingMeasures
    }
  },
  actions,
};

export default customerDetail;
