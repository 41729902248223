export const STAFF_GROUP_LIST = `
query {
  staffGroupList {
    id
    name
    staffs { # array,  .length = 人数
      profileImgUrl #String メンバーのプロフィール画像 URL
      name #String! メンバー名
    }
  }
}
`
