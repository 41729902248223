import { apolloClient } from '@/plugins/apollo';
import { 
  CREATE_CLIENT,
  SEARCH_CLIENT_LIST,
  POSSIBLY_DUPLICATED_CLIENT_LIST
} from '@/api/graphql/customer/CreateNewCustomer/index.js';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async searchClientList({ commit, state }) {
    const finishData = { 
      filter : {},
      pagination : {
        take: null,
        skip: null
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SEARCH_CLIENT_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(response => {
          commit('setListClient',response.data.searchClientList.items)
          // commit('setAlertSuccess', '新規顧客の成功を生み出す', {
          //   root: true,
          // });
          resolve(response.data.createClient);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  
  async actionCreateClient({ dispatch, commit, state }, finishData) {
    const variables = { data: finishData }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${CREATE_CLIENT}`,
          variables: variables,
        })
        .then(response => {
          commit('setAlertSuccess', '新規顧客を作成しました。', {
            root: true,
          });
          resolve(response.data.createClient);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CREATE_CLIENT, variables, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async actionPossiblyDuplicatedClientList({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${POSSIBLY_DUPLICATED_CLIENT_LIST}`,
          variables: finishData,
          // $isCompany: Boolean!
          // $firstName: String
          // $lastName: String
          // $companyName: String
          // $tel: String
          // $birthday: DateString
          // $postalCode: String
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.possiblyDuplicatedClientList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, POSSIBLY_DUPLICATED_CLIENT_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
