import { apolloClient } from '@/plugins/apollo'
import {
  EX_CHANGE_DEPOSITED_RIGHT
} from '@/api/graphql/contract/contractDetail/sp_ep/exChangeReservation/exChangeReservation.js'
// import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async exchangeDepositedRight ({ commit, state, rootState }) {
    console.log(state.exChangeReservation)
    const finishData = {
      data: {
        ...state.exChangeReservation,
        representativeEmail: state.exChangeReservation.representativeEmail || null,
        representativePostalCode: state.exChangeReservation.representativePostalCode || null,
      }
    }
    commit('setLoadingOverlayShow', { root: true })
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${EX_CHANGE_DEPOSITED_RIGHT}`,
          variables: finishData
        })
        .then(data => {
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, { root: true })
          commit('setLoadingOverlayHide', { root: true })

          resolve(data.data.exchangeDepositedRight)
        })
        .catch(error => {
          commit('setLoadingOverlayHide', { root: true })
          setCopyErrorTextCommit(commit, EX_CHANGE_DEPOSITED_RIGHT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  }
}
