import { apolloClient } from '@/plugins/apollo';
import { ENUM_PREFECTURE_LIST } from '@/api/graphql/enum/enumPrefectureList.js';
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async enumPrefectureList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${ENUM_PREFECTURE_LIST}`,
        })
        .then(data => {
          commit('setEnumPrefectureList', data.data.enumPrefectureList);
          resolve(data.data.enumPrefectureList);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ENUM_PREFECTURE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
