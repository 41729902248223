import { apolloClient } from '@/plugins/apollo';
import
{
  STAFF_LIST,
  CLIENT_BASIC_INFOR,
  FACILITY_LIST,
  ENUM_PRURCHASE_RATE_LIST,
  ENUM_SEASON_INTERST_LIST,
  ENUM_NEGOTIATION_EXIT_REASON_LIST,
  BROCHURES,
  VISITS,
  TRIALS,
  ONLINES,
  UPDATE_CLIENT,
  CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT,
  CLIENT_CHANGE_HISTORY_LIST,
  LIST_TABLE_END,
  LIST_SELECT_TABLE_END,
  CREATE_CAMPAIGN_APPLY_RESPONSE,
} from '@/api/graphql/customer/response/detail/prospectInformation/prospectInformation.js';
import router from '@/router';

import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionUpdateClient({ commit, state, rootState }, updateDataClient)
  {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      data: {
        doNotContact: updateDataClient.doNotContact,
        purchaseRateId: updateDataClient.purchaseRateId,
        furtherPurchaseRateId: updateDataClient.furtherPurchaseRateId,
        renewPurchaseRateId: updateDataClient.renewPurchaseRateId,
        negotiationExitReason: updateDataClient.negotiationExitReason,
        picId: updateDataClient.picId,
        lastResponseDate: updateDataClient.lastResponseDate,
        seasonsInterested: updateDataClient.seasonsInterested,
        facilitiesInterested: updateDataClient.facilitiesInterested,
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ UPDATE_CLIENT }`,
          variables: finishData,
        })
        .then(response =>
        {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.updateSuccess,
            { root: true },
          );
          resolve(response.data.updateClient);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async proStaffList({ commit, state })
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ STAFF_LIST }`,
        })
        .then(data =>
        {
          commit('setStaffList', data.data.staffList);
          resolve(data.data.staffList);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, STAFF_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async proClientBasicInfo({ commit, state }, clientId)
  {
    const finishData = {
      id: clientId,
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ CLIENT_BASIC_INFOR }`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data =>
        {
          commit('setClientBasicInfoPro', data.data.clientBasicInfo);
          resolve(data.data.clientBasicInfo);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CLIENT_BASIC_INFOR, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async enumPurchaseRateList({ commit, state })
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ ENUM_PRURCHASE_RATE_LIST }`,
        })
        .then(data =>
        {
          commit('setEnumPur', data.data.enumPurchaseRateList);
          resolve(data.data.enumPurchaseRateList);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ENUM_PRURCHASE_RATE_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async enumSeasonInterestList({ commit, state })
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ ENUM_SEASON_INTERST_LIST }`,
        })
        .then(data =>
        {
          commit('setEnumSeason', data.data.enumSeasonInterestList);
          resolve(data.data.enumSeasonInterestList);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ENUM_SEASON_INTERST_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async proClientChangeHistoryList({ commit, state }, { take, skip })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
        fieldName: '営業担当者',
      },
      pagination: {
        take,
        skip
      },
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ CLIENT_CHANGE_HISTORY_LIST }`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data =>
        {
          commit('setClientChange', data.data.clientChangeHistoryList.items);
          resolve(data.data.clientChangeHistoryList)
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CLIENT_CHANGE_HISTORY_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async proFacilityList({ commit, state })
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ FACILITY_LIST }`,
        })
        .then(data =>
        {
          commit('setFacilityList', data.data.facilityList);
          resolve(data.data.facilityList);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, FACILITY_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async enumNegotiationList({ commit, state })
  {
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ ENUM_NEGOTIATION_EXIT_REASON_LIST }`,
        })
        .then(data =>
        {
          commit('setEnumNego', data.data.enumNegotiationExitReasonList);
          resolve(data.data.enumNegotiationExitReasonList);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ENUM_NEGOTIATION_EXIT_REASON_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async proBrochures({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
        type: 'Brochure',
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ BROCHURES }`,
          variables: finishData,
          fetchPolicy: 'network-only',
        })
        .then(data =>
        {
          commit('setProBrochures', data.data.brochures.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, BROCHURES, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async proVisits({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
        type: 'Visit',
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ VISITS }`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data =>
        {
          commit('setProVisits', data.data.visits.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, VISITS, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async proOnlines({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
        type: 'OnlineCounseling',
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ ONLINES }`,
          variables: finishData,
          fetchPolicy: 'network-only'
        })
        .then(data =>
        {
          commit('setProOnlines', data.data.onlines.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, ONLINES, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async proTrials({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
        type: 'TrialStay',
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ TRIALS }`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data =>
        {
          commit('setProTrials', data.data.trials.items);
          resolve(data.data.trials.items)
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, TRIALS, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async proDataTableEnd({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ LIST_TABLE_END }`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data =>
        {
          commit('setDataTableEnd', data.data.campaignApplyResponseList.items);
          resolve(data.data.campaignApplyResponseList.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, LIST_TABLE_END, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async proListSelect({ commit, state })
  {
    const finishData = {
      filter: {
        clientId: parseInt(router.currentRoute.params.id),
      },
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .query({
          query: gql`${ LIST_SELECT_TABLE_END }`,
          variables: finishData,
        })
        .then(data =>
        {
          commit('setSelectTable', data.data.campaignApplyResponseList.items);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, LIST_SELECT_TABLE_END, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  // ===============update===========
  async getUpdateClient({ state, commit, rootState })
  {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
      data: {
        doNotContact: state.dataUpdate.doNotContact,
        purchaseRateId: state.dataUpdate.purchaseRateId,
        furtherPurchaseRateId: state.dataUpdate.furtherPurchaseRateId,
        renewPurchaseRateId: state.dataUpdate.renewPurchaseRateId,
        negotiationExitReason: state.dataUpdate.negotiationExitReason,
        picId: state.dataUpdate.picId,
        lastResponseDate: state.dataUpdate.lastResponseDate,
        seasonsInterested: state.dataUpdate.seasonsInterested,
        facilitiesInterested: state.dataUpdate.facilitiesInterested,
      }
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ UPDATE_CLIENT }`,
          variables: finishData,
        })
        .then(data =>
        {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.successSend,
            { root: true },
          );
          resolve(data);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, UPDATE_CLIENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  // ====================create===========
  async getCreateWebBrochure({ state, commit, rootState })
  {
    const finishData = {
      clientId: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT }`,
          variables: finishData,
        })
        .then(data =>
        {
          commit(
            'setAlertSuccess',
            rootState.alert.success.messages.successSend,
            { root: true },
          );
          resolve(data);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CREATE_WEB_BROCHURE_REQUEST_TO_CLIENT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },

  async createCampaignApplyResponsePro({ commit, state, rootState}, { data })
  {
    const finishData = data
    return new Promise((resolve, reject) =>
    {
      apolloClient
        .mutate({
          mutation: gql`${ CREATE_CAMPAIGN_APPLY_RESPONSE }`,
          variables: finishData,
        })
        .then(data =>
        {
          const newDataTableEnd = state.dataTableEnd;
          newDataTableEnd.unshift(data.data.createCampaignApplyResponse);
          commit('setDataTableEnd', newDataTableEnd);
          commit('setAlertSuccess', rootState.alert.success.messages.createSuccess, {
            root: true,
          });
          resolve(data.data.createCampaignApplyResponse);
        })
        .catch(error =>
        {
          setCopyErrorTextCommit(commit, CREATE_CAMPAIGN_APPLY_RESPONSE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
