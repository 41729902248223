import { apolloClient } from "@/plugins/apollo";
import { SEARCH_CLIENT_LIST_MEETING,CLIENT_ACTION_HISTORY_LIST_MEETING } from "@/api/graphql/myPage/Meeting"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getSearchClientListMeeting({ commit, state }, {take, skip, filter, orderBy}) {
        const finishData = {
            filter: {
                ...filter,
                // include the planned actions uat crm-222
                // uncomment below after confirming with Tokyu in the overflow comment
                lastActionIncludePlanned: true
            },
            orderBy,
            pagination: {
                take,
                skip
            }
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${SEARCH_CLIENT_LIST_MEETING}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                }).then(data => {
                    commit('getSearchClientListMeeting',data.data.searchClientList.items);
                    resolve(data.data.searchClientList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, SEARCH_CLIENT_LIST_MEETING, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async getClientActionHistoryListMeeting({ commit, state }) {
        const finishData = {}
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${CLIENT_ACTION_HISTORY_LIST_MEETING}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                }).then(data => {
                    commit('getClientActionHistoryList',data.data.enumClientActionMethodList);
                    resolve(data.data.enumClientActionMethodList)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CLIENT_ACTION_HISTORY_LIST_MEETING, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    }
}