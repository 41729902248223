import { apolloClient } from "@/plugins/apollo";
import {
    CLIENT_ACTION_HISTORY_LIST_MY_PAGE,
    CALENDAR_MY_PAGE,
    COMMENT_MY_PAGE,
    CREATE_CLIENT_COMMENT_MY_PAGE
} from "@/api/graphql/myPage/myPage/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag';

export default {
    async getClientActionHistoryListMyPage({ commit, state, getters }) {
        const finishData = {
            data: {
                status: "Todo",
                dateFrom: state.dateToMyPage,
                dateTo: state.dateToMyPage,
                staffId: getters.getUserId
            }
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${CLIENT_ACTION_HISTORY_LIST_MY_PAGE}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache',
                }).then(data => {
                    commit('setClientActionHistoryListMyPage', data.data.clientActionHistoryList.items)
                    resolve(data)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CLIENT_ACTION_HISTORY_LIST_MY_PAGE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    async getCalendarListMyPage({ commit, state, getters }) {
        const finishData = {
            data: {
                dateFrom: state.dateFromCalendarMyPage,
                dateTo: state.dateToCalendarMyPage,
                staffId: getters.getUserId
            }
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${CALENDAR_MY_PAGE}`,
                    variables: finishData
                }).then(data => {
                    commit('setCalendarListMyPage', data.data.clientActionHistoryList.items)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CALENDAR_MY_PAGE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    // Get comment parent
    async getCommetListMyPage({ commit, state, getters }) {
        commit('setLoadingOverlayShow');
        const finishData = {
            mentionedStaffId: getters.getUserId,
            pagination: {
                skip: state.skip,
                take: state.take
            }
        }
        return apolloClient
            .query({
                query: gql`${COMMENT_MY_PAGE}`,
                variables: finishData,
                fetchPolicy: 'no-cache',
            }).then(data => {
                const dataCommentParent = []
                data.data.clientCommentMentionList.items.forEach(async e => {
                    const obj = {
                        id: e.id,
                        clientId: e.clientComment.client.id,
                        content: {
                            ...e.clientComment,
                            name: e.clientComment.client.name,
                            type: e.clientComment.client.type,
                        }
                    }
                    dataCommentParent.push(obj)
                });
                commit('setCommetListMyPage', dataCommentParent)
                commit('setTotalComment', data.data.clientCommentMentionList.total)
                commit('setLoadingOverlayHide');
            })
            .catch(error => {
                setCopyErrorTextCommit(commit, COMMENT_MY_PAGE, finishData, error.graphQLErrors)
                handlError(commit, error.graphQLErrors);
                commit('setLoadingOverlayHide');
                throw error;
            });
    },

    // create comment
    async createClientCommentMyPage({ commit, state, dispatch }, id) {
        const finishData = {
            clientId: id,
            comment: state.commentMyPage
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .mutate({
                    mutation: gql`${CREATE_CLIENT_COMMENT_MY_PAGE}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache',
                }).then(data => {
                    dispatch('getCommetListMyPage')
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, CREATE_CLIENT_COMMENT_MY_PAGE, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },

    // get comment children by id
    async getCommentClientMyPage({ commit, state }, id) {
        const query = `
        query(
          $clientId: Int!
          $skip: Int
          $take: Int
        ) {
          paginateClientComment(
            clientId: $clientId
            pagination: {
              skip: $skip 
              take: $take
            }
          ) {
            items {
              commentBy {name, mentionName} 
              createdAt 
              comment 
              likes 
            }
          }
        }`
        const finishData = {
            clientId: parseInt(id),
            take: null,
            skip: null
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${query}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache',
                }).then(data => {
                    const commetListMyPageTemp = state.commetListMyPage
                    commetListMyPageTemp.find(e => {
                        if (e.clientId === parseInt(id)) {
                            e.content.comment_child = data.data.paginateClientComment.items.reverse()
                        }
                    });
                    commit('setCommetListMyPage', commetListMyPageTemp)
                    resolve(data.data.paginateClientComment.items)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, query, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}