import actions from './actions';

const CreateNewCustomer = {
  state: {
    listClient: []
  },
  getters: {
    listClient(state){
      return state.listClient
    }
  },
  mutations: {
    setListClient(state, data){
      state.listClient = data
    }
  },
  actions,
};

export default CreateNewCustomer;
