import { apolloClient } from "@/plugins/apollo";
import {
    WEBREQUEST_LIST_FACILITY,
    WEB_REQUEST_EDIT_FACILITY
} from "@/api/graphql/dashboard/webPageManage/facility/index.js"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
    async getWebrequestlistFacility({ commit, state }, variables) {
        let finishData
        if(variables.orderBy !== [] && variables.orderBy !== [null]) {
            finishData = {
                keyword: variables.filter.keyword,
                handled: variables.filter.handled,
                take: variables.pagination.take,
                skip: variables.pagination.skip,
                type: "TrialStay",
                orderBy: variables.orderBy
            }
        } else {
            finishData = {
                keyword: variables.filter.keyword,
                handled: variables.filter.handled,
                take: variables.pagination.take,
                skip: variables.pagination.skip,
                type: "TrialStay"
            }
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${WEBREQUEST_LIST_FACILITY}`,
                    variables: finishData,
                    fetchPolicy: 'network-only',
                }).then(data => {
                    commit('setWebrequestlistFacility',data.data.webRequestList.items)
                    commit('setTotalWebRequestList', data.data.webRequestList.total)
                    resolve(true)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, WEBREQUEST_LIST_FACILITY, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
    
    async getWebReqEditFacility({ commit, state },id) {
        const finishData = {
            id:parseInt(id),
        }
        return new Promise((resolve, reject) => {
            apolloClient
                .query({
                    query: gql`${WEB_REQUEST_EDIT_FACILITY}`,
                    variables: finishData,
                    fetchPolicy: 'no-cache'
                }).then(data => {
                    commit('setWebReqEditFacility',data.data.webRequest)
                    resolve(data.data.webRequest)
                })
                .catch(error => {
                    setCopyErrorTextCommit(commit, WEB_REQUEST_EDIT_FACILITY, finishData, error.graphQLErrors)
                    handlError(commit, error.graphQLErrors);
                    reject(error);
                });
        })
    },
}