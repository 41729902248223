import { apolloClient } from '@/plugins/apollo'
import { STAFF_LIST } from '@/api/graphql/manager/salesRepresentative/salesRepresentative.js'
// import router from "@/router"
import { handlError, setCopyErrorTextCommit } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  async salesRepresentativeList ({ commit, state }) {
    const finishData = {
      crmRep: state.crmRep,
      showDisabled: state.showDisabled
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${STAFF_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setStaffListSales', data.data.staffList)
          resolve(data.data.staffList)
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, STAFF_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors)
          reject(error)
        })
    })
  },
}
