import { apolloClient } from '@/plugins/apollo';
import {
  CONTRACT_TIME_INFORMATION,
  UPDATE_CONTRACT_TIME_INFORMATION,
  CAMPAIGN_APPLY_RESPONSE_LIST,
  SIGN_UP_BONUS_APPLICATION_LIST,
  DELETE_SIGN_UP_BONUS_APPLICATION,
  ADD_SIGN_UP_BONUS_APPLICATION,
  ADD_UPDATE_CONTRACT,
  SIGN_UP_BONUS_LIST,
  DELETE_CAMPAIGN_APPLY_RESPONSE,
  CONTRACT_CAMPAIGN_LIST,
  UPDATE_CONTRACT_CAMPAIGN_LIST
} from '@/api/graphql/contract/contractDetail/sp_ep/ContractTimeInformation/index.js';
import router from '@/router';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async actionContractTimeInformation({ commit, state, rootState }) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id),
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CONTRACT_TIME_INFORMATION}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setContractTimeInformation', data.data.contract);
          resolve(data.data.contract);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CONTRACT_TIME_INFORMATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionUpdateContractTimeInformation({ commit, state }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${UPDATE_CONTRACT_TIME_INFORMATION}`,
          variables: {
            contractId: parseInt(router.currentRoute.params.id),
            data: finishData,
          },
        })
        .then(data => {
          resolve(data.data.contract);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, UPDATE_CONTRACT_TIME_INFORMATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  actionGetContractCampaign ({ commit, state }) {
    const finishData = {
      id: parseInt(router.currentRoute.params.id)
    }
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: gql`${CONTRACT_CAMPAIGN_LIST}`,
        variables: finishData,
        fetchPolicy: 'no-cache'
      }).then(result => {
        resolve(result.data.contract.campaign)
      }).catch(error => {
        setCopyErrorTextCommit(commit, CONTRACT_CAMPAIGN_LIST, finishData, error.graphQLErrors)
        handlError(commit, error.graphQLErrors);
        reject(error);
      })
    })
  },

  actionUpdateContractCampaign ({ commit, state, rootState }, finishData) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: gql`${UPDATE_CONTRACT_CAMPAIGN_LIST}`,
        variables: finishData,
      }).then(result => {
        commit('setAlertSuccess', rootState.alert.success.messages.updateSuccess, { root: true })
        resolve(result.data.updateContract)
      }).catch(error => {
        setCopyErrorTextCommit(commit, UPDATE_CONTRACT_CAMPAIGN_LIST, finishData, error.graphQLErrors)
        handlError(commit, error.graphQLErrors);
        reject(error);
      })
    })
  },

  async actionCampaignApplyResponseList({ commit, state }, clientId) {
    const finishData = {
      filter: {
        clientId: clientId,
      },
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CAMPAIGN_APPLY_RESPONSE_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          // commit(
          //   'setCampaignApplyResponseList',
          //   data.data.campaignApplyResponseList.items,
          // );
          resolve(data.data.campaignApplyResponseList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CAMPAIGN_APPLY_RESPONSE_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionSignupBonusApplicationList({ commit, state }, contractId) {
    const finishData = {
      filter: {
        contractId: contractId,
      },
      pagination: {
        take: 500,
        skip: 0
      }
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SIGN_UP_BONUS_APPLICATION_LIST}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          // commit(
          //   'setSignupBonusApplicationList',
          //   data.data.signupBonusApplicationList.items,
          // );
          resolve(data.data.signupBonusApplicationList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SIGN_UP_BONUS_APPLICATION_LIST, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionDeleteSignUpBonusApplication({ commit, state }, id) {
    const finishData = {
      id: id,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_SIGN_UP_BONUS_APPLICATION}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
          resolve(data.data.deleteSignupBonusApplication.id);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_SIGN_UP_BONUS_APPLICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionAddSignupBonusApplication({ commit, state }, data) {
    const finishData = {
      contractId: data.contractId,
      signupBonusId: data.signupBonusId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ADD_SIGN_UP_BONUS_APPLICATION}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setAlertSuccess', '作成しました。', {
            root: true,
          });
          resolve(data.data.deleteSignupBonusApplication);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ADD_SIGN_UP_BONUS_APPLICATION, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionAddContract({ commit, state }, data) {
    const finishData = {
      contractId: data.contractId,
      campaignId: data.campaignId,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${ADD_UPDATE_CONTRACT}`,
          variables: finishData,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          commit('setAlertSuccess', '作成しました。', {
            root: true,
          });
          resolve(data.data.updateContract);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, ADD_UPDATE_CONTRACT, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionDeleteContract({ commit, state }, id) {
    const finishData = {
      id: id,
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`${DELETE_CAMPAIGN_APPLY_RESPONSE}`,
          variables: finishData,
        })
        .then(data => {
          commit('setAlertSuccess', '削除しました。', {
            root: true,
          });
          resolve(data.data.deleteCampaignApplyResponse.id);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, DELETE_CAMPAIGN_APPLY_RESPONSE, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
  async actionEnumSignUpBonusList({ commit, state }) {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${SIGN_UP_BONUS_LIST}`,
        })
        .then(data => {
          // commit('setAlertSuccess', '作成しました。', {
          //   root: true,
          // });
          resolve(data.data.signupBonusList.items);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, SIGN_UP_BONUS_LIST, null, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
