import actions from "./actions";

const FacilitySetting = {
  state: {
    facilityList: [],
    facilityDs: [],
    dataUpdate: {}
  },
  getters: {
    getFacilitySetting(state) {
      return state.facilityList
    },
    getFacilityDs(state) {
      return state.facilityDs
    }
  },
  mutations: {
    setFacilitySetting(state, payload) {
      state.facilityList = payload
    },
    setFacilityDs(state, payload) {
      state.facilityDs = payload
    },
    setDataUpdateFacilitySetting(state, payload) {
      state.dataUpdate = payload.dataUpdate;
    },
  },
  actions,
}

export default FacilitySetting;
