import actions from './actions';

const ActivitiesList = {
  state: {
    clientActionHistoryListActivities:[],
    clientActionHistoryListActivitiesHistory:[],
    checkChangeCreateActivityHistoryRegistration: false,
  },
  getters: {
    // return clientActionHistoryListActivities
    clientActionHistoryListActivities(state){
      return state.clientActionHistoryListActivities
    },
    // return clientActionHistoryListActivitiesHistory
    clientActionHistoryListActivitiesHistory(state){
      return state.clientActionHistoryListActivitiesHistory
    },
    getChangeCreateActivityHistoryRegistration(state){
      return state.checkChangeCreateActivityHistoryRegistration
    }
  },
  mutations: {
    // set clientActionHistoryListActivities
    setClientActionHistoryListActivities(state,data){
      state.clientActionHistoryListActivities = data
    },
    // set clientActionHistoryListActivitiesHistory
    setClientActionHistoryListActivitiesHistory(state,data){
      state.clientActionHistoryListActivitiesHistory = data
    },
    setChangeCreateActivityHistoryRegistration(state, data){
      state.checkChangeCreateActivityHistoryRegistration = !state.checkChangeCreateActivityHistoryRegistration
    }
  },
  actions,
};

export default ActivitiesList;
