import actions from './actions';

const signupBonus = {
  state: {
    signupBonusVsPromoCode: null,
  },
  getters: {
    signupBonusVsPromoCode(state) {
      return state.signupBonusVsPromoCode
    }
  },
  mutations: {
    setSignupBonusVsPromoCode(state, payload) {
      state.signupBonusVsPromoCode = payload
    }
  },
  actions
}

export default signupBonus